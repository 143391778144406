import { faCogs, faUsers, faThLarge, faCrow, faVial, faVials, faFileExport, faList, faPlusCircle, 
		faFingerprint, faFileImport, faTags, faBoxes, faClipboardCheck } from '@fortawesome/free-solid-svg-icons'
import { faDna, faEyeDropper, faFilter } from '@fortawesome/free-solid-svg-icons'
import { Roles } from '../config/Roles'
import { Permissions } from '../config/Permissions'

export const PrivateNavbarOptionsConfig = [
	{
		path: '/admin',
		title: 'header.navigation-titles.admin',
		icon: faCogs,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		children: [
			{
				path: '/users',
				title: 'header.navigation-titles.users',
				icon: faUsers,
				roles: [
					Roles.ROOT,
					Roles.ADMIN
				]
			},
			{
				path: '/workgroups',
				title: 'header.navigation-titles.workgroups',
				icon: faThLarge,
				roles: [
					Roles.ROOT,
					Roles.ADMIN
				]
			},
			{
				path: '/activities',
				title: 'header.navigation-titles.activities',
				icon: faFingerprint,
				roles: [
					Roles.ROOT
				]
			}
		]
	},
	{
		path: '/collections',
		title: 'header.navigation-titles.collections',
		icon: faCrow,
		roles: [
			Roles.ROOT,
			Roles.ADMIN,
			Roles.COLLECT
		],
		children: [
			{
				path: '/collections',
				title: 'header.navigation-titles.collections-list',
				icon: faList,
				roles: [
					Roles.ROOT,
					Roles.ADMIN,
					Roles.COLLECT
				]
			},
			{
				path: '/collections/create',
				title: 'header.navigation-titles.collections-create',
				icon: faPlusCircle,
				roles: [
					Roles.ROOT,
					Roles.ADMIN,
					Roles.COLLECT
				]
			},
			{
				path: '/collections/import',
				title: 'header.navigation-titles.collections-import',
				icon: faFileImport,
				permissions: [
					Permissions.COLLECTION_IMPORT
				]
			},
			{
				path: '/collections/export',
				title: 'header.navigation-titles.collections-export',
				icon: faFileExport,
				permissions: [
					Permissions.COLLECTION_EXPORT
				]
			}
		]
	},
	{
		path: '/analysis',
		title: 'header.navigation-titles.analysis',
		icon: faVial,
		roles: [
			Roles.ROOT,
			Roles.ADMIN,
			Roles.ANALYSIS
		],
		children: [
			{
				path: '/analysis/collectionsamples',
				title: 'header.navigation-titles.collection-samples',
				icon: faVials,
				roles: [
					Roles.ROOT,
					Roles.ADMIN,
					Roles.ANALYSIS
				]
			},
			{
				path: '/analysis/dissociations',
				title: 'header.navigation-titles.laboratory-dissociations',
				icon: faEyeDropper,
				roles: [
					Roles.ROOT,
					Roles.ADMIN,
					Roles.ANALYSIS
				]
			},
			{
				path: '/analysis/extractions',
				title: 'header.navigation-titles.laboratory-extractions',
				icon: faFilter,
				roles: [
					Roles.ROOT,
					Roles.ADMIN,
					Roles.ANALYSIS
				]
			},
			{
				path: '/analysis/syntheses',
				title: 'header.navigation-titles.laboratory-syntheses',
				icon: faDna,
				roles: [
					Roles.ROOT,
					Roles.ADMIN,
					Roles.ANALYSIS
				]
			},
			{
				path: '/analysis/tests',
				title: 'header.navigation-titles.laboratory-tests',
				icon: faClipboardCheck,
				roles: [
					Roles.ROOT,
					Roles.ADMIN,
					Roles.ANALYSIS
				]
			},
			{
				path: '/analysis/boxes',
				title: 'header.navigation-titles.storage-boxes',
				icon: faBoxes,
				roles: [
					Roles.ROOT,
					Roles.ADMIN,
					Roles.ANALYSIS
				]
			},
			{
				path: '/analysis/tags',
				title: 'header.navigation-titles.laboratory-tags',
				icon: faTags,
				roles: [
					Roles.ROOT,
					Roles.ADMIN,
					Roles.ANALYSIS
				]
			}
			
		]
	}
]