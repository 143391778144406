import * as Yup from "yup";
import moment from "moment";

import CollectionConfig from "../config/CollectionConfig";

const MaterialSamples = CollectionConfig.Samples;

const DefaultLabelIdPrefix = "D-";

const Reagents = [
	"VTM",
	"DMEM",
	"PBS",
	"OTHER"
]

const GeneralFormValidationRules = {
	id: Yup.string()
		.nullable()
		.default(() => ""),
	reagent: Yup.string()
		.oneOf([""].concat(Reagents), 'form.validation.string.enum')
		.required()
		.default(() => ""),
	collectionId: Yup.string()
		.required()
		.default(() => ""),
	organismId: Yup.string()
		.default(() => ""),
	scientificName: Yup.string()
		.default(() => ""),
	sex: Yup.string()
		.default(() => ""),
	county: Yup.string()
		.default(() => ""),
	stateProvince: Yup.string()
		.default(() => ""),
	materialSample: Yup.string()
		.oneOf([""].concat(MaterialSamples), 'form.validation.string.enum')
		.required()
		.default(() => ""),
	labelId: Yup.string()
		.max(100, 'laboratorydissociations.save-dissociation-modal.form.labelId.maximumlength')
		.required()
		.default(() => ""),
	workgroupId: Yup.string()
		.required()
		.default(() => ""),
	dissociatedById: Yup.string()
		.required()
		.default(() => ""),
	dateDissociated: Yup.date()
		.typeError('laboratorydissociations.save-dissociation-modal.form.dateDissociated.validation.format')
		.max(new Date(), "laboratorydissociations.save-dissociation-modal.form.dateDissociated.validation.future")
		.required()
		.default(() => ""),
		//.default(() => moment(new Date()).format("YYYY-MM-DD")),
	storageBoxId: Yup.string()
		//.required()
		.default(() => ""),
	slotCol: Yup.string()
			.default(() => ""),
	slotRow: Yup.string()
			.default(() => ""),
	ranOut: Yup.boolean()
    	.default(() => false),
}

const SaveMultiplesFormValidationRules = {
	labelIdPrefix: Yup.string()
		.max(20, 'collectionsamples.save-multiple-laboratory-dissociations-modal.form.labelIdPrefix.validation.maximumlength')
		.default(() => DefaultLabelIdPrefix),
	reagent: Yup.string()
		.oneOf([""].concat(Reagents), 'form.validation.string.enum')
		.required()
		.default(() => ""),
	workgroupId: Yup.string()
		.required()
		.default(() => ""),
	dissociatedById: Yup.string()
		.required()
		.default(() => ""),
	dateDissociated: Yup.date()
		.typeError('laboratorydissociations.save-dissociation-modal.form.dateDissociated.validation.format')
		.max(new Date(), "laboratorydissociations.save-dissociation-modal.form.dateDissociated.validation.future")
		.required()
		.default(() => ""),
		//.default(() => moment(new Date()).format("YYYY-MM-DD")),
	storageBoxId: Yup.string()
		//.required()
		.default(() => "")
}

const FilterFormValidationRules = {
	laboratoryId: Yup.string()
		.default(() => ""),
	storageBoxId: Yup.string()
		.default(() => ""),
	filter: Yup.string()
		.default(() => ""),
	filteredColumn: Yup.string()
		.default(() => "labelId"),
	start: Yup.string()
		.default(() => ""),
	end: Yup.string()
		.default(() => ""),
	materialSamples: Yup.array()
		.default(() => [])
}

const AdvancedFilterFormFields = {
	storageBoxId: true,
	start: true,
	end : true
}

const TissueDissociationConfig = { MaterialSamples, Reagents, DefaultLabelIdPrefix, GeneralFormValidationRules, SaveMultiplesFormValidationRules, FilterFormValidationRules, AdvancedFilterFormFields};

export default TissueDissociationConfig;