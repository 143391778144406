import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

import previrLogo from '../images/previr-logo-vazado-azul-mcti.png';

const AssignWorkgroupPage = props => {

const { t } = useTranslation();

 return (
          <Container fluid className="text-center pt-2 pb-2">
            	<Row>	
            		<Col md={12} className="mb-3"><Image width="150" src={previrLogo} alt={t('app.logo')}/></Col>
            		<Col md={12} className="mb-3"><h1 className="text-warning"><FontAwesomeIcon icon={faExclamationTriangle} /></h1></Col>
                    <Col md={12} className="mb-3"><h1 className="h3 fw-normal"><Trans i18nKey="select-workgroup.no-workgroup-found.header">No workgroup Found!!!</Trans></h1></Col>
					<Col md={12} className="mb-3"><Trans i18nKey="select-workgroup.no-workgroup-found.details-1">Instructions Part 1</Trans></Col>
					<Col md={12} className="mb-3"><Trans i18nKey="select-workgroup.no-workgroup-found.details-2">Instructions Part 2</Trans></Col>
              	    <Col md={12}><Button as={Link} variant="link" to="/login"><Trans i18nKey="select-workgroup.back-to-login">Back to Login Page</Trans></Button></Col>
              	</Row>		
          </Container>
    );
}

export default AssignWorkgroupPage;
