import React,  { useState, useEffect, Fragment } from 'react';
import { Link, useLocation, Route, useNavigate, Navigate, useOutletContext } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVial, faEye, faCheckCircle, faTimesCircle, faPlusCircle, faArrowCircleLeft, faEllipsisV } from '@fortawesome/free-solid-svg-icons'

import { faDna, faEyeDropper, faClipboardCheck, faFilter } from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Dropdown from 'react-bootstrap/Dropdown'
import ButtonGroup from 'react-bootstrap/ButtonGroup'

import Loader from "../components/Loader";
import ConfirmationDialogModal from "../components/ConfirmationDialogModal";
import Alert from 'react-bootstrap/Alert';
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Spinner from 'react-bootstrap/Spinner'

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";

import { isArrayWithLength, numToColumn } from "../helpers/commons";

import FormLabel from 'react-bootstrap/FormLabel'
import FormControl from 'react-bootstrap/FormControl'
import FormControlErrors from "../components/FormControlErrors";

import { AuthError, ValidationError } from "../helpers/custom-errors";

import SaveLaboratorySampleModal from "../components/SaveLaboratorySampleModal";
import DisplayCollectionSampleModal from "../components/DisplayCollectionSampleModal";
import SaveNucleicAcidExtractionModal from "../components/SaveNucleicAcidExtractionModal";
import SaveTissueDissociationModal from "../components/SaveTissueDissociationModal";
import SaveCDNASynthesisModal from "../components/SaveCDNASynthesisModal";
import SaveLaboratoryTestModal from "../components/SaveLaboratoryTestModal";


const storageBoxActionModals = [
	  {
        key: 'LABORATORY_SAMPLE',
        component: SaveLaboratorySampleModal,
		size: 'lg',
      },
	  {
        key: 'TISSUE_DISSOCIATION',
        component: SaveTissueDissociationModal,
		size: 'lg',
      },
	  {
        key: 'NUCLEIC_ACID_EXTRACTION',
        component: SaveNucleicAcidExtractionModal,
		size: 'lg',

      },
	  {
        key: 'CDNA_SYNTHESIS',
        component: SaveCDNASynthesisModal,
		size: 'lg',
      },
	  {
        key: 'LABORATORY_TEST',
        component: SaveLaboratoryTestModal,
		size: 'lg',
      }
    ];

const StorageBoxItemTypes = [
	  {
        key: 'sample',
        type: 'LABORATORY_SAMPLE',
		icon: faVial
      }, 
      {
        key: 'dissociation',
        type: 'TISSUE_DISSOCIATION',
		icon: faEyeDropper
      },
      {
        key: 'extraction',
        type: 'NUCLEIC_ACID_EXTRACTION',
		icon: faFilter
      },
      {
        key: 'synthesis',
        type: 'CDNA_SYNTHESIS',
		icon: faDna
      },
      {
        key: 'test',
        type: 'LABORATORY_TEST',
		icon: faClipboardCheck
      },
    ];

const renderTooltip = (text) => (
  		<Tooltip className="form-control-helper-tooltip" >
    		{text}
  		</Tooltip>
	);

const MissingSlotItem = props => {
	const [unavailableSlotsMap, setUnavailableSlotsMap] = useState(new Map());
	const [selectedSlot, setSelectedSlot] = useState("");
	const [_error, _setError] = useState(null);
	const [isOrganizing, setIsOrganizing] = useState(false);
		
	const { t } = useTranslation();
	
	useEffect(() => {
		let isMounted = true; 		
		
		if (isMounted) 
			setUnavailableSlotsMap(new Map(props.unavailableSlots
						.map((s) => [ s.row+"-"+s.col, s])));
			 
		 return () => { isMounted = false };
		  
	}, [props.unavailableSlots]);
	
	if (!props.box || !props.item || !props.unavailableSlots)
		return null;
	
	const handleSlotChange = (e) => {
        _setError(null);
		setSelectedSlot(e.target.value);
    }

	const handleOrganizeItem = (e) => {
        _setError(null);

		if (!selectedSlot) {
			_setError("storageboxes.items.alert.slot-required");
			return false;
		}
		
		setIsOrganizing(true);
		let slotInfo = selectedSlot.split("-");
		
		props.onItemUpdate(Object.assign({}, props.item, {slotRow: slotInfo[0], slotCol: slotInfo[1]}))
			.then((response) => {
				props.onSuccess(response.success);
    		})
    		.catch(error => { 
    							
				if (error instanceof AuthError) {		
					props.onError(error);
		     	} else if (error instanceof ValidationError) {		
          						
				 	log.info("Save Storage Box Item Attempt Failed: ", error.message);
          						
				 	props.onError(error);		
								
					//TODO: How to detail it?
					if (error.detail) {            						
    	              	for (let key of Object.keys(error.detail)) {
    	              		let errorMsgs = error.detail[key];
    	              			errorMsgs.forEach((message) => {
    	              				if(key === "slotCol" || key === "slotRow" )
										_setError(message);
    	              			});
    	              		}
    	              	}
          						
          	 		} else {
          				log.error("Error Saving Laboratory Sample: ", error.message);
          				
						props.onError(error);
          	 		}	
    								
    		  })
			.finally(() => {
		 		setIsOrganizing(false);
			});

    }	
	
	return (
		<Row className="mb-1">
			<Col md={4}>
				<FormLabel size="sm">{t("models.storage-box-items.enums.type."+props.item.type) + " - " + props.item.labelId}</FormLabel>
			</Col>
			<Col md="auto">
				<FormControl as="select" size="sm" onChange={handleSlotChange} value={selectedSlot} style={!(_error == null) ? {borderColor:"red"} : {}}>
					<option value={""}>-</option>
					{Array.apply(null, {length: Number(props.box.cols)})
						.map((_, col) => { 
							return (
								<Fragment key={col}>
									{ Array.apply(null, {length: Number(props.box.rows)})
										.map((_, row) => { 
								
											let unavailableSlot = unavailableSlotsMap.get((row+1)+"-"+(col+1));
								
											if (!unavailableSlot) {
												return <option key={(row+1)+"-"+(col+1)} value={(row+1)+"-"+(col+1)}>{(row+1)+"-"+numToColumn((col+1))}</option>
											} else {
												return null
											}
								
									})}
								</Fragment>
							)
						})
					}
				</FormControl>
			</Col>
			<Col md="auto">
				<OverlayTrigger overlay={renderTooltip(t("storageboxes.items.alert.organize-button"))} placement="right" rootClose={true}>
					<Button size="sm"variant="link" onClick={handleOrganizeItem} disabled={isOrganizing} > 
						{ isOrganizing 
							? <Spinner animation="border" size="sm" /> 
							: <FontAwesomeIcon className="text-success" icon={faCheckCircle} size="lg"/>
						}
					</Button>
				</OverlayTrigger>
			</Col>
			<Col md="auto">
				<OverlayTrigger overlay={renderTooltip(t("storageboxes.items.alert.remove-button"))} placement="right" rootClose={true}>
					<Button size="sm" variant="link" onClick={() => {props.onItemRemoval(props.item)}} > 
		            	<FontAwesomeIcon className="text-danger" icon={faTimesCircle} size="lg"/>
					</Button>
				</OverlayTrigger>
			</Col>
			<Col md="auto">
				<OverlayTrigger overlay={renderTooltip(t("storageboxes.items.alert.view-button"))} placement="right" rootClose={true}>
					<Button size="sm" variant="link" onClick={()=>{props.onCollectionDisplay(props.item)}} > 
		            	<FontAwesomeIcon className="text-info" icon={faEye} size="lg"/>
					</Button>
				</OverlayTrigger>
			</Col>
			<Col md="auto">
				<FormControlErrors block={true} errors={_error} />
			</Col>
		</Row>
	)

}


const StorageBox = props => {
	const [itemsMap, setItemsMap] = useState(new Map());
	
	const { t } = useTranslation();
	
	useEffect(() => {
		let isMounted = true; 		
		
		if (isMounted) 
			setItemsMap(new Map(props.items
						.map((i) => [ i.slotRow+"-"+i.slotCol, i])));
			 
		 return () => { isMounted = false };
		  
	}, [props.items]);
	
	if (!props.box || !props.items)
		return null;
		
	return (
		<Table bordered responsive>
		   <thead>
				<tr>
			 		<th scope="col" className="align-middle text-center">#</th>
					{Array.apply(null, {length: Number(props.box.cols)})
						.map((_, col) => { 
							return <th key={col} scope="col" className="align-middle text-center">{numToColumn((col+1))}</th>
					})}
				</tr>
			</thead>
			<tbody>	
			{Array.apply(null, {length: Number(props.box.rows)})
				.map((_, row) => { 
				   return (
					<tr key={row}>
						<th scope="row" className="align-middle text-center">{(row+1)}</th>
						{ Array.apply(null, {length: Number(props.box.cols)})
							.map((_, col) => { 
								
								let item = itemsMap.get((row+1)+"-"+(col+1));
								
								if (item) {
									return <td key={col} className="align-middle text-center">
										<ButtonGroup>
											<OverlayTrigger overlay={renderTooltip("["+t("models.storage-box-items.enums.type." + item.type) + "] " + item.labelId)} placement="right" rootClose={true}>
												<Button className="pr-1" variant="link" onClick={()=>{props.onItemSave(Object.assign({},{type: item.type, id: item.id, slotRow: row+1, slotCol: col+1, workgroupId: props.box.workgroupId, storageBoxId: props.box.id }));}} > 
													<FontAwesomeIcon className="text-danger" icon={faVial} size="lg"/>
												</Button>
											</OverlayTrigger>
											<Dropdown drop="left" className="pl-1">
												<Dropdown.Toggle as={Button} className="p-0" bsPrefix="remove-split-button" variant="link" id="dropdown-actions" size="sm">
													<FontAwesomeIcon icon={faEllipsisV} />
												</Dropdown.Toggle>
		       									<Dropdown.Menu>
		       										<Dropdown.Item onClick={() => {props.onItemRemoval(item)}}><Trans i18nKey={"storageboxes.items.remove-button"} /></Dropdown.Item>
													<Dropdown.Item onClick={() => {props.onCollectionDisplay(item)}}><Trans i18nKey={"storageboxes.items.view-button"} /></Dropdown.Item>
		       									</Dropdown.Menu>
		    							   </Dropdown>
										</ButtonGroup>
									</td> 
								} else {
									return <td key={col} className="align-middle text-center">
										{/*<OverlayTrigger overlay={renderTooltip(t("storageboxes.items.add-button"))} placement="right" rootClose={true}>
										<Button variant="link" onClick={()=>{props.onItemSave(Object.assign({},{slotRow: row+1, slotCol: col+1, workgroupId: props.box.workgroupId, storageBoxId: props.box.id }));}} > 
		            						<FontAwesomeIcon className="text-success" icon={faPlusCircle} size="lg"/>
										</Button>
										</OverlayTrigger>*/}
										<Dropdown drop="left" className="pl-1">
												<Dropdown.Toggle as={Button} className="p-0" bsPrefix="remove-split-button" variant="link" id="dropdown-actions" size="sm">
													<OverlayTrigger overlay={renderTooltip(t("storageboxes.items.add-button"))} placement="right" rootClose={true}>
														<FontAwesomeIcon className="text-success" icon={faPlusCircle} size="lg" />
													</OverlayTrigger>
												</Dropdown.Toggle>
		       									<Dropdown.Menu>
													{ StorageBoxItemTypes.map((item, index) => {
														return (
															<Dropdown.Item
																key={item.key}
																onClick={()=>{props.onItemSave(Object.assign({},{type: item.type, slotRow: row+1, slotCol: col+1, workgroupId: props.box.workgroupId, storageBoxId: props.box.id }));}}>
																	{/*<FontAwesomeIcon icon={item.icon}/> */}<Trans i18nKey={"storageboxes.items.add-"+item.key+"-button"} />
															</Dropdown.Item>
		    												
	      												)
	      											})}
		       									</Dropdown.Menu>
		    							</Dropdown>
									</td>
								}
					
						
								
								
						})}
					</tr>
				)
			})
		}
		</tbody>
		</Table>
	)
}

const StorageBoxItems = props => {

	const [isLoading, setIsLoading] = useState(true);
	const [storageBoxItems, setStorageBoxItems] = useState(null);
	const [removeItemModalShow, setRemoveItemModalShow] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [selectedStorageBoxSlot, setSelectedStorageBoxSlot] = useState(null);
	const [displayCollectionSampleModalShow, setDisplayCollectionSampleModalShow] = useState(false);
	const [selectedCollectionSample, setSelectedCollectionSample] = useState(null);
	
	const [storageBoxActionModalsShowMap, setStorageBoxActionModalsShowMap] = useState(new Map());
	
	const { t } = useTranslation();
	const config = useConfig();
	
	const location = useLocation();
	
	let context = useOutletContext();
	
	let navigate = useNavigate();
		
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
			
		//Initializing Action Modals Show Status
		resetModalsVisibility();
		
		if (location.state && location.state.item) {
			listStorageBoxItems(location.state.item.id)
				.catch(error => { 
					log.error("Error Loading Initial StorageBox Items List: ", error.message);
					context.onError(error);
				})
		 		.finally(() => {
		 			if (isMounted) setIsLoading(false);
		 		});
		 
			return () => { isMounted = false };
		}
		
	}, []);


  const resetModalsVisibility = () => {
	let tempMap = new Map();
	storageBoxActionModals.forEach((item, index) => {
		tempMap.set(item.key, false);
	});
	setStorageBoxActionModalsShowMap(tempMap);
  }

  const handleItemSaved = (success) => {
	  handleActionModalHide();
	  listStorageBoxItems(location.state.item.id);
	  context.onSuccess(success);
   }

  const handleActionModalHide = () => {
	  resetModalsVisibility();
	  setSelectedStorageBoxSlot(null);   
  }

  const showActionModal = (action) => { 
	  let tempMap = storageBoxActionModalsShowMap;
	  tempMap.set(action, true);
	  setStorageBoxActionModalsShowMap(tempMap);
   }
  
  const confirmStorageBoxItemRemoval = (sample) => {
	  setSelectedItem(sample);
	  setRemoveItemModalShow(true);
  }

	
   const updateStorageBoxItem = (item) =>
		new Promise((resolve, reject) => {
			API.updateStorageBoxItem(location.state.item.id, item, config.csrfToken ).then(response => {
				resolve(response);
			}).catch(error => reject(error));		
		});
		     
  const removeStorageBoxItem = (item) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		setRemoveItemModalShow(false);
		
		API.removeStorageBoxItem(location.state.item.id, item.type, item.id, config.csrfToken ).then(response => {
			context.onSuccess(response.success)
			listStorageBoxItems(location.state.item.id);
		}).catch(error => { 	
			log.error("Error Removing StorageBox Item: ", error.message);
			context.onError(error);		
		});
		
  }
	



  const handleSaveStorageBoxItemModalShow = (item) => {

	  setSelectedStorageBoxSlot(item);
	  if (item && item.type) showActionModal(item.type);
		
  }

  const handleDisplayCollection = (item) => {
	  setSelectedCollectionSample(item);
	  setDisplayCollectionSampleModalShow(true);
  }

  const handleDisplayCollectionModalHide = () => {
  	  setSelectedCollectionSample(null); 
	  setDisplayCollectionSampleModalShow(false);
   }
	
  const listStorageBoxItems = (id) =>
	new Promise((resolve, reject) => {
		API.getStorageBoxItems(id).then(response => {
			setStorageBoxItems(response.list);
			resolve(response);
		}).catch(error => { 
			reject(error);
		});
	});
	
	if (!location.state && !location.state.item) {
		return (<Navigate  to="/analysis/boxes" />)
	}
	
	if (isLoading) 
		return <Loader />
	
	if (!storageBoxItems) 
		return null;
		
	let missingSlotItems = storageBoxItems.filter((s) => {return (!s.slotCol || !s.slotRow || s.slotCol > location.state.item.cols || s.slotRow > location.state.item.rows) });
	let definedSlotsItems = storageBoxItems.filter((s) => {return (s.slotCol && s.slotRow && s.slotCol <= location.state.item.cols && s.slotRow <= location.state.item.rows) });
		
	return (
		<Container >
			{(selectedItem) && <ConfirmationDialogModal
				item={selectedItem}
				show={removeItemModalShow}
        		onHide={() => setRemoveItemModalShow(false)}
				size="lg"
				title={t("storageboxes.remove-item-confirmation-modal.title")}
				bodyText={t("storageboxes.remove-item-confirmation-modal.body", {labelId: selectedItem.labelId, type: t("models.storage-box-items.enums.type."+selectedItem.type) })}
				confirmText={t("storageboxes.remove-item-confirmation-modal.confirm")}
				cancelText={t("storageboxes.remove-item-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={removeStorageBoxItem}
			/>}
			{(selectedCollectionSample && displayCollectionSampleModalShow) && <DisplayCollectionSampleModal
				show={displayCollectionSampleModalShow}
				onHide={handleDisplayCollectionModalHide}
				size="lg"
				item={selectedCollectionSample}
				onError={context.onError}
			/>}
			
			{ storageBoxActionModals.map((item, index) => {
								
				const { 
		    		component: Component, 
		    		...rest 
		    	} = item;
			
				return (
					<Fragment key={item.key}>
					{ (storageBoxActionModalsShowMap.get(item.key)) ?
						<Fragment>
							{ (selectedStorageBoxSlot) ?
								<Component 
									show={storageBoxActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="storagebox-items"
									item={selectedStorageBoxSlot}
									onError={context.onError}
									onItemSaved={handleItemSaved}
								/>
								:
								null
							}
						</Fragment>
						:
						null
					}
					</Fragment>
	      		)
    		})}
			
			<Row className="mb-0">
        		<Col>
        			<h3><Trans i18nKey="storageboxes.items.header" values={{labelId: location.state.item.labelId}} /></h3>
        		</Col>
  
        	</Row>
        	<Row className="mb-3">
    			<Col>
    			<h5 className="text-secondary">{t("storageboxes.items.header-count", {count: storageBoxItems.length })}</h5>
    			</Col>
    		</Row>
			{(isArrayWithLength(missingSlotItems)) ? 
				<Row className="mb-3">
    				<Col>
    					<Alert variant={"warning"} >
							<Row className="mb-1"><Col>
								<FormLabel>
									<Trans i18nKey="storageboxes.items.alert.missing-info" count={missingSlotItems.length}/></FormLabel>
							</Col></Row>
							{missingSlotItems.map((item,index) => 
								<MissingSlotItem
									key={item.id+"-"+index}
									box={location.state.item}
									item={item}
									unavailableSlots={definedSlotsItems.map((s) => Object.assign({}, {col: s.slotCol, row: s.slotRow}) ) }
									onItemUpdate={updateStorageBoxItem}
									onError={context.onError}
									onSuccess={handleItemSaved}
									onItemRemoval={confirmStorageBoxItemRemoval}
									onCollectionDisplay={handleDisplayCollection}
								/>
							)}
						</Alert>
    				</Col>
    			</Row>
				: null
			}
			<Row>
        		<Col>
					<StorageBox
						box={location.state.item}
						items={definedSlotsItems}
						onItemRemoval={confirmStorageBoxItemRemoval}
						onItemSave={handleSaveStorageBoxItemModalShow}
						onCollectionDisplay={handleDisplayCollection}
					/>
				</Col>
        	</Row>
		    <Row>    					   
				<Col>
					<Button variant="secondary" className="float-right" onClick={() => navigate("/analysis/boxes/update", { state: { "item": location.state.item}})} ><FontAwesomeIcon icon={faArrowCircleLeft} /> <Trans i18nKey="storageboxes.items.back">Back</Trans></Button>
				</Col>
			</Row>  
      </Container>
	);
}

export default StorageBoxItems;
