import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { isArrayWithLength, isObject } from "../helpers/commons";
import { AuthError, ValidationError } from "../helpers/custom-errors";

import { useAuth } from "../services/use-auth";
import API from "../services/backend-api";

import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import "react-bootstrap-typeahead/css/Typeahead.css";
import "../styles/Typeahead.css";


const StorageBoxInputSearch = props => {

	const [isLoading, setIsLoading] = useState(true);
	const [isSearching, setIsSearching] = useState(false);
	const [options, setOptions] = useState([]);
	//const [defaultSelected, setDefaultSelected] = useState([]);
	const [selected, setSelected] = useState([]);
	const [pagedStorageBoxes, setPagedStorageBoxes] = useState(null);
	
	const pageSize = (props.pageSize) ? props.pageSize : 10;
	
	const { t } = useTranslation();
	
	const auth = useAuth();
	
	useEffect(() => {
		
		let isMounted = true; 
		
		if (props.storageBoxId) {
			
			//Get Storage Box 
			API.lookupStorageBox(props.storageBoxId)
				.then(response => {	
					
					let option = buildOptionItem(response);
					
					setOptions(new Array(1).fill(option));
					//setDefaultSelected(new Array(1).fill(option));
					setSelected(new Array(1).fill(option));
					
					if (props.onLoad) props.onLoad(option);
								
			})
			.catch(error => { 
				log.error("Error Loading Storage Box: ", error.message);
		 		props.onError(error);
			})
			.finally(() => {
				if (isMounted) setIsLoading(false);
			});
		 							
		} else {
			if (isMounted) { 
				setIsLoading(false);
				//setDefaultSelected([]);
				setSelected([]);
				setOptions([]);
			}
		}
		
		return () => { isMounted = false };
		  
	}, [props.storageBoxId]);
	
  	const handleStorageBoxPaginate = (e, number) => {
	
		const pageIndex = Math.ceil(number/pageSize) - 1;
	    
		pageStorageBoxes({filter: pagedStorageBoxes.filter, pageSize: pageSize, pageIndex: pageIndex})
	      .then(({ list }) => {
		
			  let updatedOptions = options;
			  
			  list.forEach((item, index) => {
			  
			  	updatedOptions[pageSize*pageIndex + index] = buildOptionItem(item)
		
	    	  })
		 	 
	    	  setOptions(updatedOptions);
	    	  
	       })
	      .catch(error => { 
		      if (error instanceof AuthError) {		
		    	  auth.onUnathorized(error);
		      } else if (error instanceof ValidationError) {		
          						
				 log.info("Paginate Storage Boxes Attempt Failed: ", error.message);
          						
				 props.onError(error.message);		
          						
          	 } else {
          		log.error("Paginate Storage Boxes Error: ", error.message);
          		props.onError(t('error.api.general'));
          	 }
		 })
		 
	}
	
	const buildTagInfo = (box) => {
		return (isArrayWithLength(box.tags)) ? box.tags.map(t => (isObject(t) ? t.name : t)).toString() : null;
	}
	
	const buildOptionItem = (box) => {
		let displayName = box.labelId;
		let tagInfo = buildTagInfo(box)
			  					
		return Object.assign({displayName: displayName, tagInfo: tagInfo}, box)
		
	}
	
	const handleStorageBoxSearch = (query) => {
		
		setIsSearching(true);
	    
	    pageStorageBoxes({filter: query, pageSize: pageSize})
	      .then(({ totalCount, list }) => {
				
			  const options = Array(totalCount).fill().map((e,index)=> {
			  		if (list[index]) {
						return buildOptionItem(list[index])
	    	  		}
			  		else
			   			return ({displayName: t(props.i18nPrefix+'storage-box-input-search.loading')}) 
			  });  	 
		
	    	  setOptions(options);
	    	  
	       })
	      .catch(error => { 
		      if (error instanceof AuthError) {		
		    	  auth.onUnathorized(error);
		      } else if (error instanceof ValidationError) {		
          						
				 log.info("Find Storage Boxes Attempt Failed: ", error.message);
         
				 props.onError(new Error(t(error.message)))		
          						
          	 } else {
          		log.error("Find Storage Boxes Error: ", error.message);
				props.onError(new Error(t('error.api.general')))
          	 }
		 })
		 .finally(() => {
			 setIsSearching(false);
		 });
	  }
	  
	  const pageStorageBoxes = (values) => {
		
		setIsSearching(true);
		
		return new Promise((resolve, reject) => {
			API.pageStorageBoxes(props.workgroupId, values)
			.then(response => {
				setPagedStorageBoxes(response);
				resolve(response);
			}).catch(error => {			
				reject(error);
			}).finally(() => {
			 setIsSearching(false);
		 	});
		});
		
	}
	  
	const handleStorageBoxSelect = (items) => {
		
		if(isArrayWithLength(items)) { 
			props.onChange(items[0]);
		} else { props.onChange(null); }
  	};
						
	const renderMenuItemChildren = (option, properties) => { 
	
		return (
			<Fragment>
          		<Highlighter search={properties.text}>
            		{option.displayName}
         		</Highlighter>,
          		<div>
            		<small>
              			<Trans i18nKey={props.i18nPrefix+'storage-box-input-search.menu-item-details'} values={{item: option}}/>
            		</small>
          		</div>
        	</Fragment>
    	)
    }
 

	// Bypass client-side filtering by returning `true`. Results are already
	// filtered by the search endpoint, so no need to do it again.
	const filterBy = () => true;
	
	
	return (
    	<Fragment>
    		<Row><Col>
				<InputGroup>
					<InputGroup.Prepend>
						<InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
					</InputGroup.Prepend>
				{(isLoading) ? <FormControl disabled  />
					: <AsyncTypeahead
						clearButton
						filterBy={filterBy}
						id="filter"
						name="filter"
						isLoading={isSearching}
						disabled={props.disabled} 
						onPaginate={handleStorageBoxPaginate}
        				paginate={true}
						labelKey={(option) => option.displayName }
						minLength={3}
						maxResults={pageSize}
						onSearch={handleStorageBoxSearch}
						onChange={handleStorageBoxSelect}
						isInvalid={props.isInvalid}
						//defaultSelected={defaultSelected}
						selected={selected}
						options={options}
						placeholder={t(props.i18nPrefix+'storage-box-input-search.placeholder')}
						emptyLabel={t(props.i18nPrefix+'storage-box-input-search.no-matches-found')}
						searchText={t(props.i18nPrefix+'storage-box-input-search.searching')}
						promptText={t(props.i18nPrefix+'storage-box-input-search.prompt')}
						paginationText={t(props.i18nPrefix+'storage-box-input-search.paginate')}
						renderMenuItemChildren={renderMenuItemChildren}
					/>
				}
				</InputGroup>
			</Col></Row>
	</Fragment>
	)
};

export default StorageBoxInputSearch;