import * as Yup from "yup";
//import moment from "moment";

import CollectionConfig from "../config/CollectionConfig";

const MaterialSamples = CollectionConfig.Samples;

const MaterialSampleCodes = CollectionConfig.SamplesCodes;

const TaxonGroups = CollectionConfig.TaxonGroups;

const AdvancedFilterFormFields = {
	laboratoryId: true,
	storageBoxId: true,
	start: true,
	end : true,
	scientificName : true,
	taxonGroup: true
}

const TaxonRanks = {
		kingdom: "KINGDOM",
	    phylum: "PHYLUM",
	    class: "CLASS",
	    family: "FAMILY",
	    order: "ORDER",
	    genus: "GENUS",
		species: "SPECIES"
}
	
const FilterFormValidationRules = {
	observatoryId: Yup.string()
		.default(() => ""),
	laboratoryId: Yup.string()
		.default(() => ""),
	storageBoxId: Yup.string()
		.default(() => ""),
	filter: Yup.string()
		.default(() => ""),
	filteredColumn: Yup.string()
		.default(() => "organismId"),
	start: Yup.string()
		.default(() => ""),
	end: Yup.string()
		.default(() => ""),
	scientificName: Yup.string()
		.test(
        	'empty-or-3-characters-check',
        	'collectionsamples.list.form.scientificName.validation.minimumlength',
        	scientificName => !scientificName || scientificName.length >= 3,
    	)
		.default(() => ""),
	taxonRank: Yup.string()
		.oneOf([""].concat(Object.values(TaxonRanks)), 'form.validation.string.enum')
   		.default(() => ""),
	taxonGroup: Yup.string()
		.oneOf([""].concat(TaxonGroups), 'form.validation.string.enum')
   		.default(() => ""),
	materialSamples: Yup.array()
		.default(() => [])
}

const CollectionSampleConfig = { TaxonRanks, FilterFormValidationRules, AdvancedFilterFormFields, MaterialSamples, MaterialSampleCodes, TaxonGroups};

export default CollectionSampleConfig;