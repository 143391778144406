import React, {Fragment, useState} from 'react';

import { Trans } from 'react-i18next';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button'
import FormControlErrors from "../components/FormControlErrors";
import { isArrayWithLength } from "../helpers/commons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import CollectionsImportPreviewModal from "../components/CollectionsImportPreviewModal";
import CollectionsImportErrorsModal from "../components/CollectionsImportErrorsModal";


const CollectionsImportErrors = props => {
	const [collectionsImportErrorsModalShow, setCollectionsImportErrorsModalShow] = useState(false);	
	const [collectionsImportPreviewModalShow, setCollectionsImportPreviewModalShow] = useState(false);
	
	const handleCollectionsImportErrorsModalHide = () => {
	  setCollectionsImportErrorsModalShow(false);
    }

	const handleCollectionsImportPreviewModalHide = () => {
	  setCollectionsImportPreviewModalShow(false);
    }
	
	return (
		<Fragment>
		{ isArrayWithLength(props.errors) ?
			<Fragment>
				<CollectionsImportErrorsModal
					show={collectionsImportErrorsModalShow}
					onHide={handleCollectionsImportErrorsModalHide}
					size="xl"
					errors={props.errors}
					data={props.data}
				/>
				<CollectionsImportPreviewModal
					show={collectionsImportPreviewModalShow}
					onHide={handleCollectionsImportPreviewModalHide}
					size="xl"
					errors={props.errors}
					data={props.data}
				/>
				<Alert variant={"danger"} className="mt-2 mb-0">
					<Trans i18nKey={"collections.import.form.data.validation.errors"} >Errors</Trans> 
					<Button className="ml-3" size="sm" variant="danger" onClick={() => { setCollectionsImportErrorsModalShow(true); }}>
						<FontAwesomeIcon icon={faExclamationCircle} /> <Trans i18nKey={"collections.import.form.data.validation.detail-button"} >More Detail</Trans>		
					</Button>
					<Button className="text-white ml-3" size="sm" variant="warning" onClick={() => { setCollectionsImportPreviewModalShow(true);  }} >
						<FontAwesomeIcon icon={faEye} /> <Trans i18nKey={"collections.import.form.data.validation.preview-button"} >Preview</Trans>		
					</Button>
				</Alert>
			</Fragment>
		  :
			<FormControlErrors block={true} errors={props.errors} />
		}
		</Fragment>
	)
}

const CollectionsImportValidationSuccess = props => {
	const [collectionsImportPreviewModalShow, setCollectionsImportPreviewModalShow] = useState(false);
	
	const handleCollectionsImportPreviewModalHide = () => {
	  setCollectionsImportPreviewModalShow(false);
    }
	
	return (
		<Fragment>
			<CollectionsImportPreviewModal
				show={collectionsImportPreviewModalShow}
				onHide={handleCollectionsImportPreviewModalHide}
				size="xl"
				data={props.data}
			/>
			<Alert variant={"success"} className="mt-2 mb-0">
				<Trans i18nKey={"collections.import.form.data.validation.success"} count={props.data.length}>Sucess</Trans> 
				<Button className="text-white ml-3" size="sm" variant="warning" onClick={() => { setCollectionsImportPreviewModalShow(true);  }} >
					<FontAwesomeIcon icon={faEye} /> <Trans i18nKey={"collections.import.form.data.validation.preview-button"} >Preview</Trans>		
				</Button>
			</Alert>

		</Fragment>
	)
}
			
const CollectionsImportValidationResults = props => {

	if (props.isSubmitting || ((!props.errors)&&(!isArrayWithLength(props.data)))) {
		return null
	}

	return (
		<Fragment>
		{ (props.errors) ? 
				<CollectionsImportErrors errors={props.errors} data={props.data} />
		 	:
				<CollectionsImportValidationSuccess data={props.data} />
		}
		</Fragment>
	)
};

export default CollectionsImportValidationResults;