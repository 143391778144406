import React, { Fragment, useState} from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faTimesCircle} from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import FormCheck from 'react-bootstrap/FormCheck';
import Alert from 'react-bootstrap/Alert';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import DismissableFeedback from "../components/DismissableFeedback";
import FormControlErrors from "../components/FormControlErrors";
import GeoLocation from "../components/GeoLocation";
import LocationInputSearch from "../components/LocationInputSearch";
import InteractiveMap from "../components/InteractiveMap";
import UTMCoordinatesConverter from "../components/UTMCoordinatesConverter";

import { formatCoordinateDegrees } from "../helpers/commons";

import CollectionConfig from "../config/CollectionConfig";

setLocale({
	mixed: {
		default: 'form.validation.mixed.default',
		required: 'form.validation.mixed.required',
	}
});

const validationSchema = Yup.object().shape({
	latitude: CollectionConfig.GeneralFormValidationRules.latitude,
	longitude: CollectionConfig.GeneralFormValidationRules.longitude,
});

const inputModes = [
	"LATLONG",
	"GEOLOCATION",
	"MAP",
	"SEARCH",
	"UTM"
]

const LocationSearch = props => {

	const [latitude, setLatitude] = useState(null);
	const [longitude, setLongitude] = useState(null);
	const [showCoordinatesConfirmation, setShowCoordinatesConfirmation] = useState(false);

	return (
		<Fragment>
			<LocationInputSearch 
				i18nPrefix={props.i18nPrefix}
				onError={(error) => { props.onError(error);}}
				fetchCoordinates={true}
				onChange={(location) => { setLatitude(location.latitude); setLongitude(location.longitude); if (location.latitude && location.longitude) setShowCoordinatesConfirmation(true);}}
			/>
							
			{ (showCoordinatesConfirmation && latitude && longitude) && (
				<Alert variant={"success"} className="mt-3">
					<Trans i18nKey={props.i18nPrefix+"location-input-search.confirmation"} values={{ latitude : formatCoordinateDegrees(latitude, false), longitude : formatCoordinateDegrees(longitude, true) }}>Coordinates are {formatCoordinateDegrees(latitude, false) + ", " + formatCoordinateDegrees(longitude, true)}</Trans>
					<Button className="ml-3" size="sm" variant="success" onClick={() => { props.onConfirmCoordinates(latitude, longitude); setShowCoordinatesConfirmation(false);}}>
						<Trans i18nKey={props.i18nPrefix+"location-input-search.confirm-button"} >Confirm?</Trans>		
					</Button>
					<Button className="ml-3" size="sm" variant="danger" onClick={() => { setShowCoordinatesConfirmation(false);}}>
						<Trans i18nKey={props.i18nPrefix+"location-input-search.discard-button"} >Discard?</Trans>		
					</Button>
				</Alert>
			)}
		</Fragment>
	)

}

const UTMConverter = props => {

	const [latitude, setLatitude] = useState(null);
	const [longitude, setLongitude] = useState(null);
	const [showCoordinatesConfirmation, setShowCoordinatesConfirmation] = useState(false);

	return (
		<Fragment>
			<UTMCoordinatesConverter 
				i18nPrefix={props.i18nPrefix}
				latitude={props.latitude}
				longitude={props.longitude}
				onError={(error) => { props.onError(error);}}
				onChange={(location) => { 
					setLatitude(location.latitude); 
					setLongitude(location.longitude); 
					if (location.latitude && location.longitude) setShowCoordinatesConfirmation(true);
					else setShowCoordinatesConfirmation(false);
				}}
			/>
							
			{ (showCoordinatesConfirmation && latitude && longitude) && (
				<Alert variant={"success"} className="mt-3">
					<Trans i18nKey={props.i18nPrefix+"utm-converter.confirmation"} values={{ latitude : formatCoordinateDegrees(latitude, false), longitude : formatCoordinateDegrees(longitude, true) }}>Coordinates are {formatCoordinateDegrees(latitude, false) + ", " + formatCoordinateDegrees(longitude, true)}</Trans>
					<Button className="ml-3" size="sm" variant="success" onClick={() => { props.onConfirmCoordinates(latitude, longitude); setShowCoordinatesConfirmation(false);}}>
						<Trans i18nKey={props.i18nPrefix+"utm-converter.confirm-button"} >Confirm?</Trans>		
					</Button>
					<Button className="ml-3" size="sm" variant="danger" onClick={() => { setShowCoordinatesConfirmation(false);}}>
						<Trans i18nKey={props.i18nPrefix+"utm-converter.discard-button"} >Discard?</Trans>		
					</Button>
				</Alert>
			)}
		</Fragment>
	)

}


const SetCollectionCoordinatesModal = props => {

	const [_error, _setError] = useState(null);
	const [inputMode, setInputMode] = useState(0);
	const [hideGeolocation, setHideGeolocation] = useState(false);
	
	const { t } = useTranslation();

	//Workaround to force geolocation retry
	const forceGeolocationRetry = () => {
		setHideGeolocation(true);
		setTimeout(() => setHideGeolocation(false), 500);
	}
	
	const handleInputModeChange = (inputModeIndex) => {
		//Clear Error
		_setError(null);
		//Reset hideGeolocation
		setHideGeolocation(false);
		//Update inputMode
		setInputMode(inputModeIndex);		
	}
	
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-set-collection-coordinates"
		    centered
		    backdrop="static"
		    keyboard={false}
		    onShow={()=> {setInputMode(0)}}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-set-collection-coordinates">
  					<Trans i18nKey="collections.set-coordinates-modal.title">Set Coordinates</Trans>
  				</Modal.Title>
  			</Modal.Header>
  				<Formik
		      		initialValues={{ latitude: props.latitude , longitude: props.longitude }}
		      		validationSchema={validationSchema}
		      		validateOnChange={false}
		      		validateOnBlur={false}     
		      		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);
		      			setInputMode(0);
					
						actions.setSubmitting(false);
						props.onCoordinatesChange(values.latitude, values.longitude);
					}}
		      	>
		      	{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue}) => (	
		      		<Form className="form-set-collection-coordinates" noValidate>
		
		      		<Modal.Body className="mb-3">
		  				<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  
				    	{((inputMode===1)&&(!hideGeolocation)) && (
							<GeoLocation 
								onRetry={forceGeolocationRetry} 
								onConfirmCoordinates={(latitude, longitude) => {setFieldValue("latitude", latitude);
								setFieldValue("longitude", longitude); setHideGeolocation(true);}}
								i18nPrefix="collections.set-coordinates-modal.form."
							/>
						)}
				    	<Row>
				    	<FormGroup as={Col} controlId="formGridInputMode">
				    	<FormLabel><Trans i18nKey="collections.set-coordinates-modal.form.input-mode.label">Choose an input mode</Trans></FormLabel>
				    	<div className="border rounded p-2">
				    	<Row>
							{ inputModes.map((item, index) => 
								<Col md="auto" className="pl-4" key={index}>
									<FormCheck 
        								className="text-uppercase"
        								type="radio"
        								id={index}
        								value={item}
        								label={t("collections.set-coordinates-modal.form.input-mode.values."+item)}
        								onChange={() => { handleInputModeChange(index); }}
        								checked={(inputMode === index)}
      								/>
								</Col>
							)}
                		</Row>
        				</div>
						</FormGroup>
						</Row>

						<Row>
				    		<FormGroup as={Col} controlId="formGridLatitude">
				    			<FormLabel><Trans i18nKey="collections.set-coordinates-modal.form.latitude.label">Latitude</Trans> *</FormLabel>
								<FormControl type={'text'} name="latitude" readOnly={(inputMode !== 0)} isInvalid={!(errors.latitude == null)} value={values.latitude} onChange={(inputMode !== 0)? null : handleChange}  />
								<FormControlErrors errors={errors.latitude} />
				    		</FormGroup>
							<FormGroup as={Col} controlId="formGridLongitude">
				    			<FormLabel><Trans i18nKey="collections.set-coordinates-modal.form.longitude.label">Longitude</Trans> *</FormLabel>
								<FormControl type={'text'} name="longitude" readOnly={(inputMode !== 0)} isInvalid={!(errors.longitude == null)} value={values.longitude} onChange={handleChange} />
								<FormControlErrors errors={errors.longitude} />
				    		</FormGroup>
						</Row>

						{(inputMode===2) && (
						<Row><Col>
							<InteractiveMap
								latitude={values.latitude}
								longitude={values.longitude}
								onConfirmCoordinates={(latitude, longitude) => {setFieldValue("latitude", latitude);setFieldValue("longitude", longitude);}}
								onError={(error) => { _setError(error);}}
								i18nPrefix="collections.set-coordinates-modal.form."
							/>
						</Col></Row>
						
						)}
						
						{(inputMode===3) && (
				    		<Row><Col>	
				    			<FormLabel><Trans i18nKey="collections.set-coordinates-modal.form.location-input-search.label">Search by Location</Trans></FormLabel>
				    			<LocationSearch
				    				i18nPrefix="collections.set-coordinates-modal.form."
				    				onConfirmCoordinates={(latitude, longitude) => {setFieldValue("latitude", latitude);
									setFieldValue("longitude", longitude); }}
				    			/>
				    		</Col></Row>
				    	)}
				    	
				    	
				    	{(inputMode===4) && (
				    	<Fragment>
				    	<Row>
				    		<FormGroup as={Col} controlId="formGridUTM">
				    			<FormLabel><Trans i18nKey="collections.set-coordinates-modal.form.utm-converter.label">UTM Coordinates</Trans></FormLabel>
				    			<UTMConverter
				    				latitude={values.latitude}
									longitude={values.longitude}
									onError={(error) => { _setError(error);}}
				    				i18nPrefix="collections.set-coordinates-modal.form."
				    				onConfirmCoordinates={(latitude, longitude) => {setFieldValue("latitude", latitude);
									setFieldValue("longitude", longitude); }}
				    			/>
							</FormGroup>
						</Row>
						</Fragment>
				    	)}
				    				
          				</Modal.Body>
          				<Modal.Footer>			      		
          				      <Button variant="success" type="submit" disabled={isSubmitting} > 
          				      		{isSubmitting ? <Trans i18nKey="collections.set-coordinates-modal.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={faMapMarkerAlt} /> <Trans i18nKey="collections.set-coordinates-modal.form.submit">Submit</Trans></span>} 
          				      </Button>
          				      <Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="collections.set-coordinates-modal.cancel">Cancel</Trans></Button>	
          				</Modal.Footer>
              		</Form>	
		      )}
		      </Formik>
		   </Modal>
	);
}

export default SetCollectionCoordinatesModal;
