import React,  { useState, useEffect } from 'react';
import { useNavigate, useLocation, useOutletContext  } from 'react-router-dom';
import log from 'loglevel';

import Loader from "../components/Loader";
import PagedList from "../components/PagedList";
import SingleFilterForm from "../components/SingleFilterForm";

import API from "../services/backend-api";

const Users = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [pagedUsers, setPagedUsers] = useState(null);
	
	const location = useLocation();
	
	let navigate = useNavigate();
	let context = useOutletContext();
		
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
		
		listUsers({})
			.catch(error => { 
				log.error("Error Loading Initial Users List: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);
	
  const updateUser = (user) => {
	  navigate("/users/update", { state: { item: user}});
  }
  
  const addUser = () => {
	  navigate("/users/create");
  }
  
  const updateUserPassword = (user) => {
	  navigate("/users/update-password", { state: { item: user}});
  }
    
  const updatePage = (values) => {
	  listUsers(values)
		.catch(error => { 
			log.error("Error Paginating Users List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const sortDataTable = (values) => {
	  values.pageIndex = 0;
	  listUsers(values)
		.catch(error => { 
			log.error("Error Sorting Users List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const displayInactive = (item) => { 
	return (item.active) ? "" : "inactive-row"	
  }
  
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
  const listUsers = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
		context.onError(null);
		
		API.listUsers(mergeQueryParams(pagedUsers, values)).then(response => {
			setPagedUsers(response);
			resolve(response);
		}).catch(error => { 	
			reject(error);
		});
	});
	
	if (isLoading) 
		return <Loader />
	
	if (!pagedUsers) 
		return null;
		
	return (
		<PagedList
			i18nPrefix="users.list."
			page={pagedUsers}
			pageSizeOptions={[10,25,50]}
			filterFormComponent={SingleFilterForm}
			filterParameters={{ filter: pagedUsers.filter, filteredColumn: pagedUsers.filteredColumn }}
			onFilter={listUsers} 
			onError={context.onError}
			onSort={sortDataTable}
			onAdd={addUser}
			onPage={updatePage}
			dataTableColumns={["firstName", "lastName", "email", "lastLogin", "totalWorkgroups"]} 
			dataTableSortableColumns={["firstName", "lastName", "email", "lastLogin"]}
			dataTableCustomDisplayColumns={[["lastLogin", "ZonedDateTime"]]}
			dataTableActions={[updateUser, updateUserPassword]}
			dataTableDefaultAction={updateUser}
			dataTableCustomRowStyle={displayInactive}		
		/>
		
	);
}

export default Users;
