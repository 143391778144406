import React from 'react';
import log from 'loglevel';

import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

import CollectionSamplesFilterFormFields from "../components/CollectionSamplesFilterFormFields";

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import { ValidationError } from "../helpers/custom-errors";

import CollectionSampleConfig from "../config/CollectionSampleConfig";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const validationSchema = Yup.object().shape(CollectionSampleConfig.FilterFormValidationRules);

const CollectionSamplesFilterForm = props => {
	
	let initialValues = {};
	
	let defaultValues = validationSchema.default();
	
	Object.keys(CollectionSampleConfig.FilterFormValidationRules)
		.forEach((key) => {
				initialValues[key] = (props.parameters[key]) ? props.parameters[key] : defaultValues[key];
		});
	
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			validateOnChange={false}
			validateOnBlur={false}     
			onSubmit={(values, actions) => {
				props.onSubmit(values)
			 	.catch(error => { 
				
					if (error instanceof ValidationError) {		
    									
    						log.info("Filtering Items Attempt Failed: ", error.message);

    						if (error.detail) {            						
    	              			for (let key of Object.keys(error.detail)) {
    	              				let errorMsgs = error.detail[key];
    	              				errorMsgs.forEach((message) => {
    	              					actions.setFieldError(key, message);
    	              				});
    	              			}
    	              		}
    					} else {
    						log.error("Error Filtering Items: ", error.message);
    					}
    								
    					props.onError(error);
			 	})
			 	.finally(() => {
			 		actions.setSubmitting(false);
			 	});
			}}
		>
		{({isSubmitting, errors, values, handleChange, setFieldValue, setFieldError, submitForm}) => (	
	
			<Form className="form-filter-data" noValidate>

				<CollectionSamplesFilterFormFields
					i18nPrefix={props.i18nPrefix}
					values={values} 
					errors={errors} 
					onChange={handleChange} 
					onError={props.onError}
					setFieldValue={setFieldValue}
					setFieldError={setFieldError}
					isSubmitting={isSubmitting}
					submitForm={submitForm}
				/>
			</Form>	
		)}
	</Formik>
	)

}

export default CollectionSamplesFilterForm;
