import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { isArrayWithLength } from "../helpers/commons";
import { AuthError, ValidationError } from "../helpers/custom-errors";

import { useAuth } from "../services/use-auth";
import API from "../services/backend-api";

import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import "react-bootstrap-typeahead/css/Typeahead.css";
import "../styles/Typeahead.css";


const LocationInputSearch = props => {

	const [isLoading, setIsLoading] = useState(false);
	const [options, setOptions] = useState([]);
	const [pagedLocations, setPagedLocations] = useState(null);
	
	const pageSize = (props.pageSize) ? props.pageSize : 10;
	
	const { t } = useTranslation();
	
	const auth = useAuth();
	
  	const handleLocationPaginate = (e, number) => {
	
		const pageIndex = Math.ceil(number/pageSize) - 1;
	    
		pageLocations({filter: pagedLocations.filter, pageSize: pageSize, pageIndex: pageIndex, isCity: true})
	      .then(({ list }) => {
			  
			  let updatedOptions = options;
			  
			  list.forEach((item, index) => {
			  
			  	let displayName = item.locality + (0 < item.stateProvinceShort.length ? ' - ' + item.stateProvinceShort :  (0 < item.country.length) ? ' - ' + item.country : '');
			  					
			  	updatedOptions[pageSize*pageIndex + index] = ({
	    		  county: item.locality,
				  stateProvince: item.stateProvinceShort,
				  country: item.country,
				  latitude: item.latitude,
				  longitude: item.longitude,
	    		  displayName: displayName,
	    	  	})
	    	  })
		 	 
	    	  setOptions(updatedOptions);
	    	  
	       })
	      .catch(error => { 
		      if (error instanceof AuthError) {		
		    	  auth.onUnathorized(error);
		      } else if (error instanceof ValidationError) {		
          						
				 log.info("Paginate Locations Attempt Failed: ", error.message);
          						
				 props.onError(error.message);		
          						
          	 } else {
          		log.error("Paginate Locations Error: ", error.message);
          		props.onError(t('error.api.general'));
          	 }
		 })
		 
	}
	
	const handleLocationSearch = (query) => {
		
		setIsLoading(true);
	    
	    pageLocations({filter: query, pageSize: pageSize, isCity: true})
	      .then(({ totalCount, list }) => {
				
			  const options = Array(totalCount).fill().map((e,index)=> {
			  		if (list[index]) {
			  			
			  			let displayName = list[index].locality + (0 < list[index].stateProvinceShort.length ? ' - ' + list[index].stateProvinceShort :  (0 < list[index].country.length) ? ' - ' + list[index].country : '');
			  			
			  			return ({
	    		  			county: list[index].locality,
				  			stateProvince: list[index].stateProvinceShort,
				  			country: list[index].country,
				  			latitude: list[index].latitude,
				  			longitude: list[index].longitude,
	    		  			displayName: displayName,
	    	  			})
	    	  		}
			  		else
			   			return ({displayName: t(props.i18nPrefix+'location-input-search.loading')}) 
			  });  	 
		
	    	  setOptions(options);
	    	  
	       })
	      .catch(error => { 
		      if (error instanceof AuthError) {		
		    	  auth.onUnathorized(error);
		      } else if (error instanceof ValidationError) {		
          						
				 log.info("Find Users Attempt Failed: ", error.message);
         
				 props.onError(new Error(t(error.message)))		
          						
          	 } else {
          		log.error("Find Users Error: ", error.message);
				props.onError(new Error(t('error.api.general')))
          	 }
		 })
		 .finally(() => {
			 setIsLoading(false);
		 });
	  }
	  
	  const pageLocations = (values) => {
		
		setIsLoading(true);
		
		return new Promise((resolve, reject) => {
			API.pageLocations(values)
			.then(response => {
				setPagedLocations(response);
				resolve(response);
			}).catch(error => {			
				reject(error);
			}).finally(() => {
			 setIsLoading(false);
		 	});
		});
		
	}
	  
	const handleLocationSelect = (items) => {

		if(isArrayWithLength(items)) { 

			props.onChange(items[0]);
	
		}			
  	};
  	
 

	// Bypass client-side filtering by returning `true`. Results are already
	// filtered by the search endpoint, so no need to do it again.
	const filterBy = () => true;
	
	return (
    	<Fragment>
    		<Row><Col>
    			<InputGroup>
					<InputGroup.Prepend>
						<InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
					</InputGroup.Prepend>
					<AsyncTypeahead
						filterBy={filterBy}
						id="filter"
						name="filter"
						isLoading={isLoading}
						onPaginate={handleLocationPaginate}
        				paginate={true}
						labelKey={(option) => option.displayName }
						minLength={3}
						maxResults={pageSize}
						onSearch={handleLocationSearch}
						onChange={handleLocationSelect}
						isInvalid={props.isInvalid}
						options={options}
						placeholder={t(props.i18nPrefix+'location-input-search.placeholder')}
						emptyLabel={t(props.i18nPrefix+'location-input-search.no-matches-found')}
						searchText={t(props.i18nPrefix+'location-input-search.searching')}
						promptText={t(props.i18nPrefix+'location-input-search.prompt')}
						paginationText={t(props.i18nPrefix+'location-input-search.paginate')}
						renderMenuItemChildren={(option, props) => (
							<Highlighter search={props.text}>
						      {option.displayName}
						    </Highlighter>
						)}
					/>
				</InputGroup>
			</Col></Row>
	</Fragment>
	)
};

export default LocationInputSearch;