import React, { useState, useEffect} from 'react';
import { Route, Navigate, } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faTimesCircle, faCheckCircle} from '@fortawesome/free-solid-svg-icons'

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import DismissableFeedback from "../components/DismissableFeedback";
import MultipleLaboratoryTestsFormFields from "../components/MultipleLaboratoryTestsFormFields";
import Prompt from "../components/Prompt";

import { useConfig } from "../services/use-config";
import API from "../services/backend-api";

import LaboratoryTestConfig from "../config/LaboratoryTestConfig";

import { compareJSON, isArrayWithLength } from "../helpers/commons";
import { AuthError, ValidationError } from "../helpers/custom-errors";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });


const saveMultipleLaboratoryTestsValidationSchema = Yup.object().shape({
	...LaboratoryTestConfig.SaveMultipleGeneralFormValidationRules,
	...LaboratoryTestConfig.SaveMultipleSequencingFormValidationRules
});

const isSequenced = (values) => {
	return JSON.parse(values.sequenced)
}

const saveMultipleLaboratoryTestsTabs = [
	  {
        key: 'general',
        component: MultipleLaboratoryTestsFormFields.GeneralSection,
		isVisible: true
      }, 
	  {
        key: 'sequencing',
        component: MultipleLaboratoryTestsFormFields.SequencingSection,
		isVisible: isSequenced 
      }
    ];


const SaveMultipleLaboratoryTestsModal = props => {

	const [_error, _setError] = useState(null);
	const [tabKey, setTabKey] = useState('general');
	
	const { t } = useTranslation();
	
	const config = useConfig();

	useEffect(() => {
		let isMounted = true; 
		 
		return () => { isMounted = false };
		  
	}, []);

	
	if (!isArrayWithLength(props.items)) {
		switch (props.origin) {
			case "laboratory-tests":
				return (<Navigate  to="/analysis/dissociations" />);
			case "laboratory-extractions":
				return (<Navigate  to="/analysis/extractions" />);
			case "laboratory-syntheses":
				return (<Navigate  to="/analysis/syntheses" />);
			case "laboratory-tests":
				return (<Navigate  to="/analysis/tests" />);
			case "collection-samples":
			default:
				return (<Navigate  to="/analysis/laboratorytests" />);
		}
		
	} 
	
	let initialValues, validationSchema, linearValidationRules;
	
	validationSchema = saveMultipleLaboratoryTestsValidationSchema;
	
	linearValidationRules = [
			LaboratoryTestConfig.SaveMultipleGeneralFormValidationRules,
			LaboratoryTestConfig.SaveMultipleSequencingFormValidationRules
	]
	
	let defaultValues = validationSchema.default();
	
	initialValues = defaultValues;
	
	const saveMultipleLaboratoryTests = (values) =>
		new Promise((resolve, reject) => {
			API.saveMultipleLaboratoryTests(values, config.csrfToken).then(response => {
				resolve(response);
			}).catch(error => reject(error));
			
		});
	
	const hasTabErrors = (indexTab, errors) => {	
		
		return (Object.keys(linearValidationRules[indexTab])
			.find((key) => !(errors[key] == null)))
    }
	
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-save-multiple-tests-modal"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-save-multiple-tests-modal">
  					<Trans i18nKey="laboratorytests.save-multiple-tests-modal.title">Save Laboratory Tests</Trans>
  				</Modal.Title>
  			</Modal.Header>
			<Formik
		      		initialValues={initialValues}
		      		validationSchema={validationSchema}
		      		validateOnChange={false}
		      		validateOnBlur={false}     
		      		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);

						let laboratoryTests = props.items.map((item, index) => {
							let labTest = {};
							labTest.id = (props.origin === "laboratory-tests") ? item.id : "";
							labTest.collectionId = item.collectionId;
							labTest.materialSample = item.materialSample;
							
							//override the rest with form values
							labTest.labelId =  values.labelIdPrefix + item.organismId;
							
							labTest.pcrType = values.pcrType;
							labTest.targetVirusId = values.targetVirusId;
							labTest.protocolId = values.protocolId;

							labTest.workgroupId = values.workgroupId;
							labTest.testedById = values.testedById;
							labTest.dateTested = values.dateTested;
							labTest.storageBoxId = values.storageBoxId;
							
							labTest.result = values.result;
							labTest.cycleThreshold = values.cycleThreshold;
							labTest.bandIntensity = values.bandIntensity;
							
							labTest.sequenced = values.sequenced;
							labTest.sequencingMethod = values.sequencingMethod;
							labTest.sequencingResult = values.sequencingResult;
							labTest.sequencingRemarks = values.sequencingRemarks;
							labTest.viralIsolation = values.viralIsolation;
							labTest.viralIsolationRemarks = values.viralIsolationRemarks;
							
							return labTest;
							
						});
						
						saveMultipleLaboratoryTests(laboratoryTests)
    						.then((response) => {
    							actions.resetForm({ values }) //Workaround to set dirty back to true
								actions.setSubmitting(false);
								props.onItemsSaved(t('laboratorytests.save-multiple-tests-modal.success'));
    						})
    						.catch(error => { 
    								
    							actions.setSubmitting(false);
								
								if (error instanceof AuthError) {		
		    	  					//auth.onUnathorized(error);
									props.onError(error);
		     					 } else if (error instanceof ValidationError) {		
          						
				 					log.info("Save Multiple Laboratory Tests Attempt Failed: ", error.message);
          						
				 					_setError(error.message);		
								
									if (error.detail) {            						
    	              					for (let key of Object.keys(error.detail)) {
    	              						let errorMsgs = error.detail[key];
    	              						errorMsgs.forEach((message) => {
    	              							if (key === "data") {
											
													try {
        												actions.setFieldError(key, JSON.parse(message));
    												} catch (e) {
        												actions.setFieldError(key, message);
    												}
											
												} else {
													actions.setFieldError(key, message);
												}
    	              						});
    	              					}
    	              				}
          						
          	 					} else {
          							log.error("Error Saving Multiple Laboratory Tests: ", error.message);
          							_setError(t('error.api.general'));
          	 					}	
    								
    						})
    			   		}
      					      			
					}
		      	>
		      	{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue, setFieldError, dirty}) => (	
		      		
		      		<Form className="form-save-multiple-tests-modal" noValidate>		
		      			<Modal.Body className="pt-2">
		  				
		  					<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  

							<Row className="mb-3 pl-3 pr-3 align-items-center">
  								<Col md={1} sm={2} xs={2} className="text-center p-1">
  									<h1 className={"text-primary"}><FontAwesomeIcon icon={faExclamationTriangle} /></h1>
								</Col>
		  						<Col className="ml-3 text-justified align-middle">
									<Trans i18nKey="laboratorytests.save-multiple-tests-modal.body" count={props.items.length}>Save Multiple Laboratory Tests</Trans>
								</Col>
							</Row>
								
  							<Prompt
		      					when={!!dirty && !isSubmitting}
		      					message={JSON.stringify({
		      		           			"title": t("form.unsaved-changes-modal.title"),
		      		           			"bodyText": t("form.unsaved-changes-modal.body"),
		      		          		 	"confirmText": t("form.unsaved-changes-modal.confirm"), 
		      		           			"cancelText": t("form.unsaved-changes-modal.cancel"),
		      		           			"size": "lg",
		      		           			"variant": "danger",
		        			   			"when": compareJSON(initialValues, values)
		      		          		})
		      		   			}
		      				/>

							<Tab.Container id="multipleLaboratoryTestsFormTab" activeKey={tabKey} onSelect={(k) => setTabKey(k)} >
					
								<Nav variant="tabs" className="mb-3">
					
								{ saveMultipleLaboratoryTestsTabs.map((tab, index) => {
							
									if (typeof tab.isVisible == "boolean") {
										if (!tab.isVisible) return null;
									} else if (typeof tab.isVisible == 'function') {
										if (!tab.isVisible(values)) return null;
									}
									
									return (
										<Nav.Item key={index}>
    										<Nav.Link eventKey={tab.key} className={ hasTabErrors(index, errors)  ? "text-danger" : ""}><Trans i18nKey={"laboratorytests.save-multiple-tests-modal.tabs."+tab.key}>{tab.key}</Trans>{ hasTabErrors(index, errors) ? "*" : ""}</Nav.Link>
  										</Nav.Item>
	      							)
	      						})}

						
								</Nav>
					
					
								<Tab.Content>
								{ saveMultipleLaboratoryTestsTabs.map((tab, index) => {
								
									const { 
		    							component: Component, 
		    								...rest 
		    						} = tab;
								
							
									return (
								
										<Tab.Pane key={index} eventKey={tab.key}>
											<Component 
												values={values} 
												errors={errors} 
												onChange={handleChange}
												dirty={dirty} 
												onError={props.onError}
												onSuccess={props.onSuccess}
												onWarning={props.onWarning}
												onInfo={props.onInfo}
												setFieldValue={setFieldValue}
												setFieldError={setFieldError}
												origin={props.origin}
												isModal={true}
												i18nPrefix="laboratorytests.save-multiple-tests-modal."
											/>
										</Tab.Pane>

	      							)
    							})}
						
							</Tab.Content>
					
					
						</Tab.Container>
								
          				</Modal.Body>
          				<Modal.Footer>			 
          				      <Button variant="success" type="submit" disabled={isSubmitting}> 
          				      		{isSubmitting ? <Trans i18nKey="laboratorytests.save-multiple-tests-modal.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={faCheckCircle} /> <Trans i18nKey={"laboratorytests.save-multiple-tests-modal.form.submit"}>Submit</Trans></span>} 
          				      </Button>
          				      <Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="laboratorytests.save-multiple-tests-modal.cancel">Cancel</Trans></Button>	
          				</Modal.Footer>
              		</Form>	
				)}
		      </Formik>
		</Modal>
	);
}

export default SaveMultipleLaboratoryTestsModal;
