import * as Yup from "yup";

const FormValidationRules = {
	labelId: Yup.string()
   		.max(100, 'storageboxes.save.form.labelId.validation.maximumlength')
   		.required()
   		.default(() => ""),
	workgroupId: Yup.string()
		.required()
		.default(() => ""),
   	tags: Yup.array()
		.default(() => []),
   	remarks: Yup.string()
		.max(255, 'storageboxes.save.form.remarks.validation.maximumlength')
   		.default(() => ""),	
	cols: Yup.string()
		.required()
		.matches("^[1-9]$|^1[0-9]$|^2[0-6]$", "storageboxes.save.form.cols.validation.format")
		.default(() => "9"),
	rows: Yup.string()
	   	.required()
		.matches("^[1-9]$|^1[0-9]$|^2[0-6]$", "storageboxes.save.form.rows.validation.format")
		.default(() => "9"),
   	discarded: Yup.boolean()
   		.default(() => false),
}

const StorageBoxConfig = { FormValidationRules };

export default StorageBoxConfig;