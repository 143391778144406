import React,  { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation, Route, Navigate, useOutletContext } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

import Loader from "../components/Loader";
import DataTable from "../components/DataTable";
import AddWorkgroupLicenseModal from "../components/AddWorkgroupLicenseModal";
import ConfirmationDialogModal from "../components/ConfirmationDialogModal";

import API from "../services/backend-api";
import { useAuth } from "../services/use-auth";
import { useConfig } from "../services/use-config";

const WorkgroupLicenses = props => {

	const [isLoading, setIsLoading] = useState(true);
	const [workgroupLicenses, setWorkgroupLicenses] = useState(null);
	const [addLicenseModalShow, setAddLicenseModalShow] = useState(false);
	const [removeLicenseModalShow, setRemoveLicenseModalShow] = useState(false);
	const [selectedLicense, setSelectedLicense] = useState(null);
	
	const { t } = useTranslation();
	const auth = useAuth();
	const config = useConfig();
	
	const location = useLocation();
	let context = useOutletContext();
	let navigate = useNavigate();
		
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
		
		if (location.state && location.state.item) {
			listWorkgroupLicenses(location.state.item.id)
				.catch(error => { 
					log.error("Error Loading Initial Workgroup Licenses List: ", error.message);
					context.onError(error);
				})
		 		.finally(() => {
		 			if (isMounted) setIsLoading(false);
		 		});
		 
			return () => { isMounted = false };
		}
		
	}, []);

  const onLicenseAdded = (success) => {
	  setAddLicenseModalShow(false);
	  context.onSuccess(success);
	  listWorkgroupLicenses(location.state.item.id);
  }
  
  const confirmWorkgroupLicenseRemoval = (user) => {
	  setSelectedLicense(user);
	  setRemoveLicenseModalShow(true);
  }
  
  const displayInactive = (item) => { 
	return (item.active) ? "" : "inactive-row"	
  }
		     
  const removeWorkgroupLicense = (license) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		setRemoveLicenseModalShow(false);
		
		API.removeWorkgroupLicense(location.state.item.id , {"id": license.id}, config.csrfToken ).then(response => {
			context.onSuccess(response.success)
			listWorkgroupLicenses(location.state.item.id);
		}).catch(error => { 	
			log.error("Error Removing Workgroup License: ", error.message);
			context.onError(error);		
		});
		
  }
	
  const listWorkgroupLicenses = (id) =>
	new Promise((resolve, reject) => {
		API.getWorkgroupLicenses(id, auth.isAdmin()).then(response => {
			setWorkgroupLicenses(response.list);
			resolve(response);
		}).catch(error => { 
			reject(error);
		});
	});
	
	if (!location.state || !location.state.item) {
		return (<Navigate  to="/workgroups" />)
	}
	
	if (isLoading) 
		return <Loader />
	
	if (!workgroupLicenses) 
		return null;
		
	return (
		<Container >
			<AddWorkgroupLicenseModal
				show={addLicenseModalShow}
	        	onHide={() => setAddLicenseModalShow(false)}
				size="lg"
				workgroup={location.state.item}
				onLicenseAdded={onLicenseAdded}
		      />
			{(selectedLicense) && <ConfirmationDialogModal
				item={selectedLicense}
				show={removeLicenseModalShow}
        		onHide={() => setRemoveLicenseModalShow(false)}
				size="lg"
				title={t("workgroups.remove-license-confirmation-modal.title")}
				bodyText={t("workgroups.remove-license-confirmation-modal.body", {license: selectedLicense, workgroup: location.state.item })}
				confirmText={t("workgroups.remove-license-confirmation-modal.confirm")}
				cancelText={t("workgroups.remove-license-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={removeWorkgroupLicense}
			/>}
			<Row className="mb-3">
			
        		<Col>
        			<h3><Trans i18nKey="workgroups.licenses.header" values={{name: location.state.item.name}} /></h3>
        		</Col>
  
        	</Row>
        	<Row className="mb-3">
    			<Col sm={6} xl={7}>
    			<h4>{t("workgroups.licenses.header-count", {count: workgroupLicenses.length})}</h4>
    			</Col>
    			<Col sm={6} xl={5}>
				<div className="d-flex justify-content-end">
					<Button variant="outline-success" onClick={() => setAddLicenseModalShow(true)}><FontAwesomeIcon icon={faPlusCircle} /> <Trans i18nKey="workgroups.licenses.add-button" /></Button>
				</div>
			</Col>
    		</Row>
        	<Row>
        		<Col>
        			<DataTable 
        				items={workgroupLicenses} 
        				i18nPrefix="workgroups.licenses.datatable." 
        				columns={["type","licenseNumber","remarks"]} 
        				actions={[confirmWorkgroupLicenseRemoval]}
        				customRowStyle={displayInactive}
        			/>
        		</Col>
        	</Row>
		    <Row>    					   
				<Col>
					<Button variant="secondary" className="float-right" onClick={() => navigate("/workgroups/update", { state: { "item": location.state.item}})}><FontAwesomeIcon icon={faArrowCircleLeft} /> <Trans i18nKey="workgroups.licenses.back">Back</Trans></Button>
				</Col>
			</Row>  
      </Container>
	);
}

export default WorkgroupLicenses;
