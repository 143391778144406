import * as Yup from "yup";
import moment from "moment";

import CollectionConfig from "../config/CollectionConfig";

const MaterialSamples = CollectionConfig.Samples;

const DefaultLabelIdPrefix = "E-";

const GeneticMaterials = [
	"ACN",
	"DNA",
	"RNA"
]

const Reagents = [
	"TRIZOL",
	"COLUNA",
	"MAGMAX_CORE",
	"OTHER"
]

const GeneralFormValidationRules = {
	id: Yup.string()
		.nullable()
		.default(() => ""),
	geneticMaterial: Yup.string()
		.oneOf([""].concat(GeneticMaterials), 'form.validation.string.enum')
		.required()
		.default(() => ""),
	reagent: Yup.string()
		.oneOf([""].concat(Reagents), 'form.validation.string.enum')
		.required()
		.default(() => ""),
	collectionId: Yup.string()
		.required()
		.default(() => ""),
	organismId: Yup.string()
		.default(() => ""),
	scientificName: Yup.string()
		.default(() => ""),
	sex: Yup.string()
		.default(() => ""),
	county: Yup.string()
		.default(() => ""),
	stateProvince: Yup.string()
		.default(() => ""),
	materialSample: Yup.string()
		.oneOf([""].concat(MaterialSamples), 'form.validation.string.enum')
		.required()
		.default(() => ""),
	labelId: Yup.string()
		.max(100, 'laboratoryextractions.save-extraction-modal.form.labelId.maximumlength')
		.required()
		.default(() => ""),
	workgroupId: Yup.string()
		.required()
		.default(() => ""),
	extractedById: Yup.string()
		.required()
		.default(() => ""),
	dateExtracted: Yup.date()
		.typeError('laboratoryextractions.save-extraction-modal.form.dateExtracted.validation.format')
		.max(new Date(), "laboratoryextractions.save-extraction-modal.form.dateExtracted.validation.future")
		.required()
		//.default(() => moment(new Date()).format("YYYY-MM-DD")),
		.default(() => ""),
	storageBoxId: Yup.string()
		//.required()
		.default(() => ""),
	slotCol: Yup.string()
			.default(() => ""),
	slotRow: Yup.string()
			.default(() => ""),
	ranOut: Yup.boolean()
    	.default(() => false),
}

const SaveMultiplesFormValidationRules = {
	labelIdPrefix: Yup.string()
		.max(20, 'collectionsamples.save-multiple-laboratory-extractions-modal.form.labelIdPrefix.validation.maximumlength')
		.default(() => DefaultLabelIdPrefix),
	geneticMaterial: Yup.string()
		.oneOf([""].concat(GeneticMaterials), 'form.validation.string.enum')
		.required()
		.default(() => ""),
	reagent: Yup.string()
		.oneOf([""].concat(Reagents), 'form.validation.string.enum')
		.required()
		.default(() => ""),
	workgroupId: Yup.string()
		.required()
		.default(() => ""),
	extractedById: Yup.string()
		.required()
		.default(() => ""),
	dateExtracted: Yup.date()
		.typeError('laboratoryextractions.save-extraction-modal.form.dateExtracted.validation.format')
		.max(new Date(), "laboratoryextractions.save-extraction-modal.form.dateExtracted.validation.future")
		.required()
		.default(() => ""),
		//.default(() => moment(new Date()).format("YYYY-MM-DD")),
	storageBoxId: Yup.string()
		//.required()
		.default(() => "")
}

const FilterFormValidationRules = {
	laboratoryId: Yup.string()
		.default(() => ""),
	storageBoxId: Yup.string()
		.default(() => ""),
	filter: Yup.string()
		.default(() => ""),
	filteredColumn: Yup.string()
		.default(() => "labelId"),
	start: Yup.string()
		.default(() => ""),
	end: Yup.string()
		.default(() => ""),
	materialSamples: Yup.array()
		.default(() => [])
}

const AdvancedFilterFormFields = {
	storageBoxId: true,
	start: true,
	end : true
}

const NucleicAcidExtractionConfig = { MaterialSamples, DefaultLabelIdPrefix, GeneticMaterials, Reagents, GeneralFormValidationRules, SaveMultiplesFormValidationRules, FilterFormValidationRules, AdvancedFilterFormFields};

export default NucleicAcidExtractionConfig;