import React, { Fragment, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimesCircle, faCheck } from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import FormLabel from 'react-bootstrap/FormLabel';
import InputGroup from 'react-bootstrap/InputGroup';

import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import DismissableFeedback from "../components/DismissableFeedback";

import { useAuth } from "../services/use-auth";
import API from "../services/backend-api";

import { AuthError, ValidationError } from "../helpers/custom-errors";
import { isArrayWithLength } from "../helpers/commons";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "../styles/Typeahead.css";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const validationSchema = Yup.object().shape({
	   taxonData: Yup.object()
	   		.nullable()
	   		.required("collections.search-species-modal.form.scientific-name.validation.required")
});


const SearchSpeciesModal = props => {

	const [_error, _setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [options, setOptions] = useState([]);
	const [pagedSpecies, setPagedSpecies] = useState(null);
	
	const pageSize = (props.pageSize) ? props.pageSize : 5;
	
	const { t } = useTranslation();
	
	const auth = useAuth();
	
	const handlePaginate = (e, number) => {
	
		const pageIndex = Math.ceil(number/pageSize) - 1;
	
		listSpecies({filter: pagedSpecies.filter, pageSize: pageSize, pageIndex: pageIndex})
	      .then(({ list }) => {
			  
			  let updatedOptions = options;
			  
			  list.forEach((item, index) => {
			  
			  	updatedOptions[pageSize*pageIndex + index] = ({
	    		  	scientificName: item.scientificName,
	    		  	taxonId: item.taxonId,
	    		  	taxonRank: item.taxonRank,
	    		  	kingdom: item.kingdom,
	    		  	phylum: item.phylum,	
	    		  	taxonClass: item.taxonClass,
	    		  	order: item.order,
	    		  	family: item.family,
	    		  	genus: item.genus,
	    		  	specificEpithet: item.specificEpithet,
	    		  	infraspecificEpithet: item.infraspecificEpithet
	    	  	})
	    	  })
		 	 
	    	  
	    	  setOptions(updatedOptions);
	    	  
	       })
	      .catch(error => { 
		      if (error instanceof AuthError) {		
		    	  auth.onUnathorized(error);
		      } else if (error instanceof ValidationError) {		
          						
				 log.info("Paginate Species Attempt Failed: ", error.message);
          						
				 _setError(error.message);		
          						
          	 } else {
          		log.error("Paginate Species Error: ", error.message);
          		_setError(t('error.api.general'));
          	 }
		 })
		 
	}
	
	const handleSearch = (term) => {
		
	    listSpecies({filter: term, pageSize: pageSize})
	      .then(({ totalCount, list }) => {
			  
			  const options = Array(totalCount).fill().map((e,index)=> {
			  		if (list[index])
			  			return ({
	    		  			scientificName: list[index].scientificName,
	    		  			taxonId: list[index].taxonId,
	    		  			taxonRank: list[index].taxonRank,
	    		  			kingdom: list[index].kingdom,
	    		  			phylum: list[index].phylum,	    		  			
	    		  			taxonClass: list[index].taxonClass,
	    		  			order: list[index].order,
	    		  			family: list[index].family,
	    		  			genus: list[index].genus,
	    		  			specificEpithet: list[index].specificEpithet,
	    		  			infraspecificEpithet: list[index].infraspecificEpithet
	    	  			})
			  		else
			   			return ({
			   				scientificName: t('collections.search-species-modal.form.filter.loading'),
			   				taxonRank: t('collections.search-species-modal.form.filter.loading')
			   			})
			  });  	 
	    	  
	    	  setOptions(options);
	    	  
	       })
	      .catch(error => { 
		      if (error instanceof AuthError) {		
		    	  auth.onUnathorized(error);
		      } else if (error instanceof ValidationError) {		
          						
				 log.info("Find Species Attempt Failed: ", error.message);
          						
				 _setError(error.message);		
          						
          	 } else {
          		log.error("Find Species Error: ", error.message);
          		_setError(t('error.api.general'));
          	 }
		 })
		 
	  }
	  
	const listSpecies = (values) => {
		
		setIsLoading(true);
		
		return new Promise((resolve, reject) => {
			API.findSpecies(values)
			.then(response => {
				setPagedSpecies(response);
				resolve(response);
			}).catch(error => {			
				reject(error);
			}).finally(() => {
			 setIsLoading(false);
		 	});
		});
		
	}
	
	const renderMenuItemChildren = (option, props) => { 
	
		return (
			<Fragment>
          		<Highlighter search={props.text}>
            		{labelKey(option)}
         		</Highlighter>,
          		{(option.taxonClass) && (
          		<div>
            		<small>
              			<Trans i18nKey="collections.search-species-modal.form.filter.details" values={{item: option}}/>
            		</small>
          		</div>
				)}
        	</Fragment>
    	)
    }
    
    const labelKey = (option) => {
    	return t("collections.search-species-modal.form.filter.label-key", { item: option} ) 
    }

	// Bypass client-side filtering by returning `true`. Results are already
	// filtered by the search endpoint, so no need to do it again.
	const filterBy = () => true;
		
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-search-species"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-search-species">
  					<Trans i18nKey="collections.search-species-modal.title">Search Species</Trans>
  				</Modal.Title>
  			</Modal.Header>
  				<Formik
		      		initialValues={{ taxonData: null }}
		      		validationSchema={validationSchema}
		      		validateOnChange={true}
		      		validateOnBlur={false}     
		      		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);
					
		      			actions.setSubmitting(false);
		      			
		      			props.onChange(values.taxonData);
					}}
		      	>
		      	{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue, validateForm}) => (	
		      		<Form className="form-search-species" noValidate>		
		      		<Modal.Body className="mb-3">
		  				<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  
				    
				    	<FormLabel><Trans i18nKey="collections.search-species-modal.form.filter.label">Search by Scientific Name</Trans></FormLabel>
				    	<InputGroup>
          						<InputGroup.Prepend>
          							<InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
          						</InputGroup.Prepend>
          						<AsyncTypeahead
          							filterBy={filterBy}
          							id="filter"
          							name="filter"
          							isLoading={isLoading}
          							onPaginate={handlePaginate}
        							paginate={true}
									labelKey={(option) => labelKey(option) }
          							minLength={3}
          							maxResults={pageSize}
          							onSearch={handleSearch}
          							onBlur={handleBlur}
          							onChange={(items) => { 
          								if(isArrayWithLength(items)) { 
          									setFieldValue("taxonData", items[0]);
          								}
          							}}
          							isInvalid={!(errors.taxonData == null)}
          							options={options}
          							placeholder={t('collections.search-species-modal.form.filter.placeholder')}
          							emptyLabel={t('collections.search-species-modal.form.filter.no-matches-found')}
          							searchText={t('collections.search-species-modal.form.filter.searching')}
          							promptText={t('collections.search-species-modal.form.filter.prompt')}
          							paginationText={t('collections.search-species-modal.form.filter.paginate')}
          							renderMenuItemChildren={renderMenuItemChildren}
          						/>	
          				</InputGroup>
          				 {!(errors.taxonData == null) 
          					? <div className="text-left invalid-feedback" style={{display: "block"}}><Trans i18nKey={errors.taxonData} /></div>
							: null
						}				
          				</Modal.Body>
          				<Modal.Footer>			      		
          				      <Button variant="success" type="submit" disabled={isSubmitting}> 
          				      		{isSubmitting ? <Trans i18nKey="collections.search-species-modal.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={faCheck} /> <Trans i18nKey="collections.search-species-modal.form.submit">Add</Trans></span>} 
          				      </Button>
          				      <Button variant="secondary" onMouseDown={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="collections.search-species-modal.cancel">Cancel</Trans></Button>	
          				</Modal.Footer>
              		</Form>	

		      )}
		      </Formik>
		   </Modal>
	);
}

export default SearchSpeciesModal;
