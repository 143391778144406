import React,  { useState} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faFileDownload} from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Spinner from 'react-bootstrap/Spinner';

import DataTable from "../components/DataTable";
import DataPagination from "../components/DataPagination";
//import SingleFilterForm from "../components/SingleFilterForm";

import moment from "moment";
import { saveAs } from 'file-saver';
import { CSVLink } from "react-csv";

import { useAuth } from "../services/use-auth";

const PagedList = props => {
	
	const [isExporting, setIsExporting] = useState(false);
	const [exportedData, setExportedData] = useState([]);
	
	const { t } = useTranslation();
	
	const auth = useAuth();
		
	const renderTooltip = (text) => (
  		<Tooltip className="form-control-helper-tooltip" >
    		{text}
  		</Tooltip>
	);
	
	
	const exportList = () => {
		
		setIsExporting(true);
		
		props.onExport({})
			.then(response => {
				//response is a blob
			    saveAs(response, t(props.i18nPrefix+"export-file-name", {user: auth.user, timestamp: moment().format('YYYYMMDD HHmmss')}));
			})
			.catch(error => { 
		 		log.error("Error Exporting List: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		setIsExporting(false);
			});
    } 

	/*const exportListLocal = (event, done) => {
		
		setIsExporting(true);
		
		props.onExport({})
			.then(response => {
				//response is a blob
			    //saveAs(response, t(props.i18nPrefix+"export-file-name", {user: auth.user, timestamp: moment().format('YYYYMMDD HHmmss')}));
				setExportedData(response.list);
				done(true);
			})
			.catch(error => { 
		 		log.error("Error Exporting List Local: ", error.message);
		 		props.onError(error);
				done(false);
			})
		 	.finally(() => {
		 		setIsExporting(false);
			});
    } 

	const getExportedData = () => {
    	return exportedData;
  	}*/
	
	if (!props.page) 
		return null;
		
	return (
		<Container >
			<Row className="mb-3">
        		<Col sm={(props.onAdd) ? 7 : 12} xl={(props.onAdd) ? 8 : 12}>
        			<h3>
        				{t(props.i18nPrefix+"header", {count: props.page.totalCount})}
        				{(props.onExport) && (props.page.totalCount > 0) && (
		            		<OverlayTrigger overlay={renderTooltip(t(props.i18nPrefix+"export-button"))} placement="right" rootClose={true}>	
		            			<Button variant="link" onClick={exportList} disabled={isExporting} > 
		            				{ isExporting 
										? <Spinner animation="border" size="sm" /> 
										: <FontAwesomeIcon icon={faFileDownload} size="lg" className="align-bottom" />
									}
								</Button>
		        			</OverlayTrigger>
		        		)}
						{/*(props.onExport) && (props.page.totalCount > 0) && (
		            		<OverlayTrigger overlay={renderTooltip(t(props.i18nPrefix+"export-button"))} placement="right" rootClose={true}>	
		            			<CSVLink
      								data={exportedData}
      								asyncOnClick={true}
      								onClick={exportListLocal}
									filename={t(props.i18nPrefix+"export-file-name", {user: auth.user, timestamp: moment().format('YYYYMMDD HHmmss')})}
    							>
      								<Button variant="link" disabled={isExporting} > 
		            				{ isExporting 
										? <Spinner animation="border" size="sm" /> 
										: <FontAwesomeIcon icon={faFileDownload} size="lg" className="align-bottom" />
									}
									</Button>
    							</CSVLink>
		        			</OverlayTrigger>
		        		)*/}
        			</h3>
        		</Col>
				{(props.onAdd) && (
					<Col sm={5} xl={4}>
						<div className="d-flex justify-content-end">
							<Button variant="outline-success"  onClick={() => {props.onAdd();}}><FontAwesomeIcon icon={faPlusCircle} /> <Trans i18nKey={props.i18nPrefix+"add-button"} /></Button>	
						</div>
					</Col>
				)}
        	</Row>
        	<Row className="mb-3">
    			{/*<Col sm={(props.onAdd) ? 7 : 12} xl={(props.onAdd) ? 8 : 12}>*/}
    			<Col>
				{(props.filterFormComponent) && (
						React.createElement(props.filterFormComponent,
						{
							"i18nPrefix": props.i18nPrefix+"form.",
							"parameters": (props.filterParameters) ? props.filterParameters : {},
    			    		"onSubmit": props.onFilter, 
    			    		"onError": props.onError
						},
						null)
    			 )}
    			</Col>
    			
				{/*(props.onAdd) && (
					<Col sm={5} xl={4}>
						<div className="d-flex justify-content-end">
							<Button variant="outline-success"  onClick={() => {props.onAdd();}}><FontAwesomeIcon icon={faPlusCircle} /> <Trans i18nKey={props.i18nPrefix+"add-button"} /></Button>	
						</div>
					</Col>
				)*/}
    		</Row>
        	<Row>
        		<Col>
        			<DataTable 
        				items={(props.list) ? props.list : props.page.list} 
        				i18nPrefix={props.i18nPrefix+"datatable."} 
        				columns={props.dataTableColumns} 
        				sortableColumns={props.dataTableSortableColumns}
        				sortBy={props.page.sortBy}
        				order={props.page.order}
        				actions={props.dataTableActions}
        				defaultAction={props.dataTableDefaultAction}
						multipleSelectActions={props.dataTableMultipleSelectActions}
        				sortDataTable={props.onSort}
        				customDisplayColumns={props.dataTableCustomDisplayColumns}
        				customRowStyle={props.dataTableCustomRowStyle}
						handleTextOverflow={props.dataTableHandleTextOverflow}
        			/>
        		</Col>
        	</Row>
        	<Row>
        		<Col>
        			<DataPagination className="justify-content-end"
        				totalCount={props.page.totalCount}
        				pageIndex={props.page.pageIndex}
        				pageSize={props.page.pageSize}
						pageSizeOptions={props.pageSizeOptions}
        				totalPageCount={props.page.totalPageCount}
        				hasPrev={props.page.hasPrev}
        				hasNext={props.page.hasNext}
        				updatePage={props.onPage} />		
        		</Col>
        	</Row>
      </Container>
	);
}

export default PagedList;
