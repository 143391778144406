import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import Button from 'react-bootstrap/Button'
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import '../styles/SingleFilterForm.css';

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const validationSchema = Yup.object().shape({
	   filter: Yup.string(),
	   filteredColumn: Yup.string()
});

const SingleFilterForm = props => {
	
	const { t } = useTranslation();
	
	return (
		<Formik
			initialValues={{ filter: props.parameters.filter, filteredColumn: props.parameters.filteredColumn }}
			validationSchema={validationSchema}
			validateOnChange={false}
			validateOnBlur={false}     
			onSubmit={(values, actions) => {
				props.onSubmit(values)
			 	.catch(error => { 
			 		log.error("Error Filtering Items: ", error.message);
			 		props.onError(error);
			 	})
			 	.finally(() => {
			 		actions.setSubmitting(false);
			 	});
			}}
		>
		{({isSubmitting, errors, values, handleChange, setFieldValue}) => (	
	
			<Form className="form-filter-data" noValidate>
				<InputGroup className="mb-3">
					<InputGroup.Prepend>
						<InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
					</InputGroup.Prepend>
					{ ((props.type === "select") && (props.filterOptions)) ?
							<FormControl as="select" name="filter" isInvalid={!(errors.filter == null)} value={values.filter} onChange={handleChange} >
		    					<option  value="">{t(props.i18nPrefix+"filter.options.blank")}</option>
		    						{ props.filterOptions.map(item =>
		    							<option key={item.id} className="text-uppercase" value={item.id}>{item.name}</option>
		    						)}
		    				</FormControl>
						:
							<FormControl type={'search'} name="filter" isInvalid={!(errors.filter == null)} value={values.filter} onChange={handleChange} placeholder={t(props.i18nPrefix+"filter.placeholder")} />
					 }
					<InputGroup.Append>
						<Button type="submit" disabled={isSubmitting} onClick={() => {}}> 
							{isSubmitting ? <Trans i18nKey={props.i18nPrefix+"submitting"}>Submit</Trans> : <Trans i18nKey={props.i18nPrefix+"submit"}>Submit</Trans>} 
						</Button>
					</InputGroup.Append>
				</InputGroup>
			</Form>	
		)}
	</Formik>
	)

}

export default SingleFilterForm;
