import React,  { Fragment, useState, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompass, faSearch, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import FormCheck from 'react-bootstrap/FormCheck';
import FormText from 'react-bootstrap/FormText';

import InputGroup from 'react-bootstrap/InputGroup';

import moment from "moment";
import { DatePicker } from "react-tempusdominus-bootstrap";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

import FormControlErrors from "../components/FormControlErrors";
import FormControlHelper from "../components/FormControlHelper";

import { ValidationError } from "../helpers/custom-errors";

import API from "../services/backend-api";
import { useAuth } from "../services/use-auth";
import { useConfig } from "../services/use-config";

import CollectionConfig from "../config/CollectionConfig";

import SetCollectionCoordinatesModal from "../components/SetCollectionCoordinatesModal";
import SetCollectionLocationModal from "../components/SetCollectionLocationModal";
import SetCollectionLicenseModal from "../components/SetCollectionLicenseModal";
import SetCollectionIdentifiedByModal from "../components/SetCollectionIdentifiedByModal";

import SearchSpeciesModal from "../components/SearchSpeciesModal";
import MultipleImageUploader from "../components/MultipleImageUploader";

import { parseInputDate, isArrayWithLength } from "../helpers/commons";

import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "../styles/Typeahead.css";

const GeneralSection = props => {

	const [workgroups, setWorkgroups] = useState([]);
	const [workgroupUsers, setWorkgroupUsers] = useState([]);
	const [coordinatesModalShow, setCoordinatesModalShow] = useState(false);
	const [locationModalShow, setLocationModalShow] = useState(false);
	
	const { t } = useTranslation();
	
	const auth = useAuth();
	
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
		
		//Fetch Collecting Workgroups
		API.findWorkgroups({"types": ["OBSERVATORY"]}, auth.isAdmin())
			.then(response => {
				if (isMounted) setWorkgroups(response.list);
			})
			.catch(error => { 
				log.error("Error Loading Collecting Workgroups: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		//Get Workgroup Users
				if (props.values.workgroupId) {
					getWorkgroupUsers(props.values.workgroupId)
				}
			});
		
		 return () => { isMounted = false };
		  
	}, []);
	
        
    const getWorkgroupUsers = (workgroupId) => {
    	API.getWorkgroupUsers(workgroupId, auth.isAdmin())
			.then(response => {
					setWorkgroupUsers(response.list); 					
			})
			.catch(error => { 
				log.error("Error Loading Workgroup Users: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		  //Do nothing yet
			});
    }
	
	const handleWorkgroupChange = (e) => {
        
        props.setFieldValue("workgroupId", e.target.value);
        
        if (!e.target.value) {
        	setWorkgroupUsers([]);
        	props.setFieldValue("capturedById", "");
        	return;
        }
        
        //Get Workgroup Users
        getWorkgroupUsers(e.target.value);
        
        //Reset capturedBy
        props.setFieldValue("capturedById", "");
    }
    
    const handleCaptureTypeChange = (e) => {
        
        props.setFieldValue("captureType", e.target.value);
        
        if (e.target.value === "COLLECTED") {
        	props.setFieldValue("markingType", "");
        	props.setFieldValue("bandMarkingInfo", "");
        } else if (e.target.value === "MARKED_AND_RELEASED") {
        	props.setFieldValue("fieldNumber", "");
        } 
    }
    
    const handleMarkingTypeChange = (e) => {
        
        props.setFieldValue("markingType", e.target.value);
        
        if (e.target.value === "TEMPORARY") {
        	props.setFieldValue("bandMarkingInfo", "");
        }
        
    }
    
    
    const handleCoordinatesChange = (latitude, longitude) => {
	  
	  props.setFieldValue("latitude", latitude);
	  props.setFieldValue("longitude", longitude);
	  
	  setCoordinatesModalShow(false);
   }
   
   const handleLocationChange = (county, stateProvince, country, latitude, longitude) => {
	  
	  props.setFieldValue("county", county);
	  props.setFieldValue("stateProvince", stateProvince);
	  props.setFieldValue("country", country);
	  
	  if (latitude && longitude) {
	  	props.setFieldValue("latitude", latitude);
	  	props.setFieldValue("longitude", longitude);
	  }
	  
	  setLocationModalShow(false);
   }
   
   const displayLocation = (county, stateProvince, country) => {						   
	  return county + (stateProvince ? ' - ' + stateProvince :  country ? ' - ' + country : '');
   }
	
	return(
		<Fragment>
		<Row>
			
			<FormGroup as={Col} controlId="formGridWorkgroup">
				<FormLabel><Trans i18nKey="collections.save.form.workgroupId.label">Workgroup</Trans> *</FormLabel>
				<FormControl as="select" name="workgroupId" isInvalid={!(props.errors.workgroupId == null)} value={props.values.workgroupId} onChange={handleWorkgroupChange} >
		    			<option value="">{t("collections.save.form.workgroupId.blank-option")}</option>
		    			{ workgroups.map(item =>
		    					<option key={item.id} value={item.id+""}>{item.name}</option>
		    			)}
		    	</FormControl>
				<FormControlErrors errors={props.errors.workgroupId} />
			</FormGroup>
			<FormGroup as={Col} controlId="formGridCapturedBy">
				<FormLabel><Trans i18nKey="collections.save.form.capturedById.label">Captured By</Trans> *</FormLabel>
				<FormControl as="select" disabled={!(props.values.workgroupId)} name="capturedById" isInvalid={!(props.errors.capturedById == null)} value={props.values.capturedById} onChange={props.onChange} >
		    			<option value="">{t("collections.save.form.capturedById.blank-option")}</option>
		    			{ workgroupUsers.map(item =>
		    					<option key={item.id} value={item.id}>{item.firstName+" "+item.lastName}</option>
		    			)}
		    	</FormControl>
				<FormControlErrors errors={props.errors.capturedById} />
			</FormGroup>
			
			<FormGroup as={Col} controlId="formGridEventDate">
				<FormLabel><Trans i18nKey="collections.save.form.eventDate.label">Event Date</Trans> *</FormLabel>
				<DatePicker
      				format="DD/MM/YYYY"
      				className={!(props.errors.eventDate == null) ? "is-invalid border border-danger rounded" : ""}
      				onChange={(e) => { 
      					if (e.date) { 
      						if (e.date.isValid()) 
      							props.setFieldValue("eventDate", moment(e.date).format("YYYY-MM-DD"));
      						else
      							props.setFieldValue("eventDate", e.date.parsingFlags().inputDate);
      					} else if (e.date === null) { // reset if null (which is !== from undefined)
      						props.setFieldValue("eventDate", "");
      					}
      				}}
      				date={props.values.eventDate}
      				parseInputDate={parseInputDate}
      				locale={config.preferredLanguage}
      				keepInvalid={true}
    			/>
				<FormControlErrors errors={props.errors.eventDate} />
			</FormGroup>
		</Row>
		<Row>
			
			<FormGroup as={Col} controlId="formGridLocation">
				<SetCollectionLocationModal
						show={locationModalShow}
	        			onHide={() => setLocationModalShow(false)}
						size="lg"
						onLocationChange={handleLocationChange}
		      	/>
				<FormLabel><Trans i18nKey="collections.save.form.location.label">Location</Trans> *</FormLabel>
				<InputGroup onClick={() => {setLocationModalShow(true);}}>
					<FormControl className="text-uppercase" type={'text'} name="location" isInvalid={!(props.errors.county == null)} style={{backgroundColor: "#ffffff"}} readOnly value={displayLocation(props.values.county, props.values.stateProvince, props.values.country)} />
					<InputGroup.Append >
						<InputGroup.Text ><FontAwesomeIcon icon={faSearch} /></InputGroup.Text> 
					</InputGroup.Append>
					<FormControlErrors errors={props.errors.county} />
				</InputGroup>
			</FormGroup>
			
			<FormGroup as={Col} controlId="formGridCoordinates">
				<SetCollectionCoordinatesModal
						show={coordinatesModalShow}
	        			onHide={() => setCoordinatesModalShow(false)}
						size="lg"
						latitude={props.values.latitude}
						longitude={props.values.longitude}
						onCoordinatesChange={handleCoordinatesChange}
		      	/>
				<FormLabel><Trans i18nKey="collections.save.form.coordinates.label">Coordinates</Trans> *</FormLabel>
				<InputGroup onClick={() => {setCoordinatesModalShow(true);}}  >
					<FormControl type={'text'} name="coordinates" isInvalid={!(props.errors.latitude == null)||!(props.errors.longitude == null)} style={{backgroundColor: "#ffffff"}} readOnly value={(props.values.latitude || props.values.longitude) ? props.values.latitude + ", " + props.values.longitude : "" } />
					<InputGroup.Append>
						<InputGroup.Text ><FontAwesomeIcon icon={faCompass} /></InputGroup.Text> 
					</InputGroup.Append>
					<FormControlErrors errors={!(props.errors.latitude == null) ? props.errors.latitude : props.errors.longitude} />
				</InputGroup>
			</FormGroup>
			
			<FormGroup as={Col} controlId="formGridLocality">
				<FormLabel><Trans i18nKey="collections.save.form.locality.label">Locality</Trans> <FormControlHelper text="collections.save.form.locality.helper"/></FormLabel>
				<FormControl className="text-uppercase" type={'text'} name="locality" isInvalid={!(props.errors.locality == null)} value={props.values.locality} onChange={props.onChange} placeholder={t('collections.save.form.locality.placeholder')} />
				<FormControlErrors errors={props.errors.locality} />
			</FormGroup>
			
		</Row>
		
		<Row>
		
			<FormGroup as={Col} controlId="formGridCaptureMethod">
				<FormLabel><Trans i18nKey="collections.save.form.captureMethod.label">Capture Method</Trans> *</FormLabel>
				<FormControl as="select" name="captureMethod" isInvalid={!(props.errors.captureMethod == null)} value={props.values.captureMethod} onChange={props.onChange} >
		    		<option  value="">{t("collections.save.form.captureMethod.options.blank")}</option>
		    		{ CollectionConfig.CaptureMethods.map(item =>
		    			<option key={item} className="text-uppercase" value={item}>{t("collections.save.form.captureMethod.options."+item)}</option>
		    		)}
		    	</FormControl>
				<FormControlErrors errors={props.errors.captureMethod} />
			</FormGroup>
			
			<FormGroup as={Col} controlId="formGridSamplingEffort">
				<FormLabel><Trans i18nKey="collections.save.form.samplingEffort.label">Sampling Effort</Trans> <FormControlHelper text="collections.save.form.samplingEffort.helper"/></FormLabel>
				<FormControl className="text-uppercase" type={'text'} name="samplingEffort" isInvalid={!(props.errors.samplingEffort == null)} value={props.values.samplingEffort} onChange={props.onChange} placeholder={t('collections.save.form.samplingEffort.placeholder')} />
				<FormControlErrors errors={props.errors.samplingEffort} />
			</FormGroup>
			
			<FormGroup as={Col} controlId="formGridHabitat">
				<FormLabel><Trans i18nKey="collections.save.form.habitat.label">Habitat</Trans> <FormControlHelper text="collections.save.form.habitat.helper"/></FormLabel>
				<FormControl className="text-uppercase" type={'text'} name="habitat" isInvalid={!(props.errors.habitat == null)} value={props.values.habitat} onChange={props.onChange} placeholder={t('collections.save.form.habitat.placeholder')} />
				<FormControlErrors errors={props.errors.habitat} />
			</FormGroup>
			
		</Row>

		<Row>
			<FormGroup as={Col} md={4} controlId="formGridCaptureType">
				<FormLabel><Trans i18nKey="collections.save.form.captureType.label">Capture Type</Trans> *</FormLabel>
				<fieldset className={!(props.errors.captureType == null) ? "is-invalid" : ""}>
					<div className={!(props.errors.captureType == null) ? "border border-danger rounded p-2" : "border rounded p-2"}>
						<Row>
							{ CollectionConfig.CaptureTypes.map((item, index) => {
								return (
									<Col key={index} md={12} className="pl-4" >
										<FormCheck 
        									name="captureType" 
        									className="text-uppercase"
        									type="radio"
        									id={"capture-type"+index}
        									value={item}
        									label={t("collections.save.form.captureType.options."+item)}
        									onChange={handleCaptureTypeChange}
        									checked={(props.values.captureType === item)}
      									/>
									</Col>
								)
							})}
						</Row>
        			</div>
				</fieldset>
				<FormControlErrors errors={props.errors.captureType} />
			</FormGroup>
			
			{ (props.values.captureType === "MARKED_AND_RELEASED") ?
			
				<Fragment>
					<FormGroup as={Col} md={4} controlId="formGridMarkingType">
						<FormLabel><Trans i18nKey="collections.save.form.markingType.label">Marking Type</Trans></FormLabel>
						<fieldset className={!(props.errors.markingType == null) ? "is-invalid" : ""} disabled={props.values.captureType !== "MARKED_AND_RELEASED"}>
							<div className={!(props.errors.markingType == null) ? "border border-danger rounded p-2" : "border rounded p-2"}>
								<Row>
									{ CollectionConfig.MarkingTypes.map((item, index) => {
										return (
											<Col key={index} md={12} className="pl-4" >
												<FormCheck 
													custom
        											name="markingType"
        											className="text-uppercase"
        											type="radio"
        											id={"marking-type"+index}
        											value={item}
        											label={t("collections.save.form.markingType.options."+item)}
        											onChange={handleMarkingTypeChange}
        											checked={(props.values.markingType === item)}
      											/>
											</Col>
										)
									})}
								</Row>
        					</div>
						</fieldset>
						<FormControlErrors errors={props.errors.markingType} />
					</FormGroup>

					<FormGroup as={Col} md={4} controlId="formGridBandMarkingInfo">
						<FormLabel><Trans i18nKey="collections.save.form.bandMarkingInfo.label">Band Marking Info</Trans> <FormControlHelper text="collections.save.form.bandMarkingInfo.helper"/></FormLabel>
						<FormControl className="text-uppercase" disabled={(props.values.captureType !== "MARKED_AND_RELEASED" || props.values.markingType !== "PERMANENT") } as="textarea" rows={2} maxLength={100} name="bandMarkingInfo" isInvalid={!(props.errors.bandMarkingInfo == null)} value={props.values.bandMarkingInfo} onChange={props.onChange} placeholder={t('collections.save.form.bandMarkingInfo.placeholder')} />
						<FormControlErrors errors={props.errors.bandMarkingInfo} />
					</FormGroup>
				</Fragment> :
				
			(props.values.captureType === "COLLECTED") ?
				
				<FormGroup as={Col} md={4} controlId="formGridFieldNumber">
					<FormLabel><Trans i18nKey="collections.save.form.fieldNumber.label">Field Number</Trans></FormLabel>
					<FormControl className="text-uppercase" as="textarea" rows={2} maxLength={100} name="fieldNumber" isInvalid={!(props.errors.bandMarkingInfo == null)} value={props.values.fieldNumber} onChange={props.onChange} placeholder={t('collections.save.form.fieldNumber.placeholder')} />
					<FormControlErrors errors={props.errors.fieldNumber} />
				</FormGroup>
				
			: null
			
			}

		</Row>
		</Fragment>
	) 
}

const TaxonomySection = props => {

	const [searchSpeciesModalShow, setSearchSpeciesModalShow] = useState(false);
	const [identifiedByModalShow, setIdentifiedByModalShow] = useState(false);
	const [identifiedByName, setIdentifiedByName] = useState('');
	
	const { t } = useTranslation();
	
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
		
		//Fetch Identified By User
		if (props.values.identifiedById) {
		
			API.findUsers({"filteredColumn": "id", "filter": props.values.identifiedById})
				.then(response => {
					if (isMounted) {
						if(isArrayWithLength(response.list) && response.list.length === 1) {
							let user = response.list[0];
							setIdentifiedByName(user.firstName + " " + user.lastName);
						} else {
							log.error("Failed Loading User using Identified By Id: ", response);
		 					props.onError(new ValidationError("users-02", 400, [], t("collections.save.form.identifiedById.validation.userNotFound")));
						}
					}
				})
				.catch(error => { 
					log.error("Error Loading Users: ", error.message);
		 			props.onError(error);
				})
			}
		
		 return () => { isMounted = false };
		  
	}, []);
	
	const handleIdentifiedByChange = (user) => {
	  
	  props.setFieldValue("identifiedById", user.id);
	  
	  setIdentifiedByName(user.name);
	  
	  setIdentifiedByModalShow(false);
   }
	
	const handleSpeciesChange = (item) => {
	  
	  props.setFieldValue("scientificName", (item.scientificName) ? item.scientificName : "");
	  props.setFieldValue("taxonId", (item.taxonId) ? item.taxonId : "");
	  props.setFieldValue("taxonRank", (item.taxonRank) ? item.taxonRank : "");
	  props.setFieldValue("kingdom", (item.kingdom) ? item.kingdom : "");
	  props.setFieldValue("phylum", (item.phylum) ? item.phylum : "");	
	  props.setFieldValue("taxonGroup", 
			  (item.taxonClass && item.taxonClass.toUpperCase().includes("AVES")) ? "AVES" 
			  : (item.order && item.order.toUpperCase().includes("CHIROPTERA")) ? "CHIROPTERA" 
			  : (item.order && item.order.toUpperCase().includes("RODENTIA")) ? "RODENTIA" 
			  : (item.order && item.order.toUpperCase().includes("PRIMATES")) ? "PRIMATES" 
			  : "OTHER");
	  props.setFieldValue("taxonClass", (item.taxonClass) ? item.taxonClass : "" ); 		
	  props.setFieldValue("order", (item.order) ? item.order : "" );	
	  props.setFieldValue("family",(item.family) ? item.family : "" );
	  props.setFieldValue("genus", (item.genus) ? item.genus : "");	
	  props.setFieldValue("specificEpithet", (item.specificEpithet) ? item.specificEpithet : "");
	  props.setFieldValue("infraspecificEpithet", (item.infraspecificEpithet) ? item.infraspecificEpithet : "");

	  setSearchSpeciesModalShow(false);
   }
	
	return(
		<Fragment>
		<Row>
			<FormGroup as={Col} md={8} controlId="formGridScientificName">
				<SearchSpeciesModal
						show={searchSpeciesModalShow}
	        			onHide={() => setSearchSpeciesModalShow(false)}
						size="lg"
						onChange={handleSpeciesChange}
		      		/>
				<FormLabel><Trans i18nKey="collections.save.form.scientificName.label">Scientific Name</Trans> *</FormLabel>
				
				<InputGroup onClick={() => {setSearchSpeciesModalShow(true);}}>
					<FormControl className="text-uppercase" type={'text'} name="scientificName" isInvalid={!(props.errors.scientificName == null)} value={props.values.scientificName} style={{backgroundColor: "#ffffff"}} readOnly />
					<InputGroup.Append >
						<InputGroup.Text ><FontAwesomeIcon icon={faSearch} /></InputGroup.Text> 
					</InputGroup.Append>
					<FormControlErrors errors={props.errors.scientificName} />
				</InputGroup>
			
			</FormGroup>
		
			
			<FormGroup as={Col} controlId="formGridTaxonGroup">
				<FormLabel><Trans i18nKey="collections.save.form.taxonGroup.label">Taxon Group</Trans> *</FormLabel>
				<FormControl readOnly name="taxonGroup" isInvalid={!(props.errors.taxonGroup == null)} value={props.values.taxonGroup} >
		    	</FormControl>
				<FormControlErrors errors={props.errors.taxonGroup} />
			</FormGroup>
			
					
			{/*<FormGroup as={Col} controlId="formGridTaxonGroup">
				<FormLabel><Trans i18nKey="collections.save.form.taxonGroup.label">Taxon Group</Trans> *</FormLabel>
				<FormControl as="select" name="taxonGroup" isInvalid={!(props.errors.taxonGroup == null)} value={props.values.taxonGroup} onChange={props.onChange} >
		    		<option value="">{t("collections.save.form.taxonGroup.options.blank")}</option>
		    		{ CollectionConfig.TaxonGroups.map(item =>
		    			<option key={item} value={item}>{t("collections.save.form.taxonGroup.options."+item)}</option>
		    		)}
		    	</FormControl>
				<FormControlErrors errors={props.errors.taxonGroup} />
			</FormGroup>
			*/}
			
			
		</Row>
		<Row>
			
			<FormGroup as={Col} controlId="formGridVernacularName">
				<FormLabel><Trans i18nKey="collections.save.form.vernacularName.label">Vernacular Name</Trans> *</FormLabel>
				<FormControl className="text-uppercase" type={'text'} name="vernacularName" isInvalid={!(props.errors.vernacularName == null)} value={props.values.vernacularName} onChange={props.onChange} placeholder={t('collections.save.form.vernacularName.placeholder')} />
				<FormControlErrors errors={props.errors.vernacularName} />
			</FormGroup>
			
			{/*<FormGroup as={Col} controlId="formGridIdentifiedBy">
				<FormLabel><Trans i18nKey="collections.save.form.identifiedById.label">Identified By</Trans> *</FormLabel>
				<FormControl as="select" name="identifiedById" isInvalid={!(props.errors.identifiedById == null)} value={props.values.identifiedById} onChange={props.onChange} >
		    			<option value="">{t("collections.save.form.identifiedById.blank-option")}</option>
		    			{ users.map(item =>
		    					<option key={item.id} value={item.id}>{item.firstName+" "+item.lastName}</option>
		    			)}
		    	</FormControl>
				<FormControlErrors errors={props.errors.identifiedById} />
			</FormGroup>
			*/}
			
			<FormGroup as={Col} controlId="formGridIdentifiedBy">
				<SetCollectionIdentifiedByModal
						show={identifiedByModalShow}
	        			onHide={() => setIdentifiedByModalShow(false)}
						size="lg"
						onChange={handleIdentifiedByChange}
		      		/>
				<FormLabel><Trans i18nKey="collections.save.form.identifiedById.label">Identified By</Trans> *</FormLabel>
				
				<InputGroup onClick={() => {setIdentifiedByModalShow(true);}}>
					<FormControl className="text-uppercase" type={'text'} isInvalid={!(props.errors.identifiedById == null)} value={identifiedByName} style={{backgroundColor: "#ffffff"}} readOnly />
					<InputGroup.Append >
						<InputGroup.Text ><FontAwesomeIcon icon={faSearch} /></InputGroup.Text> 
					</InputGroup.Append>
					<FormControlErrors errors={props.errors.identifiedById} />
				</InputGroup>
			
			</FormGroup>
			
			<FormGroup as={Col} controlId="formGridDateIdentified">
				<FormLabel><Trans i18nKey="collections.save.form.dateIdentified.label">Date Identified</Trans> *</FormLabel>
				<DatePicker
      			    format="L"
      				className={!(props.errors.dateIdentified == null) ? "is-invalid border border-danger rounded" : ""}
      				onChange={(e) => { 
      					if (e.date) { 
      						if (e.date.isValid()) 
      							props.setFieldValue("dateIdentified", moment(e.date).format("YYYY-MM-DD"));
      						else
      							props.setFieldValue("dateIdentified", e.date.parsingFlags().inputDate);
      					} else if (e.date === null) { // reset if null (which is !== from undefined)
      						props.setFieldValue("dateIdentified", "");
      					}
      				}}
      				date={props.values.dateIdentified}
      				parseInputDate={parseInputDate}
      				locale={config.preferredLanguage}
      				keepInvalid={true}
    			/>
				<FormControlErrors errors={props.errors.dateIdentified} />
			</FormGroup>
		</Row>
		
		</Fragment>
	) 

}

const HistorySection = props => {

	const [reproductiveConditionOptions, setReproductiveConditionOptions] = useState([]);
	
	const { t } = useTranslation();
	
	useEffect(() => {
		let isMounted = true; 

		if (props.values.sex) {
			// Filter ReproductiveCondition Options
			if (isMounted) setReproductiveConditionOptions(CollectionConfig.ReproductiveConditions[props.values.sex]);
		};
		
		 return () => { isMounted = false };
		  
	}, []);
	
	const handleSexChange = (e) => {
        
        props.setFieldValue("sex", e.target.value);
        
        if (!e.target.value) {
        	setReproductiveConditionOptions([]);
        	props.setFieldValue("reproductiveCondition", "");
        	return;
        }

		// Filter ReproductiveCondition Options
		setReproductiveConditionOptions(CollectionConfig.ReproductiveConditions[e.target.value]);
        
        //Reset Reproductive Status on every change
        props.setFieldValue("reproductiveCondition", "");
         
    }
	
	return(
		<Fragment>
		<Row>
			<FormGroup as={Col} controlId="formGridSpecimenSex">
				<FormLabel><Trans i18nKey="collections.save.form.sex.label">Specimen Sex</Trans> *</FormLabel>
				<FormControl as="select" name="sex" isInvalid={!(props.errors.sex == null)} value={props.values.sex} onChange={handleSexChange} >
		    		<option value="">{t("collections.save.form.sex.options.blank")}</option>
		    		{ CollectionConfig.Sexes.map(item =>
		    			<option key={item} value={item}>{t("collections.save.form.sex.options."+item)}</option>
		    		)}
		    	</FormControl>
				<FormControlErrors errors={props.errors.sex} />
			</FormGroup>

			<FormGroup as={Col} md={5} controlId="formGridReproductiveConditions">
				<FormLabel><Trans i18nKey="collections.save.form.reproductiveCondition.label">Specimen Reproductive Status</Trans></FormLabel>
				<FormControl as="select" name="reproductiveCondition" disabled={!(props.values.sex)} isInvalid={!(props.errors.reproductiveCondition == null)} value={props.values.reproductiveCondition} onChange={props.onChange} >
		    		<option value="">{t("collections.save.form.reproductiveCondition.options.blank")}</option>
		    		{ reproductiveConditionOptions.map(item =>
		    				<option key={item} value={item}>{t("collections.save.form.reproductiveCondition.options."+item)}</option>
		    		)}
		    	</FormControl>
				<FormControlErrors errors={props.errors.reproductiveCondition} />
			</FormGroup>

			<FormGroup as={Col} controlId="formGridLifeStages">
				<FormLabel><Trans i18nKey="collections.save.form.lifeStage.label">Specimen Age Groups</Trans> *</FormLabel>
				<FormControl as="select" name="lifeStage" isInvalid={!(props.errors.lifeStage == null)} value={props.values.lifeStage} onChange={props.onChange} >
		    		<option value="">{t("collections.save.form.lifeStage.options.blank")}</option>
		    		{ CollectionConfig.LifeStages.map(item =>
		    					<option key={item} value={item}>{t("collections.save.form.lifeStage.options."+item)}</option>
		    		)}
		    	</FormControl>
				<FormControlErrors errors={props.errors.lifeStage} />
			</FormGroup>

		</Row>
		
		<Row>
			<FormGroup as={Col} controlId="formGridEstablishmentMeans">
				<FormLabel><Trans i18nKey="collections.save.form.establishmentMeans.label">Establishment Means</Trans> *</FormLabel>
				<FormControl as="select" name="establishmentMeans" isInvalid={!(props.errors.establishmentMeans == null)} value={props.values.establishmentMeans} onChange={props.onChange} >
		    		<option value="">{t("collections.save.form.establishmentMeans.options.blank")}</option>
		    		{ CollectionConfig.EstablishmentMeans.map(item =>
		    			<option key={item} value={item}>{t("collections.save.form.establishmentMeans.options."+item)}</option>
		    		)}
		    	</FormControl>
				<FormControlErrors errors={props.errors.establishmentMeans} />
			</FormGroup>
			
			<FormGroup as={Col} md={5} controlId="formGridDegreeOfEstablishment">
				<FormLabel><Trans i18nKey="collections.save.form.degreeOfEstablishment.label">Degree Of Establishment</Trans> *</FormLabel>
				<FormControl as="select" name="degreeOfEstablishment" isInvalid={!(props.errors.degreeOfEstablishment == null)} value={props.values.degreeOfEstablishment} onChange={props.onChange} >
		    		<option value="">{t("collections.save.form.degreeOfEstablishment.options.blank")}</option>
		    		{ CollectionConfig.DegreesOfEstablishment.map(item =>
		    			<option key={item} value={item}>{t("collections.save.form.degreeOfEstablishment.options."+item)}</option>
		    		)}
		    	</FormControl>
				<FormControlErrors errors={props.errors.degreeOfEstablishment} />
			</FormGroup>
			
			<FormGroup as={Col} controlId="formGridSymptomaticConditions">
				<FormLabel><Trans i18nKey="collections.save.form.symptomaticCondition.label">Symptomatic Condition</Trans></FormLabel>
				<FormControl as="select" name="symptomaticCondition" isInvalid={!(props.errors.symptomaticCondition == null)} value={props.values.symptomaticCondition} onChange={props.onChange} >
		    		<option value="">{t("collections.save.form.symptomaticCondition.options.blank")}</option>
		    		{ CollectionConfig.SymptomaticConditions.map(item =>
		    			<option key={item} value={item}>{t("collections.save.form.symptomaticCondition.options."+item)}</option>
		    		)}
		    	</FormControl>
				<FormControlErrors errors={props.errors.symptomaticCondition} />
			</FormGroup>

		</Row>
		
		<Row>
		<FormGroup as={Col} controlId="formGridHistoryRemarks">
			<FormLabel><Trans i18nKey="collections.save.form.historyRemarks.label">History Remarks</Trans> <FormControlHelper text="collections.save.form.historyRemarks.helper"/></FormLabel>
			<FormControl className="text-uppercase" as="textarea" rows={3} maxLength={255} name="historyRemarks" isInvalid={!(props.errors.historyRemarks == null)} value={props.values.historyRemarks} onChange={props.onChange} placeholder={t('collections.save.form.historyRemarks.placeholder')} />
			<FormControlErrors errors={props.errors.historyRemarks} />
		</FormGroup> 
		</Row>
		
		
		{/*<Row>

			<FormGroup as={Col} md={5} controlId="formGridDegreeOfEstablishment">
				<FormLabel><Trans i18nKey="collections.save.form.degreeOfEstablishment.label">Specimen Type</Trans> *</FormLabel>
				<fieldset className={!(props.errors.degreeOfEstablishment == null) ? "is-invalid" : ""}>
					<div className={!(props.errors.degreeOfEstablishment == null) ? "border border-danger rounded p-2" : "border rounded p-2"}>
				    	<Row>
							{ CollectionConfig.DegreesOfEstablishment.map((item, index) => 
								<Col key={index} md={12} className="pl-4" >
									<FormCheck 
        								name="degreeOfEstablishment" 
        								className="text-uppercase"
        								type="radio"
        								id={index}
        								value={item}
        								label={t("collections.save.form.degreeOfEstablishment.options."+item)}
        								onChange={props.onChange}
        								checked={(props.values.degreeOfEstablishment === item)}
      								/>
								</Col>
							)}
                		</Row>
        			</div>
				</fieldset>
				<FormControlErrors errors={props.errors.degreeOfEstablishment} />
			</FormGroup>

			<FormGroup as={Col} md={7} controlId="formGridSymptomaticConditions">
				<FormLabel><Trans i18nKey="collections.save.form.symptomaticCondition.label">Specimen Symptomatic Status</Trans></FormLabel>
				<fieldset className={!(props.errors.symptomaticCondition == null) ? "is-invalid" : ""}>
					<div className={!(props.errors.symptomaticCondition == null) ? "border border-danger rounded p-2" : "border rounded p-2"}>
				    	<Row>
							{ CollectionConfig.SymptomaticConditions.map((item, index) => 
								<Col key={index} md={6} className="pl-4" >
									<FormCheck 
        								name="symptomaticCondition" 
        								className="text-uppercase"
        								type="radio"
        								id={index}
        								value={item}
        								label={t("collections.save.form.symptomaticCondition.options."+item)}
        								onChange={props.onChange}
        								checked={(props.values.symptomaticCondition === item)}
      								/>
								</Col>
							)}
                		</Row>
        			</div>
				</fieldset>
				<FormControlErrors errors={props.errors.symptomaticCondition} />
			</FormGroup>
		</Row>
		
		*/}
		</Fragment>
	) 
}

const PhysicalExamSection = props => {
	
	const { t } = useTranslation();
	
	useEffect(() => {
		
		let isMounted = true; 
		
		if (props.values.taxonGroup){
			
			const allMeasurementFieldKeys = [
				...new Set(Object.values(CollectionConfig.MeasurementFields).flat(1).map((item) => item.key))
			]
			
			const taxonGroupOnlyMeasurementFieldKeys = CollectionConfig.MeasurementFields[props.values.taxonGroup].map((item) => item.key);
		
			//Empty non relevant measurements for selected taxon group
			if (isMounted)
				allMeasurementFieldKeys
					.filter(key => !taxonGroupOnlyMeasurementFieldKeys.includes(key))
					.forEach(key => {
						props.setFieldValue(key, "");
					});
		}
		
		return () => { isMounted = false };
		  
	}, [props.values.taxonGroup]);
	
	
	const handleUnableToCompleteMeasurementsChange = (e) => {
		
        props.setFieldValue("unableToCompleteBasicMeasurements", e.target.checked);

		//Clear eventual validation errors
		if (props.values.taxonGroup){
			const taxonGroupOnlyMeasurementFieldKeys = CollectionConfig.MeasurementFields[props.values.taxonGroup].map((item) => item.key);
			
			taxonGroupOnlyMeasurementFieldKeys
				.forEach(key => {
					props.setFieldError(key, null);
				});
		}

    }
	
	return(
		<Fragment>
		    <Row> 

				<FormGroup as={Col} controlId="formGridMeasurements" className="mb-1">
					<FormLabel><Trans i18nKey="collections.save.form.measurements.label">Measurements</Trans></FormLabel>
					<fieldset>
						<div className={"border rounded p-4"}>
							<Row className="align-items-center">
						
			{ (props.values.taxonGroup)
					? CollectionConfig.MeasurementFields[props.values.taxonGroup].map((item, index) => 
						<FormGroup key={index} as={Col} md={item.cols} controlId={"formGrid-"+item.key} className="mb-2">
							<FormLabel className="text-secondary mb-1"><Trans i18nKey={"collections.save.form."+item.key+".label"} /> {(!props.values.unableToCompleteBasicMeasurements && item.required) ? "*" : null}</FormLabel>
							
								{(item.append) ? 
									<InputGroup className="mb-1" size="sm" >
										<FormControl className="text-uppercase" type={'text'} name={item.key} isInvalid={!(props.errors[item.key] == null)} value={props.values[item.key]} onChange={props.onChange} placeholder={(item.placeholder) ? t('collections.save.form.'+item.key+'.placeholder'): null }/>
										<InputGroup.Append>
     										<InputGroup.Text><Trans i18nKey={"collections.save.form."+item.key+".append-text"} /></InputGroup.Text>
    									</InputGroup.Append>
										<FormControlErrors errors={props.errors[item.key]} />
  									</InputGroup>
									:
									<Fragment>
										<FormControl className="text-uppercase" size="sm" type={'text'} name={item.key} isInvalid={!(props.errors[item.key] == null)} value={props.values[item.key]} onChange={props.onChange} placeholder={(item.placeholder) ? t('collections.save.form.'+item.key+'.placeholder'): null }/>
										<FormControlErrors errors={props.errors[item.key]} />
									</Fragment>
								}
					
						</FormGroup>
					  )
					: 
					<Fragment>
  						<Col md={1} sm={2} xs={2} className="ml-3">
  							<h1 className="text-warning"><FontAwesomeIcon size="xs" icon={faExclamationTriangle} /></h1>
						</Col>
		  				<Col className="align-middle">
							<Trans i18nKey="collections.save.form.measurements.disclaimer">Disclaimer</Trans>
						</Col>
					</Fragment>	
			}
			</Row>
			</div>
				</fieldset>
			</FormGroup>
		</Row>
		
		<Row >
			<FormGroup as={Col} controlId="formGridIncompletedMeasurements" className="mb-3">
				<FormCheck type="checkbox" >
					<FormCheck.Input type="checkbox" name="unableToCompleteBasicMeasurements" value={props.values.unableToCompleteBasicMeasurements} onChange={handleUnableToCompleteMeasurementsChange} isInvalid={!(props.errors.unableToCompleteBasicMeasurements == null)} />
					<FormCheck.Label style={{fontSize: "0.8rem"}} className="align-text-top"><Trans i18nKey="collections.save.form.unableToCompleteBasicMeasurements.label">Unable to Complete Basic Measurements</Trans></FormCheck.Label>        							
      			</FormCheck>
			</FormGroup> 
		</Row>
		
		<Row>
			<FormGroup as={Col} controlId="formGridEctoparasite">
				<FormLabel><Trans i18nKey="collections.save.form.ectoparasite.label">Ectoparasite</Trans></FormLabel>
				<FormControl className="text-uppercase" as="textarea" rows={3} maxLength={255} name="ectoparasite" isInvalid={!(props.errors.ectoparasite == null)} value={props.values.ectoparasite} onChange={props.onChange} placeholder={t('collections.save.form.ectoparasite.placeholder')} />
				<FormControlErrors errors={props.errors.ectoparasite} />
			</FormGroup> 
		</Row>
		</Fragment>
	) 

}

const SamplesSection = props => {
	
	const [workgroupUsers, setWorkgroupUsers] = useState([]);
	const typeaheadRef = useRef(null);
	
	const { t } = useTranslation();
	
	const auth = useAuth();
	
	useEffect(() => {
		let isMounted = true; 
		
		//Fetch Workgroup Users
		if (props.values.workgroupId) {
			API.getWorkgroupUsers(props.values.workgroupId, auth.isAdmin())
				.then(response => {
					if (isMounted) setWorkgroupUsers(response.list); 					
				})
				.catch(error => { 
					log.error("Error Loading Workgroup Users: ", error.message);
		 			props.onError(error);
				})
		 		.finally(() => {
		 		  //Do nothing yet
				});
		}
		
		 return () => { isMounted = false };
		  
	}, [props.values.workgroupId]);
	
	const handleSamplesChange = (e) => {
        
        props.onChange(e);
        
        if (!props.values.samples.includes("OTHER")) {
        	//Reset Reproductive Status on every change
        	props.setFieldValue("otherSampleDescription", "");
        }  
    }

	const createSamplesMediumOption = (item) => {
        
        return Object.assign({}, {"id": item, "displayText": t("collections.save.form.samplesMediums.options."+item)})
    }
	
	return(
		<Fragment>
		<Row>
			<FormGroup as={Col} controlId="formGridSamples">
				<FormLabel><Trans i18nKey="collections.save.form.samples.label">Samples</Trans> *</FormLabel>
				<fieldset className={!(props.errors.samples == null) ? "is-invalid" : ""}>
					<div className={!(props.errors.samples == null) ? "border border-danger rounded p-2" : "border rounded p-2"}>
						<Row>
							{ CollectionConfig.Samples.map((item, index) => {
								return (
									<Col key={index} md={3} className="pl-4" >
										<FormCheck 
        									name="samples" 
        									className="text-uppercase"
        									type="checkbox"
        									id={index}
        									value={item}
        									label={t("collections.save.form.samples.options."+item)}
        									onChange={handleSamplesChange}
        									checked={props.values.samples.includes(item)}
      									/>
										{(item === "OTHER" && props.values.samples.includes(item)) &&
											<Fragment>
												<FormControl className="text-uppercase" type={'text'} name="otherSampleDescription" isInvalid={!(props.errors.otherSampleDescription == null)} value={props.values.otherSampleDescription} onChange={props.onChange} placeholder={t('collections.save.form.otherSampleDescription.placeholder')} />
												<FormControlErrors errors={props.errors.otherSampleDescription} />
											</Fragment>
										}
									</Col>
								)
							})}
						</Row>
        			</div>
				</fieldset>
				<FormControlErrors errors={props.errors.samples} />
			</FormGroup>
		</Row>
		
		<Row>
			<FormGroup as={Col} controlId="formGridPreparedBy">
				<FormLabel><Trans i18nKey="collections.save.form.preparedById.label">Prepared By</Trans> *</FormLabel>
				<FormControl as="select" disabled={!(props.values.workgroupId)} name="preparedById" isInvalid={!(props.errors.preparedById == null)} value={props.values.preparedById} onChange={props.onChange} aria-describedby="preparedByHelpBlock">
		    			<option value="">{t("collections.save.form.preparedById.blank-option")}</option>
		    			{ workgroupUsers.map(item =>
		    					<option key={item.id} value={item.id}>{item.firstName+" "+item.lastName}</option>
		    			)}
		    	</FormControl>
				{ !(props.values.workgroupId) ? <FormText id="preparedByHelpBlock" muted><Trans i18nKey="collections.save.form.preparedById.helper" /></FormText> : null }
				<FormControlErrors errors={props.errors.preparedById} />
			</FormGroup>
			
			{/*<FormGroup as={Col} controlId="formGridSamplesMedium">
				<FormLabel><Trans i18nKey="collections.save.form.samplesMedium.label">Samples Medium</Trans> *</FormLabel>
				<FormControl as="select" name="samplesMedium" isInvalid={!(props.errors.samplesMedium == null)} value={props.values.samplesMedium} onChange={props.onChange} >
	    			<option value="">{t("collections.save.form.samplesMedium.options.blank")}</option>
	    			{ CollectionConfig.SamplesMediums.map(item =>
	    				<option key={item} value={item}>{t("collections.save.form.samplesMedium.options."+item)}</option>
	    			)}
	    		</FormControl>
				<FormControlErrors errors={props.errors.samplesMedium} />
			</FormGroup>
			*/}
			
			<FormGroup as={Col} controlId="formGridSamplesMediums">
				<FormLabel><Trans i18nKey="collections.save.form.samplesMediums.label">Samples Mediums</Trans> *</FormLabel>
				<Typeahead
      				multiple
      				id="samplesMediums"
					isInvalid={!(props.errors.samplesMediums == null)}
					labelKey={(option) => option.displayText }
      				onChange={(selected) => {
        				props.setFieldValue("samplesMediums", selected.map(item => item.id));
        				// Keep the menu open when making multiple selections.
        				typeaheadRef.current.toggleMenu();
      				}}
      				options={CollectionConfig.SamplesMediums.map(item => createSamplesMediumOption(item) )}
      				placeholder={t("collections.save.form.samplesMediums.placeholder")}
					emptyLabel={t("collections.save.form.samplesMediums.empty-label")}
      				ref={typeaheadRef}
      				selected={props.values.samplesMediums.map(item => createSamplesMediumOption(item))}
    			/>
				<FormControlErrors block={true} errors={props.errors.samplesMediums} />
			</FormGroup>
			
		</Row>
		
		<Row>
		

		<FormGroup as={Col} controlId="formGridSamplesPreservationMethod">
			<FormLabel><Trans i18nKey="collections.save.form.samplesPreservationMethod.label">Samples Preservation Method</Trans> *</FormLabel>
			<FormControl as="select" name="samplesPreservationMethod" isInvalid={!(props.errors.samplesPreservationMethod == null)} value={props.values.samplesPreservationMethod} onChange={props.onChange} >
	    		<option value="">{t("collections.save.form.samplesPreservationMethod.options.blank")}</option>
	    		{ CollectionConfig.SamplesPreservationMethods.map(item =>
    				<option key={item} value={item}>{t("collections.save.form.samplesPreservationMethod.options."+item)}</option>
	    		)}
	    	</FormControl>
			<FormControlErrors errors={props.errors.samplesPreservationMethod} />
		</FormGroup>

		<FormGroup as={Col} controlId="formGridSamplesCoolingAgent">
			<FormLabel><Trans i18nKey="collections.save.form.samplesCoolingAgent.label">Samples Cooling Agent</Trans> *</FormLabel>
			<FormControl as="select" name="samplesCoolingAgent" isInvalid={!(props.errors.samplesCoolingAgent == null)} value={props.values.samplesCoolingAgent} onChange={props.onChange} >
	    		<option value="">{t("collections.save.form.samplesCoolingAgent.options.blank")}</option>
	    		{ CollectionConfig.SamplesCoolingAgents.map(item =>
	    					<option key={item} value={item}>{t("collections.save.form.samplesCoolingAgent.options."+item)}</option>
	    		)}
	    	</FormControl>
			<FormControlErrors errors={props.errors.samplesCoolingAgent} />
		</FormGroup>
		</Row>
		
		<Row>
		<FormGroup as={Col} controlId="formGridSamplesRemarks">
			<FormLabel><Trans i18nKey="collections.save.form.samplesRemarks.label">Samples Note</Trans></FormLabel>
			<FormControl className="text-uppercase" as="textarea" rows={3} maxLength={255} name="samplesRemarks" isInvalid={!(props.errors.samplesRemarks == null)} value={props.values.samplesRemarks} onChange={props.onChange} placeholder={t('collections.save.form.samplesRemarks.placeholder')} />
			<FormControlErrors errors={props.errors.samplesRemarks} />
		</FormGroup> 
		</Row>
	
		</Fragment>
	) 

}

const PhotosSection = props => {

	//const { t } = useTranslation();
	
	const config = useConfig();
	
	return(
		<Fragment>
			<MultipleImageUploader 
				i18nPrefix="collections.save.form."
				maxImages={5}
				maxFileSize={config.collectionPhotoMaxFileSize}
				images={props.values.photos}
				setFieldError={props.setFieldError}
				validateField={props.validateField}
				isInvalid={!(props.errors.photos == null)}
				onChange={(images) => { props.setFieldValue("photos", images);}}
			/>
			{!(props.errors.photos == null) 
					? <div className="text-left invalid-feedback" style={{display: "block"}}><Trans i18nKey={props.errors.photos} /></div>
				: null
			}	
		</Fragment>
	) 

}


const ExtraSection = props => {

	const [workgroupLicenses, setWorkgroupLicenses] = useState([]);
	const [licenseModalShow, setLicenseModalShow] = useState(false);
	const [licenseType, setLicenseType] = useState(null);
	
	const { t } = useTranslation();
	
	const auth = useAuth();
	
	useEffect(() => {
		let isMounted = true; 
		
		//Fetch Workgroup Licenses
		if (props.values.workgroupId) {
			API.getWorkgroupLicenses(props.values.workgroupId, auth.isAdmin())
				.then(response => {
					if (isMounted) setWorkgroupLicenses(response.list); 					
				})
				.catch(error => { 
					log.error("Error Loading Workgroup Licenses: ", error.message);
		 			props.onError(error);
				})
		 		.finally(() => {
		 		  //Do nothing yet
				});
		}
		
		 return () => { isMounted = false };
		  
	}, [props.values.workgroupId]);
	
	
	const handleLicenseChange = (type, licenseNumber) => {
	  
	  switch (type) {
  		case 'SISBIO':
    		props.setFieldValue("sisbioLicenseNumber", licenseNumber);
   			break;
		case 'CEUA':
    		props.setFieldValue("ceuaLicenseNumber", licenseNumber);
   			break;
		case 'SISGEN':
    		props.setFieldValue("sisgenId", licenseNumber);
   			break;
  		default:
	  }
  
	  setLicenseModalShow(false);
   }
	
	return(
		<Fragment>
			<Row>
				
				
				{/*<FormGroup as={Col} controlId="formGridSisbioLicenseNumber">
					<FormLabel><Trans i18nKey="collections.save.form.sisbioLicenseNumber.label">Sisbio License Number</Trans></FormLabel>
					<FormControl className="text-uppercase" type={'text'} name="sisbioLicenseNumber" isInvalid={!(props.errors.sisbioLicenseNumber == null)} value={props.values.sisbioLicenseNumber} onChange={props.onChange} placeholder={t('collections.save.form.sisbioLicenseNumber.placeholder')} />
					<FormControlErrors errors={props.errors.sisbioLicenseNumber} />
				</FormGroup>
				*/}
				
				{(props.values.workgroupId) && (
					<SetCollectionLicenseModal
						show={licenseModalShow}
	        			onHide={() => setLicenseModalShow(false)}
						size="md"
						type={licenseType}
						licenses={workgroupLicenses}
						onLicenseChange={handleLicenseChange}
		      		/>
				)}
					
				<FormGroup as={Col} controlId="formGridSisbioLicenseNumber">
					<FormLabel><Trans i18nKey="collections.save.form.sisbioLicenseNumber.label">Sisbio License Number</Trans></FormLabel>
					<InputGroup >
						<FormControl className="text-uppercase" type={'text'} name="sisbioLicenseNumber" isInvalid={!(props.errors.sisbioLicenseNumber == null)} value={props.values.sisbioLicenseNumber} onChange={props.onChange} placeholder={t('collections.save.form.sisbioLicenseNumber.placeholder')} aria-describedby="sisbioLicenseNumberHelpBlock"/>
						
						{ !(props.values.workgroupId) ? 
							<FormText id="sisbioLicenseNumberHelpBlock" muted>
								<Trans i18nKey="collections.save.form.sisbioLicenseNumber.helper" />
							</FormText> 
							: 
							<InputGroup.Append onClick={() => {setLicenseType("SISBIO");setLicenseModalShow(true);}}>
								<InputGroup.Text ><FontAwesomeIcon icon={faSearch} /></InputGroup.Text> 
							</InputGroup.Append> 
						}
						<FormControlErrors errors={props.errors.sisbioLicenseNumber} />
					</InputGroup>
				</FormGroup>
				
				<FormGroup as={Col} controlId="formGridCeuaLicenseNumber">
					<FormLabel><Trans i18nKey="collections.save.form.ceuaLicenseNumber.label">Ceua License Number</Trans></FormLabel>
					<InputGroup >
						<FormControl className="text-uppercase" type={'text'} name="ceuaLicenseNumber" isInvalid={!(props.errors.ceuaLicenseNumber == null)} value={props.values.ceuaLicenseNumber} onChange={props.onChange} placeholder={t('collections.save.form.ceuaLicenseNumber.placeholder')} aria-describedby="ceuaLicenseNumberHelpBlock"/>
						
						{ !(props.values.workgroupId) ? 
							<FormText id="ceuaLicenseNumberHelpBlock" muted>
								<Trans i18nKey="collections.save.form.ceuaLicenseNumber.helper" />
							</FormText> 
							: 
							<InputGroup.Append onClick={() => {setLicenseType("CEUA");setLicenseModalShow(true);}}>
								<InputGroup.Text ><FontAwesomeIcon icon={faSearch} /></InputGroup.Text> 
							</InputGroup.Append> 
						}
						<FormControlErrors errors={props.errors.ceuaLicenseNumber} />
					</InputGroup>
				</FormGroup>
			
			</Row>

			<Row>
				<FormGroup as={Col} controlId="formGridMuseumAssetRegistryInfo">
					<FormLabel><Trans i18nKey="collections.save.form.museumCatalogNumber.label">Museum Asset Registry Info</Trans></FormLabel>
					<FormControl className="text-uppercase" type={'text'} name="museumCatalogNumber" isInvalid={!(props.errors.museumCatalogNumber == null)} value={props.values.museumCatalogNumber} onChange={props.onChange} placeholder={t('collections.save.form.museumCatalogNumber.placeholder')} />
					<FormControlErrors errors={props.errors.museumCatalogNumber} />
				</FormGroup>
				
				<FormGroup as={Col} controlId="formGridSisgenId">
					<FormLabel><Trans i18nKey="collections.save.form.sisgenId.label">Sisgen Id</Trans></FormLabel>
					<InputGroup >
						<FormControl className="text-uppercase" type={'text'} name="sisgenId" isInvalid={!(props.errors.sisgenId == null)} value={props.values.sisgenId} onChange={props.onChange} placeholder={t('collections.save.form.sisgenId.placeholder')} aria-describedby="sisgenIdHelpBlock"/>
						
						{ !(props.values.workgroupId) ? 
							<FormText id="sisgenIdHelpBlock" muted>
								<Trans i18nKey="collections.save.form.sisgenId.helper" />
							</FormText> 
							: 
							<InputGroup.Append onClick={() => {setLicenseType("SISGEN");setLicenseModalShow(true);}}>
								<InputGroup.Text ><FontAwesomeIcon icon={faSearch} /></InputGroup.Text> 
							</InputGroup.Append> 
						}
						<FormControlErrors errors={props.errors.sisgenId} />
					</InputGroup>
				</FormGroup>
				
			</Row>
		
			<Row>
				<FormGroup as={Col} controlId="formGridRemarks">
					<FormLabel><Trans i18nKey="collections.save.form.remarks.label">Remarks</Trans></FormLabel>
					<FormControl className="text-uppercase" as="textarea" rows={3} maxLength={255} name="remarks" isInvalid={!(props.errors.remarks == null)} value={props.values.remarks} onChange={props.onChange} placeholder={t('collections.save.form.remarks.placeholder')} />
					<FormControlErrors errors={props.errors.remarks} />
				</FormGroup> 
			</Row>
		</Fragment>
	) 

}

const CollectionFormFields = { GeneralSection, TaxonomySection, HistorySection, PhysicalExamSection, SamplesSection, PhotosSection, ExtraSection };
		
export default CollectionFormFields;
