import { Roles } from '../config/Roles'
import { Permissions } from '../config/Permissions'

import Workgroups from "../components/Workgroups";
import SaveWorkgroup from "../components/SaveWorkgroup";
import WorkgroupUsers from "../components/WorkgroupUsers";
import WorkgroupLicenses from "../components/WorkgroupLicenses";
import Loader from "../components/Loader";
import Users from "../components/Users";
import SaveUser from "../components/SaveUser";
import UpdateUserPassword from "../components/UpdateUserPassword";
import UpdateSelfPassword from "../components/UpdateSelfPassword";
import Collections from "../components/Collections";
import CollectionsExport from "../components/CollectionsExport";
import CollectionsImport from "../components/CollectionsImport";
import SaveCollection from "../components/SaveCollection";
import Home from "../components/Home";
import Activities from "../components/Activities";
import LaboratoryTags from "../components/LaboratoryTags";
import StorageBoxes from "../components/StorageBoxes";
import SaveStorageBox from "../components/SaveStorageBox";
import CollectionSamples from "../components/CollectionSamples";
import TissueDissociations from "../components/TissueDissociations";
import NucleicAcidExtractions from "../components/NucleicAcidExtractions";
import StorageBoxItems from "../components/StorageBoxItems";
import LaboratoryTests from "../components/LaboratoryTests";
import SaveLaboratorySample from "../components/SaveLaboratorySample";
import CDNASyntheses from "../components/CDNASyntheses";


export const PrivateRoutesConfig = [
	{
		component: Users,
		path: '/users',
		title: 'header.navigation-titles.users',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			}
		]
	},
	{
		component: SaveUser,
		path: '/users/create',
		title: 'header.navigation-titles.users-create',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			},
			{
				title: "header.navigation-titles.users",
				path : "/users"
			},
		]
	},
	{
		component: SaveUser,
		path: '/users/update',
		title: 'header.navigation-titles.users-update',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			},
			{
				title: "header.navigation-titles.users",
				path : "/users"
			},
		]
	},
	{
		component: UpdateUserPassword,
		path: '/users/update-password',
		title: 'header.navigation-titles.users-password-update',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			},
			{
				title: "header.navigation-titles.users",
				path : "/users"
			},
		]
	},
	{
		component: Workgroups,
		path: '/workgroups',
		title: 'header.navigation-titles.workgroups',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		home: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			}
		]
	},
	{
		component: SaveWorkgroup,
		path: '/workgroups/create',
		title: 'header.navigation-titles.workgroups-create',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			},
			{
				title: "header.navigation-titles.workgroups",
				path : "/workgroups"
			},
		]
	},
	{
		component: SaveWorkgroup,
		path: '/workgroups/update',
		title: 'header.navigation-titles.workgroups-update',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			},
			{
				title: "header.navigation-titles.workgroups",
				path : "/workgroups"
			},
		]
	},
	{
		component: WorkgroupUsers,
		path: '/workgroups/users',
		title: 'header.navigation-titles.workgroups-users',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			},
			{
				title: "header.navigation-titles.workgroups",
				path : "/workgroups"
			},
		]
	},
	{
		component: WorkgroupLicenses,
		path: '/workgroups/licenses',
		title: 'header.navigation-titles.workgroups-licenses',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			},
			{
				title: "header.navigation-titles.workgroups",
				path : "/workgroups"
			},
		]
	},
	{
		component: Activities,
		path: '/activities',
		title: 'header.navigation-titles.activities',
		exact: true,
		roles: [
			Roles.ROOT
		],
		hierarchy: [
			{
				title: "header.navigation-titles.admin"
			}
		]
	},
	{
		component: Collections,
		path: '/collections',
		title: 'header.navigation-titles.collections-list',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN,
			Roles.COLLECT,
		],
		home: [
			Roles.COLLECT
		],
		hierarchy: [
			{
				title: "header.navigation-titles.collections"
			}
		]
	},
	{
		component: CollectionsExport,
		path: '/collections/export',
		title: 'header.navigation-titles.collections-export',
		exact: true,
		permissions: [
			Permissions.COLLECTION_EXPORT
		],
		hierarchy: [
			{
				title: "header.navigation-titles.collections"
			}
		]
	},
	{
		component: CollectionsImport,
		path: '/collections/import',
		title: 'header.navigation-titles.collections-import',
		exact: true,
		permissions: [
					Permissions.COLLECTION_IMPORT
		],
		hierarchy: [
			{
				title: "header.navigation-titles.collections"
			}
		]
	},
	{
		component: SaveCollection,
		path: '/collections/create',
		title: 'header.navigation-titles.collections-create',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN,
			Roles.COLLECT,
		],
		hierarchy: [
			{
				title: "header.navigation-titles.collections"
			},
			{
				title: "header.navigation-titles.collections-list",
				path : "/collections"
			},
		]
	},
	{
		component: SaveCollection,
		path: '/collections/update',
		title: 'header.navigation-titles.collections-update',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN,
			Roles.COLLECT,
		],
		hierarchy: [
			{
				title: "header.navigation-titles.collections"
			},
			{
				title: "header.navigation-titles.collections-list",
				path : "/collections"
			},
		]
	},
	{
		component: SaveCollection,
		path: '/collections/clone',
		title: 'header.navigation-titles.collections-clone',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN,
			Roles.COLLECT,
		],
		hierarchy: [
			{
				title: "header.navigation-titles.collections"
			},
			{
				title: "header.navigation-titles.collections-list",
				path : "/collections"
			},
		]
	},
	{
		component: Loader,
		path: '/analysis',
		title: 'header.navigation-titles.analysis',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN,
			Roles.ANALYSIS,
		],
	},
	{
		component: LaboratoryTags,
		path: '/analysis/tags',
		title: 'header.navigation-titles.laboratory-tags',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN,
			Roles.ANALYSIS,
		],
		hierarchy: [
			{
				title: "header.navigation-titles.analysis"
			}
		]
	},
	{
		component: StorageBoxes,
		path: '/analysis/boxes',
		title: 'header.navigation-titles.storage-boxes',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN,
			Roles.ANALYSIS,
		],
		hierarchy: [
			{
				title: "header.navigation-titles.analysis"
			}
		]
	},
	{
		component: SaveStorageBox,
		path: '/analysis/boxes/create',
		title: 'header.navigation-titles.storage-boxes-create',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN,
			Roles.ANALYSIS,
		],
		hierarchy: [
			{
				title: "header.navigation-titles.analysis"
			},
			{
				title: "header.navigation-titles.storage-boxes",
				path : "/analysis/boxes"
			},
		]
	},
	{
		component: SaveStorageBox,
		path: '/analysis/boxes/update',
		title: 'header.navigation-titles.storage-boxes-update',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN,
			Roles.ANALYSIS,
		],
		hierarchy: [
			{
				title: "header.navigation-titles.analysis"
			},
			{
				title: "header.navigation-titles.storage-boxes",
				path : "/analysis/boxes"
			},
		]
	},
	{
		component: StorageBoxItems,
		path: '/analysis/boxes/items',
		title: 'header.navigation-titles.storage-boxes-items',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN,
			Roles.ANALYSIS,
		],
		hierarchy: [
			{
				title: "header.navigation-titles.analysis"
			},
			{
				title: "header.navigation-titles.storage-boxes",
				path : "/analysis/boxes"
			},
		]
	},
	{
		component: CollectionSamples,
		path: '/analysis/collectionsamples',
		title: 'header.navigation-titles.collection-samples',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN,
			Roles.ANALYSIS,
		],
		home: [
			Roles.ANALYSIS
		],
		hierarchy: [
			{
				title: "header.navigation-titles.analysis"
			}
		]
	},
	{
		component: SaveLaboratorySample,
		path: '/analysis/laboratorysamples/update',
		title: 'header.navigation-titles.laboratory-samples-update',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN,
			Roles.ANALYSIS,
		],
		home: [
			Roles.ANALYSIS
		],
		hierarchy: [
			{
				title: "header.navigation-titles.analysis"
			},
			{
				title: "header.navigation-titles.collection-samples",
				path : "/analysis/collectionsamples"
			},
		]
	},
	{
		component: TissueDissociations,
		path: '/analysis/dissociations',
		title: 'header.navigation-titles.laboratory-dissociations',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN,
			Roles.ANALYSIS,
		],
		home: [
			Roles.ANALYSIS
		],
		hierarchy: [
			{
				title: "header.navigation-titles.analysis"
			}
		]
	},
	{
		component: NucleicAcidExtractions,
		path: '/analysis/extractions',
		title: 'header.navigation-titles.laboratory-extractions',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN,
			Roles.ANALYSIS,
		],
		home: [
			Roles.ANALYSIS
		],
		hierarchy: [
			{
				title: "header.navigation-titles.analysis"
			}
		]
	},
	{
		component: CDNASyntheses,
		path: '/analysis/syntheses',
		title: 'header.navigation-titles.laboratory-syntheses',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN,
			Roles.ANALYSIS,
		],
		home: [
			Roles.ANALYSIS
		],
		hierarchy: [
			{
				title: "header.navigation-titles.analysis"
			}
		]
	},
	{
		component: LaboratoryTests,
		path: '/analysis/tests',
		title: 'header.navigation-titles.laboratory-tests',
		exact: true,
		roles: [
			Roles.ROOT,
			Roles.ADMIN,
			Roles.ANALYSIS,
		],
		home: [
			Roles.ANALYSIS
		],
		hierarchy: [
			{
				title: "header.navigation-titles.analysis"
			}
		]
	},
	{
		component: UpdateSelfPassword,
		path: '/password',
		title: 'changepassword',
		exact: true
	},
	{
		component: Loader,
		path: '/profile/change-workgroup',
		title: 'changeworkgroup',
		exact: true
	},
	{
		component: Home,
		path: '/home',
		title: 'home',
		exact: true
	},
]