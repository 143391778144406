
const Headers =[
	"organismId", "workgroupId", "capturedById", "eventDate", "county", "stateProvince", "country", "latitude", "longitude", 
	"locality", "captureMethod", "samplingEffort", "habitat", "captureType", "markingType", "bandMarkingInfo", "fieldNumber", 
	"scientificName", "taxonGroup", "vernacularName", "identifiedById", "dateIdentified",
	"taxonId", "taxonRank", "kingdom", "phylum", "taxonClass", "order", "family", "genus", "specificEpithet", "infraspecificEpithet",
	"sex", "reproductiveCondition", "lifeStage", "establishmentMeans", "degreeOfEstablishment", "symptomaticCondition", "historyRemarks",
	"forearmLength", "headBodyLength", "tailLength", "earLength", "footLength", "footIncludingNailLength", "tragusLength", "calcarLength",
	"tibiaLength", "totalLength", "tarsusLength", "wingLength", "culmenLength", "nostrilsLength", "morphometricMeasurements", "weight", "ectoparasite",
	"samples", "otherSampleDescription", "preparedById", "samplesMediums", "samplesPreservationMethod", "samplesCoolingAgent", "samplesRemarks",
	"museumCatalogNumber", "sisgenId", "sisbioLicenseNumber", "ceuaLicenseNumber", "remarks",
	/*"whenCreated", "whenModified", "photos"*/
]

const Actions = [
	  "CREATE",
 	  "UPDATE"
 ];

const DelimiterOptions = [
	  {
        key: 'auto-detect',
		value: ""
      },
      {
        key: 'comma',
		value: ','
      },
	  {
        key: 'semicolon',
		value: ';'
      },
      {
        key: 'tab',
		value: '	'
      },
      {
        key: 'pipe',
		value: '|'
      },
	  
 ];

const FirstRowHeaderOptions = [
	  {
        key: 'true',
		value: true
      }/*,
      {
        key: 'false',
		value: false
      }*/
 ];

const ColumnMappingStrategyOptions = [
	  {
        key: 'position'
      }/*,
      {
        key: 'name'
      }*/
 ];




const CollectionsImportConfig = { Headers, Actions, DelimiterOptions, FirstRowHeaderOptions, ColumnMappingStrategyOptions
};

export default CollectionsImportConfig;