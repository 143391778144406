import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import log from 'loglevel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faCogs, faCrow, faVial, faQuestion, faUserFriends } from '@fortawesome/free-solid-svg-icons'
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';

import { PrivateNavbarOptionsConfig } from '../config/PrivateNavbarOptionsConfig';

import { useAuth } from "../services/use-auth";
import { AuthError, ValidationError } from "../helpers/custom-errors";
import { getAllowedNavbarOptions, isArrayWithLength } from "../helpers/commons";

import '../styles/PrivateNavbar.css';

const PrivateNavbarItems = props => {
	
	const auth = useAuth();
	
	const logoutUser = () => {
		 //_setError(null);
		  auth.logoutUser()
			.then((response) => {
				/*Do Nothing*/
				//navigate("/login");
			})
			.catch(error => {
				if (error instanceof AuthError) {		
					auth.onUnathorized(error);
				} else if (error instanceof ValidationError) {			
					log.info("User Logout Attempt Failed: ", error.message);
					//_setError(error.message);
				} else {	
					log.error("User Logout Error: ", error.message);
					//_setError(t('error.api.general'));
				}
			});
	 }
	
	const getWorkgroupIcon = (type) => {
		switch (type) {
		  case "OBSERVATORY":
			  return faCrow;
		  case "LABORATORY":
			  return faVial;
		  case "ADMINISTRATION":
			  return faCogs;
		  case "OTHER":
			  return faUserFriends;
		  default:
			  return faQuestion;
		}
	}
	
	 /*const changeWorkgroup = (workgroupId) => {
		 //_setError(null);
		  auth.selectUserWorkgroup({ values: {"workgroupId": workgroupId} })
			.then((response) => {
				//navigate("/");
			})
			.catch(error => {
				if (error instanceof AuthError) {		
					auth.onUnathorized(error);
				} else if (error instanceof ValidationError) {			
					log.info("User Logout Attempt Failed: ", error.message);
					//_setError(error.message);
				} else {	
					log.error("User Logout Error: ", error.message);
					//_setError(t('error.api.general'));
				}
			});
	 }*/
	
	if (!auth.user) 
		return null;
	
	let allowedOptions = getAllowedNavbarOptions(PrivateNavbarOptionsConfig, auth.user);
	
	let allowedNavItems = allowedOptions.map(({ path, title, icon, children }) => {
		  
		let allowedNavChildrenItems = !isArrayWithLength(children) ? null : getAllowedNavbarOptions(children, auth.user);
		
		if (!isArrayWithLength(allowedNavChildrenItems)) {
			return <Nav.Link key={path} as={Link} to={path}><FontAwesomeIcon icon={icon} /> <Trans i18nKey={title}>{title}</Trans></Nav.Link>
		}	
		  
		return (
			<Dropdown key={path} as={Nav.Item} to={path}>
	    		<Dropdown.Toggle as={Nav.Link} id="rbac-collasible-nav-dropdown">
	    			<FontAwesomeIcon icon={icon} /> <Trans i18nKey={title}>{title}</Trans>
	    		</Dropdown.Toggle>
	    		<Dropdown.Menu>
	    			{ allowedNavChildrenItems.map(({ path, title, icon }) => (
	    				<Dropdown.Item key={path} as={Link} to={path}><FontAwesomeIcon icon={icon} /> <Trans i18nKey={title}>{title}</Trans></Dropdown.Item>
	    			))}
	    		</Dropdown.Menu>
	    	</Dropdown>
		)
		  
	});
	
	return (
		<Fragment>	    
			<Nav className="mr-auto">
				{allowedNavItems}
			</Nav>
			<Nav>
			    <Dropdown as={Nav.Item} alignRight>
						<Dropdown.Toggle as={Nav.Link} id="user-collasible-nav-dropdown"><FontAwesomeIcon icon={faUserCircle} /> {auth.user.firstName}</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Header className="text-uppercase font-weight-bolder text-dark">
								<Trans i18nKey="header.navigation-titles.profile.workgroups" count={auth.user.workgroups.length}>Workgroups</Trans>
							</Dropdown.Header>
							{auth.user.workgroups.map((workgroup) => {
								return <Dropdown.Item className="pl-5" key={workgroup.id}>{workgroup.name} [{workgroup.types.map( type => <FontAwesomeIcon key={type} icon={getWorkgroupIcon(type)} />)}]</Dropdown.Item>	
								
							})}
							<Dropdown.Divider />
							<Dropdown.Item as={Link} to="/password"><Trans i18nKey="header.navigation-titles.profile.change-password">Alterar Senha</Trans></Dropdown.Item>
					    	<Dropdown.Divider />
					    	<Dropdown.Item onClick={() => {logoutUser()}}>
					    		<Trans i18nKey="header.navigation-titles.logout">Logout</Trans>
					    	</Dropdown.Item>
					    </Dropdown.Menu>
				</Dropdown>
			</Nav>
		</Fragment>
	)
};

export default PrivateNavbarItems;