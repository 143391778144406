import React,  { Fragment, useState, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';

import InputMask from "react-input-mask";

import InputGroup from 'react-bootstrap/InputGroup';

import FormControlErrors from "../components/FormControlErrors";
import Switch from "../components/Switch";
import SetWorkgroupHeadquartersModal from "../components/SetWorkgroupHeadquartersModal";

import WorkgroupConfig from "../config/WorkgroupConfig";

import API from "../services/backend-api";

import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "../styles/Typeahead.css";

const WorkgroupFormFields = props => {

	const [coordinators, setCoordinators] = useState([]);
	const [headquartersModalShow, setHeadquartersModalShow] = useState(false);
	const typeaheadRef = useRef(null);
	
	const { t } = useTranslation();
	
	useEffect(() => {
		let isMounted = true; 
		
		//Fetch Collecting Coordinators
		API.findUsers({})
			.then(response => {
				if (isMounted) setCoordinators(response.list);
			})
			.catch(error => { 
				log.error("Error Loading Coordinators: ", error.message);
		 		props.onError(error);
			})
		
		 return () => { isMounted = false };
		  
	}, []);
	
	const handleHeadquartersChange = (county, stateProvince, country, latitude, longitude) => {
	  
	  props.setFieldValue("headquarters", county + " - " + stateProvince);
	  
	  setHeadquartersModalShow(false);
   }

	const createTypeOption = (item) => {
	        
        return Object.assign({}, {"id": item, "displayText": t("workgroups.save.form.types.options."+item)})
    }
    
	return(
		<Fragment>
			<Row>
				<FormGroup as={Col} md={8} controlId="formGridName">
					<FormLabel ><Trans i18nKey="workgroups.save.form.name.label">Name</Trans> *</FormLabel>
				    <FormControl className="text-uppercase" type={'text'} name="name" isInvalid={!(props.errors.name == null)} value={props.values.name} onChange={props.onChange} placeholder={t('workgroups.save.form.name.placeholder')} />
				    <FormControlErrors errors={props.errors.name} />
				</FormGroup>
				
				<FormGroup as={Col} controlId="formGridShortcode">
			    	<FormLabel ><Trans i18nKey="workgroups.save.form.shortcode.label">Shortcode</Trans> *</FormLabel>
			    	<FormControl className="text-uppercase" as={InputMask} mask='aa-9' type={'text'} name="shortcode" isInvalid={!(props.errors.shortcode == null)} value={props.values.shortcode} onChange={props.onChange} placeholder={t('workgroups.save.form.shortcode.placeholder')} />
			    	<FormControlErrors errors={props.errors.shortcode} />
			    </FormGroup>
							
			</Row>
			
			<Row>	
				<FormGroup as={Col} controlId="formGridCoordinator">
				    <FormLabel><Trans i18nKey="workgroups.save.form.coordinatorId.label">Coordinator</Trans> *</FormLabel>
					<FormControl as="select" name="coordinatorId" isInvalid={!(props.errors.coordinatorId == null)} value={props.values.coordinatorId} onChange={props.onChange} >
		    			<option value="">{t("workgroups.save.form.coordinatorId.blank-option")}</option>
		    				{ coordinators.map(item =>
		    					<option key={item.id} value={item.id+""}>{item.firstName+' '+item.lastName}</option>
		    				)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.coordinatorId} />
				 </FormGroup>
			
				<FormGroup as={Col} controlId="formGridRegion">
		    		<FormLabel ><Trans i18nKey="workgroups.save.form.region.label">Region</Trans> *</FormLabel>
		    		<FormControl as="select" name="region" isInvalid={!(props.errors.region == null)} value={props.values.region} onChange={props.onChange} >
		    			<option value="">{t("workgroups.save.form.region.options.blank")}</option>
		    			{ WorkgroupConfig.Regions.map(item =>
		    				<option key={item} value={item}>{t("workgroups.save.form.region.options."+item)}</option>
		    			)}
		    		</FormControl>
		    		<FormControlErrors errors={props.errors.region} />
		    	</FormGroup>
				
				<FormGroup as={Col} controlId="formGridHeadquarters">
					<SetWorkgroupHeadquartersModal
						show={headquartersModalShow}
	        			onHide={() => setHeadquartersModalShow(false)}
						size="lg"
						onChange={handleHeadquartersChange}
		      		/>
					<FormLabel><Trans i18nKey="workgroups.save.form.headquarters.label">Headquarters</Trans> *</FormLabel>
					<InputGroup>
						<FormControl className="text-uppercase" type={'text'} name="headquarters" isInvalid={!(props.errors.headquarters == null)} value={props.values.headquarters} onChange={props.onChange} placeholder={t('workgroups.save.form.headquarters.placeholder')} />
						<InputGroup.Append onClick={() => {setHeadquartersModalShow(true);}}>
							<InputGroup.Text ><FontAwesomeIcon icon={faSearch} /></InputGroup.Text> 
						</InputGroup.Append>
						<FormControlErrors errors={props.errors.headquarters} />
					</InputGroup>
				</FormGroup>
			
				
			</Row>
			
			<Row>
				<FormGroup as={Col} controlId="formGridTypes">
				<FormLabel><Trans i18nKey="workgroups.save.form.types.label">Types</Trans> *</FormLabel>
				<Typeahead
      				multiple
      				id="types"
					isInvalid={!(props.errors.types == null)}
					labelKey={(option) => option.displayText }
      				onChange={(selected) => {
        				props.setFieldValue("types", selected.map(item => item.id));
        				// Keep the menu open when making multiple selections.
        				typeaheadRef.current.toggleMenu();
      				}}
      				options={WorkgroupConfig.Types.map(item => createTypeOption(item) )}
      				placeholder={t("workgroups.save.form.types.placeholder")}
					emptyLabel={t("workgroups.save.form.types.empty-label")}
      				ref={typeaheadRef}
      				selected={props.values.types.map(item => createTypeOption(item))}
    			/>
				<FormControlErrors block={true} errors={props.errors.samplesMediums} />
			</FormGroup>
			</Row>
			
			{/*<Row>
			    
			    <FormGroup as={Col} controlId="formGridType">
			    	<FormLabel ><Trans i18nKey="workgroups.save.form.type.label">Type</Trans> *</FormLabel>
			    	<FormControl as="select" name="type" isInvalid={!(props.errors.type == null)} value={props.values.type} onChange={props.onChange} >
			    		<option value="">{t("workgroups.save.form.type.options.blank")}</option>
			    		{ WorkgroupConfig.Types.map(item =>
			    			<option key={item} value={item}>{t("workgroups.save.form.type.options."+item)}</option>
			    		)}
			    	</FormControl>
			    	<FormControlErrors errors={props.errors.type} />
			    </FormGroup>

			  </Row>*/}
		    			
			  {(props.action === 'update')&& 
			  	<Row>    					   
	    			<FormGroup as={Col} controlId="formGridActive">
	    				<FormLabel ><Trans i18nKey="workgroups.save.form.active.label">Active</Trans></FormLabel>
	    				<Switch 
	    					name="active"
	    					value={props.values.active}
	    					checked={JSON.parse(props.values.active)}
	    					onChange={props.onChange}
	    				/>
	    			</FormGroup>
	    		</Row>
			  }
				
				
		
		</Fragment>
		
	) 
}


export default WorkgroupFormFields;
