import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faTimesCircle} from '@fortawesome/free-solid-svg-icons'

import moment from "moment";

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from "react-bootstrap/AccordionContext";

import * as Yup from "yup";
import { setLocale } from 'yup';

import DismissableFeedback from "../components/DismissableFeedback";
import Loader from "../components/Loader";
import SubjectActivities from "../components/SubjectActivities";

import { formatCoordinateDegrees, isArrayWithLength } from "../helpers/commons";

import API from "../services/backend-api";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });
	 
const GeneralDataFieldsValidationRules = {
	workgroupId: Yup.bool().default(true),
	capturedById: Yup.bool().default(true),
	eventDate: Yup.bool().default(true),
	county: Yup.bool().default(true),
	locality:  Yup.bool().default(true),
	coordinates:  Yup.bool().default(true),
	//latitude:  Yup.bool().default(true),
	//longitude: Yup.bool().default(true),
	captureMethod:  Yup.bool().default(true),
	samplingEffort:  Yup.bool().default(true),
	habitat:  Yup.bool().default(true),
	captureType:  Yup.bool().default(true),
	markingType:   Yup.bool().default(true)
}

const TaxonomyDataFieldsValidationRules = {
	scientificName: Yup.bool().default(true),
	vernacularName: Yup.bool().default(true),
	identifiedById: Yup.bool().default(true),
	dateIdentified: Yup.bool().default(true),
	//taxonGroup: Yup.bool().default(true),
	//taxonClass: Yup.bool().default(true),
	//family: Yup.bool().default(true),
	//order: Yup.bool().default(true),
	//genus: Yup.bool().default(true),
	//specificEpithet: Yup.bool().default(true)
}

const HistoryFormValidationRules = {
	sex: Yup.bool().default(true),
	reproductiveCondition: Yup.bool().default(true),
	lifeStage: Yup.bool().default(true),
	degreeOfEstablishment: Yup.bool().default(true),
	establishmentMeans: Yup.bool().default(true),
	symptomaticCondition: Yup.bool().default(true)
}

const PhysicalExamFormValidationRules = {
	measurements: Yup.bool().default(true),
	ectoparasite: Yup.bool().default(true),
}

const SamplesFormValidationRules = {
	samples: Yup.bool().default(true),
	preparedById: Yup.bool().default(true),
	//samplesMedium: Yup.bool().default(true),
	samplesMediums: Yup.bool().default(true),
	samplesPreservationMethod: Yup.bool().default(true),
	samplesCoolingAgent: Yup.bool().default(true),
	samplesRemarks: Yup.bool().default(true)
}

const ExtraFormValidationRules = {
	sisbioLicenseNumber: Yup.bool().default(true),
	ceuaLicenseNumber: Yup.bool().default(true),
	sisgenId: Yup.bool().default(true),
	remarks: Yup.bool().default(true)
}

const accordionCardConfig = [
	{
		key: "generalData",
		value: true, 
		data: GeneralDataFieldsValidationRules
	},
	{
		key: "taxonomyData",
		value: true, 
		data: TaxonomyDataFieldsValidationRules
	},
	{
		key: "physicalExamData",
		value: false, 
		data: PhysicalExamFormValidationRules
	},
	{
		key: "historyData",
		value: true, 
		data: HistoryFormValidationRules
	},
	{
		key: "samplesData",
		value: true, 
		data: SamplesFormValidationRules
	},
	{
		key: "extraData",
		value: true, 
		data: ExtraFormValidationRules
	}
]


const ContextAwareToggle = ({ children, eventKey, callback }) => {
  
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Button variant="link" onClick={decoratedOnClick}  className="float-right">
     	 <FontAwesomeIcon size="lg" icon={isCurrentEventKey ? faChevronUp : faChevronDown} />
    </Button>
  );
}

const DisplayCollectionSampleModal = props => {

	const [_error, _setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [collection, setCollection] = useState(null);
	const [workgroupsMap, setWorkgroupsMap] = useState(new Map());
	const [usersMap, setUsersMap] = useState(new Map());
	
	const { t } = useTranslation();

	useEffect(() => {
		let isMounted = true; 
		
		if (props.item && props.item.organismId) {
			API.lookupCollectionByOrganismId(props.item.organismId)
			.then(response => {
				if (isMounted) setCollection(response);
					
				//Fetch Observatories Groups
				API.findObservatories({})
					.then(response => {
						if (isMounted) {
							setWorkgroupsMap(new Map(response.list.map((u) => [ u.id.toString(), u.name])));
						}
					}).catch(error => {			
						log.error("Error Loading Observatory Workgroups: ", error.message);
		 				props.onError(error);
					});
				
				//Fetch Users
				API.findUsers({})
					.then(response => {
						if (isMounted) 
							setUsersMap(new Map(response.list.map((u) => [ u.id.toString(), u.firstName + " "+ u.lastName])));
						})
					.catch(error => { 
						log.error("Error Loading Users: ", error.message);
		 				props.onError(error);
					})
					
			})
			.catch(error => { 
				log.error("Error Loading Collection: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) {
		 			setIsLoading(false);
		 		}
			});
		} 
		 
		return () => { isMounted = false };
		  
	}, []);

	
	if (!props.item) {
		return (<Navigate  to="/analysis/collectionsamples" />)
	} else if (!isLoading && !collection) {
		return null;
	}
	
	
	
	const displayMeasurement = (key, value) => {
    	switch (key) {
			case "forearmLength":
			case "headBodyLength":
			case "tailLength":
			case "earLength":
			case "footLength":
			case "footIncludingNailLength":
			case "tragusLength":
			case "calcarLength":
			case "tibiaLength":
			case "totalLength":
			case "tarsusLength":
			case "wingLength":
			case "culmenLength":
			case "nostrilsLength":
				return (value) ? t("collections.save.form."+key+".label") + ": " + value + " mm" : "";
			case "morphometricMeasurements":
				return (value) ? t("collections.save.form."+key+".label") + ": " + value : "";
			case "weight":
				return (value) ? t("collections.save.form."+key+".label") + ": " + value + " g" : "";
		}	
    }
	
	let displayedCollectionSample = {};
		      			
	accordionCardConfig.forEach((card) => {
		Object.keys(card.data)
			.forEach((key) => {
				switch (key) {
					case "coordinates":
						displayedCollectionSample[key] = formatCoordinateDegrees(collection["latitude"], false) 
						displayedCollectionSample[key] += ", " + formatCoordinateDegrees(collection["longitude"], true);
						break;
					case "county":
						displayedCollectionSample[key] = collection[key];
						displayedCollectionSample[key] += " - " + collection["stateProvince"];
						displayedCollectionSample[key] += " / " + collection["country"];
						break;
					case "measurements":
						let measurementsArray = [];
						measurementsArray.push(displayMeasurement("forearmLength", collection["forearmLength"]));
						measurementsArray.push(displayMeasurement("headBodyLength", collection["headBodyLength"]));
						measurementsArray.push(displayMeasurement("tailLength", collection["tailLength"]));
						measurementsArray.push(displayMeasurement("earLength", collection["earLength"]));
						measurementsArray.push(displayMeasurement("footLength", collection["footLength"]));
						measurementsArray.push(displayMeasurement("footIncludingNailLength", collection["footIncludingNailLength"]));
						measurementsArray.push(displayMeasurement("tragusLength", collection["tragusLength"]));
						measurementsArray.push(displayMeasurement("calcarLength", collection["calcarLength"]));
						measurementsArray.push(displayMeasurement("tibiaLength", collection["tibiaLength"]));
						measurementsArray.push(displayMeasurement("totalLength", collection["totalLength"]));
						measurementsArray.push(displayMeasurement("tarsusLength", collection["tarsusLength"]));
						measurementsArray.push(displayMeasurement("wingLength", collection["wingLength"]));
						measurementsArray.push(displayMeasurement("culmenLength", collection["culmenLength"]));
						measurementsArray.push(displayMeasurement("nostrilsLength", collection["nostrilsLength"]));
						measurementsArray.push(displayMeasurement("morphometricMeasurements", collection["morphometricMeasurements"]));
						measurementsArray.push(displayMeasurement("weight", collection["weight"]));
						
						let measurementsArrayCleaned = measurementsArray.filter( item => (item));
						
						displayedCollectionSample[key] =  (isArrayWithLength(measurementsArrayCleaned)) ? measurementsArrayCleaned.join(", ") : "";
								
						break;
					case "samples":
						displayedCollectionSample[key] = collection[key].map(item => t("collections.save.form."+key+".options."+item));
						//displayedCollectionSample[key] += ";" + collection["otherSampleDescription"];
						break;
					case "samplesMediums":
						displayedCollectionSample[key] = collection[key].map(item => t("collections.save.form."+key+".options."+item));
						break;
					case "eventDate":
					case "dateIdentified":
						displayedCollectionSample[key] = moment(collection[key]).format("L");
						break;
					case "captureMethod":
					case "captureType":
					case "markingType":
					case "sex":
					case "reproductiveCondition":
					case "lifeStage":
					case "degreeOfEstablishment":
					case "establishmentMeans":
					case "symptomaticCondition":
					case "samplesPreservationMethod":
					case "samplesCoolingAgent":
						displayedCollectionSample[key] = collection[key] ? t("collections.save.form."+key+".options."+collection[key]) : collection[key];
						break;
					case "workgroupId":
						displayedCollectionSample[key] = workgroupsMap.get(collection[key]);
						break;
					case "capturedById":
					case "identifiedById":
					case "preparedById":
						displayedCollectionSample[key] = usersMap.get(collection[key]);
						break;
					default:
						displayedCollectionSample[key] = collection[key];
					}
			});
	});

	
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-display-collection-sample-modal"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-display-collection-sample-modal">
  					<Trans i18nKey="collectionsamples.display-collection-sample-modal.title" values={{organismId: collection.organismId}}>Display Collection Data</Trans>
  				</Modal.Title>
  			</Modal.Header>
  			{(isLoading) ? (
				<Modal.Body className="mb-3"><Loader /></Modal.Body>
			) : (
		      			
		      	<Modal.Body className="pt-2">
		  				
		  					<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  

								<Row className="pl-3 pr-3">
									<Col>
										<Accordion defaultActiveKey="0">
										{ accordionCardConfig.map((card, index) => (
											
											<Card key={card.key}>
    											<Card.Header className="pt-1 pb-1">
      												<Row>
      												<FormGroup as={Col} className="mb-0 my-auto" controlId={"formGrid-"+card.key}>		
        												<FormLabel>
        													<Trans i18nKey={"collectionsamples.display-collection-sample-modal.form."+card.key+".label"}>{card.key}</Trans>
														</FormLabel>
	     											</FormGroup>
													<Col>
														<ContextAwareToggle eventKey={index.toString()} />
													</Col>
													</Row>
    											</Card.Header>
    											<Accordion.Collapse eventKey={index.toString()}>
      												<Card.Body className="pt-2 pb-2">
      													<Row>
      													{ Object.keys(card.data).map((field, index) => (
															<Fragment key={index}>
																<FormLabel column sm="4">
																	<Trans i18nKey={"collections.save.form."+field+".label"}>{field}</Trans>:
																</FormLabel>
    															<Col sm="8">
      																<FormControl plaintext readOnly defaultValue={displayedCollectionSample[field]} />
    															</Col>
															</Fragment>
														))}
														</Row>
      												</Card.Body>
    											</Accordion.Collapse>
  											</Card>
											
											 ))}
										</Accordion>
									</Col>
								</Row>
								
								<Row className="pl-3 pr-3 mt-3">
									<Col>
										<SubjectActivities 
		 									subject="COLLECTION" 
		 									subjectId={props.item.collectionId} 
		 									onError={props.onError}
		 								/>
									</Col>
								</Row>
          		</Modal.Body>
				)}
          		<Modal.Footer>			      		
          			<Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="collectionsamples.display-collection-sample-modal.cancel">Cancel</Trans></Button>	
          		</Modal.Footer>

		</Modal>
	);
}

export default DisplayCollectionSampleModal;
