import React, { useState, useEffect} from 'react';
import { Route, Navigate, } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faTimesCircle, faCheckCircle} from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import DismissableFeedback from "../components/DismissableFeedback";
import MultipleCDNASynthesesFormFields from "../components/MultipleCDNASynthesesFormFields";
import Prompt from "../components/Prompt";

import { useConfig } from "../services/use-config";
import API from "../services/backend-api";

import CDNASynthesisConfig from "../config/CDNASynthesisConfig";

import { compareJSON, isArrayWithLength } from "../helpers/commons";
import { AuthError, ValidationError } from "../helpers/custom-errors";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const validationSchema = Yup.object().shape(CDNASynthesisConfig.SaveMultiplesFormValidationRules);

const SaveMultipleCDNASynthesesModal = props => {

	const [_error, _setError] = useState(null);
	
	const { t } = useTranslation();
	
	const config = useConfig();

	useEffect(() => {
		let isMounted = true; 
		 
		return () => { isMounted = false };
		  
	}, []);

	
	if (!isArrayWithLength(props.items)) {
		switch (props.origin) {
			case "laboratory-syntheses":
				return (<Navigate  to="/analysis/dissociations" />);
			case "laboratory-extractions":
				return (<Navigate  to="/analysis/extractions" />);
			case "laboratory-syntheses":
				return (<Navigate  to="/analysis/syntheses" />);
			case "collection-samples":
			default:
				return (<Navigate  to="/analysis/collectionsamples" />);
		}
		
	} 
	
	let initialValues = {};
	
	let defaultValues = validationSchema.default();
	
	initialValues = defaultValues;
	
	const saveMultipleLaboratorySyntheses = (values) =>
		new Promise((resolve, reject) => {
			API.saveMultipleLaboratorySyntheses(values, config.csrfToken).then(response => {
				resolve(response);
			}).catch(error => reject(error));
			
		});
	
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-save-multiple-laboratory-syntheses-modal"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-save-multiple-laboratory-syntheses-modal">
  					<Trans i18nKey="laboratorysyntheses.save-multiple-syntheses-modal.title">Save CDNA Syntheses</Trans>
  				</Modal.Title>
  			</Modal.Header>
			<Formik
		      		initialValues={initialValues}
		      		validationSchema={validationSchema}
		      		validateOnChange={false}
		      		validateOnBlur={false}     
		      		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);

						let laboratorySyntheses = props.items.map((item, index) => {
							let labSynthesis = {};
							labSynthesis.id = (props.origin === "laboratory-syntheses") ? item.id : "";
							labSynthesis.collectionId = item.collectionId;
							labSynthesis.materialSample = item.materialSample;
							
							//override the rest with form values
							labSynthesis.labelId =  values.labelIdPrefix + item.organismId;
							labSynthesis.workgroupId = values.workgroupId;
							labSynthesis.synthesizedById = values.synthesizedById;
							labSynthesis.dateSynthesized = values.dateSynthesized;
							labSynthesis.storageBoxId = values.storageBoxId;
							
							return labSynthesis;
							
						});
						
						saveMultipleLaboratorySyntheses(laboratorySyntheses)
    						.then((response) => {
    							actions.resetForm({ values }) //Workaround to set dirty back to true
								actions.setSubmitting(false);
								props.onItemsSaved(t('laboratorysyntheses.save-multiple-syntheses-modal.success'));
    						})
    						.catch(error => { 
    								
    							actions.setSubmitting(false);
								
								if (error instanceof AuthError) {		
		    	  					//auth.onUnathorized(error);
									props.onError(error);
		     					 } else if (error instanceof ValidationError) {		
          						
				 					log.info("Save Multiple CDNA Syntheses Attempt Failed: ", error.message);
          						
				 					_setError(error.message);		
								
									if (error.detail) {            						
    	              					for (let key of Object.keys(error.detail)) {
    	              						let errorMsgs = error.detail[key];
    	              						errorMsgs.forEach((message) => {
    	              							if (key === "data") {
											
													try {
        												actions.setFieldError(key, JSON.parse(message));
    												} catch (e) {
        												actions.setFieldError(key, message);
    												}
											
												} else {
													actions.setFieldError(key, message);
												}
    	              						});
    	              					}
    	              				}
          						
          	 					} else {
          							log.error("Error Saving Multiple CDNA Syntheses: ", error.message);
          							_setError(t('error.api.general'));
          	 					}	
    								
    						})
    			   		}
      					      			
					}
		      	>
		      	{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue, dirty}) => (	
		      		
		      		<Form className="form-save-multiple-laboratory-syntheses-modal" noValidate>		
		      			<Modal.Body className="pt-2">
		  				
		  					<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  

							<Row className="mb-3 pl-3 pr-3 align-items-center">
  								<Col md={1} sm={2} xs={2} className="text-center p-1">
  									<h1 className={"text-primary"}><FontAwesomeIcon icon={faExclamationTriangle} /></h1>
								</Col>
		  						<Col className="ml-3 text-justified align-middle">
									<Trans i18nKey="laboratorysyntheses.save-multiple-syntheses-modal.body" count={props.items.length}>Save Multiple CDNA Syntheses</Trans>
								</Col>
							</Row>
								
  							<Prompt
		      					when={!!dirty && !isSubmitting}
		      					message={JSON.stringify({
		      		           			"title": t("form.unsaved-changes-modal.title"),
		      		           			"bodyText": t("form.unsaved-changes-modal.body"),
		      		          		 	"confirmText": t("form.unsaved-changes-modal.confirm"), 
		      		           			"cancelText": t("form.unsaved-changes-modal.cancel"),
		      		           			"size": "lg",
		      		           			"variant": "danger",
		        			   			"when": compareJSON(initialValues, values)
		      		          		})
		      		   			}
		      				/>

							<MultipleCDNASynthesesFormFields 
								values={values} 
								errors={errors} 
								onChange={handleChange} 
								onError={props.onError}
								setFieldValue={setFieldValue}
								i18nPrefix="laboratorysyntheses.save-multiple-syntheses-modal."
							/>

								
          				</Modal.Body>
          				<Modal.Footer>			 
          				      <Button variant="success" type="submit" disabled={isSubmitting}> 
          				      		{isSubmitting ? <Trans i18nKey="laboratorysyntheses.save-multiple-syntheses-modal.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={faCheckCircle} /> <Trans i18nKey={"laboratorysyntheses.save-multiple-syntheses-modal.form.submit"}>Submit</Trans></span>} 
          				      </Button>
          				      <Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="laboratorysyntheses.save-multiple-syntheses-modal.cancel">Cancel</Trans></Button>	
          				</Modal.Footer>
              		</Form>	
				)}
		      </Formik>
		</Modal>
	);
}

export default SaveMultipleCDNASynthesesModal;
