import * as Yup from "yup";
import moment from "moment";

import CollectionConfig from "../config/CollectionConfig";

const MaterialSamples = CollectionConfig.Samples;

const MaterialSampleCodes = CollectionConfig.SamplesCodes;

const GeneralFormValidationRules = {
	id: Yup.string()
		.nullable()
		.default(() => ""),
	organismId: Yup.string()
		.default(() => ""),
	scientificName: Yup.string()
		.default(() => ""),
	sex: Yup.string()
		.default(() => ""),
	county: Yup.string()
		.default(() => ""),
	stateProvince: Yup.string()
		.default(() => ""),
	collectionId: Yup.string()
		.required()
		.default(() => ""),
	materialSample: Yup.string()
		.oneOf([""].concat(MaterialSamples), 'form.validation.string.enum')
		.required()
		.default(() => ""),
	labelId: Yup.string()
		.max(100, 'collectionsamples.save-laboratory-sample.form.labelId.maximumlength')
		.required()
		.default(() => ""),
	workgroupId: Yup.string()
		.required()
		.default(() => ""),
	screenedById: Yup.string()
		.required()
		.default(() => ""),
	dateScreened: Yup.date()
		.typeError('collectionsamples.save-laboratory-sample.form.dateScreened.validation.format')
		.max(new Date(), "collectionsamples.save-laboratory-sample.form.dateScreened.validation.future")
		.required()
		//.default(() => ""),
		.default(() => moment(new Date()).format("YYYY-MM-DD")),
	storageBoxId: Yup.string()
		//.required()
		.default(() => ""),
	slotCol: Yup.string()
			.default(() => ""),
	slotRow: Yup.string()
			.default(() => ""),
	ranOut: Yup.boolean()
    	.default(() => false),
}

const TissueDissociationsFormValidationRules = {
	/*tissueDissociations: Yup.array()
		.default(() => [])*/
}

const ExtractionsFormValidationRules = {
	/*extractions: Yup.array()
		.default(() => [])*/
}

const CDNASynthesesFormValidationRules = {
	/*syntheses: Yup.array()
		.default(() => [])*/
}

const LaboratoryTestsFormValidationRules = {
	/*tests: Yup.array()
		.default(() => [])*/
}

const SaveMultiplesFormValidationRules = {
	workgroupId: Yup.string()
		.required()
		.default(() => ""),
	screenedById: Yup.string()
		.required()
		.default(() => ""),
	dateScreened: Yup.date()
		.typeError('collectionsamples.save-laboratory-sample.form.dateScreened.validation.format')
		.max(new Date(), "collectionsamples.save-laboratory-sample.form.dateScreened.validation.future")
		.required()
		.default(() => moment(new Date()).format("YYYY-MM-DD")),
	storageBoxId: Yup.string()
		//.required()
		.default(() => "")
}

const LaboratorySampleConfig = { MaterialSamples, MaterialSampleCodes, GeneralFormValidationRules, 
	TissueDissociationsFormValidationRules, ExtractionsFormValidationRules, CDNASynthesesFormValidationRules, LaboratoryTestsFormValidationRules, 
	SaveMultiplesFormValidationRules 
};

export default LaboratorySampleConfig;