import React, { Fragment, useState, useRef } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { Trans, useTranslation} from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagic, faBarcode, faTimesCircle, faTag } from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import FormLabel from 'react-bootstrap/FormLabel';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner';

//import InputMask from "react-input-mask";
import BarcodeReader from 'react-barcode-reader'

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import DismissableFeedback from "../components/DismissableFeedback";

import { useConfig } from "../services/use-config";
import { useAuth } from "../services/use-auth";
import API from "../services/backend-api";

import { ValidationError } from "../helpers/custom-errors";

import CollectionConfig from "../config/CollectionConfig";

const barcodeButton = {
	backgroundColor: "#e9ecef", 
	border: "1px solid #ced4da", 
	color: "#495057",
	minWidth: 190
}

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });
	 

const validationSchema = Yup.object().shape(
	Object.assign(CollectionConfig.OrganismIdValidationRules,
	{ barcodeReader: Yup.string() })
);

const CollectionOrganismIdModal = props => {

	const [_error, _setError] = useState(null);
	const [isScanning, setIsScanning] = useState(false);
	const [isGenerating, setIsGenerating] = useState(false);

	const isScanningRef = useRef(isScanning);
	isScanningRef.current = isScanning;
	const scanningTimer = useRef(null);
	
	const config = useConfig();
	
	const auth = useAuth();
	
	const { t } = useTranslation();
	
	const getNextCollectionOrganismId = (values) =>
		new Promise((resolve, reject) => {
			API.getNextCollectionOrganismId(values, auth.isAdmin(), config.csrfToken).then(response => {
					resolve(response);
			}).catch(error => reject(error));
		});
	
	if (!props.collection) {
		return (<Navigate  to="/collections" />)
	}
	
	let initialValues = { 
		organismId: props.collection.organismId,
	};
	
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-collection-organism-id-modal"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-collection-organism-id-modal">
  					<Trans i18nKey="collections.collection-organism-id-modal.title">Set Collection OrganismId</Trans>
  				</Modal.Title>
  			</Modal.Header>
  			
  			<Formik
		      		initialValues={initialValues}
		      		validationSchema={validationSchema}
		      		validateOnChange={false}
		      		validateOnBlur={false}     
		      		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);
		      			
		      			props.onChange(values.organismId);
					
					}}
		      	>
		      	{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue, validateField, setFieldError}) => (	
		      		
		      		<Form className="form-collection-organism-id-modal" noValidate>		
		      			<Modal.Body className="mb-3">
		  				
		  					<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	 

							{(isScanning) && (
				    			<BarcodeReader
				    			      onError={(error) => { clearInterval(scanningTimer.current); setFieldError("organismId", "collections.collection-organism-id-modal.form.barcode-reader.validation.scanning-error"); setIsScanning(false); }}
				    			      onScan={(data) => { clearInterval(scanningTimer.current); setFieldValue("organismId", data);  setIsScanning(false); }}
				    			/>
				    		)}

							<Row className="mb-0">
							<Col md={12}>
								<FormLabel><Trans i18nKey="collections.collection-organism-id-modal.form.organismId.label">Organism Id</Trans> *</FormLabel>
							</Col>
				    		<FormGroup as={Col} md={5} className="mb-1" controlId="formGridOrganismId">
								<FormControl type={'text'} className="text-uppercase" name="organismId" mask='99aa9a99999' isInvalid={!(props.errors.organismId == null)||!(errors.organismId == null)||!(errors.barcodeReader == null)}  value={values.organismId} onChange={handleChange}  />
				    			{/*<FormControlErrors errors={!(errors.barcodeReader == null) ? errors.barcodeReader : errors.organismId} />*/}
				    		</FormGroup>
							<Col>
									<Button 
				    				 		variant="secondary" 
				    				 		className="mr-3"
				    				 		style={barcodeButton} 
				    				 		onClick={() => { 
				    				 			validateField("barcodeReader").then((e) => {
				    				 				if (!isScanning) {
				    				 					setIsScanning(true); 
				    				 					scanningTimer.current = setTimeout(() => { setIsScanning(false); setFieldError("barcodeReader", "collections.collection-organism-id-modal.form.barcode-reader.validation.scanning-timeout") }, 10*1000);
				    				 				} else{
				    				 					setIsScanning(false); 
				    				 					if (scanningTimer && scanningTimer.current) clearInterval(scanningTimer.current);
				    				 				}
				    				 			})
				    				 		}}>
				    				 	{(isScanning) ? (
				    				 		<Fragment>
				    				 			<Spinner animation="border" size="sm" />
				    				 		</Fragment>
				    				 	) : (
				    				 		<Fragment>
				    				 			<FontAwesomeIcon icon={faBarcode} /> <Trans i18nKey="collections.collection-organism-id-modal.form.organismId.scan-button">Click to Scan</Trans>
				    				 		</Fragment>
				    				 	)}
				    				 </Button>

									<Button 
				    				 	variant="secondary" 
				    				 	style={barcodeButton} 
				    				 	onClick={() => { 
				    				 		
				    				 		_setError(null);
				    				 		
				    				 		if (!props.collection.workgroupId) {
				    				 			_setError(t("collections.collection-organism-id-modal.form.organismId.validation.missing-workgroup"));
				    				 		} else if (!props.collection.taxonGroup) { 
				    				 			_setError(t("collections.collection-organism-id-modal.form.organismId.validation.missing-taxon-group"));
				    				 		} else {
				    				 		
				    				 		 	setIsGenerating(true);
				    				 		
				    				 			getNextCollectionOrganismId({workgroupId: props.collection.workgroupId, taxonGroup: props.collection.taxonGroup})
    												.then((response) => {
    													setFieldValue("organismId", response.organismId); 
    												})
    												.catch(error => { 
    								
    													if (error instanceof ValidationError) {		
    									
    														log.info("Get Next Collection Organism Id Attempt Failed: ", error.message);

    														if (error.detail) {            						
    	              											for (let key of Object.keys(error.detail)) {
    	              												let errorMsgs = error.detail[key];
    	              												errorMsgs.forEach((message) => {
    	              													setFieldError(key, message);
    	              												});
    	              											}
    	              										}
    													} else {
    														log.error("Error Getting Next Collection Organism Id: ", error.message);
    													}
    								
    												_setError(error.message);
    								
    												})
    												.finally(() => { setIsGenerating(false) })
    											}
				    				 		
				    				 	}}>
				    				 	{(isGenerating) ? (
				    				 		<Fragment>
				    				 			<Spinner animation="border" size="sm" />
				    				 		</Fragment>
				    				 	) : (
				    				 		<Fragment>
				    				 			<FontAwesomeIcon icon={faMagic} /> <Trans i18nKey="collections.collection-organism-id-modal.form.organismId.generate-button">Click to Generate Label ID</Trans>
				    				 		</Fragment>
				    				 	)}
				    				 </Button>
								</Col>
						</Row>
						
						<Row>
							<Col>
							{!(errors.barcodeReader == null) 
          						? <div className="text-left invalid-feedback" style={{display: "block"}}><Trans i18nKey={errors.barcodeReader} /></div>
								: !(errors.organismId == null) 
									? <div className="text-left invalid-feedback" style={{display: "block"}}><Trans i18nKey={errors.organismId} /></div>
									: <div className="text-left invalid-feedback" style={{display: "block"}}><Trans i18nKey={props.errors.organismId} /></div>
							}	
							</Col>
						</Row>

          				</Modal.Body>
          				<Modal.Footer>			      		
          				      <Button variant="success" type="submit" disabled={isSubmitting}> 
          				      		{isSubmitting ? <Trans i18nKey="collections.collection-organism-id-modal.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={faTag} /> <Trans i18nKey="collections.collection-organism-id-modal.form.submit">Add</Trans></span>} 
          				      </Button>
          				      <Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="collections.collection-organism-id-modal.cancel">Cancel</Trans></Button>	
          				</Modal.Footer>
              		</Form>	
				)}
		    </Formik>
		</Modal>
	);
}

export default CollectionOrganismIdModal;
