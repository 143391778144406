import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import log from 'loglevel';
import Container from 'react-bootstrap/Container';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import FormControlErrors from "../components/FormControlErrors";
import DismissableFeedback from "../components/DismissableFeedback";
import { useAuth } from "../services/use-auth";
import { ValidationError } from "../helpers/custom-errors";
//import { useQuery } from "../utils/commons";

import '../styles/ForgotPasswordPage.css';

import previrLogo from '../images/previr-logo-vazado-azul-mcti.png';

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   },
	   string: {
	     email: 'form.validation.string.email',
	   },
});

const validationSchema = Yup.object().shape({
	   email: Yup.string()
	     .email()
	     .required()
	 });

const ForgotPasswordSuccessPage = props => {

const { t } = useTranslation();

 return (
          <Container fluid className="text-center pt-4 pb-4">
          		<DismissableFeedback feedback={props.success} type="success" onClose={() => props.onSuccess(null)}/>	
            	<Row>	
            		<Col md={12} className="mb-3"><Image width="100" src={previrLogo} alt={t('app.logo')}/></Col>
            		<Col md={12} className="mb-3"><h1 className="text-success"><FontAwesomeIcon icon={faCheckCircle} /></h1></Col>
                    <Col md={12} className="mb-3"><h1 className="h3 fw-normal"><Trans i18nKey="forgot-password.success.header">Email was sent!</Trans></h1></Col>
					<Col md={12} className="mb-3"><Trans i18nKey="forgot-password.success.details">Instructions</Trans></Col>
              	    <Col md={12}><Button as={Link} variant="link" to="/login"><Trans i18nKey="forgot-password.back-to-login">Back to Login Page</Trans></Button></Col>
              	</Row>		
          </Container>
    );

}

const ForgotPasswordPage = props => {
	
  const [_error, _setError] = useState(null);
  const [_success, _setSuccess] = useState(null);
  
  const { t } = useTranslation();
  
  const auth = useAuth();

  const onSuccess = (success) => {
	_setSuccess(success);
  };
  
  useEffect(() => {
	let isMounted = true;

	if (auth.globalError) {
  		if (isMounted) _setError(auth.globalError.message)
  	}

	return () => { isMounted = false };
	
  }, [auth]);

  if (_success) {
  	return <ForgotPasswordSuccessPage success={_success} onSuccess={onSuccess} />
  }
	  
	  return (
          <Container className="text-center pt-2 pb-2">
          	<DismissableFeedback feedback={_error} type="danger" onClose={() => _setError(null)} />
			
          	<Formik
          		initialValues={{ email: '' }}
          		validationSchema={validationSchema}
          		validateOnChange={false}
          		validateOnBlur={false}     
          		onSubmit={(values, actions) => {
          			//Clear Error/Success
          			_setError(null);
          			_setSuccess(null);
 
          			auth.sendUserPasswordResetEmail({ values: values })
          				.then((response) => {
          					_setSuccess(response.success);
          					
          					actions.resetForm();
          				})
          				.catch(error => {
          					
          					if (error instanceof ValidationError) {		
          						
          						log.info("User Password Recovery Attempt Failed: ", error.message);
          						
          						_setError(error.message);
          						
          						if (error.detail) {            						
              						for (let key of Object.keys(error.detail)) {
              							let errorMsgs = error.detail[key];
              							errorMsgs.forEach((message) => {
              								actions.setFieldError(key, message);
              							});
              						}
              					}
          						
          					} else {
          						
          						log.error("User Password Recovery Error: ", error.message);
          						
          						_setError(t('error.api.general'));
          					}
          					
          				})
          				.finally(() => {
          					actions.setSubmitting(false);
          				});
          		}}
          	>
          		{({isSubmitting, errors, values, handleChange, setFieldValue}) => (	
          			<Form className="form-forgot-password" noValidate>
          				
          				<Image className="mb-2" width="150" src={previrLogo} alt={t('app.logo')}/>
                  		<h1 className="h3 mb-3 fw-normal"><Trans i18nKey="forgot-password.form.header">Please sign in</Trans></h1>
                  		
                  		<FormGroup controlId="formEmail">
          	                <FormLabel><Trans i18nKey="forgot-password.form.email.label">Email address</Trans></FormLabel>
          	                <FormControl type={'email'} name="email" isInvalid={!(errors.email == null)} value={values.email} onChange={handleChange} placeholder={t('forgot-password.form.email.placeholder')} />
          	                <FormControlErrors errors={errors.email} />
          	            </FormGroup>
                  		
                  		<Button variant="danger" type="submit" size="lg" block disabled={isSubmitting}> 
                  		    {isSubmitting ? <Trans i18nKey="forgot-password.form.submitting">Please wait...</Trans> : <Trans i18nKey="forgot-password.form.submit">Submit</Trans>} 
              			</Button>
              			
              			<Button as={Link} variant="link" to="/login"><Trans i18nKey="forgot-password.back-to-login">Back to Login Page</Trans></Button>
              			
              		</Form>		
          		 )}
   		     </Formik>  
          </Container>
    );
}

export default ForgotPasswordPage;
