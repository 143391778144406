import React,  { Fragment, useState, useEffect} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';

import FormControlErrors from "../components/FormControlErrors";
import FormControlHelper from "../components/FormControlHelper";
import StorageBoxInputSearch from "../components/StorageBoxInputSearch";

import CDNASynthesisConfig from "../config/CDNASynthesisConfig";

import moment from "moment";
import { DatePicker } from "react-tempusdominus-bootstrap";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";

import { parseInputDate } from "../helpers/commons";

const MultipleCDNASynthesesFormFields = props => {

	const [workgroups, setWorkgroups] = useState([]);
	const [workgroupUsers, setWorkgroupUsers] = useState([]);
	
	const { t } = useTranslation();
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
		
		if (isMounted) {
			
		}
		
		//Fetch Laboratory Workgroups
		API.findLaboratories({})
			.then(response => {
				if (isMounted) setWorkgroups(response.list);
			})
			.catch(error => { 
				log.error("Error Loading Analysis Workgroups: ", error.message);
		 		props.onError(error);
			})
			.finally(() => {
		 		//Get Workgroup Users
				if (props.values.workgroupId) {
					getWorkgroupUsers(props.values.workgroupId);
					//getStorageBoxes(props.values.workgroupId);
				}
			});
		
		 return () => { isMounted = false };
		  
	}, []);
	
	const getWorkgroupUsers = (workgroupId) => {
    	API.findUsers({"filteredColumn": "workgroupId", "filter": workgroupId})
			.then(response => {
					setWorkgroupUsers(response.list); 					
			})
			.catch(error => { 
				log.error("Error Loading Workgroup Users: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		  //Do nothing yet
			});
    }

	/*const getStorageBoxes = (workgroupId) => {
    	API.findStorageBoxes(workgroupId, {})
			.then(response => {
					setStorageBoxes(response.list); 					
			})
			.catch(error => { 
				log.error("Error Loading Workgroup Storage Boxes: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		  //Do nothing yet
			});
    }*/

	const handleWorkgroupChange = (e) => {
        
        if (!e.target.value) {
        	setWorkgroupUsers([]);
			//setStorageBoxes([]);
        	
			props.setFieldValue("workgroupId", "");
			props.setFieldValue("synthesizedById", "");
			props.setFieldValue("storageBoxId", "");
			
        	return;
        }
        
		props.setFieldValue("workgroupId", e.target.value);

        //Get Workgroup Users
        getWorkgroupUsers(e.target.value);
        
        //Get Storage Boxes
		//getStorageBoxes(e.target.value);
        
        //Reset dissociatedBy and storageBox
        props.setFieldValue("synthesizedById", "");
		props.setFieldValue("storageBoxId", "");
    }

	const handleStorageBoxChange = (storageBox) => {
        
        if (!storageBox) {
        	
			props.setFieldValue("storageBoxId", "");
			
        	return;
        } /*else if (storageBox.id === props.values.storageBoxId) {
			return;
		}*/
		
		props.setFieldValue("storageBoxId", storageBox.id);

    }

    
	return(
		<Fragment>					
			<Row>
							
				<FormGroup as={Col} md={6} controlId="formGridLabelIdPrefix">
    				<FormLabel ><Trans i18nKey={props.i18nPrefix+"form.labelIdPrefix.label"}>Label Id Prefix</Trans> <FormControlHelper text={props.i18nPrefix+"form.labelIdPrefix.helper"} /> *</FormLabel>
					<FormControl type={'text'} name="labelIdPrefix" isInvalid={!(props.errors.labelIdPrefix == null)} value={props.values.labelIdPrefix} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.labelIdPrefix.placeholder")} />
				    <FormControlErrors errors={props.errors.labelIdPrefix} />
				</FormGroup>
				
			</Row>
			
			
			<Row >
				
				<FormGroup as={Col} className="mb-0" controlId="formGridWorkgroup">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.workgroupId.label"}>Workgroup</Trans> *</FormLabel>
					<FormControl as="select" name="workgroupId" isInvalid={!(props.errors.workgroupId == null)} value={props.values.workgroupId} onChange={handleWorkgroupChange} >
		    			<option value="">{t(props.i18nPrefix+"form.workgroupId.blank-option")}</option>
		    				{ workgroups.map(item =>
		    					<option key={item.id} value={item.id+""}>{item.name + " (" + item.shortcode + ")"}</option>
		    				)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.workgroupId} />
				 </FormGroup>
			
				<FormGroup as={Col} controlId="formGridStorageBox">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.storageBoxId.label"}>Storage Box</Trans></FormLabel>
					<StorageBoxInputSearch 
				    	i18nPrefix={props.i18nPrefix+"form."}
				    	onError={props.onError}
				    	isInvalid={!(props.errors.storageBoxId == null)}
				    	onChange={handleStorageBoxChange}
						workgroupId={props.values.workgroupId}
						disabled={!(props.values.workgroupId)}
						storageBoxId={props.values.storageBoxId}
				    />
					<FormControlErrors errors={props.errors.storageBoxId} />
				</FormGroup>
			</Row>
			
			<Row>

				<FormGroup as={Col} controlId="formGridDateSynthesized">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.dateSynthesized.label"}>Date Synthesized</Trans> *</FormLabel>
					<DatePicker
      					format="DD/MM/YYYY"
      					className={!(props.errors.dateSynthesized == null) ? "is-invalid border border-danger rounded" : ""}
      					onChange={(e) => { 
      						if (e.date) { 
      							if (e.date.isValid()) 
      								props.setFieldValue("dateSynthesized", moment(e.date).format("YYYY-MM-DD"));
      							else
      								props.setFieldValue("dateSynthesized", e.date.parsingFlags().inputDate);
      						} else if (e.date === null) { // reset if null (which is !== from undefined)
      							props.setFieldValue("dateSynthesized", "");
      						}
      					}}
      					date={props.values.dateSynthesized}
      					parseInputDate={parseInputDate}
      					locale={config.preferredLanguage}
      					keepInvalid={true}
    				/>
					<FormControlErrors errors={props.errors.dateSynthesized} />
				</FormGroup>
				
				<FormGroup as={Col} controlId="formGridSynthesizedById">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.synthesizedById.label"}>Synthesized By</Trans> *</FormLabel>
					<FormControl as="select" disabled={!(props.values.workgroupId)} name="synthesizedById" isInvalid={!(props.errors.synthesizedById == null)} value={props.values.synthesizedById} onChange={props.onChange} >
		    			<option value="">{t(props.i18nPrefix+"form.synthesizedById.blank-option")}</option>
		    			{ workgroupUsers.map(item =>
		    					<option key={item.id} value={item.id}>{item.firstName+" "+item.lastName}</option>
		    			)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.synthesizedById} />
				</FormGroup>
				
			</Row>
		    							
		
		</Fragment>
		
	) 
}


export default MultipleCDNASynthesesFormFields;
