import React,  { useState, useEffect } from 'react';
import { useLocation, useOutletContext} from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import Badge from 'react-bootstrap/Badge';

import Loader from "../components/Loader";
import SaveLaboratoryTagModal from "../components/SaveLaboratoryTagModal";
import ConfirmationDialogModal from "../components/ConfirmationDialogModal";


import API from "../services/backend-api";
import { useAuth } from "../services/use-auth";
import { useConfig } from "../services/use-config";

import { isArrayWithLength } from "../helpers/commons";


const tagToken = {
	backgroundColor: "#e7f4ff",
	color: '#007bff'
};

const LaboratoryTags = props => {

	const [workgroups, setWorkgroups] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isFindingTags, setIsFindingTags] = useState(false);
	const [selectedWorkgroupId, setSelectedWorkgroupId] = useState("");
	const [laboratoryTags, setLaboratoryTags] = useState([]);
	const [saveTagModalShow, setSaveTagModalShow] = useState(false);
	const [selectedTag, setSelectedTag] = useState(null);
		
	const [deleteTagModalShow, setDeleteTagModalShow] = useState(false);
	
	const { t } = useTranslation();
	const auth = useAuth();
	const config = useConfig();
	
	const location = useLocation();
	
	let context = useOutletContext();
		
	const findLaboratoryTags = (id) => {
		setIsFindingTags(true);
	
		API.findLaboratoryTags(id, {}, auth.isAdmin()).then(response => {
			setLaboratoryTags(response.list);
		}).catch(error => { 
			log.error("Error Finding Laboratory Tags: ", error.message);
			context.onError(error);	
		}).finally(() => {
		 	setIsFindingTags(false);
		});
	
  	};

	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
			
		//Fetch Laboratory Workgroups
		API.findWorkgroups({"types": ["LABORATORY"]}, auth.isAdmin())
			.then(response => {
				if (isMounted) {
					setWorkgroups(response.list);
					
					if (isArrayWithLength(response.list) && (response.list.length === 1)) {
						
						let labId = response.list[0].id;
						
						setSelectedWorkgroupId(labId);
					
						findLaboratoryTags(labId);
					}
				}
			})
			.catch(error => { 
				log.error("Error Loading Analysis Workgroups: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		
	}, []);

	if (isLoading) 
		return <Loader />
		
  const handleLaboratoryTagSaved = (success) => {
	  setSaveTagModalShow(false);
	  context.onSuccess(success);
	  findLaboratoryTags(selectedWorkgroupId);
  }
  
  const confirmLaboratoryTagRemoval = (tag) => {
	  setSelectedTag(tag);
	  setDeleteTagModalShow(true);
  }

  const newLaboratoryTag = () => {
	  setSelectedTag(null);
	  setSaveTagModalShow(true);
    }

 const updateLaboratoryTag = (tag) => {
	  setSelectedTag(tag);
	  setSaveTagModalShow(true);
 }
		     
 const deleteLaboratoryTag = (tag) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		setDeleteTagModalShow(false);
		
		API.deleteLaboratoryTag(tag.id, auth.isAdmin(), config.csrfToken).then(response => {
			context.onSuccess(response.success)
			findLaboratoryTags(selectedWorkgroupId);
		}).catch(error => { 	
			log.error("Error Removing Laboratory Tag: ", error.message);
			context.onError(error);		
		});
  }
	
	
  const handleWorkgroupChange = (e) => {
	
	let workgroupId = e.target.value;
	
	setSelectedWorkgroupId(workgroupId);
	setLaboratoryTags([]);
	
	if (workgroupId) findLaboratoryTags(workgroupId);
  }
	
		
	return (
		<Container >
			{(saveTagModalShow) && <SaveLaboratoryTagModal
				show={saveTagModalShow}
	        	onHide={() => setSaveTagModalShow(false)}
				size="sm"
				tag={selectedTag}
				workgroupId={selectedWorkgroupId}
				onTagSaved={handleLaboratoryTagSaved}
		      />
			}
			{(selectedTag) && <ConfirmationDialogModal
				item={selectedTag}
				show={deleteTagModalShow}
        		onHide={() => setDeleteTagModalShow(false)}
				size="lg"
				title={t("laboratorytags.delete-tag-confirmation-modal.title")}
				bodyText={t("laboratorytags.delete-tag-confirmation-modal.body", {tag: selectedTag})}
				confirmText={t("laboratorytags.delete-tag-confirmation-modal.confirm")}
				cancelText={t("laboratorytags.delete-tag-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteLaboratoryTag}
			/>}
			<Row className="mb-3">
			
        		<Col>
        			<h3>
						{t("laboratorytags.list.header")}
					</h3>
        		</Col>
  
        	</Row>

			<Row className="mb-3">
				<FormGroup as={Col} sm={7} xl={8} controlId="formGridWorkgroup">
					<FormLabel><Trans i18nKey="laboratorytags.list.form.workgroupId.label">Workgroup</Trans> *</FormLabel>
					<FormControl as="select" name="workgroupId" value={selectedWorkgroupId} onChange={handleWorkgroupChange} >
		    			<option value="">{t("laboratorytags.list.form.workgroupId.blank-option")}</option>
		    			{ workgroups.map(item =>
		    				<option key={item.id} value={item.id+""}>{item.name+" ("+item.shortcode+")"}</option>
		    			)}
		    		</FormControl>
				</FormGroup>    			

    			{ (selectedWorkgroupId) &&
					<Col sm={5} xl={4}>
						<FormLabel> </FormLabel>
						<div className="d-flex justify-content-end">
							<Button variant="outline-success" disabled={false} onClick={() => {newLaboratoryTag();}}><FontAwesomeIcon icon={faPlusCircle} /> <Trans i18nKey="laboratorytags.list.add-button" /></Button>	
						</div>
					</Col>
				}
				
    		</Row>

			<Row>
        		<Col>
        			
					{ (isFindingTags) ? <Loader/> :
						((selectedWorkgroupId) && (!isArrayWithLength(laboratoryTags))) ?
							<span className="font-italic"><Trans i18nKey="laboratorytags.list.no-items"></Trans></span>
						:
							<h4>
								{laboratoryTags.map((tag, index) => (
									<Badge className="m-1" style={tagToken} key={index} variant="light">
										<Button variant="link" onClick={() => { updateLaboratoryTag(tag); } }>
				      						{tag.name}
				      					</Button>
										<Button variant="link" size="sm" style={{color: "red"}} onClick={() => { confirmLaboratoryTagRemoval(tag);} }>
				      						<FontAwesomeIcon icon={faTimesCircle} />
				      					</Button>
									</Badge>
								 ))}
							</h4>
					 }
					
        		</Col>
        	</Row>


      </Container>
	);
}

export default LaboratoryTags;
