import { useLocation } from 'react-router-dom';
import { intersection } from 'lodash';
import moment from "moment";
import log from 'loglevel';

export const useQuery = () => { 
	  return new URLSearchParams(useLocation().search);
};

export const isArrayWithLength = (arr) => {
 return (Array.isArray(arr) && arr.length)
}

export const isAllowedRoute = (route, user) => {
 if (!isArrayWithLength(route.roles) && !isArrayWithLength(route.permissions)) return true;
 else return intersection(route.roles, user.roles).length + intersection(route.permissions, user.permissions).length;
}

export const getAllowedRoutes = (routes, user) => {
 return routes.filter(({ roles, permissions }) => {
		 if(!isArrayWithLength(roles) && !isArrayWithLength(permissions)) return true;
		 else return intersection(roles, user.roles).length + intersection(permissions, user.permissions).length;
 });
}

export const getHomeBrowserRoute = (routes, user) => {
	return getHomeHashRoute(routes, user);
}

export const getHomeHashRoute = (routes, user) => {
	
	let homeRoutes = routes.filter(({ home }) => {
		if (!home) return false;
		else if(!isArrayWithLength(home)) return false;
		else return intersection(home, user.roles).length;
	});
	
	if (isArrayWithLength(homeRoutes))
		 return homeRoutes[0].path
	 
	return "/home";
}


export const getAllowedNavbarOptions = (options, user) => {
	 
	 return options.filter(({ roles, permissions }) => {
		 if(!isArrayWithLength(roles) && !isArrayWithLength(permissions)) return true;
		 else return intersection(roles, user.roles).length + intersection(permissions, user.permissions).length;
	 });
}

export const getCoordinateDirection = (degrees, isLongitude) => {
	return degrees > 0 ? (isLongitude ? "E" : "N") : isLongitude ? "W" : "S";
}

export const formatCoordinateDegrees = (degrees, isLongitude) => {
	return `${0 | degrees}° ${
    0 | ((Math.abs(degrees) % 1) * 60)
}' ${0 | (((Math.abs(degrees) * 60) % 1) * 60)}" ${getCoordinateDirection(
    degrees,
    isLongitude,
)}`;
}

export const loadScript = (id, url, callback) => {
	  
	  const existingScript = document.getElementById(id);
	  
	  if (!existingScript) {
	    const script = document.createElement('script');
	    script.src = url;
	    script.id = id;
	    
	    document.body.appendChild(script);
	    //document.getElementsByTagName("head")[0].appendChild(script);
	    
	    script.onload = () => { 
	      if (callback) callback();
	    };
	    
	    script.onerror = () =>{ /* TODO: handle error */ }
	    
	  } else {
		  if (callback) callback();
	  }

};

export const promiseWithTimeout = (promise, milliseconds) => {

    const timeout =  new Promise((resolve, reject) =>
        setTimeout(() => 
            reject(`Promise timeout reached (limit: ${milliseconds} ms)`), 
            	milliseconds));

    return Promise.race([
        timeout, 
        promise
    ]);
};


export const parseInputDate = (inputDate) => {
    
    	let resultDate;
		
		if (inputDate) {
    		if (moment.isMoment(inputDate) || inputDate instanceof Date) {
				resultDate = moment.utc(inputDate);
			} else {	
				
				//resultDate = moment.utc(inputDate, 'DD/MM/YYYY');
				resultDate = moment.utc(inputDate, 'L');
				
				//If invalid set a custom flag: inputDate
				if (!resultDate.isValid())
					resultDate = moment.invalid({inputDate: inputDate});
			}
    		
    	}
 
    	return resultDate;
    }

 
 export const compareJSON = (obj1, obj2) => {

  var ret = {};
  for(var i in obj2) {
    if(!obj1.hasOwnProperty(i) || obj2[i] !== obj1[i]) {
      ret[i] = obj2[i];
    }
  }
  return ret;
}


export const niceBytes = (x) => {
 const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let l = 0, n = parseInt(x, 10) || 0;
  while(n >= 1024 && ++l){
      n = n/1024;
  }
  return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}
    

// converts numbers to spreadsheet letter columns eg. 1 -> A
export const numToColumn = (num) => {
  let s = '', t;

  while (num > 0) {
    t = (num - 1) % 26;
    s = String.fromCharCode(65 + t) + s;
    num = (num - t)/26 | 0;
  }
  return s || undefined;
}

export const isObject = (val) => {
  if (val === null) { return false;}
    return ( (typeof val === 'function') || (typeof val === 'object') );
}

export const Regex = {
	DOUBLE: '^[0-9]{0,}(\\.[0-9]{1,})?$',
}