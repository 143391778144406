import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Route, Navigate, useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faChevronDown, faChevronUp, faClone, faTimesCircle} from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import FormGroup from 'react-bootstrap/FormGroup';
import FormCheck from 'react-bootstrap/FormCheck';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from "react-bootstrap/AccordionContext";

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import DismissableFeedback from "../components/DismissableFeedback";
import Loader from "../components/Loader";

import { useAuth } from "../services/use-auth";
import API from "../services/backend-api";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });
	 
const GeneralDataFieldsValidationRules = {
	workgroupId: Yup.bool().default(true),
	capturedById: Yup.bool().default(true),
	eventDate: Yup.bool().default(true),
	county: Yup.bool().default(true),
	locality:  Yup.bool().default(true),
	coordinates:  Yup.bool().default(true),
	//latitude:  Yup.bool().default(true),
	//longitude: Yup.bool().default(true),
	captureMethod:  Yup.bool().default(true),
	samplingEffort:  Yup.bool().default(true),
	habitat:  Yup.bool().default(true),
	captureType:  Yup.bool().default(true),
	markingType:   Yup.bool().default(true)
}

const TaxonomyDataFieldsValidationRules = {
	scientificName: Yup.bool().default(true),
	vernacularName: Yup.bool().default(true),
	identifiedById: Yup.bool().default(true),
	dateIdentified: Yup.bool().default(true),
	//taxonGroup: Yup.bool().default(true),
	//taxonClass: Yup.bool().default(true),
	//family: Yup.bool().default(true),
	//order: Yup.bool().default(true),
	//genus: Yup.bool().default(true),
	//specificEpithet: Yup.bool().default(true)
}

const HistoryFormValidationRules = {
	sex: Yup.bool().default(true),
	reproductiveCondition: Yup.bool().default(true),
	lifeStage: Yup.bool().default(true),
	degreeOfEstablishment: Yup.bool().default(true),
	establishmentMeans: Yup.bool().default(true),
	symptomaticCondition: Yup.bool().default(true)
}

const PhysicalExamFormValidationRules = {
	measurements: Yup.bool().default(false),
	ectoparasite: Yup.bool().default(true),
}

const SamplesFormValidationRules = {
	samples: Yup.bool().default(true),
	preparedById: Yup.bool().default(true),
	//samplesMedium: Yup.bool().default(true),
	samplesMediums: Yup.bool().default(true),
	samplesPreservationMethod: Yup.bool().default(true),
	samplesCoolingAgent: Yup.bool().default(true),
	samplesRemarks: Yup.bool().default(true)
}

const ExtraFormValidationRules = {
	sisbioLicenseNumber: Yup.bool().default(true),
	ceuaLicenseNumber: Yup.bool().default(true),
	sisgenId: Yup.bool().default(true),
	remarks: Yup.bool().default(true)
}

const accordionCardConfig = [
	{
		key: "generalData",
		value: true, 
		data: GeneralDataFieldsValidationRules
	},
	{
		key: "taxonomyData",
		value: true, 
		data: TaxonomyDataFieldsValidationRules
	},
	{
		key: "physicalExamData",
		value: false, 
		data: PhysicalExamFormValidationRules
	},
	{
		key: "historyData",
		value: true, 
		data: HistoryFormValidationRules
	},
	{
		key: "samplesData",
		value: true, 
		data: SamplesFormValidationRules
	},
	{
		key: "extraData",
		value: true, 
		data: ExtraFormValidationRules
	}
]

const validationSchema = Yup.object().shape(
	Object.assign({}, ...accordionCardConfig.map((card) => card.data))
);


const ContextAwareToggle = ({ children, eventKey, callback }) => {
  
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Button variant="link" onClick={decoratedOnClick}  className="float-right">
     	 <FontAwesomeIcon size="lg" icon={isCurrentEventKey ? faChevronUp : faChevronDown} />
    </Button>
  );
}

const CloneCollectionModal = props => {

	const [_error, _setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [originalCollection, setOriginalCollection] = useState(null);
	
	let navigate = useNavigate();
	
	const auth = useAuth();

	useEffect(() => {
		let isMounted = true; 
		
		if (props.collection) {
			API.lookupCollection(props.collection.id, auth.isAdmin())
			.then(response => {
					setOriginalCollection(response);
			})
			.catch(error => { 
				log.error("Error Loading Original Collection: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) {
		 			setIsLoading(false);
		 		}
			});
		} 
		 
		return () => { isMounted = false };
		  
	}, []);

	
	if (!props.collection) {
		return (<Navigate  to="/collections" />)
	} else if (!isLoading && !originalCollection) {
		return null;
	}
	
	
	let initialValues = Object.assign(
		...accordionCardConfig.map((card) => { return { [card.key]: card.value } })
		, validationSchema.default()
	);

	
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-clone-collection-modal"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-clone-collection-modal">
  					<Trans i18nKey="collections.clone-collection-modal.title">Clone Collection Data</Trans>
  				</Modal.Title>
  			</Modal.Header>
  			{(isLoading) ? (
				<Modal.Body className="mb-3"><Loader /></Modal.Body>
			) : (
  				<Formik
		      		initialValues={initialValues}
		      		validationSchema={validationSchema}
		      		validateOnChange={true}
		      		validateOnBlur={true}     
		      		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);
		      			
		      			let clonedCollection = {};
		      			
		      			accordionCardConfig.forEach((card) => {
		      				if (values[card.key] === true) {
		      					Object.keys(card.data)
									.forEach((key) => {
										if (values[key] === true) {
											switch (key) {
												case "coordinates":
													clonedCollection["latitude"] = originalCollection["latitude"];
													clonedCollection["longitude"] = originalCollection["longitude"];
													break;
												case "county":
													clonedCollection[key] = originalCollection[key];
													clonedCollection["stateProvince"] = originalCollection["stateProvince"];
													clonedCollection["country"] = originalCollection["country"];
													break;
												case "scientificName":
													clonedCollection[key] = originalCollection[key];
													clonedCollection["taxonGroup"] = originalCollection["taxonGroup"];
													clonedCollection["taxonId"] = originalCollection["taxonId"];
													clonedCollection["taxonRank"] = originalCollection["taxonRank"];
													clonedCollection["kingdom"] = originalCollection["kingdom"];
													clonedCollection["phylum"] = originalCollection["phylum"];
													clonedCollection["taxonClass"] = originalCollection["taxonClass"];
													clonedCollection["order"] = originalCollection["order"];
													clonedCollection["family"] = originalCollection["family"];
													clonedCollection["genus"] = originalCollection["genus"];
													clonedCollection["specificEpithet"] = originalCollection["specificEpithet"];
													clonedCollection["infraspecificEpithet"] = originalCollection["infraspecificEpithet"];
													clonedCollection["family"] = originalCollection["family"];
													break;
												case "measurements":
													clonedCollection["forearmLength"] = originalCollection["forearmLength"];
													clonedCollection["headBodyLength"] = originalCollection["headBodyLength"];
													clonedCollection["tailLength"] = originalCollection["tailLength"];
													clonedCollection["earLength"] = originalCollection["earLength"];
													clonedCollection["footLength"] = originalCollection["footLength"];
													clonedCollection["footIncludingNailLength"] = originalCollection["footIncludingNailLength"];
													clonedCollection["tragusLength"] = originalCollection["tragusLength"];
													clonedCollection["calcarLength"] = originalCollection["calcarLength"];
													clonedCollection["tibiaLength"] = originalCollection["tibiaLength"];
													clonedCollection["totalLength"] = originalCollection["totalLength"];
													clonedCollection["tarsusLength"] = originalCollection["tarsusLength"];
													clonedCollection["wingLength"] = originalCollection["wingLength"];
													clonedCollection["culmenLength"] = originalCollection["culmenLength"];
													clonedCollection["nostrilsLength"] = originalCollection["nostrilsLength"];
													clonedCollection["morphometricMeasurements"] = originalCollection["morphometricMeasurements"];
													clonedCollection["weight"] = originalCollection["weight"];
													break;
												case "samples":
													clonedCollection[key] = originalCollection[key];
													clonedCollection["otherSampleDescription"] = originalCollection["otherSampleDescription"];
													break;
												default:
													clonedCollection[key] = originalCollection[key];
											}
										}
									});
		      				}
		      			
		      			});
		      			
		      			/*if (values.generalData === true) {
		      				Object.keys(GeneralDataFieldsValidationRules)
								.forEach((key) => {
								if (values[key] === true)
									clonedCollection[key] = originalCollection[key];
								});
		      			}
		      			
		      			if (values.taxonomyData === true) {
		      				Object.keys(TaxonomyDataFieldsValidationRules)
								.forEach((key) => {
								if (values[key] === true)
									clonedCollection[key] = originalCollection[key];
								});
		      			}*/
		      			
		      			navigate("/collections/clone", { state: { item: clonedCollection}});
					
					}}
		      	>
		      	{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue}) => (	
		      		
		      		<Form className="form-clone-collection-modal" noValidate>		
		      			<Modal.Body className="pt-2">
		  				
		  					<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  

  								<Row className="mb-2 pl-3 pr-3 align-items-center">
  									<Col md={1} sm={2} xs={2} className="text-center p-1">
  										<h1 className={"text-primary"}><FontAwesomeIcon icon={faExclamationTriangle} /></h1>
									</Col>
		  							<Col className="ml-3 text-justified align-middle">
										<Trans i18nKey="collections.clone-collection-modal.body">Clone Collection Body</Trans>
									</Col>
								</Row>

								<Row className="pl-3 pr-3">
									<Col>
										<Accordion defaultActiveKey="0">
										{ accordionCardConfig.map((card, index) => (
											
											<Card key={card.key}>
    											<Card.Header className="pt-1 pb-1">
      												<Row>
      												<FormGroup as={Col} className="mb-0 my-auto" controlId={"formGrid-"+card.key}>		
															<FormCheck type={"checkbox"} >
        														<FormCheck.Input 
        															type={"checkbox"}
        															name={card.key}
        															value={values[card.key]}
        															checked={(values[card.key])}
	     															onChange={handleChange} 
	     														/>
        														<FormCheck.Label>
        															<Trans i18nKey={"collections.clone-collection-modal.form."+card.key+".label"}>{card.key}</Trans>
																</FormCheck.Label>
      														</FormCheck>
	     											</FormGroup>
													<Col>
														<ContextAwareToggle eventKey={index.toString()} />
													</Col>
													</Row>
    											</Card.Header>
    											<Accordion.Collapse eventKey={index.toString()}>
      												<Card.Body className="pt-2 pb-2">
      													<Row>
      													{ Object.keys(card.data).map((field, index) => (
															<Fragment key={index}>
																<Col md={1} />
																<FormGroup as={Col} md={5} className="mb-1" controlId={"formGrid-"+field}>		
																		<FormCheck type={"checkbox"} >
        																<FormCheck.Input 
        																	type={"checkbox"}
        																	name={field}
        																	value={values[field]}
        																	checked={values[field]}
	     																	onChange={handleChange} 
	     																	disabled={!(values[card.key])}
	     																/>
        																<FormCheck.Label>
        																	<Trans i18nKey={"collections.save.form."+field+".label"}>{field}</Trans>
																		</FormCheck.Label>
      																</FormCheck>
	     														</FormGroup>
															</Fragment>
														))}
														</Row>
      												</Card.Body>
    											</Accordion.Collapse>
  											</Card>
											
											 ))}
										</Accordion>
									</Col>
								</Row>

								{/*<Row className="pl-3 pr-3">
									<Col>
										<Accordion defaultActiveKey="0">
  											<Card>
    											<Card.Header>
      												<Accordion.Toggle as={Button} variant="link" eventKey="0">
        												<FormGroup className="mb-1" controlId="formGridGeneralData">		
															<FormCheck type={"checkbox"} >
        														<FormCheck.Input 
        															type={"checkbox"}
        															name="generalData"
        															value={values.generalData}
        															checked={values.generalData}
	     															onChange={handleChange} 
	     														/>
        														<FormCheck.Label>
        															<Trans i18nKey={"collections.clone-collection-modal.form.general-data.label"}>General Data</Trans>
																</FormCheck.Label>
      														</FormCheck>
	     												</FormGroup>
      												</Accordion.Toggle>
    											</Card.Header>
    											<Accordion.Collapse eventKey="0">
      												<Card.Body>
      													<Row>
      													{ Object.keys(GeneralDataFieldsValidationRules).map((field, index) => (
															<Fragment key={index}>
																<Col md={1} />
																<FormGroup as={Col} md={5} className="mb-1" controlId={"formGrid-"+field}>		
																		<FormCheck type={"checkbox"} >
        																<FormCheck.Input 
        																	type={"checkbox"}
        																	name={field}
        																	value={values[field]}
        																	checked={values[field]}
	     																	onChange={handleChange} 
	     																	disabled={!(values.generalData)}
	     																/>
        																<FormCheck.Label>
        																	<Trans i18nKey={"collections.save.form."+field+".label"}>{field}</Trans>
																		</FormCheck.Label>
      																</FormCheck>
	     														</FormGroup>
															</Fragment>
														))}
														</Row>
      												</Card.Body>
    											</Accordion.Collapse>
  											</Card>
  											<Card>
    											<Card.Header>
      												<Accordion.Toggle as={Button} variant="link" eventKey="1">
        												Taxonomy Data
      												</Accordion.Toggle>
    											</Card.Header>
    											<Accordion.Collapse eventKey="1">
      												<Card.Body>Hello! I'm another body</Card.Body>
    											</Accordion.Collapse>
  											</Card>
										</Accordion>
									</Col>
								</Row>*/}

								{/*<Row className="pl-3 pr-3"><Col md={6}>

								<Row>
									<FormGroup as={Col} className="mb-1" md={12} controlId="formGridGeneralData">		
										<FormCheck type={"checkbox"} >
        									<FormCheck.Input 
        										type={"checkbox"}
        										name="generalData"
        										value={values.generalData}
        										checked={values.generalData}
	     										onChange={handleChange} 
	     									/>
        									<FormCheck.Label>
        										<Trans i18nKey={"collections.clone-collection-modal.form.general-data.label"}>General Data</Trans>
											</FormCheck.Label>
      									</FormCheck>
	     							</FormGroup>
								</Row>

								{ Object.keys(GeneralDataFieldsValidationRules).map((field, index) => (
								
									<Row key={index}>
										<Col md={1} />
										<FormGroup as={Col} className="mb-1" controlId={"formGrid-"+field}>		
											<FormCheck type={"checkbox"} >
        										<FormCheck.Input 
        											type={"checkbox"}
        											name={field}
        											value={values[field]}
        											checked={values[field]}
	     											onChange={handleChange} 
	     											disabled={!(values.generalData)}
	     										/>
        										<FormCheck.Label>
        											<Trans i18nKey={"collections.save.form."+field+".label"}>{field}</Trans>
												</FormCheck.Label>
      										</FormCheck>
	     								</FormGroup>
									</Row>
								
								))}
							
							</Col>
							
				    		<Col md={6}>
				    		
				    		<Row>
									<FormGroup as={Col} className="mb-1" md={12} controlId="formGridTaxonomyData">		
										<FormCheck type={"checkbox"} >
        									<FormCheck.Input 
        										type={"checkbox"}
        										name="taxonomyData"
        										value={values.taxonomyData}
        										checked={values.taxonomyData}
	     										onChange={handleChange} 
	     									/>
        									<FormCheck.Label>
        										<Trans i18nKey={"collections.clone-collection-modal.form.taxonomy-data.label"}>Taxonomy Data</Trans>
											</FormCheck.Label>
      									</FormCheck>
	     							</FormGroup>
								</Row>

								{ Object.keys(TaxonomyDataFieldsValidationRules).map((field, index) => (
								
									<Row key={index}>
										<Col md={1} />
										<FormGroup as={Col} className="mb-1" controlId={"formGrid-"+field}>		
											<FormCheck type={"checkbox"} >
        										<FormCheck.Input 
        											type={"checkbox"}
        											name={field}
        											value={values[field]}
        											checked={values[field]}
	     											onChange={handleChange}
	     											disabled={!(values.taxonomyData)}
	     										/>
        										<FormCheck.Label>
        											<Trans i18nKey={"collections.save.form."+field+".label"}>{field}</Trans>
												</FormCheck.Label>
      										</FormCheck>
	     								</FormGroup>
									</Row>
								
								))}

				    		</Col></Row>*/}

          				</Modal.Body>
          				<Modal.Footer>			      		
          				      <Button variant="success" type="submit" disabled={isSubmitting}> 
          				      		{isSubmitting ? <Trans i18nKey="collections.clone-collection-modal.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={faClone} /> <Trans i18nKey="collections.clone-collection-modal.form.submit">Clone</Trans></span>} 
          				      </Button>
          				      <Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="collections.clone-collection-modal.cancel">Cancel</Trans></Button>	
          				</Modal.Footer>
              		</Form>	
				)}
		      </Formik>
			)}
		</Modal>
	);
}

export default CloneCollectionModal;
