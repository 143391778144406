import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe} from '@fortawesome/free-solid-svg-icons'

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';

import logoHeader from '../images/previr-logo-horizontal-claro-mcti.png';
import PrivateNavbarItems from '../components/PrivateNavbarItems';

import { isArrayWithLength } from "../helpers/commons";

import { useConfig } from "../services/use-config";

const Header = props => {
	
	const { t } = useTranslation();
	
	const config = useConfig();
	
	const showLanguageNavDropdown = () => {
		if (!isArrayWithLength(config.availableLanguages) || config.availableLanguages.length === 1) 
			return null;
		
		return (
	        <Nav>
	    		<Dropdown as={Nav.Item} alignRight>
	    			<Dropdown.Toggle as={Nav.Link} id="language-collasible-nav-dropdown"><FontAwesomeIcon icon={faGlobe} /> <Trans i18nKey={"app.language."+config.preferredLanguage}>{config.preferredLanguage}</Trans></Dropdown.Toggle>
	    			<Dropdown.Menu>
	    				{config.availableLanguages.map((lang) => (
	 	                   (lang !== config.preferredLanguage) 
	 	                   		&& <Dropdown.Item  key={lang} onClick={() => config.handleLanguageChange(lang, changeLanguageResponse)} ><FontAwesomeIcon icon={faGlobe} /> <Trans i18nKey={"app.language."+lang}>{lang}</Trans></Dropdown.Item>
	    				))}
	    			</Dropdown.Menu>
	    		</Dropdown>
	    	</Nav>
		)
	  };
	  
	const changeLanguageResponse = (type, feedback ) => {
		if (type === "success")
			props.onSuccess(feedback);
		else
			props.onError(new Error(feedback));
	};
	  
	return (
		<Navbar variant="dark" bg="dark" expand="lg" className="mb-3">
		    <Navbar.Brand>
		      <img
		        src={logoHeader}
		        height="45"
		        className="d-inline-block align-top"
		        alt={t('app.logo')}
		      />
		    </Navbar.Brand>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" />
			<Navbar.Collapse id="responsive-navbar-nav">	
		    	{(props.isPrivate) && <PrivateNavbarItems />}
		    	{showLanguageNavDropdown()}
			</Navbar.Collapse>
		</Navbar>	  
	);
}

export default Header;
