import * as Yup from "yup";

const Subjects = [
	"USER",
	"WORKGROUP",
	"COLLECTION",
	/*"SAMPLE",*/
	"LABORATORY_SAMPLE",
	"STORAGE_BOX",
	"LABORATORY_TAG",
	"NUCLEIC_ACID_EXTRACTION",
	"TISSUE_DISSOCIATION",
	"CDNA_SYNTHESIS",
	"LABORATORY_TEST"
]

const SubjectActions = {
	"USER":	[
		"LOGIN",
		"LOGOUT",
	 	"CHANGE_PASSWORD",
		"FORGOT_PASSWORD",
	 	"RESET_PASSWORD",
		"PASSWORD_UPDATE",
	 	"SELF_PASSWORD_UPDATE",
		"CREATE",
		"UPDATE",
	],
	"WORKGROUP":	[
		"CREATE",
		"UPDATE",
		"ADD_USER",
	 	"REMOVE_USER",
	 	"ADD_LICENSE",
	 	"REMOVE_LICENSE"
	],
	"COLLECTION":	[
		"CREATE",
		"UPDATE",
		"EXPORT",
		"IMPORT"
	],
	"LABORATORY_SAMPLE":	[
		"CREATE",
		"UPDATE",
		"EXPORT"
	],
	"STORAGE_BOX":	[
		"CREATE",
		"UPDATE"
	],
	"LABORATORY_TAG":	[
		"CREATE",
		"UPDATE",
		"DELETE"
	],
	"NUCLEIC_ACID_EXTRACTION":	[
		"CREATE",
		"UPDATE",
		"DELETE"
	],
	"TISSUE_DISSOCIATION":	[
		"CREATE",
		"UPDATE",
		"DELETE"
	],
	"CDNA_SYNTHESIS":	[
		"CREATE",
		"UPDATE",
		"DELETE"
	],
	"LABORATORY_TEST":	[
		"CREATE",
		"UPDATE",
		"DELETE"
	]
}

const FilterFormValidationRules = {
	   subject: Yup.string()
			.oneOf([""].concat(Subjects), 'form.validation.string.enum')
			.default(() => ""),
	   action: Yup.string()
			.when('subject', (subject, schema) => {
			if (Object.keys(SubjectActions).includes(subject)) {
				return Yup.string()
					.oneOf(SubjectActions[subject], 'form.validation.string.enum');		
	        }
			return schema;
        })
			.default(() => ""),
	   filter: Yup.string()
			.default(() => ""),
	   filteredColumn: Yup.string()
			.default(() => ""),
	   start: Yup.string()
			.default(() => ""),
	   end: Yup.string()
			.default(() => ""),
};

const ActivityConfig = { Subjects, SubjectActions, FilterFormValidationRules };

export default ActivityConfig;