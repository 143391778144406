import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, Navigate } from 'react-router-dom';
import log from 'loglevel';
import Container from 'react-bootstrap/Container';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import FormControlErrors from "../components/FormControlErrors";
import DismissableFeedback from "../components/DismissableFeedback";
import UserPasswordChecklist from "../components/UserPasswordChecklist";
import Loader from "../components/Loader";

import { useAuth } from "../services/use-auth";
import { ValidationError} from "../helpers/custom-errors";

import { getHomeBrowserRoute } from "../helpers/commons";
import { PrivateRoutesConfig } from '../config/PrivateRoutesConfig';

import UserPasswordConfig from "../config/UserPasswordConfig";

import '../styles/ChangePasswordPage.css';

import previrLogo from '../images/previr-logo-vazado-azul-mcti.png';

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const validationSchema = Yup.object().shape(UserPasswordConfig.FormValidationRules);

const ChangePasswordPage = props => {
	
  const [_error, _setError] = useState(null);
  
  const { t } = useTranslation();
  
  let navigate = useNavigate();
  
  const auth = useAuth();
  
  if (!auth.initialized)
		return <Loader />
  
  if (!auth.user) {
    	// Redirect them to the /login page
    	return <Navigate to="/login" replace />;
  	}

  if (auth.status !== "CHANGE_PASSWORD") {
		// if status is NOT CHANGE_PASSWORD user must be redirect as well
		return <Navigate to={getHomeBrowserRoute(PrivateRoutesConfig, auth.user)} replace />;
  }
  
	  return (
          <Container className="text-center pt-2 pb-2">
          	<DismissableFeedback feedback={_error} type="danger" onClose={() => _setError(null)} />
          	<Formik
          		initialValues={{ newPassword: '', confirmedNewPassword: '' }}
          		validationSchema={validationSchema}
          		validateOnChange={false}
          		validateOnBlur={false}     
          		onSubmit={(values, actions) => {
          			//Clear Error
          			_setError(null);
          			auth.changeUserPassword({ values: values })
          				.then((response) => {
							if (response.status === 'ASSIGN_WORKGROUP') {
							  	navigate("/assign-workgroup");
							} else if (response.status === 'COMPLETED') {
							  	navigate(getHomeBrowserRoute(PrivateRoutesConfig, response.userData));
							} else {
								actions.setSubmitting(false);
							}
          				})
          				.catch(error => {
          					
          					actions.setSubmitting(false);
          					
          					if (error instanceof ValidationError) {		
          						
          						log.info("Change User Password Attempt Failed: ", error.message);
          						
          						_setError(error.message);
          						
          						if (error.detail) {            						
              						for (let key of Object.keys(error.detail)) {
              							let errorMsgs = error.detail[key];
              							errorMsgs.forEach((message) => {
              								actions.setFieldError(key, message);
              							});
              						}
              					}
          						
          					} else {
          						
          						log.error("Change User Password Error: ", error.message);
          						
          						_setError(t('error.api.general'));
          					}
          					
          				})
          		}}
          	>
          		{({isSubmitting, errors, values, handleChange, setFieldValue}) => (	
          			<Form className="form-change-password" noValidate>
          				
          			<Row><Col md={{offset: 4, span: 4}}>
          				<Image className="mb-2" width="150" src={previrLogo} alt={t('app.logo')}/>
                  	</Col></Row>	
                  	
                  	<Row className="align-items-center"><Col md={{offset: 4, span: 4}}>
                  		
                  		<Row><Col>
                  			<h1 className="h3 mb-3 fw-normal"><Trans i18nKey="change-password.form.header">Welcome</Trans></h1>
                  		</Col></Row>
                  		
                  		<Row>
                  			<FormGroup as={Col} controlId="formNewPassword">
          	                	<FormLabel className="sr-only"><Trans i18nKey="change-password.form.new-password.label">Email address</Trans></FormLabel>
          	                	<FormControl type={'password'} name="newPassword" isInvalid={!(errors.newPassword == null)} value={values.newPassword} onChange={handleChange} placeholder={t('change-password.form.new-password.placeholder')} />
          	                	<FormControlErrors errors={errors.newPassword} />
          	            	</FormGroup>
          	            </Row>
                  		
      	                <Row>
                  			<FormGroup as={Col} controlId="formConfirmedNewPassword">
      	                		<FormLabel className="sr-only"><Trans i18nKey="change-password.form.confirmed-new-password.label">Password</Trans></FormLabel>
      	                		<FormControl type={'password'} name="confirmedNewPassword" isInvalid={!(errors.confirmedNewPassword == null)} value={values.confirmedNewPassword} onChange={handleChange} placeholder={t('change-password.form.confirmed-new-password.placeholder')} />
								<FormControlErrors errors={errors.confirmedNewPassword} />
      	                	</FormGroup>
      	                </Row>
	
						<Row><Col>
                  			<Button variant="success" type="submit" size="lg" block disabled={isSubmitting}> 
                  		    	{isSubmitting ? <Trans i18nKey="change-password.form.submitting">Please wait...</Trans> : <Trans i18nKey="change-password.form.submit">Submit</Trans>} 
              				</Button>
              			</Col></Row>
              			
              		</Col>
              			
              		<Col md="auto">
						<UserPasswordChecklist className="d-none d-lg-block" value={values.newPassword} valueAgain={values.confirmedNewPassword}/>
              		</Col>
              			
              		</Row>
              			
              		</Form>		
          		 )}
   		     </Formik>  
          </Container>
    );
}

export default ChangePasswordPage;
