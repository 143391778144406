import React, { Fragment } from "react";
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import Header from "../components/Header";

const NotFoundPage = props => {
  let location = useLocation();
 const { t } = useTranslation();

  return (
	<Fragment>
		<Header />
		<Alert variant={"danger"}><Trans i18nKey="app.path-not-found.alert">No match for</Trans> <code>{location.pathname}</code></Alert>
	</Fragment>
  );
}

export default NotFoundPage;