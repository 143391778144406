import React, { useState, useEffect, Fragment } from 'react';
import { useLocation, useOutletContext  } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import Container from 'react-bootstrap/Container'

import PagedList from "../components/PagedList";

import TissueDissociationsFilterForm from "../components/TissueDissociationsFilterForm";
import Loader from "../components/Loader";
import SaveTissueDissociationModal from "../components/SaveTissueDissociationModal";
import SaveMultipleTissueDissociationsModal from "../components/SaveMultipleTissueDissociationsModal";
import SaveMultipleNucleicAcidExtractionsModal from "../components/SaveMultipleNucleicAcidExtractionsModal";
import SaveNucleicAcidExtractionModal from "../components/SaveNucleicAcidExtractionModal";

import DisplayCollectionSampleModal from "../components/DisplayCollectionSampleModal";
import ConfirmationDialogModal from "../components/ConfirmationDialogModal";

import Filler from "../components/Filler";

import TissueDissociationConfig from "../config/TissueDissociationConfig";
import { numToColumn } from "../helpers/commons";

import API from "../services/backend-api";
import { useAuth } from "../services/use-auth";
import { useConfig } from "../services/use-config";

import * as Yup from "yup";

const validationSchema = Yup.object().shape(TissueDissociationConfig.FilterFormValidationRules);

const dataTableActionModals = [
	  {
        key: 'action-0',
        component: SaveTissueDissociationModal,
		size: 'lg',
		multiple : false
      },
		{
        key: 'action-1',
        component: Filler,
		size: 'lg',
		multiple : false
      },
	  {
        key: 'action-2',
        component: SaveNucleicAcidExtractionModal,
		size: 'lg',
		multiple : false
      },
	  {
        key: 'action-3',
        component: DisplayCollectionSampleModal,
		size: 'lg',
		multiple : false
      },
	  {
        key: 'multiple-action-0',
        component: SaveMultipleTissueDissociationsModal,
		size: 'lg',
		multiple : true
      },
	  {
        key: 'multiple-action-1',
        component: Filler,
		size: 'lg',
		multiple : true
      },
	  {
        key: 'multiple-action-2',
        component: SaveMultipleNucleicAcidExtractionsModal,
		size: 'lg',
		multiple : true
      },
    ];


const TissueDissociations = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [pagedTissueDissociations, setPagedTissueDissociations] = useState(null);
	const [selectedDataTableItem, setSelectedDataTableItem] = useState(null);
	const [selectedDataTableItems, setSelectedDataTableItems] = useState(null);
	const [dataTableActionModalsShowMap, setDataTableActionModalsShowMap] = useState(new Map());
	
	const location = useLocation();
	let context = useOutletContext();
	
	const { t } = useTranslation();
	const auth = useAuth();
	const config = useConfig();
	
	
	let defaultValues = validationSchema.default();
	
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
			
		//Initializing Action Modals Show Status
		resetModalsVisibility();
			
		listTissueDissociations({})
			.catch(error => { 
		 		log.error("Error Loading Initial Tissue Dissociations Page: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		
	}, []);
	

  const resetModalsVisibility = () => {
	let tempMap = new Map();
	dataTableActionModals.forEach((item, index) => {
		tempMap.set(item.key, false);
	});
	setDataTableActionModalsShowMap(tempMap);
  }
  
  const saveTissueDissociation = (item, action) => {
	  setSelectedDataTableItem(item);
	  showActionModal("action-0")
  }

  const addTissueDissociation = () => {
	  let item = {};
	  setSelectedDataTableItem(item);
	  showActionModal("action-0")
  }

  const deleteTissueDissociation = (extraction) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		API.deleteLaboratoryDissociation(extraction.id, auth.isAdmin(), config.csrfToken).then(response => {
			context.onSuccess(response.success)
			refreshPage();
		}).catch(error => { 	
			log.error("Error Removing Extraction: ", error.message);
			context.onError(error);		
		}).finally(() => {
			handleActionModalHide();
		});
  }

  const deleteMultipleTissueDissociations = (items) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		let listOfItemsId = items.map(e => Object.assign({}, {"id": e.id}));
		
		API.deleteMultipleLaboratoryDissociations(listOfItemsId, auth.isAdmin(), config.csrfToken).then(response => {
			//context.onSuccess(response.success)
			context.onSuccess(t('laboratorydissociations.delete-multiple-dissociations-confirmation-modal.success'));
			refreshPage();
		}).catch(error => { 	
			log.error("Error Removing Dissociations: ", error.message);
			context.onError(error);		
		}).finally(() => {
			handleActionModalHide();
		});
  }

  const confirmTissueDissociationDeletion = (item, action) => {
	  setSelectedDataTableItem(item);
	  showActionModal(action)
  }

   const handleItemsSaved = (success) => {
	  handleActionModalHide();
	  refreshPage();
	  context.onSuccess(success);
   }

  const saveMultipleTissueDissociations = (items, action) => {
	  setSelectedDataTableItems(items);
	  showActionModal(action)
  }

  const createMultipleNucleicAcidExtractions = (items, action) => {

	  setSelectedDataTableItems(items);
	  showActionModal(action)
  }

  const confirmMultipleTissueDissociationsDeletion = (items, action) => {

	  setSelectedDataTableItems(items);
	  showActionModal(action)
  }

  const displayCollectionSample = (item, action) => {
	  setSelectedDataTableItem(item);
	  showActionModal(action)
  }

  const createLaboratoryExtraction = (item, action) => {
	
	  let val= {};
	  val.collectionId = item.collectionId;
	  val.materialSample = item.materialSample;
	  val.organismId = item.organismId;
	  val.workgroupId = item.workgroupId;

	  setSelectedDataTableItem(val);
	  showActionModal(action)
  }

    
  const updatePage = (values) => {
	  listTissueDissociations(values)
		.catch(error => { 
			log.error("Error Paginating Extractions List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const sortTissueDissociations = (values) => {
	  values.pageIndex = 0;
	  listTissueDissociations(values)
		.catch(error => { 
			log.error("Error Sorting Extractions List: ", error.message);
	 		context.onError(error);
		})
		
  }

  const displayRanOut = (item) => { 
	return (item.laboratorySampleRanOut) ? "inactive-row": "";
  }
  
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  
		  queryParams.pageIndex = currentPage.pageIndex;
		  queryParams.pageSize = currentPage.pageSize;
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;

		  for(let property in TissueDissociationConfig.FilterFormValidationRules) {
				if(currentPage.hasOwnProperty(property)) {
					queryParams[property] = (currentPage[property]) ? currentPage[property] : defaultValues[property];
				}
		   }
	  }
	  
	  if (newValues) {
		  for(let property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
  const refreshPage = () => {
		  
	  //List Tissue Dissociations with no new values
	  listTissueDissociations({})
		.catch(error => { 
	 		log.error("Error Refreshing Tissue Dissociations List: ", error.message);
	 		context.onError(error);
		})	
  }  

  const filterTissueDissociations = (values) => {
	  values.pageIndex = 0;
	  return listTissueDissociations(values)
		.catch(error => { 
			log.error("Error Filtering Tissue Dissociations List: ", error.message);
	 		context.onError(error);
		})
		
  }

 const exportTissueDissociations = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.exportLaboratoryDissociations(mergeQueryParams(pagedTissueDissociations, values), true).then(response => {
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});

  const listTissueDissociations = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.pageLaboratoryDissociations(mergeQueryParams(pagedTissueDissociations, values)).then(response => {
			setPagedTissueDissociations(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
	const handleActionModalHide = () => {
	  resetModalsVisibility();
	  setSelectedDataTableItem(null); 
      setSelectedDataTableItems(null);
	  
    }

	const showActionModal = (action) => { 
	  let tempMap = dataTableActionModalsShowMap;
	  tempMap.set(action, true);
	  setDataTableActionModalsShowMap(tempMap);
   }

	if (isLoading) 
		return <Loader />
	
	if (!pagedTissueDissociations) 
		return null;
		
	const buildLabInfo = (s) => {
		
		return (s.laboratory) ? 
			s.laboratory 
				+ ((s.storageBoxLabelId) 
					? " | " + s.storageBoxLabelId
						+ ((s.slotCol && s.slotRow) 
							? " [" + s.slotRow + "-" + numToColumn(s.slotCol)+"]"
							: "")
					: "")
			: "";
	}
			
	return (
		<Container >
			{ dataTableActionModals.map((item, index) => {
								
				const { 
		    		component: Component, 
		    		...rest 
		    	} = item;
			
				return (
					<Fragment key={item.key}>
					{ (dataTableActionModalsShowMap.get(item.key)) ?
						<Fragment>
							{(item.multiple && selectedDataTableItems) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="laboratory-dissociations"
									items={selectedDataTableItems}
									onError={context.onError}
									onItemsSaved={handleItemsSaved}
								/>
								: 
								(!item.multiple && selectedDataTableItem) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="laboratory-dissociations"
									item={selectedDataTableItem}
									onError={context.onError}
									onItemSaved={handleItemsSaved}
								/>
								:
								null
							}
						</Fragment>
						:
						null
					}
					</Fragment>
	      		)
    		})}

			{(selectedDataTableItem) && <ConfirmationDialogModal
				item={selectedDataTableItem}
				show={dataTableActionModalsShowMap.get("action-1")}
        		onHide={handleActionModalHide}
				size="lg"
				title={t("laboratorydissociations.delete-dissociation-confirmation-modal.title")}
				bodyText={t("laboratorydissociations.delete-dissociation-confirmation-modal.body", {item: selectedDataTableItem})}
				confirmText={t("laboratorydissociations.delete-dissociation-confirmation-modal.confirm")}
				cancelText={t("laboratorydissociations.delete-dissociation-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteTissueDissociation}
			/>}
			
			{(selectedDataTableItems) && <ConfirmationDialogModal
				item={selectedDataTableItems}
				show={dataTableActionModalsShowMap.get("multiple-action-1")}
        		onHide={handleActionModalHide}
				size="lg"
				title={t("laboratorydissociations.delete-multiple-dissociations-confirmation-modal.title")}
				bodyText={t("laboratorydissociations.delete-multiple-dissociations-confirmation-modal.body", {count: selectedDataTableItems.length})}
				confirmText={t("laboratorydissociations.delete-multiple-dissociations-confirmation-modal.confirm")}
				cancelText={t("laboratorydissociations.delete-multiple-dissociations-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteMultipleTissueDissociations}
			/>}
			
			<PagedList
				i18nPrefix="laboratorydissociations.list."
				page={pagedTissueDissociations}
				list={pagedTissueDissociations.list.map((s) => Object.assign({}, s, {labInfo: buildLabInfo(s)}))}
				pageSizeOptions={[10,25,50,100]}
				filterFormComponent={TissueDissociationsFilterForm}
				onFilter={filterTissueDissociations} 
				onError={context.onError}
				onSort={sortTissueDissociations}
				onAdd={addTissueDissociation}
				onPage={updatePage}
				//onExport={exportTissueDissociations}
				dataTableColumns={["labelId", "materialSample", "dateDissociated", "labInfo"]}
				dataTableSortableColumns={["labelId","dateDissociated"]}
				dataTableCustomDisplayColumns={[["dateDissociated", "LocalDate"],["materialSample", "Enum"]]}
				dataTableActions={[saveTissueDissociation, confirmTissueDissociationDeletion, createLaboratoryExtraction, displayCollectionSample]}
				dataTableDefaultAction={saveTissueDissociation}
				dataTableMultipleSelectActions={[saveMultipleTissueDissociations, confirmMultipleTissueDissociationsDeletion, createMultipleNucleicAcidExtractions]}
				dataTableCustomRowStyle={displayRanOut}
				dataTableHandleTextOverflow={true}
			/>
				
      </Container>
	
	);
}

export default TissueDissociations;
