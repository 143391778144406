import * as Yup from "yup";
import moment from "moment";

import CollectionConfig from "../config/CollectionConfig";

const MaterialSamples = CollectionConfig.Samples;

const DefaultLabelIdPrefix = "C-";

const GeneralFormValidationRules = {
	id: Yup.string()
		.nullable()
		.default(() => ""),
	collectionId: Yup.string()
		.required()
		.default(() => ""),
	organismId: Yup.string()
		.default(() => ""),
	scientificName: Yup.string()
		.default(() => ""),
	sex: Yup.string()
		.default(() => ""),
	county: Yup.string()
		.default(() => ""),
	stateProvince: Yup.string()
		.default(() => ""),
	materialSample: Yup.string()
		.oneOf([""].concat(MaterialSamples), 'form.validation.string.enum')
		.required()
		.default(() => ""),
	labelId: Yup.string()
		.max(100, 'laboratorysyntheses.save-synthesis-modal.form.labelId.maximumlength')
		.required()
		.default(() => ""),
	workgroupId: Yup.string()
		.required()
		.default(() => ""),
	synthesizedById: Yup.string()
		.required()
		.default(() => ""),
	dateSynthesized: Yup.date()
		.typeError('laboratorysyntheses.save-synthesis-modal.form.dateSynthesized.validation.format')
		.max(new Date(), "laboratorysyntheses.save-synthesis-modal.form.dateSynthesized.validation.future")
		.required()
		.default(() => ""),
		//.default(() => moment(new Date()).format("YYYY-MM-DD")),
	storageBoxId: Yup.string()
		//.required()
		.default(() => ""),
	slotCol: Yup.string()
			.default(() => ""),
	slotRow: Yup.string()
			.default(() => ""),
	ranOut: Yup.boolean()
    	.default(() => false),
}

const SaveMultiplesFormValidationRules = {
	labelIdPrefix: Yup.string()
		.max(20, 'laboratorysyntheses.save-multiple-syntheses-modal.form.labelIdPrefix.validation.maximumlength')
		.default(() => DefaultLabelIdPrefix),
	workgroupId: Yup.string()
		.required()
		.default(() => ""),
	synthesizedById: Yup.string()
		.required()
		.default(() => ""),
	dateSynthesized: Yup.date()
		.typeError('laboratorysyntheses.save-synthesis-modal.form.dateSynthesized.validation.format')
		.max(new Date(), "laboratorysyntheses.save-synthesis-modal.form.dateSynthesized.validation.future")
		.required()
		.default(() => ""),
		//.default(() => moment(new Date()).format("YYYY-MM-DD")),
	storageBoxId: Yup.string()
		//.required()
		.default(() => "")
}

const FilterFormValidationRules = {
	laboratoryId: Yup.string()
		.default(() => ""),
	storageBoxId: Yup.string()
		.default(() => ""),
	filter: Yup.string()
		.default(() => ""),
	filteredColumn: Yup.string()
		.default(() => "labelId"),
	start: Yup.string()
		.default(() => ""),
	end: Yup.string()
		.default(() => ""),
	materialSamples: Yup.array()
		.default(() => [])
}

const AdvancedFilterFormFields = {
	storageBoxId: true,
	start: true,
	end : true,
	materialSamples : true
}

const CDNASynthesisConfig = { MaterialSamples, DefaultLabelIdPrefix, GeneralFormValidationRules, SaveMultiplesFormValidationRules, FilterFormValidationRules, AdvancedFilterFormFields};

export default CDNASynthesisConfig;