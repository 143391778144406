import React, { useState, useEffect} from 'react';
//import PropTypes from 'prop-types';  
import { useLocation, } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCheckCircle, faPlusCircle} from '@fortawesome/free-solid-svg-icons'

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import DismissableFeedback from "../components/DismissableFeedback";
import Loader from "../components/Loader";
import LaboratoryTestFormFields from "../components/LaboratoryTestFormFields";
import SubjectActivities from "../components/SubjectActivities";
import Prompt from "../components/Prompt";

import { useConfig } from "../services/use-config";
import API from "../services/backend-api";

import LaboratoryTestConfig from "../config/LaboratoryTestConfig";

import { compareJSON } from "../helpers/commons";
import { AuthError, ValidationError } from "../helpers/custom-errors";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const saveLaboratoryTestValidationSchema = Yup.object().shape({
	...LaboratoryTestConfig.GeneralFormValidationRules,
	...LaboratoryTestConfig.SequencingFormValidationRules
});

const isSequenced = (values) => {
	return JSON.parse(values.sequenced)
}

const saveLaboratoryTestTabs = [
	  {
        key: 'general',
        component: LaboratoryTestFormFields.GeneralSection,
		isVisible: true
      }, 
	  {
        key: 'sequencing',
        component: LaboratoryTestFormFields.SequencingSection,
		isVisible: isSequenced 
      }
    ];


const SaveLaboratoryTestModal = props => {

	const [tabKey, setTabKey] = useState('general');
	const [_error, _setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [laboratoryTest, setLaboratoryTest] = useState(null);
	
	const { t } = useTranslation();
	
	const location = useLocation();
	const config = useConfig();

	useEffect(() => {
		let isMounted = true; 
		
		if (props.item && props.item.id) {
			
			API.lookupLaboratoryTest(props.item.id)
				.then(response => {
					setLaboratoryTest(response);
				})
				.catch(error => { 
					log.error("Error Loading Laboratory Test: ", error.message);
		 			props.onError(error);
				})
		 		.finally(() => {
		 			if (isMounted) {
		 				setIsLoading(false);
		 			}
				}); 
		} else {
			if (isMounted) {
		 		setIsLoading(false);
		 	}
		}
		 
		return () => { isMounted = false };
		  
	}, []);

	
	if  (!props.item) {
		return null
	} else if (props.item.id) {
		if (!isLoading && !laboratoryTest) {
			return null;
		}
	}	
			
	let initialValues = {}, action, actionIcon, validationSchema, linearValidationRules;
	
	let defaultValues = saveLaboratoryTestValidationSchema.default();
	
	validationSchema = saveLaboratoryTestValidationSchema;
	linearValidationRules = [
			LaboratoryTestConfig.GeneralFormValidationRules,
			LaboratoryTestConfig.SequencingFormValidationRules
		]
	
	//Define Modal Origin
	/*origin = (location.pathname === '/analysis/boxes/items') ? "storagebox-items" 
		: (location.pathname === '/analysis/laboratorysample/update') ? "laboratory-sample"
		: null;*/
	
	//if laboratoryTest != null, then we have an update
	if (laboratoryTest) {		
		Object.keys(Object.assign({}, LaboratoryTestConfig.GeneralFormValidationRules, LaboratoryTestConfig.SequencingFormValidationRules))
			.forEach((key) => {
				switch (key) {
  					case "sequenced":
					case "viralIsolation":
					default:
						initialValues[key] = (laboratoryTest[key]) ? laboratoryTest[key] : defaultValues[key];
						break;
				}

		});
		
		action = "update";
		actionIcon = faCheckCircle
		
		//TODO: Check for consistency depending on Origin
		switch (props.origin) {
  			case "storagebox-items":
			case "laboratory-samples":
			case "laboratory-extractions":
			case "laboratory-syntheses":
			case "laboratory-tests":
			default:
				break;
		}
	
	} else {	
		initialValues = defaultValues;
		action = "create";
		actionIcon = faPlusCircle
		
		//Override with values from props depending on Origin
		switch (props.origin) {
  			case "storagebox-items":
				initialValues.workgroupId = props.item.workgroupId;
				initialValues.storageBoxId = props.item.storageBoxId;
				initialValues.slotCol = props.item.slotCol;
				initialValues.slotRow = props.item.slotRow;
				break;
			case "laboratory-samples":
			case "laboratory-extractions":
			case "laboratory-syntheses":
				initialValues.collectionId = props.item.collectionId;
				initialValues.materialSample = props.item.materialSample;
				initialValues.organismId = props.item.organismId;
				initialValues.workgroupId = props.item.workgroupId;
				break;
			case "laboratory-tests":
			default:
				break;
		}

	}
	
	const saveLaboratoryTest = (values) =>
		new Promise((resolve, reject) => {
			if (values.id) {
				API.updateLaboratoryTest(values.id, values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			} else {
				API.createLaboratoryTest(values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			}
		});
		
	const hasTabErrors = (indexTab, errors) => {	
		
		return (Object.keys(linearValidationRules[indexTab])
			.find((key) => !(errors[key] == null)))
    }
	
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-save-laboratory-test-modal"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-save-laboratory-test-modal">
  					<Trans i18nKey="laboratorytests.save-test-modal.title">Save Laboratory Test</Trans>
  				</Modal.Title>
  			</Modal.Header>
  			{(isLoading) ? (
				<Modal.Body className="mb-3"><Loader /></Modal.Body>
			) : (
  				<Formik
		      		initialValues={initialValues}
		      		validationSchema={validationSchema}
		      		validateOnChange={false}
		      		validateOnBlur={false}     
		      		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);

						saveLaboratoryTest(values)
    						.then((response) => {
    							actions.resetForm({ values }) //Workaround to set dirty back to true
								actions.setSubmitting(false);
								props.onItemSaved(t('laboratorytests.save-test-modal.success'));
    						})
    						.catch(error => { 
    								
    							actions.setSubmitting(false);
								
								if (error instanceof AuthError) {		
		    	  					//auth.onUnathorized(error);
									props.onError(error);
		     					 } else if (error instanceof ValidationError) {		
          						
				 					log.info("Save Laboratory Test Attempt Failed: ", error.message);
          						
				 					_setError(error.message);		
								
									if (error.detail) {            						
    	              					for (let key of Object.keys(error.detail)) {
    	              						let errorMsgs = error.detail[key];
    	              						errorMsgs.forEach((message) => {
    	              							actions.setFieldError(key, message);
    	              						});
    	              					}
    	              				}
          						
          	 					} else {
          							log.error("Error Saving Laboratory Test: ", error.message);
          							_setError(t('error.api.general'));
          	 					}	
    								
    						})
    			   		}
      					      			
					}
		      	>
		      	{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue, setFieldError, dirty}) => (	
		      		
		      		<Form className="form-save-laboratory-test-modal" noValidate>		
		      			<Modal.Body className="pt-2">
		  				
		  					<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  

  							<Prompt
		      					when={!!dirty && !isSubmitting}
		      					message={JSON.stringify({
		      		           			"title": t("form.unsaved-changes-modal.title"),
		      		           			"bodyText": t("form.unsaved-changes-modal.body"),
		      		          		 	"confirmText": t("form.unsaved-changes-modal.confirm"), 
		      		           			"cancelText": t("form.unsaved-changes-modal.cancel"),
		      		           			"size": "lg",
		      		           			"variant": "danger",
		        			   			"when": compareJSON(initialValues, values)
		      		          		})
		      		   			}
		      				/>

							<Tab.Container id="laboratoryTestFormTab" activeKey={tabKey} onSelect={(k) => setTabKey(k)} >
					
								<Nav variant="tabs" className="mb-3">
					
								{ saveLaboratoryTestTabs.map((tab, index) => {
							
									if (typeof tab.isVisible == "boolean") {
										if (!tab.isVisible) return null;
									} else if (typeof tab.isVisible == 'function') {
										if (!tab.isVisible(values)) return null;
									}
									
									return (
										<Nav.Item key={index}>
    										<Nav.Link eventKey={tab.key} className={ hasTabErrors(index, errors)  ? "text-danger" : ""}><Trans i18nKey={"laboratorytests.save-test-modal.tabs."+tab.key}>{tab.key}</Trans>{ hasTabErrors(index, errors) ? "*" : ""}</Nav.Link>
  										</Nav.Item>
	      							)
	      						})}

						
								</Nav>
					
					
								<Tab.Content>
								{ saveLaboratoryTestTabs.map((tab, index) => {
								
									const { 
		    							component: Component, 
		    								...rest 
		    						} = tab;
								
							
									return (
								
										<Tab.Pane key={index} eventKey={tab.key}>
											<Component 
												values={values} 
												errors={errors} 
												onChange={handleChange}
												dirty={dirty} 
												onError={props.onError}
												onSuccess={props.onSuccess}
												onWarning={props.onWarning}
												onInfo={props.onInfo}
												setFieldValue={setFieldValue}
												setFieldError={setFieldError}
												action={action}
												origin={props.origin}
												isModal={true}
												i18nPrefix="laboratorytests.save-test-modal."
											/>
										</Tab.Pane>

	      							)
    							})}
						
							</Tab.Content>
					
					
						</Tab.Container>

	
							{(action === "update") && (
		 						<SubjectActivities 
		 							subject="LABORATORY_TEST" 
		 							subjectId={props.item.id} 
		 							onError={props.onError}
		 						/>
		 					)}
	
						
          				</Modal.Body>
          				<Modal.Footer>			 
          				      <Button variant="success" type="submit" disabled={isSubmitting}> 
          				      		{isSubmitting ? <Trans i18nKey="laboratorytests.save-test-modal.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={actionIcon} /> <Trans i18nKey={"laboratorytests.save-test-modal.form.submit-"+action}>Submit</Trans></span>} 
          				      </Button>
          				      <Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="laboratorytests.save-test-modal.cancel">Cancel</Trans></Button>	
          				</Modal.Footer>
              		</Form>	
				)}
		      </Formik>
			)}
		</Modal>
	);
}

export default SaveLaboratoryTestModal;
