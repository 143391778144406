import * as Yup from "yup";

const Types = [
	"SISBIO",
	"CEUA",
	"SISGEN"
]

const FormValidationRules = {
	licenseNumber: Yup.string()
   		.max(100, 'workgroups.save.form.licenseNumber.validation.maximumlength')
   		.required()
   		.default(() => ""),
   type: Yup.string()
   		.oneOf([""].concat(Types), 'form.validation.string.enum')
   		.required()
   		.default(() => ""),
   remarks: Yup.string()
   		.max(255, 'workgroups.save.form.remarks.validation.maximumlength')
   		.default(() => ""),
   active: Yup.boolean()
   		.default(() => true),
}

const WorkgroupLicenseConfig = { Types, FormValidationRules };

export default WorkgroupLicenseConfig;