import React, { Fragment, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { isArrayWithLength} from "../helpers/commons";
import { AuthError, ValidationError } from "../helpers/custom-errors";

import { useAuth } from "../services/use-auth";
import API from "../services/backend-api";

import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import "react-bootstrap-typeahead/css/Typeahead.css";
import "../styles/Typeahead.css";


const ScientificNameInputSearch = props => {

	const [isLoading, setIsLoading] = useState(false);
	const [options, setOptions] = useState([]);
	const [pagedSpecies, setPagedSpecies] = useState(null);
	
	const pageSize = (props.pageSize) ? props.pageSize : 10;
	
	const { t } = useTranslation();
	
	const auth = useAuth();
	
  	const handlePaginate = (e, number) => {
	
		const pageIndex = Math.ceil(number/pageSize) - 1;
	    
		findSpecies({filter: pagedSpecies.filter, pageSize: pageSize, pageIndex: pageIndex})
	      .then(({ list }) => {
		
			  let updatedOptions = options;
			  
			  list.forEach((item, index) => {
			  		
			  	updatedOptions[pageSize*pageIndex + index] = buildOption(item);
		
	    	  })
		 	 
	    	  setOptions(updatedOptions);
	    	  
	       })
	      .catch(error => { 
		      if (error instanceof AuthError) {		
		    	  auth.onUnathorized(error);
		      } else if (error instanceof ValidationError) {		
          						
				 log.info("Paginate Collection Samples Attempt Failed: ", error.message);
          						
				 props.onError(error.message);		
          						
          	 } else {
          		log.error("Paginate Collection Samples Error: ", error.message);
          		props.onError(t('error.api.general'));
          	 }
		 })
		 
	}
	
	
	
	const buildOption = (item) => {
		
		let displayName = buildGeneralInfo(item);
		
		return Object.assign({}, item, {displayName: displayName})
		
	}
	
	const buildGeneralInfo = (s) => {
		return t(props.i18nPrefix+"scientific-name-input-search.menu-item-display", { item: s} ) 
	}
	
	const handleSearch = (query) => {
		
		setIsLoading(true);
	    
	    findSpecies({filter: query, pageSize: pageSize})
	      .then(({ totalCount, list }) => {
				
			  const options = Array(totalCount).fill().map((e,index)=> {
			  		if (list[index]) {
						return buildOption(list[index]); 
	    	  		}
			  		else
			   			return ({displayName: t(props.i18nPrefix+'scientific-name-input-search.loading')}) 
			  });  	 
		
	    	  setOptions(options);
	    	  
	       })
	      .catch(error => { 
		      if (error instanceof AuthError) {		
		    	  auth.onUnathorized(error);
		      } else if (error instanceof ValidationError) {		
          						
				 log.info("Find Species Attempt Failed: ", error.message);
         
				 props.onError(new Error(t(error.message)))		
          						
          	 } else {
          		log.error("Find Species Error: ", error.message);
				props.onError(new Error(t('error.api.general')))
          	 }
		 })
		 .finally(() => {
			 setIsLoading(false);
		 });
	  }
	  
	  const findSpecies = (values) => {
		
		setIsLoading(true);
		
		return new Promise((resolve, reject) => {
			API.findSpecies(values)
			.then(response => {
				setPagedSpecies(response);
				resolve(response);
			}).catch(error => {			
				reject(error);
			}).finally(() => {
			 setIsLoading(false);
		 	});
		});
		
	}
	  
	const handleSelect = (items) => {

		if (props.onChange) {
			if(isArrayWithLength(items)) { 
				props.onChange(items[0]);
			} else { 
				props.onChange(null);
			}	
		}	
  	};

	const handleBlur = (e) => {
		props.onBlur(e.target.value);
  	};
						
	const renderMenuItemChildren = (option, properties) => { 
	
		return (
			<Fragment>
          		<Highlighter search={properties.text}>
            		{option.displayName}
         		</Highlighter>,
          		{(option.taxonClass) && (
          		<div>
            		<small>
						<Trans i18nKey={props.i18nPrefix+'scientific-name-input-search.menu-item-details'} values={{item: option}}/>
            		</small>
          		</div>
				)}
        	</Fragment>
    	)
    }
 
	// Bypass client-side filtering by returning `true`. Results are already
	// filtered by the search endpoint, so no need to do it again.
	const filterBy = () => true;
	
	return (
    	<Fragment>
    		<Row><Col>
    			<InputGroup>
					<InputGroup.Prepend>
						<InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
					</InputGroup.Prepend>
					<AsyncTypeahead
						clearButton
						filterBy={filterBy}
						id="filter"
						name="filter"
						isLoading={isLoading}
						onPaginate={handlePaginate}
        				paginate={true}
						labelKey={(option) => option.displayName }
						minLength={3}
						maxResults={pageSize}
						onSearch={handleSearch}
						onChange={handleSelect}
						//onBlur={handleBlur}
						isInvalid={props.isInvalid}
						options={options}
						placeholder={t(props.i18nPrefix+'scientific-name-input-search.placeholder')}
						emptyLabel={t(props.i18nPrefix+'scientific-name-input-search.no-matches-found')}
						searchText={t(props.i18nPrefix+'scientific-name-input-search.searching')}
						promptText={t(props.i18nPrefix+'scientific-name-input-search.prompt')}
						paginationText={t(props.i18nPrefix+'scientific-name-input-search.paginate')}
						renderMenuItemChildren={renderMenuItemChildren}
					/>
				</InputGroup>
			</Col></Row>
	</Fragment>
	)
};

export default ScientificNameInputSearch;