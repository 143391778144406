import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext  } from 'react-router-dom';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileUpload} from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import CollectionConfig from "../config/CollectionConfig";
import CollectionsImportConfig from "../config/CollectionsImportConfig";
import CollectionsImportFormFields from "../components/CollectionsImportFormFields";

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";
import { useAuth } from "../services/use-auth";

import { ValidationError } from "../helpers/custom-errors";
import { isArrayWithLength } from "../helpers/commons";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

Yup.addMethod(Yup.array, 'unique', function(message, key) {
    return this.test('unique', message, function(list) {
		let keysMap = new Map();
		for (let i = 0; i < list.length; i++) {
			if (list[i][key]) {
				if (keysMap.get(list[i][key])) {
	 	    		return this.createError({
                		path: `${this.path}[${i}].${key}`,
                		message: message
					});		
	 	    	} else {
	 	    		keysMap.set(list[i][key], true);
	 	    	}
			}
		}
		
		return true;
	
	});
});

const collectionValidationRules = Object.assign({}, 
		CollectionConfig.OrganismIdValidationRules,
		CollectionConfig.GeneralFormValidationRules,
		CollectionConfig.TaxonomyFormValidationRules,
		CollectionConfig.HistoryFormValidationRules,
		CollectionConfig.PhysicalExamFormValidationRules,
		CollectionConfig.SamplesFormValidationRules,
		CollectionConfig.PhotosFormValidationRules,
		CollectionConfig.ExtraFormValidationRules
);

const validationSchema = Yup.object().shape({
	   workgroupId: Yup.string()
		 .required()
		 .default(() => ""),
	   action: Yup.string()
		.oneOf([""].concat(CollectionsImportConfig.Actions), 'form.validation.string.enum')
		.required()
   		.default(() => "CREATE"),
	   data : Yup.array().of(Yup.object().shape(collectionValidationRules))
		.unique('collections.import.form.data.validation.duplicated-organismId', "organismId")
		.max(255, 'collections.import.form.data.validation.maximum-size' )
		.default(() => []),
	});
	

const CollectionsImport = props => {

	const config = useConfig();
	const auth = useAuth();
	const { t } = useTranslation();
	
	let navigate = useNavigate();
	let context = useOutletContext();
	
	const importCollections = (values) =>
		new Promise((resolve, reject) => {
			API.importCollections(values, auth.isAdmin(), config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
		});
	
	
	let initialValues = validationSchema.default();
	
	return (
		<Formik
    		initialValues={initialValues}
    		validationSchema={validationSchema}
    		validateOnChange={false}
    		validateOnBlur={false}     
    		onSubmit={(values, actions) => {
    			//Clear Error/Success
    			context.onError(null);
    			context.onSuccess(null);
				
				importCollections(values)
    				.then((response) => {
    					navigate("/collections", {state: { success: t("collections.import.success")}});
    				})
    				.catch(error => { 
    								
    					actions.setSubmitting(false);
    								
    					if (error instanceof ValidationError) {		
    									
    						log.info("Import Collections Attempt Failed: ", error.message);

    						if (error.detail) {            						
    	              			for (let key of Object.keys(error.detail)) {
    	              				let errorMsgs = error.detail[key];
    	              				errorMsgs.forEach((message) => {
    	              					if (key === "data") {
											
											try {
        										actions.setFieldError(key, JSON.parse(message));
    										} catch (e) {
        										actions.setFieldError(key, message);
    										}
											
										} else {
											actions.setFieldError(key, message);
										}
    	              				});
    	              			}
    	              		}
    					} else {
    						log.error("Error Importing Collections: ", error.message);
    					}
    								
    					context.onError(error);
    								
    				})

				}}
		      	>
		      	{({isSubmitting, errors, values, handleChange, setFieldValue, setFieldError, validateField}) => (	
		      	
		      	<Container>
		    		<Row className="mb-3">
		            	<Col>
		            		<h3>
		            			<Trans i18nKey={"collections.import.header"} >Import Collections</Trans> 
		            		</h3>
		            	</Col>
		      
		            </Row>
		            <Row className="mb-3">
		        		<Col>
		        			<Form className="form-collections-import" noValidate>

		      				<CollectionsImportFormFields 
								i18nPrefix={"collections.import."}
								values={values} 
								errors={errors} 
								onChange={handleChange} 
								onError={context.onError}
								onSuccess={context.onSuccess}
								setFieldValue={setFieldValue}
								setFieldError={setFieldError}
								validateField={validateField}
								isSubmitting={isSubmitting}
							/>
						
					   		<Row className="mt-3">    					   
    							<Col>  
    								<Button variant="success" type="submit" disabled={isSubmitting || !isArrayWithLength(values.data)} > 
    									{isSubmitting ? <Trans i18nKey="collections.import.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={faFileUpload} /> <Trans i18nKey={"collections.import.form.submit"}>Submit</Trans></span>} 
    								</Button>

    					   		</Col>
    			       		</Row>   
    			       
    			    </Form>	
		      	 </Col>
			     </Row>
			  </Container>
		      )}
		 </Formik>
	);
}

export default CollectionsImport;
