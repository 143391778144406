import React,  { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation, Route, Navigate, useOutletContext} from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import LaboratorySampleFormFields from "../components/LaboratorySampleFormFields";

import Loader from "../components/Loader";
import Prompt from "../components/Prompt";

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";

import LaboratorySampleConfig from "../config/LaboratorySampleConfig";

import { ValidationError } from "../helpers/custom-errors";

import { compareJSON } from "../helpers/commons";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   },
	   string: {
		     email: 'form.validation.string.email',
		   },
	 });


const updateLaboratorySampleValidationSchema = Yup.object().shape({
	...LaboratorySampleConfig.GeneralFormValidationRules,
	...LaboratorySampleConfig.TissueDissociationsFormValidationRules,
	...LaboratorySampleConfig.ExtractionsFormValidationRules,
	...LaboratorySampleConfig.CDNASynthesesFormValidationRules,
	...LaboratorySampleConfig.LaboratoryTestsFormValidationRules
});

const createLaboratorySampleValidationSchema = Yup.object().shape({
	...LaboratorySampleConfig.GeneralFormValidationRules,
	...LaboratorySampleConfig.TissueDissociationsFormValidationRules,
	...LaboratorySampleConfig.ExtractionsFormValidationRules,
	...LaboratorySampleConfig.CDNASynthesesFormValidationRules,
	...LaboratorySampleConfig.LaboratoryTestsFormValidationRules
});


const saveLaboratorySampleTabs = [
	  {
        key: 'general',
        component: LaboratorySampleFormFields.GeneralSection,
		actions: ['create', 'update'],
      }, 
	  {
        key: 'tissue-dissociations',
        component: LaboratorySampleFormFields.TissueDissociationsSection,
		actions: ['create', 'update']
      },
      {
        key: 'extractions',
        component: LaboratorySampleFormFields.ExtractionsSection,
		actions: ['create', 'update']
      },
	  {
        key: 'cdna-syntheses',
        component: LaboratorySampleFormFields.CDNASynthesesSection,
		actions: ['create', 'update']
      },
	  {
        key: 'laboratory-tests',
        component: LaboratorySampleFormFields.LaboratoryTestsSection,
		actions: ['create', 'update']
      }
    ];

const SaveLaboratorySample = props => {
	
	const [tabKey, setTabKey] = useState('general');
	const [isLoading, setIsLoading] = useState(true);
	const [laboratorySample, setLaboratorySample] = useState(null);
	
	let navigate = useNavigate();
	
	const location = useLocation();
	
	let context = useOutletContext();
	
	const { t } = useTranslation();
	
	const config = useConfig();
	
	const tabs = saveLaboratorySampleTabs;
	
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
		
		if (location.state && location.state.item && location.state.item.laboratorySampleId) {
			
			API.lookupLaboratorySample(location.state.item.laboratorySampleId)
				.then(response => {
					setLaboratorySample(response);
				})
				.catch(error => { 
					log.error("Error Loading Laboratory Sample: ", error.message);
		 			context.onError(error);
				})
		 		.finally(() => {
		 			if (isMounted) {
		 				setIsLoading(false);
		 			}
				}); 
		} else {
			if (isMounted) {
		 		setIsLoading(false);
		 	}
		}
		 
		 return () => { isMounted = false };
		  
	}, [location.state]);
	
	if (!location.state || !location.state.item) {
		return (<Navigate  to="/analysis/collectionsamples" />)
	} else if (isLoading) {
		return <Loader />
	} else if (location.state.item.laboratorySampleId && !laboratorySample) {
		return null;
	}
	
	let initialValues = {}, action, actionIcon, validationSchema, linearValidationRules;
	
	let defaultValues = createLaboratorySampleValidationSchema.default();
		
	if (location.state.item && location.state.item.laboratorySampleId) {
		Object.keys(Object.assign({}, LaboratorySampleConfig.GeneralFormValidationRules, LaboratorySampleConfig.TissueDissociationsFormValidationRules, LaboratorySampleConfig.ExtractionsFormValidationRules))
			.forEach((key) => {
				initialValues[key] = (laboratorySample[key]) ? laboratorySample[key] : defaultValues[key];
		});
				
		action = "update";
		actionIcon = faCheckCircle;
		validationSchema = updateLaboratorySampleValidationSchema;
		linearValidationRules = [
			LaboratorySampleConfig.GeneralFormValidationRules,
			LaboratorySampleConfig.TissueDissociationsFormValidationRules,
			LaboratorySampleConfig.ExtractionsFormValidationRules,
			LaboratorySampleConfig.CDNASynthesesFormValidationRules,
			LaboratorySampleConfig.LaboratoryTestsFormValidationRules
		]
		
	} else {
		
		initialValues = defaultValues;
		action = "create";
		actionIcon = faPlusCircle
		validationSchema = createLaboratorySampleValidationSchema;
		linearValidationRules = [
			LaboratorySampleConfig.GeneralFormValidationRules,
			LaboratorySampleConfig.TissueDissociationsFormValidationRules,
			LaboratorySampleConfig.ExtractionsFormValidationRules,
			LaboratorySampleConfig.CDNASynthesesFormValidationRules,
			LaboratorySampleConfig.LaboratoryTestsFormValidationRules
		]
		
		initialValues.collectionId = location.state.item.collectionId;
		initialValues.materialSample = location.state.item.materialSample;
		initialValues.organismId = location.state.item.organismId;
		initialValues.scientificName = location.state.item.scientificName;
		initialValues.sex = location.state.item.sex;
		initialValues.county = location.state.item.county;
		initialValues.stateProvince = location.state.item.stateProvince;
			
		//By defaut, we clone organismId to labelId
		initialValues.labelId = location.state.item.organismId;
		
	}
		
	const saveLaboratorySample = (values) =>
		new Promise((resolve, reject) => {
			if (values.id) {
				API.updateLaboratorySample(values.id, values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			} else {
				API.createLaboratorySample(values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			}
		});
		

	const hasTabErrors = (indexTab, errors) => {	
		
		return (Object.keys(linearValidationRules[indexTab])
			.find((key) => !(errors[key] == null)))
    }

	const buildSampleInfo = (s) => {
		let sampleInfo = Object.assign({}, {
			organismId: s.organismId,
			materialSample: t("models.collections.enums.samples."+s.materialSample),
			scientificName: s.scientificName,
			sex: t("models.collections.enums.sex."+s.sex),
			county: s.county,
			stateProvince: s.stateProvince});
		return sampleInfo;
    }
    
  
	return (
		<Container >
			<Row className="mb-3">
        		<Col>
        			<h3>
        				<Trans i18nKey={"collectionsamples.save-laboratory-sample.header"} values={{sampleInfo: buildSampleInfo(initialValues)}}>Save Laboratory Sample</Trans> 
        			</h3>
        		</Col>
  
        	</Row>
        	<Row className="mb-3">
    			<Col>
    				<Formik
    					initialValues={initialValues}
    					validationSchema={validationSchema}
    					validateOnChange={false}
    					validateOnBlur={false}     
    					onSubmit={(values, actions) => {
    						//Clear Error/Success
    						context.onError(null);
    						context.onSuccess(null);
					
    						saveLaboratorySample(values)
    							.then((response) => {
    								actions.resetForm({ values }) //Workaround to set dirty back to true
    								navigate("/analysis/collectionsamples", {state: { success: t("collectionsamples.save-laboratory-sample.success")}});
    							})
    							.catch(error => { 
    								
    								actions.setSubmitting(false);
    								
    								if (error instanceof ValidationError) {		
    									
    									log.info("Save Laboratory Sample Attempt Failed: ", error.message);

    									if (error.detail) {            						
    	              						for (let key of Object.keys(error.detail)) {
    	              							let errorMsgs = error.detail[key];
    	              							errorMsgs.forEach((message) => {
    	              								actions.setFieldError(key, message);
    	              							});
    	              						}
    	              					}
    								} else {
    									log.error("Error Saving Laboratory Sample: ", error.message);
    								}
    								
    								context.onError(error);
    								
    							})
					}}
		      	>
		      	{({isSubmitting, dirty, errors, values, handleChange, setFieldValue}) => (	
		      	
		      	<Form className="form-user" noValidate>
		      		<Prompt
		      			when={!!dirty && !isSubmitting}
		      			message={JSON.stringify({
		      		           "title": t("form.unsaved-changes-modal.title"),
		      		           "bodyText": t("form.unsaved-changes-modal.body"),
		      		           "confirmText": t("form.unsaved-changes-modal.confirm"), 
		      		           "cancelText": t("form.unsaved-changes-modal.cancel"),
		      		           "size": "lg",
		      		           "variant": "danger",
		        			   "when": compareJSON(initialValues, values)
		      		          }
		      		      )
		      		   }
		      		/>

					<Tab.Container id="laboratorySampleFormTab" activeKey={tabKey} onSelect={(k) => setTabKey(k)} >
					
					<Nav variant="tabs" className="mb-3">
					
						{ tabs.map((tab, index) => {
							
							if (!tab.actions.includes(action)) return null;
							
							return (
								<Nav.Item key={index}>
    								<Nav.Link eventKey={tab.key} className={ hasTabErrors(index, errors)  ? "text-danger" : ""}><Trans i18nKey={"collectionsamples.save-laboratory-sample.tabs."+tab.key}>{tab.key}</Trans>{ hasTabErrors(index, errors) ? "*" : ""}</Nav.Link>
  								</Nav.Item>
	      					)
	      				})}

						
					</Nav>
					
					
					<Tab.Content>
						{ tabs.map((tab, index) => {
								
							const { 
		    					component: Component, 
		    						...rest 
		    				} = tab;
								
							
							if (!tab.actions.includes(action)) return null;
							
							return (
								
								<Tab.Pane key={index} eventKey={tab.key}>
									<Component 
										values={values} 
										errors={errors} 
										onChange={handleChange}
										dirty={dirty} 
										onError={context.onError}
										onSuccess={context.onSuccess}
										onWarning={context.onWarning}
										onInfo={context.onInfo}
										setFieldValue={setFieldValue}
										action={action}
										origin={"laboratory-samples"}
										isModal={false}
										i18nPrefix="collectionsamples.save-laboratory-sample."
									/>
								</Tab.Pane>

	      						)
    						})}
						
					</Tab.Content>
					
					
					</Tab.Container>
					
	    			    <Row className="mt-3">    					   
    						<Col>
								{(action === "create")||(action == "update" && !!dirty) ?
    							<Button variant="success" type="submit" disabled={isSubmitting}> 
    								{isSubmitting ? <Trans i18nKey="collectionsamples.save-laboratory-sample.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={actionIcon} /> <Trans i18nKey={"collectionsamples.save-laboratory-sample.form.submit-"+action}>Submit</Trans></span>} 
    							</Button>
								: null
								}
    							<Button as={Link} variant="secondary" className="float-right" to={"/analysis/collectionsamples"}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="collectionsamples.save-laboratory-sample.cancel">Cancel</Trans></Button>
    					   </Col>
    			       </Row>     
		      	</Form>	
		      )}
		      </Formik>
		      
		      </Col>
	        </Row>
	     </Container>
	);
}

export default SaveLaboratorySample;
