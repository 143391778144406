import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate, useLocation, useOutletContext  } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import Container from 'react-bootstrap/Container'

import PagedList from "../components/PagedList";

import LaboratoryTestsFilterForm from "../components/LaboratoryTestsFilterForm";
import Loader from "../components/Loader";
import SaveLaboratoryTestModal from "../components/SaveLaboratoryTestModal";
import DisplayCollectionSampleModal from "../components/DisplayCollectionSampleModal";
import ConfirmationDialogModal from "../components/ConfirmationDialogModal";
import SaveMultipleLaboratoryTestsModal from "../components/SaveMultipleLaboratoryTestsModal";

import Filler from "../components/Filler";

import LaboratoryTestConfig from "../config/LaboratoryTestConfig";

import { numToColumn } from "../helpers/commons";

import API from "../services/backend-api";
import { useAuth } from "../services/use-auth";
import { useConfig } from "../services/use-config";

import * as Yup from "yup";

const validationSchema = Yup.object().shape(LaboratoryTestConfig.FilterFormValidationRules);

const dataTableActionModals = [
	  {
        key: 'action-0',
        component: SaveLaboratoryTestModal,
		size: 'lg',
		multiple : false
      },
		{
        key: 'action-1',
        component: Filler,
		size: 'lg',
		multiple : false
      },
	  {
        key: 'action-2',
        component: DisplayCollectionSampleModal,
		size: 'lg',
		multiple : false
      },
	  {
        key: 'multiple-action-0',
        component: SaveMultipleLaboratoryTestsModal,
		size: 'lg',
		multiple : true
      },
	  {
        key: 'multiple-action-1',
        component: Filler,
		size: 'lg',
		multiple : true
      }
    ];


const LaboratoryTests = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [pagedLaboratoryTests, setPagedLaboratoryTests] = useState(null);
	/*const [saveLaboratoryTestModalShow, setSaveLaboratoryTestModalShow] = useState(false);
	const [displayCollectionSampleModalShow, setDisplayCollectionSampleModalShow] = useState(false);
	const [selectedLaboratoryTest, setSelectedLaboratoryTest] = useState(null);*/
	const [selectedDataTableItem, setSelectedDataTableItem] = useState(null);
	const [selectedDataTableItems, setSelectedDataTableItems] = useState(null);
	const [dataTableActionModalsShowMap, setDataTableActionModalsShowMap] = useState(new Map());
	
	
	const location = useLocation();
	let context = useOutletContext();
	
	const { t } = useTranslation();
	const auth = useAuth();
	const config = useConfig();
	
	let navigate = useNavigate();
	
	let defaultValues = validationSchema.default();
	
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
			
		//Initializing Action Modals Show Status
		resetModalsVisibility();
			
		listLaboratoryTests({})
			.catch(error => { 
		 		log.error("Error Loading Initial Laboratory Tests Page: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		
	}, []);
	

  const resetModalsVisibility = () => {
	let tempMap = new Map();
	dataTableActionModals.forEach((item, index) => {
		tempMap.set(item.key, false);
	});
	setDataTableActionModalsShowMap(tempMap);
  }

  	const handleActionModalHide = () => {
	  resetModalsVisibility();
	  setSelectedDataTableItem(null); 
      setSelectedDataTableItems(null);
	  
    }

	const showActionModal = (action) => { 
	  let tempMap = dataTableActionModalsShowMap;
	  tempMap.set(action, true);
	  setDataTableActionModalsShowMap(tempMap);
   }
  
  const saveLaboratoryTest = (item, action) => {
	  setSelectedDataTableItem(item);
	  showActionModal("action-0")
  }

 const saveMultipleLaboratoryTests = (items, action) => {
	  setSelectedDataTableItems(items);
	  showActionModal(action)
  }

 const confirmMultipleLaboratoryTestsDeletion = (items, action) => {

	  setSelectedDataTableItems(items);
	  showActionModal(action)
  }

  const addLaboratoryTest = () => {
	  let item = {};
	  setSelectedDataTableItem(item);
	  showActionModal("action-0")
  }

  const deleteLaboratoryTest = (item) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		API.deleteLaboratoryTest(item.id, auth.isAdmin(), config.csrfToken).then(response => {
			context.onSuccess(response.success)
			refreshPage();
		}).catch(error => { 	
			log.error("Error Removing Laboratory Test: ", error.message);
			context.onError(error);		
		}).finally(() => {
			handleActionModalHide();
		});
  }

  const deleteMultipleLaboratoryTests = (items) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		let listOfItemsId = items.map(e => Object.assign({}, {"id": e.id}));
		
		API.deleteMultipleLaboratoryTests(listOfItemsId, auth.isAdmin(), config.csrfToken).then(response => {
			context.onSuccess(t('laboratorytests.delete-multiple-tests-confirmation-modal.success'));
			refreshPage();
		}).catch(error => { 	
			log.error("Error Removing Laboratory Tests: ", error.message);
			context.onError(error);		
		}).finally(() => {
			handleActionModalHide();
		});
  }


  const confirmLaboratoryTestDeletion = (item, action) => {
	  setSelectedDataTableItem(item);
	  showActionModal(action)
  }

   const handleItemsSaved = (success) => {
	  handleActionModalHide();
	  refreshPage();
	  context.onSuccess(success);
   }


  const displayCollectionSample = (item, action) => {
	  setSelectedDataTableItem(item);
	  showActionModal(action)
  }

 /*
  const saveLaboratoryTest = (test) => {
	  setSelectedLaboratoryTest(test);
	  setSaveLaboratoryTestModalShow(true);
 }

  const handleSaveLaboratoryTestModalHide = () => {
	  setSelectedLaboratoryTest(null); 
	  setSaveLaboratoryTestModalShow(false);
   }

   const handleLaboratoryTestSaved = (success) => {
	  setSelectedLaboratoryTest(null); 
	  setSaveLaboratoryTestModalShow(false);
	  refreshPage();
	  context.onSuccess(success);
   }

  const displayCollectionSample = (item) => {
	  setSelectedLaboratoryTest(item);
	  setDisplayCollectionSampleModalShow(true);
  }

  const handleDisplayCollectionSampleModalHide = () => {
  	  setSelectedLaboratoryTest(null); 
	  setDisplayCollectionSampleModalShow(false);
   }*/
    
  const updatePage = (values) => {
	  listLaboratoryTests(values)
		.catch(error => { 
			log.error("Error Paginating Laboratory Tests List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const sortLaboratoryTests = (values) => {
	  values.pageIndex = 0;
	  listLaboratoryTests(values)
		.catch(error => { 
			log.error("Error Sorting Laboratory Tests List: ", error.message);
	 		context.onError(error);
		})
		
  }

  
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  
		  queryParams.pageIndex = currentPage.pageIndex;
		  queryParams.pageSize = currentPage.pageSize;
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;

		  for(let property in LaboratoryTestConfig.FilterFormValidationRules) {
				if(currentPage.hasOwnProperty(property)) {
					queryParams[property] = (currentPage[property]) ? currentPage[property] : defaultValues[property];
				}
		   }
	  }
	  
	  if (newValues) {
		  for(let property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
  const refreshPage = () => {
		  
	  //List Laboratory Tests with no new values
	  listLaboratoryTests({})
		.catch(error => { 
	 		log.error("Error Refreshing Laboratory Tests List: ", error.message);
	 		context.onError(error);
		})	
  }  

  const filterLaboratoryTests = (values) => {
	  values.pageIndex = 0;
	  return listLaboratoryTests(values)
		.catch(error => { 
			log.error("Error Filtering Laboratory Tests List: ", error.message);
	 		context.onError(error);
		})
		
  }

 const exportLaboratoryTests = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.exportLaboratoryTests(mergeQueryParams(pagedLaboratoryTests, values), true).then(response => {
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
	const findLaboratoryTests = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.findLaboratoryTests(mergeQueryParams(pagedLaboratoryTests, values), true).then(response => {
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});

  const listLaboratoryTests = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.pageLaboratoryTests(mergeQueryParams(pagedLaboratoryTests, values)).then(response => {
			setPagedLaboratoryTests(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
	
	if (isLoading) 
		return <Loader />
	
	if (!pagedLaboratoryTests) 
		return null;
		
	const buildLabInfo = (s) => {
		
		return (s.laboratory) ? 
			s.laboratory 
				+ ((s.storageBoxLabelId) 
					? " | " + s.storageBoxLabelId
						+ ((s.slotCol && s.slotRow) 
							? " [" + s.slotRow + "-" + numToColumn(s.slotCol)+"]"
							: "")
					: "")
			: "";
	}
			
	return (
		<Container >
			{ dataTableActionModals.map((item, index) => {
								
				const { 
		    		component: Component, 
		    		...rest 
		    	} = item;
			
				return (
					<Fragment key={item.key}>
					{ (dataTableActionModalsShowMap.get(item.key)) ?
						<Fragment>
							{(item.multiple && selectedDataTableItems) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="laboratory-tests"
									items={selectedDataTableItems}
									onError={context.onError}
									onItemsSaved={handleItemsSaved}
								/>
								: 
								(!item.multiple && selectedDataTableItem) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="laboratory-tests"
									item={selectedDataTableItem}
									onError={context.onError}
									onItemSaved={handleItemsSaved}
								/>
								:
								null
							}
						</Fragment>
						:
						null
					}
					</Fragment>
	      		)
    		})}

			{(selectedDataTableItem) && <ConfirmationDialogModal
				item={selectedDataTableItem}
				show={dataTableActionModalsShowMap.get("action-1")}
        		onHide={handleActionModalHide}
				size="lg"
				title={t("laboratorytests.delete-test-confirmation-modal.title")}
				bodyText={t("laboratorytests.delete-test-confirmation-modal.body", {item: selectedDataTableItem})}
				confirmText={t("laboratorytests.delete-test-confirmation-modal.confirm")}
				cancelText={t("laboratorytests.delete-test-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteLaboratoryTest}
			/>}
			
			{(selectedDataTableItems) && <ConfirmationDialogModal
				item={selectedDataTableItems}
				show={dataTableActionModalsShowMap.get("multiple-action-1")}
        		onHide={handleActionModalHide}
				size="lg"
				title={t("laboratorytests.delete-multiple-tests-confirmation-modal.title")}
				bodyText={t("laboratorytests.delete-multiple-tests-confirmation-modal.body", {count: selectedDataTableItems.length})}
				confirmText={t("laboratorytests.delete-multiple-tests-confirmation-modal.confirm")}
				cancelText={t("laboratorytests.delete-multiple-tests-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteMultipleLaboratoryTests}
			/>}
			
			<PagedList
				i18nPrefix="laboratorytests.list."
				page={pagedLaboratoryTests}
				list={pagedLaboratoryTests.list.map((s) => Object.assign({}, s, {labInfo: buildLabInfo(s)}))}
				pageSizeOptions={[10,25,50,100]}
				filterFormComponent={LaboratoryTestsFilterForm}
				onFilter={filterLaboratoryTests} 
				onError={context.onError}
				onSort={sortLaboratoryTests}
				onAdd={addLaboratoryTest}
				onPage={updatePage}
				onExport={exportLaboratoryTests}
				dataTableColumns={["labelId", "targetVirus", "result", "dateTested", "labInfo"]}
				dataTableSortableColumns={["labelId", "dateTested", "result"]}
				dataTableCustomDisplayColumns={[["dateTested", "LocalDate"],["materialSample", "Enum"], ["result", "Enum"]]}
				dataTableActions={[saveLaboratoryTest, confirmLaboratoryTestDeletion, displayCollectionSample]}
				dataTableDefaultAction={saveLaboratoryTest}
				dataTableMultipleSelectActions={[saveMultipleLaboratoryTests, confirmMultipleLaboratoryTestsDeletion]}
				dataTableHandleTextOverflow={true}
				
			/>
			
			
      </Container>
	
	);
}

export default LaboratoryTests;
