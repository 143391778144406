import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate, useLocation, useOutletContext  } from 'react-router-dom';
import log from 'loglevel';

import Loader from "../components/Loader";

import PagedList from "../components/PagedList";
import CloneCollectionModal from "../components/CloneCollectionModal";
import SingleFilterForm from "../components/SingleFilterForm";

import API from "../services/backend-api";
import { useAuth } from "../services/use-auth";

const Collections = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [pagedCollections, setPagedCollections] = useState(null);
	const [cloneCollectionModalShow, setCloneCollectionModalShow] = useState(false);
	const [selectedCollection, setSelectedCollection] = useState(null);
	
	const auth = useAuth();
	let navigate = useNavigate();
	
	const location = useLocation();
	let context = useOutletContext();
	
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
		
		listCollections({})
			.catch(error => { 
		 		log.error("Error Loading Initial Collections List: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);
	
  const updateCollection = (item) => {
	  navigate("/collections/update", { state: { item: item}});
  }
  
  const cloneCollection = (collection) => {
	  setSelectedCollection(collection);
	  setCloneCollectionModalShow(true);
  }
  
  const handleCloneCollectionModalHide = () => {
	  setSelectedCollection(null); 
	  setCloneCollectionModalShow(false);
  }
  
  const addCollection = () => {
	  navigate("/collections/create");
  }
    
  const updatePage = (values) => {
	  listCollections(values)
		.catch(error => { 
			log.error("Error Paginating Collections List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const sortDataTable = (values) => {
	  values.pageIndex = 0;
	  listCollections(values)
		.catch(error => { 
			log.error("Error Sorting Collections List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
  
  const refreshPage = () => {
	  
	  let currentParams = {};
	  if (pagedCollections) {
		  currentParams.pageIndex = pagedCollections.pageIndex;
		  currentParams.pageSize = pagedCollections.pageSize;
		  currentParams.sortBy = pagedCollections.sortBy;
		  currentParams.order = pagedCollections.order;
		  currentParams.filteredColumn = pagedCollections.filteredColumn;
		  currentParams.filter = pagedCollections.filter;  
	  }
		  
	  listCollections(currentParams)
		.catch(error => { 
	 		log.error("Error Refreshing Collections List: ", error.message);
	 		context.onError(error);
		})	
  }  
 

  const listCollections = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.listCollections(mergeQueryParams(pagedCollections, values), auth.isAdmin()).then(response => {
			setPagedCollections(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
	
   const exportCollections = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.exportCollections(mergeQueryParams(pagedCollections, values), auth.isAdmin(), true).then(response => {
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
	/*const exportCollections = (values) => {
		return API.getExportCollectionsUrl(mergeQueryParams(pagedCollections, values), auth.isAdmin(), true);
	}*/
	
	if (isLoading) 
		return <Loader />
	
	if (!pagedCollections) 
		return null;
		
	return (
		<Fragment>
			{(selectedCollection) && <CloneCollectionModal
				show={cloneCollectionModalShow}
				onHide={handleCloneCollectionModalHide}
				size="lg"
				collection={selectedCollection}
				onError={context.onError}
			/>}
			
			<PagedList
				i18nPrefix="collections.list."
				page={pagedCollections}
				pageSizeOptions={[10,20]}
				filterFormComponent={SingleFilterForm}
				filterParameters={{ filter: pagedCollections.filter, filteredColumn: pagedCollections.filteredColumn }}
				onFilter={listCollections} 
				onError={context.onError}
				onSort={sortDataTable}
				onAdd={addCollection}
				onExport={exportCollections}
				onPage={updatePage}
				dataTableColumns={["organismId", "county", "stateProvince", "totalSamples", "eventDate",  "whenModified"]}
				dataTableSortableColumns={["organismId", "locality", "eventDate", "whenModified"]}
				dataTableCustomDisplayColumns={[["eventDate", "LocalDate"], ["whenModified", "ZonedDateTime"]]}
				dataTableActions={[updateCollection, cloneCollection]}
				dataTableDefaultAction={updateCollection}
			/>
		</Fragment>
	);
}

export default Collections;
