import React, { Fragment, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faTimesCircle, faCheck } from '@fortawesome/free-solid-svg-icons'


import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import DismissableFeedback from "../components/DismissableFeedback";
import FormControlErrors from "../components/FormControlErrors";

import { isArrayWithLength } from "../helpers/commons";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const validationSchema = Yup.object().shape({
	   licenseNumber: Yup.string()
	   		.required("collections.set-license-modal.form.licenseNumber.validation.required")
});


const SetCollectionLicenseModal = props => {

	const [_error, _setError] = useState(null);
	
	const { t } = useTranslation();
	
	let availableLicenses = props.licenses.filter((license) => (license.type === props.type));
	
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-set-collection-license"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
			<Modal.Header closeButton>
  				<Modal.Title id="contained-modal-set-collection-license">
  					<Trans i18nKey="collections.set-license-modal.title" values={{type: props.type}}>Set License</Trans>
  				</Modal.Title>
  			</Modal.Header>
  				<Formik
		      		initialValues={{ licenseNumber: ""}}
		      		validationSchema={validationSchema}
		      		validateOnChange={true}   
		      		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);
					
						actions.setSubmitting(false);
						
						props.onLicenseChange(props.type, values.licenseNumber, values.country, null, null);
					}}
		      	>
		      	{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue}) => (	
		      		<Form className="form-set-collection-license" noValidate>
		      		<Modal.Body className="mb-3">
		  				<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  
				    	
				    	<Row className="align-items-center">
				    		{(isArrayWithLength(availableLicenses)) ?
								<FormGroup as={Col} controlId="formGridLicenseNumber">
									<FormLabel><Trans i18nKey="collections.set-license-modal.form.licenseNumber.label">License Number</Trans></FormLabel>
									<FormControl as="select" name="licenseNumber" isInvalid={!(errors.licenseNumber == null)} value={values.licenseNumber} onChange={handleChange} >
		    							<option value="">{t("collections.set-license-modal.form.licenseNumber.blank-option")}</option>
		    							{ availableLicenses.map(item =>
		    								<option key={item.id} value={item.licenseNumber}>{(item.remarks)? item.licenseNumber + " (" + item.remarks + ")" : item.licenseNumber }</option>
		    							)}
		    						</FormControl>
									<FormControlErrors errors={errors.licenseNumber} />
								</FormGroup>
							:
								<Fragment>
  									<Col md={1} sm={2} xs={2} className="ml-3">
  										<h1 className="text-warning"><FontAwesomeIcon size="xs" icon={faExclamationTriangle} /></h1>
									</Col>
		  							<Col className="ml-3 align-middle">
										<Trans i18nKey="collections.set-license-modal.form.licenseNumber.validation.nolicenses" values={{type: props.type}}>No licenses found</Trans>
									</Col>
								</Fragment>
							}
						</Row>
				    	
          				</Modal.Body>
          				<Modal.Footer>			      		
          				      {(isArrayWithLength(availableLicenses)) ?
							  	<Button variant="success" type="submit" disabled={isSubmitting} > 
          				      		{isSubmitting ? <Trans i18nKey="collections.set-license-modal.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={faCheck} /> <Trans i18nKey="collections.set-license-modal.form.submit">Submit</Trans></span>} 
          				      	</Button>
								: null
							  }
          				      <Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="collections.set-license-modal.cancel">Cancel</Trans></Button>	
          				</Modal.Footer>
              		</Form>	
		      )}
		      </Formik>
		   </Modal>
	);
}

export default SetCollectionLicenseModal;
