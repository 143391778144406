import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormLabel from 'react-bootstrap/FormLabel';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import DismissableFeedback from "../components/DismissableFeedback";
import LocationInputSearch from "../components/LocationInputSearch";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });


const validationSchema = Yup.object().shape({
	   location: Yup.object({
			county: Yup.string()
				.required("workgroups.set-headquarters-modal.form.location.validation.required")
				.default(() => ""),
			stateProvince: Yup.string()
				.default(() => ""),
			country: Yup.string()
				.default(() => ""),
			latitude: Yup.string()
				.default(() => ""),
			longitude: Yup.string()
				.default(() => ""),
		})
});


const SetWorkgroupHeadquartersModal = props => {

	const [_error, _setError] = useState(null);
	
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-set-collection-location"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-set-collection-location">
  					<Trans i18nKey="workgroups.set-headquarters-modal.title">Set Location</Trans>
  				</Modal.Title>
  			</Modal.Header>
  				<Formik
		      		initialValues={validationSchema.default()}
		      		validationSchema={validationSchema}
		      		validateOnChange={true}   
		      		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);
					
						actions.setSubmitting(false);
						props.onChange(values.location.county, values.location.stateProvince, values.location.country, values.location.latitude, values.location.longitude);
						
					}}
		      	>
		      	{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue}) => (	
		      		<Form className="form-set-collection-location" noValidate>
		      		<Modal.Body className="mb-3">
		  				<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  	
				    		
				    		<Row><Col>	
				    			<FormLabel><Trans i18nKey="workgroups.set-headquarters-modal.form.location-input-search.label">Search by Locality</Trans></FormLabel>
				    			<LocationInputSearch 
				    				i18nPrefix="workgroups.set-headquarters-modal.form."
				    				onError={(error) => { _setError(error);}}
				    				isInvalid={!(errors.location == null)}
				    				fetchCoordinates={true}
				    				onChange={(location) => { setFieldValue("location", location); }}
				    			/>
								{!(errors.location == null) 
          							? <div className="text-left invalid-feedback" style={{display: "block"}}><Trans i18nKey={errors.location.county} /></div>
									: null
								}
								
							</Col></Row>
						
          				</Modal.Body>
          				<Modal.Footer>			      		
          				      <Button variant="success" type="submit" disabled={isSubmitting} > 
          				      		{isSubmitting ? <Trans i18nKey="workgroups.set-headquarters-modal.form.submitting">Please wait...</Trans> : <Trans i18nKey="workgroups.set-headquarters-modal.form.submit">Submit</Trans>} 
          				      </Button>
          				      <Button variant="secondary" onClick={props.onHide}><Trans i18nKey="workgroups.set-headquarters-modal.cancel">Cancel</Trans></Button>	
          				</Modal.Footer>
              		</Form>	
		      )}
		      </Formik>
		   </Modal>
	);
}

export default SetWorkgroupHeadquartersModal;
