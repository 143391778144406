import React, {Fragment} from 'react';

const Filler = props => {
	
	return (
		<Fragment>
		</Fragment>
	)
};

export default Filler;