import React from 'react';
import { Trans } from 'react-i18next';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


import DataTable from "../components/DataTable";

import CollectionsImportConfig from "../config/CollectionsImportConfig";

const CollectionsImportPreviewModal = props => {
	
	return (
		<Modal
		    show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-collections-import-preview"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-collections-import-preview">
  					{(props.title)
  						? props.title
  						: <Trans i18nKey="collections.import-preview-modal.title">Collection Import Preview Title</Trans>
  					}
  				</Modal.Title>
  			</Modal.Header>
  			<Modal.Body>
  				<Container>
  					<Row>
  					<Col>
  						<DataTable 
							items={props.data}
							columns={CollectionsImportConfig.Headers}
							tooltipData={props.errors}
							tooltipTargetStyles={{color: "red"}}
						/>
					</Col>
				</Row>
				</Container>
		  	</Modal.Body>
          	<Modal.Footer>			      		
          		<Button variant="secondary" onClick={props.onHide}>
          			<Trans i18nKey="collections.import-preview-modal.close">Close</Trans>
				</Button>	
          	</Modal.Footer>
		</Modal>
	);
}

export default CollectionsImportPreviewModal;
