import React,  { Fragment, useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';

//import { CSVReader } from 'react-papaparse';
import { useCSVReader, formatFileSize } from "react-papaparse"

import Loader from "../components/Loader";
import FormControlErrors from "../components/FormControlErrors";
import CollectionsImportValidationResults from "../components/CollectionsImportValidationResults";

import CollectionConfig from "../config/CollectionConfig";
import CollectionsImportConfig from "../config/CollectionsImportConfig";
//import CollectionsImportStyles from "../styles/CollectionsImportStyles";
import DropZoneStyles from "../styles/DropZoneStyles";

import * as Yup from "yup";

import { useAuth } from "../services/use-auth";
import API from "../services/backend-api";

import { isArrayWithLength } from "../helpers/commons";

const collectionValidationRules = Object.assign({}, 
		CollectionConfig.OrganismIdValidationRules,
		CollectionConfig.GeneralFormValidationRules,
		CollectionConfig.TaxonomyFormValidationRules,
		CollectionConfig.HistoryFormValidationRules,
		CollectionConfig.PhysicalExamFormValidationRules,
		CollectionConfig.SamplesFormValidationRules,
		CollectionConfig.PhotosFormValidationRules,
		CollectionConfig.ExtraFormValidationRules
);

const collectionValidationSchema = Yup.object().shape(collectionValidationRules);

const CollectionsImportFormFields = props => {

	const [delimiter, setDelimiter] = useState("");
	const [header, setHeader] = useState(true);
	const [columnMappingStrategy, setColumnMappingStrategy] = useState("position");
	const [fileLoaded, setFileLoaded] = useState(false);
	
	const [workgroups, setWorkgroups] = useState([]);
	const [workgroupUsersMap, setWorkgroupUsersMap] = useState(new Map());
	const [usersMap, setUsersMap] = useState(new Map());
	const [isLoading, setIsLoading] = useState(true);
	const [transformationErrors, setTransformationErrors] = useState([]);
	
	const { CSVReader } = useCSVReader();
	const [zoneHover, setZoneHover] = useState(false)
  	const [removeHoverColor, setRemoveHoverColor] = useState(
    	DropZoneStyles.DEFAULT_REMOVE_HOVER_COLOR
  	)
	
	const auth = useAuth();
	const { t } = useTranslation();
	
	const getNormalizedFullName = (firstName, lastName) => {
		return getNormalizedString(firstName.concat(" ", lastName))
	};
	
	const getNormalizedString = (str) => {
		return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
	};
	
	useEffect(() => {
		let isMounted = true; 		
		
		//Fetch Collecting Workgroups
		API.findWorkgroups({"types": ["OBSERVATORY"]}, auth.isAdmin())
			.then(response => {
				if (isMounted) setWorkgroups(response.list);
			})
			.catch(error => { 
				log.error("Error Loading Collecting Workgroups: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
			
		//Fetch Users
		API.findUsers({})
			.then(response => {
				if (isMounted) 
					setUsersMap(new Map(response.list.map((u) => [ getNormalizedFullName(u.firstName, u.lastName) , u.id])));
			})
			.catch(error => { 
				log.error("Error Loading Users: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);
	
	if (isLoading) 
		return <Loader />
		
	const getWorkgroupByShortcode = (code) => {		
		return workgroups.find(({shortcode}) => shortcode === code)
	};
	
	const getWorkgroupById = (workgroupId) => {		
		return workgroups.find(({id}) => id == workgroupId)
	};
	
	const getWorkgroupUsers = (workgroupId) => {
    	API.getWorkgroupUsers(workgroupId, auth.isAdmin())
			.then(response => {
				setWorkgroupUsersMap(new Map(response.list.map((u) => [ getNormalizedFullName(u.firstName, u.lastName) , u.id])));
				//setWorkgroupUsersMap(new Map(response.list.map((u) => [ u.firstName + " " + u.lastName , u.id])));	
			})
			.catch(error => { 
				log.error("Error Loading Workgroup Users: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		  //Do nothing yet
			});
    }
		
	const handleWorkgroupChange = (e) => {
        
        props.setFieldValue("workgroupId", e.target.value);
        
        if (!e.target.value) {
        	setWorkgroupUsersMap(new Map());
        	return;
        }
        
        //Get Workgroup Users
        getWorkgroupUsers(e.target.value);
    }
		
	/*const handleOnDrop = (data) => {
    	//log.info("Data: ", data);

		//Set File Loaded
		setFileLoaded(true);

		//Clear Error/Success
		props.setFieldError("data", null);
    	props.onError(null);
    	props.onSuccess(null);
		
		if (isArrayWithLength(transformationErrors)) {
			props.setFieldError("data", transformationErrors[0]);
			setTransformationErrors([]);
			return;
		}
		
		let parsedData = data.map((i) => {
			let d = i.data;
			d.unableToCompleteBasicMeasurements = true;
			return d;
		});
		
		let parsingErrors = [];
		
		data.forEach((i) => {
			i.errors.forEach(e => {
   				log.error("Error Parsing CSV File: ", e);
				 parsingErrors.push(t("collections.import.form.csvreader.validation.parse-failed", {error: e}));
				
   			});
		});
		
		props.setFieldValue("data", parsedData);
		
		if (isArrayWithLength(parsingErrors))
			props.setFieldError("data", parsingErrors[0]);
		else if (!isArrayWithLength(parsedData))
			props.setFieldError("data", t("collections.import.form.csvreader.validation.no-data"));
			
  	};*/

	/*const handleOnError = (err, file, inputElem, reason) => {
    	log.error(err);
		props.setFieldError("data", t("collections.import.form.csvreader.validation.parse-failed", {error: err}));
  	};*/
  	
  	const handleOnUploadAccepted = (results) => {
    	log.info("Results: ", results);

		setZoneHover(false)
		
		//Set File Loaded
		setFileLoaded(true);

		//Clear Error/Success
		props.setFieldError("data", null);
    	props.onError(null);
    	props.onSuccess(null);
		
		if (isArrayWithLength(transformationErrors)) {
			props.setFieldError("data", transformationErrors[0]);
			setTransformationErrors([]);
			return;
		}
		
		/*let parsedData = results.data.map((item) => {
			let d = item;
			d.active = true;
			return d;
		});*/
		
		//Remove unnecessary headers/columns here
		let parsedData = results.data.map((item) => {
			let d = item;
			
			//Object.keys(d).forEach( k => {if (!AccountConfig.ImportHeaders.includes(k)) delete d[k];} )
			
			return d;
		});
		
		let parsingErrors = [];
		
		
		results.errors.forEach((errors) => {
			errors.forEach(e => {
   				log.error("Error Parsing CSV File: ", e);
				 parsingErrors.push(t(props.i18nPrefix+"form.csvreader.validation.parse-failed", {error: e}));
				
   			});
		});
		
		props.setFieldValue("data", parsedData);
		
		if (isArrayWithLength(parsingErrors))
			props.setFieldError("data", parsingErrors[0]);
		else if (!isArrayWithLength(parsedData))
			props.setFieldError("data", t(props.i18nPrefix+"form.csvreader.validation.no-data"));
			
  	};

	const handleOnRemoveFile = (data) => {
    	props.setFieldValue("data", []);

		props.setFieldError("data", null);
		
		setTransformationErrors([]);
		
		setFileLoaded(false);
		
		//Clear Error/Success
    	props.onError(null);
    	props.onSuccess(null);

  	};

	const handleTransformHeaderByPosition = (header, position) => {
		return (CollectionsImportConfig.Headers.length > position) ? CollectionsImportConfig.Headers[position] : header;
  	};

	//const captureMethodsMap = new Map(CollectionConfig.CaptureMethods.map((e) => [t("collections.save.form.captureMethod.options."+e), e]))
	
	
	const collectionEnumsMap = new Map([
		["captureMethod", new Map(CollectionConfig.CaptureMethods.map((e) => [t("collections.save.form.captureMethod.options."+e), e]))],
		["captureType", new Map(CollectionConfig.CaptureTypes.map((e) => [t("collections.save.form.captureType.options."+e), e]))],
		["markingType", new Map(CollectionConfig.MarkingTypes.map((e) => [t("collections.save.form.markingType.options."+e), e]))],
		["taxonGroup", new Map(CollectionConfig.TaxonGroups.map((e) => [t("collections.save.form.taxonGroup.options."+e), e]))],
		["sex", new Map(CollectionConfig.Sexes.map((e) => [t("collections.save.form.sex.options."+e), e]))],
		["reproductiveCondition", new Map(Object.values(CollectionConfig.ReproductiveConditions).flat().map((e) => [t("collections.save.form.reproductiveCondition.options."+e), e]))],
		["symptomaticCondition", new Map(CollectionConfig.SymptomaticConditions.map((e) => [t("collections.save.form.symptomaticCondition.options."+e), e]))],
		["lifeStage", new Map(CollectionConfig.LifeStages.map((e) => [t("collections.save.form.lifeStage.options."+e), e]))],
		["establishmentMeans", new Map(CollectionConfig.EstablishmentMeans.map((e) => [t("collections.save.form.establishmentMeans.options."+e), e]))],
		["degreeOfEstablishment", new Map(CollectionConfig.DegreesOfEstablishment.map((e) => [t("collections.save.form.degreeOfEstablishment.options."+e), e]))],
		["samples", new Map(CollectionConfig.Samples.map((e) => [t("collections.save.form.samples.options."+e), e]))],
		["samplesMediums", new Map(CollectionConfig.SamplesMediums.map((e) => [t("collections.save.form.samplesMediums.options."+e), e]))],
		["samplesPreservationMethod", new Map(CollectionConfig.SamplesPreservationMethods.map((e) => [t("collections.save.form.samplesPreservationMethod.options."+e), e]))],
		["samplesCoolingAgent", new Map(CollectionConfig.SamplesCoolingAgents.map((e) => [t("collections.save.form.samplesCoolingAgent.options."+e), e]))],
	]);
	
	
	let defaultCollectionValues = collectionValidationSchema.default();
	
	const handleTransform = (value, key) => {
		
		let errors = transformationErrors; 
		
		let result;
		
		let trimmedValue = (value) ? value.trim() : value;
		
		switch (key) {
  			case 'workgroupId':
				if(!trimmedValue) {
					result = defaultCollectionValues[key];
				} else {
					let workgroup = getWorkgroupByShortcode(trimmedValue);
					let selectedWorkgroup = getWorkgroupById(props.values.workgroupId);
					if (!workgroup) {
						errors.push(t("collections.import.form.data.validation.workgroup-not-found", {workgroup: trimmedValue}));
						result = defaultCollectionValues[key];
					} else if (workgroup.id != props.values.workgroupId) {
						errors.push(t("collections.import.form.data.validation.workgroup-mismatch", {workgroup: trimmedValue, selectedWorkgroup: selectedWorkgroup.shortcode}));
						result = defaultCollectionValues[key];
					} else {
						result = workgroup.id;
					}
				}
				
    			break;
			case 'capturedById':
			case 'preparedById':	
				if(!trimmedValue) {
					result = defaultCollectionValues[key];
				} else {
					let workgroupUserId = workgroupUsersMap.get(getNormalizedString(trimmedValue.toUpperCase()));
					if (!workgroupUserId) {
						errors.push(t(props.i18nPrefix+"form.data.validation.workgroup-user-not-found", {user: trimmedValue}));
						result = defaultCollectionValues[key];
					} else {
						result = workgroupUserId;
					}
				}
    			break;
			case 'identifiedById':
				if(!trimmedValue) {
					result = defaultCollectionValues[key];
				} else {
					let userId = usersMap.get(getNormalizedString(trimmedValue.toUpperCase()));
					if (!userId) {
						errors.push(t(props.i18nPrefix+"form.data.validation.user-not-found", {user: trimmedValue}));
						result = defaultCollectionValues[key];
					} else {
						result = userId;
					}
				}
    			break;
			case 'captureMethod':
			case 'captureType':
			case 'markingType':
			case 'taxonGroup':
			case 'sex':
			case 'reproductiveCondition':
			case 'symptomaticCondition':
			case 'lifeStage':
			case 'establishmentMeans':
			case 'degreeOfEstablishment':
			case 'samplesPreservationMethod':
			case 'samplesCoolingAgent':
				result = (trimmedValue && collectionEnumsMap.get(key) && collectionEnumsMap.get(key).get(trimmedValue)) ? collectionEnumsMap.get(key).get(trimmedValue) : trimmedValue;
    			break;
			case 'samples':
			case 'samplesMediums':
				result = (trimmedValue) ? trimmedValue.split("|").map((e) => (e && collectionEnumsMap.get(key) && collectionEnumsMap.get(key).get(e)) ? collectionEnumsMap.get(key).get(e) : e) : defaultCollectionValues[key];
    			break;
			case 'photos':
				result = [];
    			break;
			case 'latitude':
			case 'longitude':
			case 'forearmLength':
			case 'headBodyLength':
			case 'tailLength':
			case 'earLength':
			case 'footLength':
			case 'footIncludingNailLength':
			case 'tragusLength':
			case 'calcarLength':
			case 'tibiaLength':
			case 'totalLength':
			case 'tarsusLength':
			case 'wingLength':
			case 'culmenLength':
			case 'nostrilsLength':
			case 'weight':
				result = (trimmedValue) ? trimmedValue.replace(',','.') : defaultCollectionValues[key];
    			break;
  			default:
    			result = trimmedValue;
		}
		
		setTransformationErrors(errors);
		
		return result;
  	};

    
	return(
		<Fragment>
			<Row>
				<FormGroup as={Col} md={5} controlId="formGridWorkgroup">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.workgroupId.label"}>Workgroup</Trans> *</FormLabel>
					<FormControl as="select" name="workgroupId" disabled={fileLoaded} isInvalid={!(props.errors.workgroupId == null)} value={props.values.workgroupId} onChange={handleWorkgroupChange} >
		    			<option value="">{t(props.i18nPrefix+"form.workgroupId.blank-option")}</option>
		    			{ workgroups.map(item =>
		    				<option key={item.id} value={item.id+""}>{item.name}</option>
		    			)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.workgroupId} />
				</FormGroup>
				
				<FormGroup as={Col} md={7} controlId="formGridActions">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.action.label"}>Action</Trans> *</FormLabel>
					<FormControl as="select" name="action" disabled={fileLoaded} isInvalid={!(props.errors.action == null)} value={props.values.action} onChange={props.onChange} >
		    			{ CollectionsImportConfig.Actions.map(item =>
		    				<option key={item} value={item}>{t(props.i18nPrefix+"form.action.options."+item)}</option>
		    			)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.action} />
				</FormGroup>
			</Row>
			{ (props.values.workgroupId) &&
				<Fragment>
					<Row>
						<FormGroup as={Col} md={5} controlId="formGridDelimiter">
							<FormLabel><Trans i18nKey={props.i18nPrefix+"form.delimiter.label"}>Delimiter</Trans> </FormLabel>
							<FormControl as="select" name="delimiter" disabled={fileLoaded}  value={delimiter} onChange={(e) => {setDelimiter(e.target.value)}} >
		    				{ CollectionsImportConfig.DelimiterOptions.map(item =>
		    					<option key={item.key} value={item.value}>{t(props.i18nPrefix+"form.delimiter.options."+item.key)}</option>
		    				)}
		    				</FormControl>
						</FormGroup>
								
						<FormGroup as={Col} md={3} controlId="formGridHeader">
							<FormLabel><Trans i18nKey={props.i18nPrefix+"form.header.label"}>Header</Trans> </FormLabel>
							<FormControl as="select" name="header" disabled={fileLoaded} value={header} onChange={(e) => {setHeader(e.target.value)}} >
		    				{ CollectionsImportConfig.FirstRowHeaderOptions.map(item =>
		    					<option key={item.key} value={item.value}>{t(props.i18nPrefix+"form.header.options."+item.key)}</option>
		    				)}
		    				</FormControl>
						</FormGroup>
								
						<FormGroup as={Col} md={4} controlId="formGridColumnMappingStrategy">
							<FormLabel><Trans i18nKey={props.i18nPrefix+"form.columnMappingStrategy.label"}>Column Mapping Strategy</Trans> </FormLabel>
							<FormControl as="select" name="columnMappingStrategy" disabled={fileLoaded} value={columnMappingStrategy} onChange={(e) => {setColumnMappingStrategy(e.target.value)}} >
		    				{ CollectionsImportConfig.ColumnMappingStrategyOptions.map(item =>
		    					<option key={item.key} value={item.key}>{t(props.i18nPrefix+"form.columnMappingStrategy.options."+item.key)}</option>
		    				)}
		    				</FormControl>
						</FormGroup>
	
					</Row>
							
					<Row>
						<Col>
							<CSVReader
          						onDragOver={event => {
        							event.preventDefault()
        							setZoneHover(true)
      							}}
      							onDragLeave={event => {
        							event.preventDefault()
        							setZoneHover(false)
      							}}
          						onUploadAccepted={handleOnUploadAccepted}
          						
          						config={{
									"delimiter" : delimiter, 
									"header" : JSON.parse(header),
									"transformHeader" : handleTransformHeaderByPosition,
									"transform" : handleTransform,
									"skipEmptyLines": true
								}}
          						
								/*onError={handleOnError}*/
								
          						/*onDrop={handleOnDrop}
          						addRemoveButton
								
								style={{
									zone : Object.assign({}, DropZoneStyles.Zone, (!(props.errors.data == null)) ? {borderColor: "red"}: {}),
									file: DropZoneStyles.File, 
									info: DropZoneStyles.Info, 
									size: DropZoneStyles.Size,
									name: DropZoneStyles.Name,
									progressBar: DropZoneStyles.ProgressBar,
									zoneHover: DropZoneStyles.ZoneHover,
									default: DropZoneStyles.Default,
									remove: DropZoneStyles.Remove
								}}*/
        					>
        					  {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
        Remove
      }) => (
        <>
          <div
            {...getRootProps()}
            style={Object.assign(
              {},
              DropZoneStyles.DropZone,
              zoneHover && DropZoneStyles.DropZoneHover, (!(props.errors.data == null) ? {borderColor: "red"}: {})
            )}
          >
            {acceptedFile ? (
              <>
                <div style={DropZoneStyles.DropFile}>
                  <div
                    {...getRemoveFileProps()}
                    style={DropZoneStyles.RemoveButton}
                    onMouseOver={event => {
                      event.preventDefault()
                      setRemoveHoverColor(DropZoneStyles.REMOVE_HOVER_COLOR_LIGHT)
                    }}
                    onMouseOut={event => {
                      event.preventDefault()
                      setRemoveHoverColor(DropZoneStyles.DEFAULT_REMOVE_HOVER_COLOR)
                    }}
                    onClick={event => {
                 		getRemoveFileProps().onClick(event);
                 		handleOnRemoveFile();
               }}
                  >
                    <Remove color={removeHoverColor} />
                  </div>
                  <div style={DropZoneStyles.DropFileInfo}>
                    <span style={DropZoneStyles.FileSizeInfo}>
                      {formatFileSize(acceptedFile.size)}
                    </span>
                    <span style={DropZoneStyles.FileNameInfo}>{acceptedFile.name}</span>
                  </div>
                  <div style={DropZoneStyles.ProgressBar}>
                    <ProgressBar style={DropZoneStyles.ProgressBarBackgroundColor} />
                  </div>
                  
                </div>
              </>
            ) : (
              <span><Trans i18nKey={props.i18nPrefix+"form.csvreader.placeholder"} /></span>
            )}
          </div>
        </>
      )}
       						</CSVReader>
						</Col>
					</Row>
					<Row><Col><CollectionsImportValidationResults isSubmitting={props.isSubmitting} errors={props.errors.data} data={props.values.data} /></Col></Row>
				</Fragment>
			}
		
		</Fragment>
		
	) 
}


export default CollectionsImportFormFields;
