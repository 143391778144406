import React,  { Fragment, useState, useEffect} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faQuestion } from '@fortawesome/free-solid-svg-icons'
import { faVirus, faVirusSlash } from '@fortawesome/free-solid-svg-icons'


import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import FormCheck from 'react-bootstrap/FormCheck';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';

import FormControlErrors from "../components/FormControlErrors";
import FormControlHelper from "../components/FormControlHelper";
import Switch from "../components/Switch";

import CollectionSampleInputSearch from "../components/CollectionSampleInputSearch";
import StorageBoxInputSearch from "../components/StorageBoxInputSearch";

import moment from "moment";
import { DatePicker } from "react-tempusdominus-bootstrap";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

import { numToColumn } from "../helpers/commons";

import LaboratoryTestConfig from "../config/LaboratoryTestConfig";

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";

import { parseInputDate } from "../helpers/commons";

const ResultButtonVariantMap = new Map([
  ['POSITIVE', 'danger'],
  ['NEGATIVE', 'success'],
  ['INCONCLUSIVE', 'secondary'],
]);

const ResultButtonIconMap = new Map([
  ['POSITIVE', faVirus],
  ['NEGATIVE', faVirusSlash],
  ['INCONCLUSIVE', faQuestion],
]);


const GeneralSection = props => {

	const [workgroups, setWorkgroups] = useState([]);
	const [workgroupUsers, setWorkgroupUsers] = useState([]);
	const [targetViruses, setTargetViruses] = useState([]);
	const [laboratoryTestProtocols, setLaboratoryTestProtocols] = useState([]);
	const [availableStorageBoxSlots, setAvailableStorageBoxSlots] = useState([]);
	const [cloneOrganismId, setCloneOrganismId] = useState(true);
	
	const { t } = useTranslation();
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
		
		if (props.action === "create") {
			setCloneOrganismId(true);
			props.setFieldValue("labelId", buildLabelId(props.values));
		} else {
			setCloneOrganismId((buildLabelId(props.values) === props.values.labelId));
		}
		
		//Fetch Laboratory Workgroups
		API.findLaboratories({})
			.then(response => {
				if (isMounted) setWorkgroups(response.list);
			})
			.catch(error => { 
				log.error("Error Loading Analysis Workgroups: ", error.message);
		 		props.onError(error);
			})
			.finally(() => {
		 		//Get Workgroup Users
				if (props.values.workgroupId) {
					getWorkgroupUsers(props.values.workgroupId);
				}
			});
			
		//Fetch Target Viruses and Test Protocols
		API.findTargetViruses({})
			.then(response => {
				if (isMounted) setTargetViruses(response.list);
			})
			.catch(error => { 
				log.error("Error Loading Target Viruses: ", error.message);
		 		props.onError(error);
			})
			.finally(() => {
		 		//Get All Laboratory Test Protocols
				API.findLaboratoryTestProtocols({})
					.then(response => {
						if (isMounted) {	
							
							//setVirusProtocolsMap
							//response.list
							setLaboratoryTestProtocols(response.list);
							
						}
					})
					.catch(error => { 
						log.error("Error Loading Laboratory Test Protocols: ", error.message);
		 				props.onError(error);
					})
					.finally(() => {
		 				//Set related Laboratory Test Protocols
						if (props.values.targetVirusId) {
							//props.values.targetVirusId
							//setLaboratoryTestProtocols(response.list);
					}
				});
			});
		
		 return () => { isMounted = false };
		  
	}, []);
	
	const getWorkgroupUsers = (workgroupId) => {
    	API.findUsers({"filteredColumn": "workgroupId", "filter": workgroupId})
			.then(response => {
					setWorkgroupUsers(response.list); 					
			})
			.catch(error => { 
				log.error("Error Loading Workgroup Users: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		  //Do nothing yet
			});
    }


	const getAvailableStorageBoxSlots = (storageBox) => {
		
		if (storageBox) {
			
			let allStorageBoxSlots = Array.apply(null, {length: Number(storageBox.cols)})
				.map((_, col) => { 
					return Array.apply(null, {length: Number(storageBox.rows)})
						.map((_, row) => { 
							return Object.assign({}, {col: col+1, row: row+1});								
						})
					}
				).flat();
				
			//setAvailableStorageBoxSlots(allStorageBoxSlots);
			
			//Get Storage Box Items
			getStorageBoxItems(storageBox)
				.then(response => {
		
					let unavailableSlotsMap = new Map(response.list
								.filter(s => (s.slotCol && s.slotRow && s.type !== "NUCLEIC_ACID_EXTRACTION" && props.values.id !== s.id ))
								.map((s) => [ s.slotRow+"-"+s.slotCol, s]));
					
					setAvailableStorageBoxSlots(allStorageBoxSlots.filter(s => !unavailableSlotsMap.get(s.row+"-"+s.col)));				
				})
				.catch(error => { 
					log.error("Error Loading Storage Box Samples: ", error.message);
		 			props.onError(error);
			})
			
		} else {
			setAvailableStorageBoxSlots([]);
		}
	}
	
	const getStorageBoxItems = (storageBox) =>
		new Promise((resolve, reject) => {
			API.getStorageBoxItems(storageBox.id).then(response => {
			resolve(response);
		}).catch(error => { 
			reject(error);
		});
	});
	
	const handleWorkgroupChange = (e) => {
        
        props.setFieldValue("workgroupId", e.target.value);
        
        if (!e.target.value) {
        	setWorkgroupUsers([]);
        	
			props.setFieldValue("testedById", "");
			props.setFieldValue("storageBoxId", "");
			props.setFieldValue("slotCol", "");
			props.setFieldValue("slotRow", "");
			
        	return;
        }
        
        //Get Workgroup Users
        getWorkgroupUsers(e.target.value);

		//Get Storage Boxes
		//getStorageBoxes(e.target.value);
        
        //Reset extractedBy and storageBox
        props.setFieldValue("testedById", "");
		props.setFieldValue("storageBoxId", "");
		props.setFieldValue("slotCol", "");
		props.setFieldValue("slotRow", "");
    }


	const handleTargetVirusChange = (e) => {
        
        props.setFieldValue("targetVirusId", e.target.value);

		//Reset Laboratory Test Protocol 
		props.setFieldValue("protocolId", "");
      
    }

	const handlePCRTypeChange = (e) => {
        
        props.setFieldValue("pcrType", e.target.value);

		if (!e.target.value) {
        	
			props.setFieldValue("result", "");
			props.setFieldValue("cycleThreshold", "");
			props.setFieldValue("bandIntensity", "");
			props.setFieldValue("sequenced", false);
			props.setFieldValue("sequencingMethod", "");
			props.setFieldValue("sequencingResult", "");
			props.setFieldValue("sequencingRemarks", "");
			props.setFieldValue("viralIsolation", false);
			props.setFieldValue("viralIsolationRemarks", "");
			
        	return;
        }

		//Reset Related Fields
		
		props.setFieldValue("cycleThreshold", "");
		props.setFieldValue("bandIntensity", "");
      
    }

	const handleTestResultChange = (e) => {
        
        props.setFieldValue("result", e.target.value);

		//Reset Related Fields
		props.setFieldValue("cycleThreshold", "");
		props.setFieldValue("bandIntensity", "");
		props.setFieldValue("sequenced", false);
		props.setFieldValue("sequencingMethod", "");
		props.setFieldValue("sequencingResult", "");
		props.setFieldValue("sequencingRemarks", "");
		props.setFieldValue("viralIsolation", false);
		props.setFieldValue("viralIsolationRemarks", "");
      
    }

	const setTestResultValue = (result) => {
        
		if (props.values.result != result) {

        	props.setFieldValue("result", result);

			//Reset Related Fields
			props.setFieldValue("cycleThreshold", "");
			props.setFieldValue("bandIntensity", "");
			props.setFieldValue("sequenced", false);
			props.setFieldValue("sequencingMethod", "");
			props.setFieldValue("sequencingResult", "");
			props.setFieldValue("sequencingRemarks", "");
			props.setFieldValue("viralIsolation", false);
			props.setFieldValue("viralIsolationRemarks", "");
		}
      
    }

	const setSequencingResultValue = (result) => {
        
		if (props.values.sequencingResult != result) {

        	props.setFieldValue("sequencingResult", result);

		}
      
    }

	const handleSequencedChange = (e) => {
		       
        props.setFieldValue("sequenced", !JSON.parse(e.target.value));

		//Reset Related Fields
		props.setFieldValue("sequencingMethod", "");
		props.setFieldValue("sequencingResult", "");
		props.setFieldValue("sequencingRemarks", "");
      
    }

	const handleStorageBoxLoad = (storageBox) => {
		getAvailableStorageBoxSlots(storageBox);
	}

	const handleStorageBoxChange = (storageBox) => {
		
        if (!storageBox) {
			setAvailableStorageBoxSlots([]);
        	
			props.setFieldValue("storageBoxId", "");
			props.setFieldValue("slotCol", "");
			props.setFieldValue("slotRow", "");
			
        	return;
        } /*else if (storageBox.id === props.values.storageBoxId) {
			return;
		}*/


		//Get Available Storage Box Slots
		getAvailableStorageBoxSlots(storageBox);
		
		props.setFieldValue("storageBoxId", storageBox.id);

        //Reset StorageBox Slot Position
		props.setFieldValue("slotCol", "");
		props.setFieldValue("slotRow", "");
    }

	const handleStorageBoxSlotPositionChange = (e) => {
		
		if (!e.target.value) {
			props.setFieldValue("slotCol", "");
			props.setFieldValue("slotRow", "");
		} else {
			let slotPositionInfo = e.target.value.split("-");
			props.setFieldValue("slotRow", slotPositionInfo[0]);		
		    props.setFieldValue("slotCol", slotPositionInfo[1]);
		}
		
	}

	const buildGeneralInfo = (s) => {
       
		let generalInfo = s.organismId
			+ " - " + t("models.collections.enums.samples."+s.materialSample)
			+ " - " + s.scientificName
			+ " - " + t("models.collections.enums.sex."+s.sex)
			+ " - " + s.county + "/" + s.stateProvince;
    
		return generalInfo;
    }

	const buildLabelId = (i) => {
		return (i.organismId) ? LaboratoryTestConfig.DefaultLabelIdPrefix + i.organismId : "";
	}

/*	const buildSpecimenInfo = (s) => {
       
		let specimenInfo = s.scientificName + " (" + t("models.collections.enums.sex."+s.sex) + ")";
    
		return specimenInfo;
    }

	const buildLocationInfo = (s) => {
	       
		let locationInfo = s.county + "/" + s.stateProvince;
    
		return locationInfo;
    }
*/

	const handleCloneOrganismIdChange = (e) => {
        
		setCloneOrganismId(e.target.checked);
		
        props.setFieldValue("labelId", (e.target.checked) ? buildLabelId(props.values) : "");
        
    }

	const handleCollectionSampleChange = (sample) => {
		
		if (!sample) {
			
			//props.setFieldValue("id", "");
			props.setFieldValue("collectionId", "");
			props.setFieldValue("materialSample", "");
        	props.setFieldValue("organismId", "");
			
			if (cloneOrganismId) {
				props.setFieldValue("labelId", "");
			}
			
			props.setFieldError("labInfo", "");
			
        	return;
        } 
		
		
		//props.setFieldValue("id", sample.laboratorySampleId);
		props.setFieldValue("collectionId", sample.collectionId);
		props.setFieldValue("materialSample", sample.materialSample);
        props.setFieldValue("organismId", sample.organismId);
		
		if (cloneOrganismId) {
			props.setFieldValue("labelId", buildLabelId(sample));
		}
		
		//Check for sample lab info
		if (!sample.labInfo) {
			props.setFieldError("labInfo", t(props.i18nPrefix+"form.labInfo.validation.notfound"));
			return;
		}
		
		//If we got here, then we can cleanup labInfo error
		props.setFieldError("labInfo", "");
		
    }

	return(
		<Fragment>
			<Row >
				
				{(props.origin === "storagebox-items" || props.origin === "laboratory-tests") ? 
					(props.action === "create") ?
				 	<FormGroup as={Col} controlId="formGridSample">
				    	<FormLabel><Trans i18nKey={props.i18nPrefix+"form.collection-sample-input-search.label"}>Select Extraction</Trans> *</FormLabel>
						<CollectionSampleInputSearch 
				    		i18nPrefix={props.i18nPrefix+"form."}
				    		onError={props.onError}
				    		isInvalid={!(props.errors.collectionId == null)}
				    		onChange={handleCollectionSampleChange}
				    	/>
						<FormControlErrors block={true} errors={props.errors.collectionId} />
						<FormControlErrors block={true} errors={props.errors.labInfo} />
				 	</FormGroup>
					:
				
					<FormGroup as={Col} controlId="formGridGeneralInfo">
				    	<FormLabel><Trans i18nKey={props.i18nPrefix+"form.generalInfo.label"}>General Info</Trans></FormLabel>
						<FormControl plaintext readOnly defaultValue={buildGeneralInfo(props.values)} />
						<FormControlErrors block={true} errors={props.errors.collectionId} /> 
					</FormGroup>
				
				: null
				}
				
			</Row>

			<Row>

				<FormGroup as={Col} md={6} controlId="formGridPCRType">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.pcrType.label"}>PCR Type</Trans> *</FormLabel>
					<FormControl as="select" name="pcrType" isInvalid={!(props.errors.pcrType == null)} value={props.values.pcrType} onChange={handlePCRTypeChange} >
		    			<option value="">{t(props.i18nPrefix+"form.pcrType.blank-option")}</option>
		    			{ LaboratoryTestConfig.PCRTypes.map(item =>
							<option key={item} className="text-uppercase" value={item}>{t("models.tests.enums.pcrType."+item)}</option>
		    			)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.pcrType} />
				</FormGroup>
						
			</Row>
			
			<Row>

				<FormGroup as={Col} controlId="formGridTargetVirus">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.targetVirusId.label"}>Target Virus</Trans> *</FormLabel>
					<FormControl as="select" name="targetVirusId" isInvalid={!(props.errors.targetVirusId == null)} value={props.values.targetVirusId} onChange={handleTargetVirusChange} >
		    			<option value="">{t(props.i18nPrefix+"form.targetVirusId.blank-option")}</option>
		    			{ targetViruses.map(item =>
		    				<option key={item.id} value={item.id+""}>{item.name}</option>
		    			)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.targetVirusId} />
				 </FormGroup>
			
				<FormGroup as={Col} controlId="formGridTestProtocol">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.protocolId.label"}>Laboratory Test Protocol</Trans> *</FormLabel>
					<FormControl as="select" name="protocolId" disabled={!(props.values.targetVirusId)} isInvalid={!(props.errors.protocolId == null)} value={props.values.protocolId} onChange={props.onChange} >
		    			<option value="">{t(props.i18nPrefix+"form.protocolId.blank-option")}</option>
		    			{ laboratoryTestProtocols.filter(item => (props.values.targetVirusId && item.targetVirusId === props.values.targetVirusId )) 
							.map(item =>
		    					<option key={item.id} value={item.id+""}>{item.name}</option>
		    				)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.protocolId} />
				 </FormGroup>
				
			</Row>
			
			<Row>
				
				<FormGroup as={Col} className="mb-0" controlId="formGridLabelId">
    				<FormLabel ><Trans i18nKey={props.i18nPrefix+"form.labelId.label"}>Label Id</Trans> *</FormLabel>
					<FormControl type={'text'} name="labelId" isInvalid={!(props.errors.labelId == null)} readOnly={cloneOrganismId} value={props.values.labelId} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.labelId.placeholder")} />
				    <FormControlErrors errors={props.errors.labelId} />
				</FormGroup>
				
				<FormGroup as={Col} className="mb-0" controlId="formGridWorkgroup">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.workgroupId.label"}>Workgroup</Trans> *</FormLabel>
					<FormControl as="select" name="workgroupId" disabled={(props.origin === "storagebox-items")} isInvalid={!(props.errors.workgroupId == null)} value={props.values.workgroupId} onChange={handleWorkgroupChange} >
		    			{(props.origin === "storagebox-items") ?
							workgroups.filter(item => (item.id == props.values.workgroupId )) 
		    					.map(item =>
		    						<option key={item.id} value={item.id+""}>{item.name + " (" + item.shortcode + ")"}</option>
		    					)
						: <Fragment>
							<option value="">{t(props.i18nPrefix+"form.workgroupId.blank-option")}</option>
		    				{ workgroups.map(item =>
		    					<option key={item.id} value={item.id+""}>{item.name + " (" + item.shortcode + ")"}</option>
		    				)}
						</Fragment>
						}
		    		</FormControl>
					<FormControlErrors errors={props.errors.workgroupId} />
				 </FormGroup>
			
			</Row>
			
			<Row className="mt-0">
				<FormGroup as={Col} className="align-self-center" controlId="formGridCloneOrganismId">
				    <FormCheck size="sm" type="checkbox" disabled={!(props.values.organismId)} onChange={handleCloneOrganismIdChange} checked={cloneOrganismId} label={t(props.i18nPrefix+"form.labelId.clone-checkbox")}/>
				 </FormGroup>
			</Row>
			
			<Row>

				<FormGroup as={Col} controlId="formGridDateTested">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.dateTested.label"}>Date Tested</Trans> *</FormLabel>
					<DatePicker
      					format="DD/MM/YYYY"
      					className={!(props.errors.dateTested == null) ? "is-invalid border border-danger rounded" : ""}
      					onChange={(e) => { 
							if (e.date) { 
      							if (e.date.isValid()) 
      								props.setFieldValue("dateTested", moment(e.date).format("YYYY-MM-DD"));
      							else
      								props.setFieldValue("dateTested", e.date.parsingFlags().inputDate);
      						} else if (e.date === null) { // reset if null (which is !== from undefined)
      							props.setFieldValue("dateTested", "");
      						}
      					}}
      					date={props.values.dateTested}
      					parseInputDate={parseInputDate}
      					locale={config.preferredLanguage}
      					keepInvalid={true}
    				/>
					<FormControlErrors errors={props.errors.dateTested} />
				</FormGroup>
				
				<FormGroup as={Col} controlId="formGridTestedById">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.testedById.label"}>Tested By</Trans> *</FormLabel>
					<FormControl as="select" disabled={!(props.values.workgroupId)} name="testedById" isInvalid={!(props.errors.testedById == null)} value={props.values.testedById} onChange={props.onChange} >
		    			<option value="">{t(props.i18nPrefix+"form.testedById.blank-option")}</option>
		    			{ workgroupUsers.map(item =>
		    					<option key={item.id} value={item.id}>{item.firstName+" "+item.lastName}</option>
		    			)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.testedById} />
				</FormGroup>
				
			</Row>
			
			<Row>
				
				<FormGroup as={Col} md={9} controlId="formGridStorageBox">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.storageBoxId.label"}>Storage Box</Trans></FormLabel>
					<StorageBoxInputSearch 
				    	i18nPrefix={props.i18nPrefix+"form."}
				    	onError={props.onError}
				    	isInvalid={!(props.errors.storageBoxId == null)}
				    	onChange={handleStorageBoxChange}
						onLoad={handleStorageBoxLoad}
						workgroupId={props.values.workgroupId}
						disabled={!(props.values.workgroupId)}
						//initialStorageBoxId={props.values.storageBoxId}
						storageBoxId={props.values.storageBoxId}
				    />
					<FormControlErrors errors={props.errors.storageBoxId} />
				</FormGroup>
				
				
				<FormGroup as={Col} controlId="formGridStorageBoxSlotPosition">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.storageBoxSlotPosition.label"}>Storage Box Slot Position</Trans></FormLabel>
					<FormControl as="select" disabled={!(props.values.storageBoxId)} name="storageBoxSlotPosition" isInvalid={!(props.errors.slotCol == null) || !(props.errors.slotRow == null)} value={props.values.slotRow+"-"+props.values.slotCol} onChange={handleStorageBoxSlotPositionChange} >
		    			<option value="">{t(props.i18nPrefix+"form.storageBoxSlotPosition.blank-option")}</option>
		    			{ availableStorageBoxSlots.map(item =>
								<option key={item.row+"-"+item.col} value={item.row+"-"+item.col}>{(item.row)+"-"+numToColumn(item.col)}</option>
		    			)}
		    		</FormControl>
					<FormControlErrors errors={(props.errors.slotCol) ? props.errors.slotCol : props.errors.slotRow} />
				</FormGroup>
			</Row>	
			
			<Row>    					   
				<FormGroup as={Col} md="auto">
					<Row>		
						<FormGroup className="mb-0" as={Col} controlId="formGridResult">
							<FormLabel><Trans i18nKey={props.i18nPrefix+"form.result.label"}>Result</Trans> *</FormLabel>
						</FormGroup>
					</Row>
					<Row>
						<Col className="pr-0">
							<ButtonToolbar >
								{ LaboratoryTestConfig.TestResults.map(item =>
									<ButtonGroup key={item} className="mr-3">
        								<Button
											disabled={!props.values.pcrType}
											variant={(props.values.result === item) ? ResultButtonVariantMap.get(item) : "outline-"+ResultButtonVariantMap.get(item)} 
											onClick={() => {setTestResultValue(item);}}
										>
											<FontAwesomeIcon icon={ResultButtonIconMap.get(item)} /> {t("models.tests.enums.result."+item)}
										</Button>
      								</ButtonGroup>
		    					)}
    						</ButtonToolbar>
							<FormControlErrors block={true} errors={props.errors.result} />
						</Col>
					</Row>
				</FormGroup>
				{ (props.values.result === "POSITIVE") ? 
					<Fragment>
					{(props.values.pcrType === "REALTIME") ? 
						<Col>
							<Row>		
								<FormGroup className="mb-0" as={Col} controlId="formCycleThresholdLabel">
									<FormLabel><Trans i18nKey={props.i18nPrefix+"form.cycleThreshold.label"}>CT</Trans> <FormControlHelper text={props.i18nPrefix+"form.cycleThreshold.helper"} /> *</FormLabel>
								</FormGroup>
							</Row>
							<Row>
								<FormGroup as={Col} controlId="formCycleThreshold">
									<FormControl type={'text'} name="cycleThreshold" isInvalid={!(props.errors.cycleThreshold == null)} value={props.values.cycleThreshold} onChange={props.onChange} />
									<FormControlErrors errors={props.errors.cycleThreshold} />
								</FormGroup>
							</Row>
						</Col>
						:
						<Fragment>
						{(props.values.pcrType === "CONVENTIONAL") ? 
						<Col>
							<Row>		
								<FormGroup className="mb-0" as={Col} controlId="formBandIntensityLabel">
									<FormLabel><Trans i18nKey={props.i18nPrefix+"form.bandIntensity.label"}>Band Intensity</Trans>  *</FormLabel>
								</FormGroup>
							</Row>
							<Row>
								<FormGroup as={Col} controlId="formBandIntensity">
									<FormControl as="select" name="bandIntensity" isInvalid={!(props.errors.bandIntensity == null)} value={props.values.bandIntensity} onChange={props.onChange} >
		    							<option value="">{t(props.i18nPrefix+"form.bandIntensity.blank-option")}</option>
		    							{ LaboratoryTestConfig.BandIntensities.map(item =>
											<option key={item} className="text-uppercase" value={item}>{t("models.tests.enums.bandIntensity."+item)}</option>
		    							)}
		    						</FormControl>
									<FormControlErrors errors={props.errors.bandIntensity} />
								</FormGroup>
							</Row>
						</Col>
						:
						null
						}
						</Fragment>
					}
					</Fragment>
					:
					null
				}
				
			</Row>
				
							
			{ (props.values.result === "POSITIVE") ? 
				<Row>
					<FormGroup as={Col} md={6} controlId="formGridSequenced">
	    				<FormLabel ><Trans i18nKey={props.i18nPrefix+"form.sequenced.label"}>Sequenced?</Trans></FormLabel>
	    				<Switch 
	    					name="sequenced"
	    					value={props.values.sequenced}
	    					checked={JSON.parse(props.values.sequenced)}
	    					onChange={handleSequencedChange}
	    				/>
						<FormControlErrors block={true} errors={props.errors.sequenced} />
	    			</FormGroup>
	       		</Row>
				: null
			}
			
 	    			
		
		</Fragment>
		
	) 
}


const SequencingSection = props => {
	
	const { t } = useTranslation();

	const setSequencingResultValue = (result) => {
        
		if (props.values.sequencingResult != result) {

        	props.setFieldValue("sequencingResult", result);

		}
      
    }

	const handleViralIsolationChange = (e) => {
		       
        props.setFieldValue("viralIsolation", !JSON.parse(e.target.value));

		//Reset Related Fields
		props.setFieldValue("viralIsolationRemarks", "");
      
    }

	return(
		<Fragment>
			<Row > 
				<FormGroup as={Col} md="auto" className="mb-0">
					<Row>    					   
	    				<FormGroup className="mb-0" as={Col} controlId="formGridSequencingResult">
							<FormLabel><Trans i18nKey={props.i18nPrefix+"form.sequencingResult.label"}>Sequencing Result</Trans> *</FormLabel>
						</FormGroup>
					</Row>
					<Row className="align-items-center mb-3">
						<Col className="pr-0">
							<ButtonToolbar >
							{ LaboratoryTestConfig.SequencingResults.map(item =>
								<ButtonGroup key={item} className="mr-3">
        							<Button
										variant={(props.values.sequencingResult === item) ? ResultButtonVariantMap.get(item) : "outline-"+ResultButtonVariantMap.get(item)} 
										onClick={() => {setSequencingResultValue(item);}}
									>
										<FontAwesomeIcon icon={ResultButtonIconMap.get(item)} /> {t("models.tests.enums.sequencingResult."+item)}
									</Button>
      							</ButtonGroup>
		    				)}
    						</ButtonToolbar>
							<FormControlErrors block={true} errors={props.errors.sequencingResult} />
						</Col>
					</Row>
				</FormGroup>
				<Col>
					<Row>
						<FormGroup as={Col} className="mb-0" controlId="formGridSequencingMethodLabel">
							<FormLabel><Trans i18nKey={props.i18nPrefix+"form.sequencingMethod.label"}>Sequencing Method</Trans> *</FormLabel>
						</FormGroup>
					</Row>
					<Row>
						<FormGroup as={Col} controlId="formGridSequencingMethod">
							<FormControl as="select" name="sequencingMethod" isInvalid={!(props.errors.sequencingMethod == null)} value={props.values.sequencingMethod} onChange={props.onChange} >
		    					<option value="">{t(props.i18nPrefix+"form.sequencingMethod.blank-option")}</option>
		    					{ LaboratoryTestConfig.SequencingMethods.map(item =>
									<option key={item} className="text-uppercase" value={item}>{t("models.tests.enums.sequencingMethod."+item)}</option>
		    					)}
		    				</FormControl>
							<FormControlErrors errors={props.errors.sequencingMethod} />
						</FormGroup>
					</Row>
				</Col>
			</Row>
			<Row>
				<FormGroup as={Col} controlId="formGridSequencingRemarks">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.sequencingRemarks.label"}>Sequencing Remarks</Trans></FormLabel>
					<FormControl className="text-uppercase" as="textarea" rows={3} maxLength={255} name="sequencingRemarks" isInvalid={!(props.errors.sequencingRemarks == null)} value={props.values.sequencingRemarks} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.sequencingRemarks.placeholder")} />
					<FormControlErrors errors={props.errors.sequencingRemarks} />
				</FormGroup> 
			</Row>
			<Row>
				<FormGroup as={Col} md={6} controlId="formGridViralIsolation">
	    			<FormLabel ><Trans i18nKey={props.i18nPrefix+"form.viralIsolation.label"}>Viral Isolation?</Trans></FormLabel>
	    				<Switch 
	    					name="viralIsolation"
	    					value={props.values.viralIsolation}
	    					checked={JSON.parse(props.values.viralIsolation)}
	    					onChange={handleViralIsolationChange}
	    				/>
					<FormControlErrors block={true} errors={props.errors.viralIsolation} />
	    		</FormGroup>
	       	</Row>
			<Row>
				<FormGroup as={Col} controlId="formGridViralIsolationRemarks">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.viralIsolationRemarks.label"}>Viral Isolation Remarks</Trans></FormLabel>
					<FormControl className="text-uppercase" as="textarea" disabled={!(props.values.viralIsolation)} rows={3} maxLength={255} name="viralIsolationRemarks" isInvalid={!(props.errors.viralIsolationRemarks == null)} value={props.values.viralIsolationRemarks} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.viralIsolationRemarks.placeholder")} />
					<FormControlErrors errors={props.errors.viralIsolationRemarks} />
				</FormGroup> 
			</Row>
			
		</Fragment>
		
	) 
}

const LaboratoryTestFormFields = { GeneralSection, SequencingSection };
		
export default LaboratoryTestFormFields;
