import React,  { useState, useEffect, Fragment } from 'react';
import { useLocation, useNavigate, useOutletContext} from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle} from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import Card from 'react-bootstrap/Card'

import Loader from "../components/Loader";
import SingleFilterForm from "../components/SingleFilterForm";
import DataPagination from "../components/DataPagination";

import API from "../services/backend-api";
import { useAuth } from "../services/use-auth";

import { isArrayWithLength } from "../helpers/commons";

import '../styles/StorageBoxes.css';


/*const thumbnail = {
    background: "#f8f8f8",
    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 15%)",
    cursor: "pointer"
}

const thumbTitle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
}*/

const thumbTitleDiscarded = {
    /*overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",*/
	textDecoration: "line-through"
}

/*const thumbSubTitle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
}

const thumbText = {
	fontSize: "80%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
}*/

const BoxThumbnail = props => {
	
	const { t } = useTranslation();
	
	if (!props.box)
		return null;
		
	
	return (
		<Card className="thumbnail">
  			<Card.Body onClick={() => props.onClick(props.box)}>
    			<Card.Title className="thumb-title" style={(!JSON.parse(props.box.discarded)) ? {} : thumbTitleDiscarded }>
					{props.box.labelId}
				</Card.Title>
    			<Card.Subtitle className="thumb-subtitle mb-2 text-danger">{t("storageboxes.list.cards.subtitle", {count: Number(props.box.totalItems)})}</Card.Subtitle>
    			<Card.Text className="thumb-text mb-1 text-muted">{(isArrayWithLength(props.box.tags)) ? props.box.tags.join(", ") : "-"}</Card.Text>
  			</Card.Body>
		</Card>
	)
}

const StorageBoxes = props => {

	const [workgroups, setWorkgroups] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	//const [isFindingBoxes, setIsFindingBoxes] = useState(false);
	const [selectedWorkgroupId, setSelectedWorkgroupId] = useState("");
	//const [storageBoxes, setStorageBoxes] = useState([]);
	const [pagedBoxes, setPagedBoxes] = useState(null);
	const [pageSize] = useState(8);
	
	const { t } = useTranslation();
	const auth = useAuth();
	
	const location = useLocation();
	
	let context = useOutletContext();
	
	let navigate = useNavigate();
		
	const pageStorageBoxes = (id, values) => 
		new Promise((resolve, reject) => {
	
			//Clear Error
 			context.onError(null);
	
			API.pageStorageBoxes(id, mergeQueryParams(pagedBoxes, values))
			.then(response => {
				setPagedBoxes(response);
			    resolve(response);
			}).catch(error => { 
				reject(error);
			});	
	   });

	const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
		  queryParams.pageSize = currentPage.pageSize;
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }

	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
			
		//Fetch Laboratory Workgroups
		API.findWorkgroups({"types": ["LABORATORY"]}, auth.isAdmin())
			.then(response => {
				if (isMounted) {
					setWorkgroups(response.list);
					
					if (location.item && location.item.workgroupId) {
						setSelectedWorkgroupId(location.item.workgroupId);
						
						pageStorageBoxes(location.item.workgroupId, {pageSize: pageSize})
							.catch(error => { 
		 						log.error("Error Loading Initial Boxes Paged List: ", error.message);
		 						context.onError(error);
							})
							
					} else {
						
						if (isArrayWithLength(response.list) && (response.list.length === 1)) {
						
							let labId = response.list[0].id;
						
							setSelectedWorkgroupId(labId);
					
							pageStorageBoxes(labId, {pageSize: pageSize})
								.catch(error => { 
		 							log.error("Error Loading Initial Boxes Paged List: ", error.message);
		 							context.onError(error);
								})
						}
					}
				}
			})
			.catch(error => { 
				log.error("Error Loading Analysis Workgroups: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted)
					setIsLoading(false);
			});
		
	}, []);

	
  if (isLoading) 
		return <Loader />	
	
  const handleWorkgroupChange = (e) => {
	
	let workgroupId = e.target.value;
	
	setSelectedWorkgroupId(workgroupId);
	//setStorageBoxes([]);
	setPagedBoxes(null);
	
	if (workgroupId)
		pageStorageBoxes(workgroupId, {pageSize: pageSize})
			.catch(error => { 
		 		log.error("Error Loading Boxes Paged List: ", error.message);
		 		context.onError(error);
			})
  }
	
  const updateStorageBox = (box) => {
	  navigate("/analysis/boxes/update", { state: { item: box}});
  }
  
  const createStorageBox = (workgroupId) => {
	navigate("/analysis/boxes/create", { state: { item: {"workgroupId": workgroupId}}});
  }

  const updatePage = (values) => {
	  return pageStorageBoxes(selectedWorkgroupId, values)
	  .catch(error => { 
		 	log.error("Error Updating Boxes Paged List: ", error.message);
		 	context.onError(error);
		})
  };

  const filterStorageBoxes = (values) => {
	  return pageStorageBoxes(selectedWorkgroupId, values)
		.catch(error => { 
		 	log.error("Error Filtering Boxes Paged List: ", error.message);
		 	context.onError(error);
		})
  };
		
	return (
		<Container >

			<Row className="mb-3">
			
        		<Col>
        			<h3>
						{t("storageboxes.list.header")}
					</h3>
        		</Col>
  
        	</Row>

			<Row className="mb-3">
				<FormGroup as={Col} sm={7} xl={8} controlId="formGridWorkgroup">
					<FormLabel><Trans i18nKey="storageboxes.list.form.workgroupId.label">Workgroup</Trans> *</FormLabel>
					<FormControl as="select" name="workgroupId" value={selectedWorkgroupId} onChange={handleWorkgroupChange} >
		    			<option value="">{t("storageboxes.list.form.workgroupId.blank-option")}</option>
		    			{ workgroups.map(item =>
		    				<option key={item.id} value={item.id+""}>{item.name+" ("+item.shortcode+")"}</option>
		    			)}
		    		</FormControl>
				</FormGroup>    			
				
    		</Row>

			{ (!selectedWorkgroupId || !pagedBoxes) ? null :
			<Fragment>
				<Row className="mb-3">
    				<Col sm={7} xl={8}>
						<SingleFilterForm
							i18nPrefix={"storageboxes.list.form."}
							parameters={{ filter: pagedBoxes.filter, filteredColumn: pagedBoxes.filteredColumn }}
    			    		onSubmit={filterStorageBoxes}
    			    		onError={context.onError}
						/>
    				</Col>
					<Col sm={5} xl={4}>
						<div className="d-flex justify-content-end">
							<Button variant="outline-success" disabled={false} onClick={() => {createStorageBox(selectedWorkgroupId);}}><FontAwesomeIcon icon={faPlusCircle} /> <Trans i18nKey="storageboxes.list.add-button" /></Button>	
						</div>
					</Col>
    			</Row>

				<Row>
        			
					{  !(isArrayWithLength(pagedBoxes.list)) ?
							<Col><span className="font-italic"><Trans i18nKey="storageboxes.list.no-items"></Trans></span></Col>
						:
							<Fragment>
							{ pagedBoxes.list.map((box, index) => 
								<Col key={index} md={4} className="mb-3">
									<BoxThumbnail 
										box={box} 
										onClick={updateStorageBox}/>
								</Col>
							)}
							</Fragment>
					}

        		</Row>

					{(pagedBoxes.totalPageCount > 1) ?
						<Row>
        					<Col>
	        					<DataPagination className="justify-content-end"
	        						totalCount={pagedBoxes.totalCount}
        							pageIndex={pagedBoxes.pageIndex}
        							pageSize={pagedBoxes.pageSize}
        							totalPageCount={pagedBoxes.totalPageCount}
        							hasPrev={pagedBoxes.hasPrev}
        							hasNext={pagedBoxes.hasNext}
        							updatePage={updatePage} />
        					</Col>

        				</Row>
						: null
					}

			</Fragment>
		  }

      </Container>
	);
}

export default StorageBoxes;
