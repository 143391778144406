import React,  { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation, Route, Navigate, useOutletContext } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

import Loader from "../components/Loader";
import DataTable from "../components/DataTable";
import AddWorkgroupUserModal from "../components/AddWorkgroupUserModal";
import ConfirmationDialogModal from "../components/ConfirmationDialogModal";

import API from "../services/backend-api";
import { useAuth } from "../services/use-auth";
import { useConfig } from "../services/use-config";

const WorkgroupUsers = props => {

	const [isLoading, setIsLoading] = useState(true);
	const [workgroupUsers, setWorkgroupUsers] = useState(null);
	const [addUserModalShow, setAddUserModalShow] = useState(false);
	const [removeUserModalShow, setRemoveUserModalShow] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	
	const { t } = useTranslation();
	const auth = useAuth();
	const config = useConfig();
	
	const location = useLocation();
	let context = useOutletContext();
	let navigate = useNavigate();
		
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
		
		if (location.state && location.state.item) {
			listWorkgroupUsers(location.state.item.id)
				.catch(error => { 
					log.error("Error Loading Initial Workgroup Users List: ", error.message);
					context.onError(error);
				})
		 		.finally(() => {
		 			if (isMounted) setIsLoading(false);
		 		});
		 
			return () => { isMounted = false };
		}
		
	}, []);

  const onUserAdded = (success) => {
	  setAddUserModalShow(false);
	  context.onSuccess(success);
	  listWorkgroupUsers(location.state.item.id);
  }
  
  const confirmWorkgroupUserRemoval = (user) => {
	  setSelectedUser(user);
	  setRemoveUserModalShow(true);
  }
  
  const displayInactive = (item) => { 
	return (item.active) ? "" : "inactive-row"	
  }
		     
  const removeWorkgroupUser = (user) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		setRemoveUserModalShow(false);
		
		API.removeWorkgroupUser(location.state.item.id , {"userId": user.id}, config.csrfToken ).then(response => {
			context.onSuccess(response.success)
			listWorkgroupUsers(location.state.item.id);
		}).catch(error => { 	
			log.error("Error Removing Workgroup User: ", error.message);
			context.onError(error);		
		});
		
  }
	
  const listWorkgroupUsers = (id) =>
	new Promise((resolve, reject) => {
		API.getWorkgroupUsers(id, auth.isAdmin()).then(response => {
			setWorkgroupUsers(response.list);
			resolve(response);
		}).catch(error => { 
			reject(error);
		});
	});
	
	if (!location.state || !location.state.item) {
		return (<Navigate  to="/workgroups" />)
	}
	
	if (isLoading) 
		return <Loader />
	
	if (!workgroupUsers) 
		return null;
		
	return (
		<Container >
			<AddWorkgroupUserModal
				show={addUserModalShow}
	        	onHide={() => setAddUserModalShow(false)}
				size="lg"
				workgroup={location.state.item}
				onUserAdded={onUserAdded}
		      />
			{(selectedUser) && <ConfirmationDialogModal
				item={selectedUser}
				show={removeUserModalShow}
        		onHide={() => setRemoveUserModalShow(false)}
				size="lg"
				title={t("workgroups.remove-user-confirmation-modal.title")}
				bodyText={t("workgroups.remove-user-confirmation-modal.body", {user: selectedUser, workgroup: location.state.item })}
				confirmText={t("workgroups.remove-user-confirmation-modal.confirm")}
				cancelText={t("workgroups.remove-user-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={removeWorkgroupUser}
			/>}
			<Row className="mb-3">
			
        		<Col>
        			<h3><Trans i18nKey="workgroups.users.header" values={{name: location.state.item.name}} /></h3>
        		</Col>
  
        	</Row>
        	<Row className="mb-3">
    			<Col sm={6} xl={7}>
    			<h4>{t("workgroups.users.header-count", {count: workgroupUsers.length})}</h4>
    			</Col>
    			<Col sm={6} xl={5}>
				<div className="d-flex justify-content-end">
					<Button variant="outline-success" onClick={() => setAddUserModalShow(true)}><FontAwesomeIcon icon={faUserPlus} /> <Trans i18nKey="workgroups.users.add-button" /></Button>
				</div>
			</Col>
    		</Row>
        	<Row>
        		<Col>
        			<DataTable 
        				items={workgroupUsers} 
        				i18nPrefix="workgroups.users.datatable." 
        				columns={["firstName", "lastName", "email"]} 
        				actions={[confirmWorkgroupUserRemoval]}
        				customRowStyle={displayInactive}
        			/>
        		</Col>
        	</Row>
		    <Row>    					   
				<Col>
					<Button variant="secondary" className="float-right"  onClick={() => navigate("/workgroups/update", { state: { "item": location.state.item}})} ><FontAwesomeIcon icon={faArrowCircleLeft} /> <Trans i18nKey="workgroups.users.back">Back</Trans></Button>
				</Col>
			</Row>  
      </Container>
	);
}

export default WorkgroupUsers;
