import React,  { Fragment, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation, Route, Navigate, useOutletContext  } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimesCircle, faCheckCircle, faChevronRight, faChevronLeft, faEdit } from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import CollectionFormFields from "../components/CollectionFormFields";
import CollectionOrganismIdModal from "../components/CollectionOrganismIdModal";
import SubjectActivities from "../components/SubjectActivities";
import Prompt from "../components/Prompt";

import Loader from "../components/Loader";
import CustomStepper from "../components/CustomStepper";
import Stepper from 'bs-stepper'

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";
import { useAuth } from "../services/use-auth";

import CollectionConfig from "../config/CollectionConfig";

import { ValidationError } from "../helpers/custom-errors";
import { compareJSON } from "../helpers/commons";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
});

const linearValidationRules = [
	CollectionConfig.GeneralFormValidationRules,
	CollectionConfig.TaxonomyFormValidationRules,
	CollectionConfig.HistoryFormValidationRules,
	CollectionConfig.PhysicalExamFormValidationRules,
	CollectionConfig.SamplesFormValidationRules,
	CollectionConfig.PhotosFormValidationRules,
	CollectionConfig.ExtraFormValidationRules
]

const completeValidationRules = {
	...Object.assign({}, ...linearValidationRules)
}

const linearValidationSchema = linearValidationRules.map((rules) => Yup.object().shape(rules));

const completeValidationSchema = Yup.object().shape(completeValidationRules);

const saveCollectionSteps = [
	  {
        key: 'general',
        component: CollectionFormFields.GeneralSection,
      }, 
      {
        key: 'taxonomy',
        component: CollectionFormFields.TaxonomySection,
      },
      {
        key: 'history',
        component: CollectionFormFields.HistorySection,
      },
      {
        key: 'physical-exam',
        component: CollectionFormFields.PhysicalExamSection,
      },
      {
        key: 'samples',
        component: CollectionFormFields.SamplesSection,
      },
      {
        key: 'photos',
        component: CollectionFormFields.PhotosSection,
      },
      {
        key: 'extra',
        component: CollectionFormFields.ExtraSection,
      },
    ];

const SaveCollection = props => {

	const [currentStep, setCurrentStep] = useState(0);
	const [collection, setCollection] = useState(null);
	const [stepper, setStepper] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	//const [googleMapsScriptLoaded, setGoogleMapsScriptLoaded] = useState(false);
	const [collectionOrganismIdModalShow, setCollectionOrganismIdModalShow] = useState(false);
	
	const steps = saveCollectionSteps;
  	const isLastStep = (currentStep === (steps.length - 1));
	
	let navigate = useNavigate();
	
	const auth = useAuth();
	
	const config = useConfig();
	
	const location = useLocation();
	
	let context = useOutletContext();
	
	const { t } = useTranslation();
	
	const initializeStepper = () => {
		var stepperEl = document.querySelector("#collection-stepper");
		
		if (stepperEl) {
			setStepper(new Stepper(stepperEl,
				{
					linear: (location.pathname === '/collections/create'),
			    	animation: true
				}
			));
		
			stepperEl.addEventListener('show.bs-stepper', function (event) {
  				// event.preventDefault() can be used to prevent the display of a step
  				setCurrentStep(event.detail.indexStep);
			})
		}
    }
	
	/*const defaultFormFieldValue = (fieldName) => {
	
		switch (fieldName) {
			case "samples":
			case "photos":
				return [];
				break;
			case "eventDate":
				return moment(new Date()).format("YYYY-MM-DD");
				break;
			default:
				return "";
		}
     }*/
	
	useEffect(() => {
		let isMounted = true; 
		
		/*if (location.state && location.state.success)
			context.onSuccess(location.state.success)
		*/ 
			
		if (location.pathname === '/collections/update' && location.state && location.state.item) {
			API.lookupCollection(location.state.item.id, auth.isAdmin())
			.then(response => {
					setCollection(response);
			})
			.catch(error => { 
				log.error("Error Loading Collection: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) {
		 			setIsLoading(false);
		 			//initializeStepper();
		 		}
			});
		} else {
			if (location.pathname === '/collections/clone' && location.state && location.state.item) {
				if (isMounted) {
					setCollection(location.state.item);
				}
			}
			
			if (isMounted) {
	 			setIsLoading(false);
	 			//initializeStepper();
	 		}
		}
		
		//Script for locality search
		/*loadScript(
    		"googleapis_maps",
      		"https://maps.googleapis.com/maps/api/js?key="+config.googleAPIKey+"&libraries=places",
      		() => { 
      			if (isMounted) { setGoogleMapsScriptLoaded(true); }
      		}
    	);*/
		 
		 return () => { isMounted = false };
		  
	}, []);
	
	
	if (location.pathname === '/collections/update') {
		if (!location.state.item) {
			return (<Navigate  to="/collections" />)
		} else if (isLoading) {
			return <Loader />
		} else if (!collection) {
			return null;
		}
	} else if (location.pathname === '/collections/clone') {
		if (!location.state.item) {
			return (<Navigate  to="/collections" />)
		} else if (isLoading) {
			return <Loader />
		} else if (!collection) {
			return null;
		}
	} else {
		if (isLoading) {
			return <Loader />
		}
	}
	
	let initialValues = {}, action, actionIcon, currentValidationSchema;
	
	
	
	let totalValidatationRules = Object.assign({}, completeValidationRules, CollectionConfig.OrganismIdValidationRules);
	
	let defaultValues = Yup.object().shape(totalValidatationRules).default();
	
	
	if (location.pathname === '/collections/update') {
		Object.keys(totalValidatationRules)
			.forEach((key) => {
				initialValues[key] = (collection[key]) ? collection[key] : defaultValues[key];
			});
		action = "update";
		actionIcon = faCheckCircle;
		currentValidationSchema = completeValidationSchema;
	} else if (location.pathname === '/collections/clone') {
		//Cloned values if any		
		Object.keys(totalValidatationRules)
		.forEach((key) => {
			//initialValues[key] = (location.state.item[key]) ? location.state.item[key] : ["samples", "photos"].includes(key) ? [] : "";
			initialValues[key] = (collection[key]) ? collection[key] : defaultValues[key]; 
		});
		action = "clone";
		actionIcon = faPlusCircle; //faClone
		currentValidationSchema = completeValidationSchema;
	} 
	else {
		initialValues = defaultValues;
		
		action = "create";
		actionIcon = faPlusCircle;
		currentValidationSchema = linearValidationSchema[currentStep];
	}
	
	const saveCollection = (values) =>
		new Promise((resolve, reject) => {
			if (location.pathname === '/collections/update') {
				API.updateCollection(location.state.item.id, values, auth.isAdmin(), config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			} else {
				API.createCollection(values, auth.isAdmin(), config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			}
		});

    const hasStepErrors = (indexStep, errors) => {	
		
		return (Object.keys(linearValidationRules[indexStep])
			.find((key) => !(errors[key] == null)))
    }
    
    const handleNextStep = (values) => {
		 if (isLastStep && !(values.organismId)) {
			 setCollectionOrganismIdModalShow(true);
		 } else {
			 setCurrentStep(currentStep + 1);
			 stepper.next();
		 }
    }
    
    const handleStepBack = () => {
		 setCurrentStep(currentStep - 1);
		 stepper.previous();
    }
    
    /*const handleMoveToStep = (step) => {
		 setCurrentStep(step);
		 stepper.to(step);
    }*/
  
	return (
		<Container>
		<Formik
    		initialValues={initialValues}
    		validationSchema={currentValidationSchema}
    		validateOnChange={false}
    		validateOnBlur={false}     
    		onSubmit={(values, actions) => {
    			//Clear Error/Success
    			context.onError(null);
    			context.onSuccess(null);
    										
    			if (!values.organismId)	{
    				actions.setSubmitting(false);
    				setCollectionOrganismIdModalShow(true);
    			
    			} else {
    													
    			  saveCollection(values)
    				.then((response) => {
    					actions.resetForm({ values }) //Workaround to set dirty back to true
    					navigate("/collections", {state: { success: t("collections.save.success")}});
    				})
    				.catch(error => { 
    								
    					actions.setSubmitting(false);
    								
    					if (error instanceof ValidationError) {		
    									
    						log.info("Save Collection Attempt Failed: ", error.message);

    						if (error.detail) {            						
    	              			for (let key of Object.keys(error.detail)) {
    	              				let errorMsgs = error.detail[key];
    	              				errorMsgs.forEach((message) => {
    	              					actions.setFieldError(key, message);
    	              				});
    	              				
    	              				if (key === "organismId")
    	              					setCollectionOrganismIdModalShow(true)
    	              			}
    	              		}
    					} else {
    						log.error("Error Saving Collection: ", error.message);
    					}
    								
    					context.onError(error);
    								
    				})
    			   }
    				
				}}
		      	>
		      	{({isSubmitting, dirty, errors, values, handleChange, setFieldValue, validateForm, setFieldError, validateField}) => (	
		      	
		      	<Fragment>
		    		<Row>
		            	<Col>
		            		<h3 className={!(errors.organismId == null) ? "text-danger" : ""}>
		            			<Trans i18nKey={"collections.save.header-"+action} values={{organismId: values.organismId}}>Save Collection</Trans> 
		            			{(values.organismId) && (
		            				<Button variant="link" onClick={() => setCollectionOrganismIdModalShow(true)} > 
		            					<FontAwesomeIcon icon={faEdit} size="lg" className="align-bottom" />
		        					</Button>
		        				)}
		            		</h3>
		            	</Col>
		      
		            </Row>
		            <Row className="mb-3">
		        		<Col>
		        			<Form className="form-collection" noValidate>
		        				<Prompt
		        					when={!!dirty && !isSubmitting}
		        					message={JSON.stringify({
		        							"title": t("form.unsaved-changes-modal.title"),
		        							"bodyText": t("form.unsaved-changes-modal.body"),
		        							"confirmText": t("form.unsaved-changes-modal.confirm"), 
		        							"cancelText": t("form.unsaved-changes-modal.cancel"),
		        							"size": "lg",
		        							"variant": "danger",
		        							
		        							"when": compareJSON(initialValues, values)
		        						})
		        					}
		        				/>

		        				{(collectionOrganismIdModalShow) && (
		        					<CollectionOrganismIdModal
		        						show={collectionOrganismIdModalShow}
		        						onHide={() => {setCollectionOrganismIdModalShow(false);}}
		        						onChange={(organismId) => { setFieldValue("organismId", organismId); setCollectionOrganismIdModalShow(false); }}
		        						size="lg"
		        						collection={values}
		        						errors={errors}
		        					/>
		        				)}		
		      		
						<CustomStepper.Container id="collection-stepper" onMounted={initializeStepper}>
						
							<CustomStepper.Header className="mb-1">
							
								{ steps.map((step, index) => {
									return (
		    							<Fragment key={index}>
											{(index > 0) && <CustomStepper.HeaderLine /> }
											<CustomStepper.HeaderItem 
												number={index+1}
												label={t("collections.save.steps."+step.key)}
												target={"#collection-stepper-content-"+index}
												isInvalid={hasStepErrors(index, errors)}
											/>
										</Fragment>
	      							)
	      						})}
							
							</CustomStepper.Header>
						
						<Row className="align-items-center">
						<Col md="auto">
							<OverlayTrigger
      							overlay={
        							<Tooltip>
          								<Trans i18nKey="collections.save.form.submit-previous">Previous</Trans>
        							</Tooltip>
      							}
    						>
								<Button onClick={handleStepBack} variant="link" disabled={currentStep === 0}>
                      				<FontAwesomeIcon icon={faChevronLeft} size="3x" />
                    			</Button>
							</OverlayTrigger>
						</Col>
						
						<Col>
						
						<CustomStepper.Content className="p-0">
							{ steps.map((step, index) => {
								
								const { 
		    						component: Component, 
		    						...rest 
		    					} = step;
								
								return (
		    						<Fragment key={index}>	
										<CustomStepper.ContentItem 
											key={index}
											id={"collection-stepper-content-"+index}
										>
											<Component 
												values={values} 
												errors={errors} 
												onChange={handleChange} 
												onError={context.onError}
												setFieldValue={setFieldValue}
												setFieldError={setFieldError}
												validateField={validateField}
												//googleMapsScriptLoaded={googleMapsScriptLoaded}
											/>
										</CustomStepper.ContentItem>
									</Fragment>
	      						)
    						})}
						
						</CustomStepper.Content>
						</Col>
						<Col md="auto">
							<OverlayTrigger
      							overlay={
        							<Tooltip>
          								<Trans i18nKey="collections.save.form.submit-next">Next</Trans>
        							</Tooltip>
      							}
    						>
							<Button onClick={() => { 
								validateForm().then((e) => {
									if (!hasStepErrors(currentStep, e)) handleNextStep(values)
								});}}
								variant="link" className="float-right" disabled={isLastStep && (values.organismId)} >
                      				<FontAwesomeIcon icon={faChevronRight} size="3x" />
                    		</Button>
							</OverlayTrigger>
						</Col>
						</Row>
						
						</CustomStepper.Container>
						
					   <Row className="mt-3">    					   
    						<Col>  
    							<Button variant="success" type="submit" disabled={isSubmitting||((!isLastStep) && location.pathname === '/collections/create')} > 
    								{isSubmitting ? <Trans i18nKey="collections.save.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={actionIcon} /> <Trans i18nKey={"collections.save.form.submit-"+action}>Submit</Trans></span>} 
    							</Button>
    							
    							<Button as={Link} variant="secondary" className="float-right" to={"/collections"}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="collections.save.cancel">Cancel</Trans></Button>
    					   </Col>
    			       </Row>   
    			    </Form>	
		      	 </Col>
			     </Row>
			  </Fragment>
		      )}
		 </Formik>
		 
		 {(location.pathname === '/collections/update') && (
		 	<SubjectActivities 
		 		subject="COLLECTION" 
		 		subjectId={location.state.item.id} 
		 		onError={context.onError}
		 	/>
		 )}
		 </Container>
	);
}

export default SaveCollection;
