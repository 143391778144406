import * as Yup from "yup";

const Types = [
	"OBSERVATORY",
	"LABORATORY",
	"ADMINISTRATION",
	"OTHER"
]

const Regions = [
	"NORTE",
	"NORDESTE",
	"CENTRO_OESTE",
	"SUDESTE",
	"SUL"
]

const FormValidationRules = {
	name: Yup.string()
   		.max(100, 'workgroups.save.form.name.validation.maximumlength')
   		.required()
   		.default(() => ""),
   headquarters: Yup.string()
   		.max(100, 'workgroups.save.form.headquarters.validation.maximumlength')
   		.required()
   		.default(() => ""),
   coordinatorId: Yup.string()
		.required()
		.default(() => ""),
   shortcode: Yup.string()
 	   	.matches("^[a-zA-Z]{2}\\x2D[0-9]{1}$", 'workgroups.save.form.shortcode.validation.format')
 	   	.required()
 	   	.default(() => ""),
   types: Yup.array()
		.min(1, 'workgroups.save.form.types.validation.required')
		.default(() => []),
   region: Yup.string()
   		.oneOf([""].concat(Regions), 'form.validation.string.enum')
   		.required()
   		.default(() => ""),
   active: Yup.boolean()
   		.default(() => true),
}

const WorkgroupConfig = { Types, Regions, FormValidationRules };

export default WorkgroupConfig;