import React, { useState, useEffect} from 'react';
//import PropTypes from 'prop-types';  
import { useLocation, } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCheckCircle, faPlusCircle} from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import DismissableFeedback from "../components/DismissableFeedback";
import Loader from "../components/Loader";
import TissueDissociationFormFields from "../components/TissueDissociationFormFields";
import SubjectActivities from "../components/SubjectActivities";
import Prompt from "../components/Prompt";

import { useConfig } from "../services/use-config";
import API from "../services/backend-api";

import TissueDissociationConfig from "../config/TissueDissociationConfig";

import { compareJSON } from "../helpers/commons";
import { AuthError, ValidationError } from "../helpers/custom-errors";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const validationSchema = Yup.object().shape(TissueDissociationConfig.GeneralFormValidationRules);

const SaveTissueDissociationModal = props => {

	const [_error, _setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [dissociation, setDissociation] = useState(null);
	
	const { t } = useTranslation();
	
	const location = useLocation();
	const config = useConfig();

	useEffect(() => {
		let isMounted = true; 
		
		if (props.item && props.item.id) {
			
			API.lookupLaboratoryDissociation(props.item.id)
				.then(response => {
					setDissociation(response);
				})
				.catch(error => { 
					log.error("Error Loading Laboratory Dissociation: ", error.message);
		 			props.onError(error);
				})
		 		.finally(() => {
		 			if (isMounted) {
		 				setIsLoading(false);
		 			}
				}); 
		} else {
			if (isMounted) {
		 		setIsLoading(false);
		 	}
		}
		 
		return () => { isMounted = false };
		  
	}, []);

	
	if  (!props.item) {
		return null
	} else if (props.item.id) {
		if (!isLoading && !dissociation) {
			return null;
		}
	}	
			
	let initialValues = {}, action, actionIcon;
	
	let defaultValues = validationSchema.default();
	
	//Define Modal Origin
	/*let origin = (location.pathname === '/analysis/boxes/items') ? "storagebox-items" 
		: (location.pathname === '/analysis/laboratorysamples/update') ? "laboratory-samples"
		: (location.pathname === '/analysis/collectionsamples') ? "collection-samples"
		: null;
	*/
	
	//if dissociation != null, then we have an update
	if (dissociation) {
		Object.keys(TissueDissociationConfig.GeneralFormValidationRules)
			.forEach((key) => {
				initialValues[key] = (dissociation[key]) ? dissociation[key] : defaultValues[key];
			});
		
		action = "update";
		actionIcon = faCheckCircle
		
		//TODO: Check for consistency depending on Origin
		switch (props.origin) {
  			case "storagebox-items":
			case "laboratory-samples":
			case "collection-samples":
			case "laboratory-dissociations":
			default:
				break;
		}

	
	} else {	
		initialValues = defaultValues;
		action = "create";
		actionIcon = faPlusCircle
		
		//Override with values from props depending on Origin
		switch (props.origin) {
  			case "storagebox-items":
				initialValues.workgroupId = props.item.workgroupId;
				initialValues.storageBoxId = props.item.storageBoxId;
				initialValues.slotCol = props.item.slotCol;
				initialValues.slotRow = props.item.slotRow;
				break;
			case "laboratory-samples":
				initialValues.collectionId = props.item.collectionId;
				initialValues.materialSample = props.item.materialSample;
				initialValues.organismId = props.item.organismId;
				initialValues.workgroupId = props.item.workgroupId;
				break;
			case "collection-samples":
				initialValues.collectionId = props.item.collectionId;
				initialValues.materialSample = props.item.materialSample;
				initialValues.organismId = props.item.organismId;
				//Atention: Laboratory Id comes from a different field
				initialValues.workgroupId = props.item.laboratoryId;
				break;
			case "laboratory-dissociations":
			default:
				break;
		}

	}
	
	const saveLaboratoryDissociation = (values) =>
		new Promise((resolve, reject) => {
			if (values.id) {
				API.updateLaboratoryDissociation(values.id, values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			} else {
				API.createLaboratoryDissociation(values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			}
		});
	
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-save-laboratory-dissociation-modal"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-save-laboratory-dissociation-modal">
  					<Trans i18nKey="laboratorydissociations.save-dissociation-modal.title">Save Laboratory Dissociation</Trans>
  				</Modal.Title>
  			</Modal.Header>
  			{(isLoading) ? (
				<Modal.Body className="mb-3"><Loader /></Modal.Body>
			) : (
  				<Formik
		      		initialValues={initialValues}
		      		validationSchema={validationSchema}
		      		validateOnChange={false}
		      		validateOnBlur={false}     
		      		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);

						saveLaboratoryDissociation(values)
    						.then((response) => {
    							actions.resetForm({ values }) //Workaround to set dirty back to true
								actions.setSubmitting(false);
								props.onItemSaved(t('laboratorydissociations.save-dissociation-modal.success'));
    						})
    						.catch(error => { 
    								
    							actions.setSubmitting(false);
								
								if (error instanceof AuthError) {		
		    	  					//auth.onUnathorized(error);
									props.onError(error);
		     					 } else if (error instanceof ValidationError) {		
          						
				 					log.info("Save Laboratory Dissociation Attempt Failed: ", error.message);
          						
				 					_setError(error.message);		
								
									if (error.detail) {            						
    	              					for (let key of Object.keys(error.detail)) {
    	              						let errorMsgs = error.detail[key];
    	              						errorMsgs.forEach((message) => {
    	              							actions.setFieldError(key, message);
    	              						});
    	              					}
    	              				}
          						
          	 					} else {
          							log.error("Error Saving Laboratory Dissociation: ", error.message);
          							_setError(t('error.api.general'));
          	 					}	
    								
    						})
    			   		}
      					      			
					}
		      	>
		      	{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue, setFieldError, dirty}) => (	
		      		
		      		<Form className="form-save-laboratory-dissociation-modal" noValidate>		
		      			<Modal.Body className="pt-2">
		  				
		  					<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  

  							<Prompt
		      					when={!!dirty && !isSubmitting}
		      					message={JSON.stringify({
		      		           			"title": t("form.unsaved-changes-modal.title"),
		      		           			"bodyText": t("form.unsaved-changes-modal.body"),
		      		          		 	"confirmText": t("form.unsaved-changes-modal.confirm"), 
		      		           			"cancelText": t("form.unsaved-changes-modal.cancel"),
		      		           			"size": "lg",
		      		           			"variant": "danger",
		        			   			"when": compareJSON(initialValues, values)
		      		          		})
		      		   			}
		      				/>

							<TissueDissociationFormFields 
								values={values} 
								errors={errors} 
								onChange={handleChange} 
								onError={props.onError}
								setFieldValue={setFieldValue}
								setFieldError={setFieldError}
								action={action}
								origin={props.origin}
								isModal={true}
								i18nPrefix="laboratorydissociations.save-dissociation-modal."
							/>
	
							{(action === "update") && (
		 						<SubjectActivities 
		 							subject="TISSUE_DISSOCIATION" 
		 							subjectId={props.item.id} 
		 							onError={props.onError}
		 						/>
		 					)}
	
						
          				</Modal.Body>
          				<Modal.Footer>			 
          				      <Button variant="success" type="submit" disabled={isSubmitting}> 
          				      		{isSubmitting ? <Trans i18nKey="laboratorydissociations.save-dissociation-modal.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={actionIcon} /> <Trans i18nKey={"laboratorydissociations.save-dissociation-modal.form.submit-"+action}>Submit</Trans></span>} 
          				      </Button>
          				      <Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="laboratorydissociations.save-dissociation-modal.cancel">Cancel</Trans></Button>	
          				</Modal.Footer>
              		</Form>	
				)}
		      </Formik>
			)}
		</Modal>
	);
}

export default SaveTissueDissociationModal;
