import React,{ useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/pt';
//import 'moment/locale/en';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'

import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import FormCheck from 'react-bootstrap/FormCheck';

import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import { isArrayWithLength } from "../helpers/commons";

import '../styles/DataTable.css';


const DataTableHeader = props => {
	const [selectedAll, setSelectedAll] = useState(false);
	
	var row = [];
	var columns = [];
	
	useEffect(() => {
		let isMounted = true; 
				
		if (isMounted) {
			setSelectedAll(false);
		}
		
		return () => { isMounted = false };
		  
	}, [props.items]);
	
	
	const handleMultipleSelectionChange = (e) => {
		setSelectedAll(e.target.checked);
		props.onMultipleSelectChange(e.target.checked);
    }
	
	if (isArrayWithLength(props.columns)) {
		if (isArrayWithLength(props.items)) {
			
			columns = props.columns.filter(column => {
				if (props.items[0].hasOwnProperty(column)) {
					return true;
				} else {
					log.warn("DataTableHeader: Column [", column,"] not found => Removed")
					return false;
				}
			})
			
		} else {
			columns = props.columns;
		}
	} else if (isArrayWithLength(props.items)) {
		for (var key in props.items[0]) {
			columns.push(key);
		}
	}

	row = columns.map((column, index) => {
		
		if ((!isArrayWithLength(props.items)) || (!isArrayWithLength(props.sortableColumns)) || (isArrayWithLength(props.sortableColumns) && props.sortableColumns.indexOf(column) === -1)) {
			return (<th scope="col" key={index}>{(props.i18nPrefix) ? <Trans i18nKey={props.i18nPrefix+"columns."+column} /> : column } </th>)
		} else {
			
			let className = "header";
			let sortBy = column;
			let order = props.order;

			if(props.sortBy === column) {
				 if(props.order === "asc") {
				      order = "desc";
				      className += " headerSortDown";
				 } else {
				      order = "asc";
				      className += " headerSortUp";
				 }
			} else {
				 order = "asc";
			}

			return (<th scope="col" className={className} key={index}  onClick={() => {props.sortDataTable({sortBy: sortBy, order: order });}}>
					{(props.i18nPrefix) ? <Trans i18nKey={props.i18nPrefix+"columns."+column} /> : column}
				</th>)
		}
		
	})
		
	if (isArrayWithLength(props.actions) && row.length !== 0) {
		row.push(<th key={"actions"}>
			{(props.i18nPrefix) ? <Trans i18nKey={props.i18nPrefix+"columns.actions"} /> : "actions"}
		</th>)
	}
	
	if (isArrayWithLength(props.multipleSelectActions) && row.length !== 0) {
		
		var actionItems = [];
		
		var selectedItems = props.items.filter((item, index) => props.selectedItems[index]);
		
		actionItems = props.multipleSelectActions.map((action, index) => 
			<Dropdown.Item key={index} onClick={() => {action(selectedItems, "multiple-action-"+index);}}><Trans i18nKey={props.i18nPrefix+"multiple-action-"+index} /></Dropdown.Item>
		)
		
		row.unshift(<th key={"multipleSelectActions"} className="align-top" >
			<FormCheck type="checkbox">
        		<FormCheck.Input type="checkbox" checked={selectedAll} onChange={handleMultipleSelectionChange} />
				{ (isArrayWithLength(selectedItems)) ?
					<Dropdown drop="left">
						<Dropdown.Toggle as={Button} className="p-0" bsPrefix="remove-split-button" variant="link" id="dropdown-actions" size="sm">
							<FontAwesomeIcon icon={faEllipsisV} />
						</Dropdown.Toggle>
		       			<Dropdown.Menu>
		       				{actionItems}
		       			</Dropdown.Menu>
		    		</Dropdown> : null
				}
			</FormCheck>
		</th>)
	}
	
	return row;
}

const DataTableRowItem = props => {

	const { t } = useTranslation();
	
	const renderTooltip = (text) => (
  		<Tooltip>
    		{t(text)}
  		</Tooltip>
	);
	
	let displayValue = Array.isArray(props.item) ? props.item.toString() : props.item;

	if (props.customDisplay) {
			
		switch (props.customDisplay) {
			 case 'ZonedDateTime':
				  displayValue = (props.item) ? <Moment local format="L LT">{props.item}</Moment> : "-";
			  	   break;
			 case 'LocalDate':
				  displayValue = (props.item) ? <Moment local format="L">{props.item}</Moment> : "-";
				   break;
			 case 'Enum':
				  displayValue = isArrayWithLength(props.item)
						? props.item.map(i => t(props.i18nPrefix+"enums."+props.column+"."+i)).join(', ')
						: (props.item) ? <Trans i18nKey={props.i18nPrefix+"enums."+props.column+"."+props.item}/> : "-";
				   break;
			 default:
				  break
			}
		}
		
		if (props.tooltipData) {
			return (
				<td style={props.tooltipTargetStyles} >
					<OverlayTrigger overlay={renderTooltip(props.tooltipData)}>
						<span title={t(props.tooltipData)}>{(displayValue) ? displayValue : "-"}</span>
					</OverlayTrigger>
				</td>
			);
		} else if (props.handleTextOverflow) {
				return (<td className="table-row-item-overflow">{displayValue}</td>);
		} else {
			return (<td>{displayValue}</td>);
		}
	
};

const DataTableRow = props => {
	var row = [];
	var columns = [];
	
	if (isArrayWithLength(props.columns)) {
		columns = props.columns.filter(column => {
			if (props.item.hasOwnProperty(column)) {
				return true;
			}
			
			return false;
		})
				
	} else {
		for (var key in props.item) {
			columns.push(key);
		}
	}
	
	let customDisplayColumnsMap = (isArrayWithLength(props.customDisplayColumns)) ? new Map(props.customDisplayColumns) : null;
		
	row = columns.map((column, index) => {
		
		return(<DataTableRowItem
	           		key={index}
					item={props.item[column]}
					tooltipData={( props.tooltipData && props.tooltipData[column]) ? props.tooltipData[column] : null}
					tooltipTargetStyles={props.tooltipTargetStyles}
	           		column={column} 
	           		customDisplay={(customDisplayColumnsMap && customDisplayColumnsMap.get(column)) ? customDisplayColumnsMap.get(column) : null}
	           		i18nPrefix={props.i18nPrefix} 
					handleTextOverflow={props.handleTextOverflow}
	    />);
			
	});
	
	if (isArrayWithLength(props.actions) && row.length !== 0) {
		
		var actionItems = [];
		
		/*for (var key2 in props.actions) {
			actionItems.push(<Dropdown.Item key={key2} onClick={() => {props.actions[key2](props.item);}}><Trans i18nKey={props.i18nPrefix+"action-"+key2} /></Dropdown.Item>); 
		}*/
		
		actionItems = props.actions.map((action, index) => 
			<Dropdown.Item key={index} onClick={() => {action(props.item,"action-"+index);}}><Trans i18nKey={props.i18nPrefix+"action-"+index} /></Dropdown.Item>
		)
		
		row.push(<td key={"actions"} >
			<Dropdown drop="left">
				<Dropdown.Toggle as={Button} bsPrefix="remove-split-button" variant="link" id="dropdown-actions" size="sm">
					<FontAwesomeIcon icon={faEllipsisV} />
				</Dropdown.Toggle>
		       <Dropdown.Menu>
		       		{actionItems}
		       </Dropdown.Menu>
		    </Dropdown>
		</td>
		)
	}
	
	if (isArrayWithLength(props.multipleSelectActions) && row.length !== 0) {
		row.unshift(<td key={"multipleSelectActions"}>
			<FormCheck checked={(!props.selected) ? false : props.selected} onChange={(e) => {props.onSelect(props.index, e.target.checked);}} />
		</td>)
	}
	
	return row;
}



const DataTable = props => {

	const [selectedItems, setSelectedItems] = useState([]);
	
	useEffect(() => {
		let isMounted = true; 
				
		if (isArrayWithLength(props.items) && isMounted) {
			setSelectedItems(Array.from({length: props.items.length}, (v, i) => false));
		}
		
		return () => { isMounted = false };
		  
	}, [props.items]);
	

	if (!isArrayWithLength(props.items)) {
		if (props.noItemsDisclaimer)
			return <Trans i18nKey={props.i18nPrefix+"no-items-disclaimer"} />
		else
			return null
	}
	
	const handleRowDoubleClick = (event, item) => {
		if (props.defaultAction && (event.detail === 2)) 
			props.defaultAction(item) 
    };
	
	const handleMultipleSelectionChange = (checked) => {	
		setSelectedItems(Array.from({length: props.items.length}, (v, i) => checked));
    }

	const handleSelection = (index, checked) => {
		
		//alert(index + " " + checked)
		
		let s = selectedItems.map((item, i) => {
				return (index !== i) ? item : checked;	
		});
		
		setSelectedItems(s);
        
    }
	
	const items = props.items.map((item, index) => {
	      return (
	        <tr key={index} onClick={(e) => {handleRowDoubleClick(e, item);}} className={(props.customRowStyle) ? props.customRowStyle(item) : ""}>
	           <DataTableRow 
	           		item={item}
					tooltipData={(isArrayWithLength(props.tooltipData) && props.tooltipData[index]) ? props.tooltipData[index] : null }
					tooltipTargetStyles={props.tooltipTargetStyles}
	           		columns={props.columns} 
	           		customDisplayColumns={props.customDisplayColumns}
	           		i18nPrefix={props.i18nPrefix} 
					handleTextOverflow={props.handleTextOverflow}
	           		actions={props.actions}
	           		defaultAction={props.defaultAction}
					multipleSelectActions={props.multipleSelectActions}
					onSelect={handleSelection}
					selected={selectedItems[index]}
					index={index}
	           	/>
	        </tr>
	        )
	      })
	
	return (
		<Table striped responsive hover className={isArrayWithLength(props.sortableColumns) ? "table-sortable" : ""}>
			<thead>
				<tr>
				 <DataTableHeader 
				 	items={props.items} 
				 	columns={props.columns} 
				 	sortableColumns={props.sortableColumns} 
				 	sortBy={props.sortBy} 
				 	order={props.order} 
				 	i18nPrefix={props.i18nPrefix} 
				 	actions={props.actions}
					multipleSelectActions={props.multipleSelectActions}
					onMultipleSelectChange={handleMultipleSelectionChange}
					selectedItems={selectedItems}
				 	sortDataTable={props.sortDataTable} />
				</tr>
			</thead>
			<tbody>
	          {items}
	        </tbody>
	     </Table>
	);
}

export default DataTable;
