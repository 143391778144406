import React from 'react';
import {Trans } from 'react-i18next';

import Pagination from 'react-bootstrap/Pagination'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import FormLabel from 'react-bootstrap/FormLabel'
import FormControl from 'react-bootstrap/FormControl'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'

import { isArrayWithLength } from "../helpers/commons";

const DataPagination = props => {
		
	if (props.totalCount === 0)
		return null;
	
	let pageFirstItem = props.pageIndex*props.pageSize + 1;
	let pageLastItem = Math.min((props.pageIndex+1)*props.pageSize, props.totalCount);
	
	return (
		<Row>
			<FormLabel column md="auto">
      			<Trans i18nKey="pagination.pageSize"/>
    		</FormLabel>
			<Col md="auto">
				<FormControl as="select" value={props.pageSize} disabled={(!isArrayWithLength(props.pageSizeOptions) || props.pageSizeOptions.length === 1 )} onChange={(e) => {props.updatePage({pageIndex: 0, pageSize: e.target.value});}}>
					{ (isArrayWithLength(props.pageSizeOptions) && props.pageSizeOptions.length > 1 ) ?
      					props.pageSizeOptions.map(item => <option key={item} value={item}>{item}</option>)
					: 
						<option value={props.pageSize}>{props.pageSize}</option>
	    		    }
				</FormControl>
			</Col>
        	<Col>
				<Pagination className="justify-content-end">
					<Pagination.Prev disabled={!props.hasPrev} onClick={() => {props.updatePage({pageIndex: props.pageIndex-1, pageSize: props.pageSize});}}><FontAwesomeIcon icon={faAngleLeft} /> <Trans i18nKey="pagination.previous"/></Pagination.Prev>
					<Pagination.Item active><Trans i18nKey="pagination.displaying" values={{firstItem: pageFirstItem, lastItem: pageLastItem, totalCount : props.totalCount}} /></Pagination.Item>
					<Pagination.Next disabled={!props.hasNext} onClick={() => {props.updatePage({pageIndex: props.pageIndex+1, pageSize: props.pageSize});}}><Trans i18nKey="pagination.next"/> <FontAwesomeIcon icon={faAngleRight} /></Pagination.Next>
				</Pagination>
			</Col>
        </Row>
	);
	
}

export default DataPagination;
