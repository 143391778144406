import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useOutletContext  } from 'react-router-dom';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';

import FormControlErrors from "../components/FormControlErrors";
import Loader from "../components/Loader";

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import moment from "moment";
import { saveAs } from 'file-saver';

import API from "../services/backend-api";
import { useAuth } from "../services/use-auth";

import { ValidationError } from "../helpers/custom-errors";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const validationSchema = Yup.object().shape({
	   workgroupId: Yup.string()
		 .required()
		 .default(() => ""),
	   sortBy: Yup.string()
		 .required()
		 .default(() => "organismId"),
	   order: Yup.string()
		 .required()
		 .default(() => "asc"),
	});

const exportFormats = [
	  {
        key: 'sibbr'
      }
 ];
	
const sortByOptions = [
	  {
        key: 'organismId'
      },
      {
        key: 'eventDate'
      }
 ];
 
const orderOptions = [
	  {
        key: 'asc'
      },
      {
        key: 'desc'
      }
 ];


const CollectionsExport = props => {
	const [workgroups, setWorkgroups] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	
	const auth = useAuth();
	const { t } = useTranslation();
	let context = useOutletContext();
	
	useEffect(() => {
		let isMounted = true; 		
		
		//Fetch Collecting Workgroups
		API.findWorkgroups({"types": ["OBSERVATORY"]}, auth.isAdmin())
			.then(response => {
				if (isMounted) setWorkgroups(response.list);
			})
			.catch(error => { 
				log.error("Error Loading Collecting Workgroups: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);
	
   const exportCollections = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.exportCollectionsSibbr(values.workgroupId, {"sortBy" : values.sortBy, "order" : values.order}, auth.isAdmin(), true).then(response => {
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
	const getWorkgroup = (workgroupId) => {		
		return workgroups.find(({id}) => id == workgroupId)
	};
	
	if (isLoading) 
		return <Loader />
		
	let initialValues = validationSchema.default();
	
	return (
		<Formik
    		initialValues={initialValues}
    		validationSchema={validationSchema}
    		validateOnChange={false}
    		validateOnBlur={false}     
    		onSubmit={(values, actions) => {
    			//Clear Error/Success
    			context.onError(null);
    			context.onSuccess(null);
    										
    			exportCollections(values)
    				.then((response) => {
    					
    					let workgroup = getWorkgroup(values.workgroupId);
    					
    					//response is a blob
			    		saveAs(response, 'PREVIR - Collections - ' + ((workgroup && workgroup.name) ? workgroup.name + ' - ' : "") + auth.user.firstName + ' - ' + moment().format('YYYYMMDD HHmmss') + '.csv');
    				})
    				.catch(error => { 
    								
    					//actions.setSubmitting(false);
    								
    					if (error instanceof ValidationError) {		
    									
    						log.info("Export Collections Attempt Failed: ", error.message);

    						if (error.detail) {            						
    	              			for (let key of Object.keys(error.detail)) {
    	              				let errorMsgs = error.detail[key];
    	              				errorMsgs.forEach((message) => {
    	              					actions.setFieldError(key, message);
    	              				});
    
    	              			}
    	              		}
    					} else {
    						log.error("Error Exporting Collections: ", error.message);
    					}
    								
    					context.onError(error);
    								
    				})
    				.finally(() => {
		 				actions.setSubmitting(false);
					});
    				
				}}
		      	>
		      	{({isSubmitting, errors, values, handleChange}) => (	
		      	
		      	<Container>
		    		<Row className="mb-3">
		            	<Col>
		            		<h3>
		            			<Trans i18nKey={"collections.export.header"} >Export Collections</Trans> 
		            		</h3>
		            	</Col>
		      
		            </Row>
		            <Row className="mb-3">
		        		<Col>
		        			<Form className="form-collections-export" noValidate>
		        				
		      				<Row>
			
								<FormGroup as={Col} md={8} controlId="formGridWorkgroup">
									<FormLabel><Trans i18nKey="collections.export.form.workgroupId.label">Workgroup</Trans> *</FormLabel>
									<FormControl as="select" name="workgroupId" isInvalid={!(errors.workgroupId == null)} value={values.workgroupId} onChange={handleChange} >
		    							<option value="">{t("collections.export.form.workgroupId.blank-option")}</option>
		    							{ workgroups.map(item =>
		    								<option key={item.id} value={item.id+""}>{item.name}</option>
		    							)}
		    						</FormControl>
									<FormControlErrors errors={errors.workgroupId} />
								</FormGroup>
								
								<FormGroup as={Col} md={4} controlId="formGridFormat">
									<FormLabel><Trans i18nKey="collections.export.form.format.label">Format</Trans> </FormLabel>
									<FormControl as="select" name="format" value={'sibbr'} disabled={true} >
		    							{ exportFormats.map(item =>
		    								<option key={item.key} value={item.key}>{t("collections.export.form.format.options."+item.key)}</option>
		    							)}
		    						</FormControl>
									<FormControlErrors errors={errors.workgroupId} />
								</FormGroup>
	
							</Row>
							
							<Row>
			
								<FormGroup as={Col} md={8} controlId="formGridSortBy">
									<FormLabel><Trans i18nKey="collections.export.form.sortBy.label">Sort By</Trans> </FormLabel>
									<FormControl as="select" name="sortBy" isInvalid={!(errors.sortBy == null)} value={values.sortBy} onChange={handleChange} >
		    							{ sortByOptions.map(item =>
		    								<option key={item.key} value={item.key}>{t("collections.export.form.sortBy.options."+item.key)}</option>
		    							)}
		    						</FormControl>
									<FormControlErrors errors={errors.sortBy} />
								</FormGroup>
								
								<FormGroup as={Col} md={4} controlId="formGridOrder">
									<FormLabel><Trans i18nKey="collections.export.form.order.label">Order</Trans> </FormLabel>
									<FormControl as="select" name="order" isInvalid={!(errors.order == null)} value={values.order} onChange={handleChange} >
		    							{ orderOptions.map(item =>
		    								<option key={item.key} value={item.key}>{t("collections.export.form.order.options."+item.key)}</option>
		    							)}
		    						</FormControl>
									<FormControlErrors errors={errors.order} />
								</FormGroup>
								

	
							</Row>
						
					   		<Row className="mt-3">    					   
    							<Col>  
    								<Button variant="success" type="submit" disabled={isSubmitting} > 
    									{isSubmitting ? <Trans i18nKey="collections.export.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={faFileDownload} /> <Trans i18nKey={"collections.export.form.submit"}>Submit</Trans></span>} 
    								</Button>
    					   		</Col>
    			       		</Row>   
    			       
    			       
    			    </Form>	
		      	 </Col>
			     </Row>
			  </Container>
		      )}
		 </Formik>
	);
}

export default CollectionsExport;
