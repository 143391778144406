import React,  { Fragment, useState, useEffect} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes, faQuestion } from '@fortawesome/free-solid-svg-icons'
import { faVirus, faVirusSlash } from '@fortawesome/free-solid-svg-icons'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import FormCheck from 'react-bootstrap/FormCheck';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';

import FormControlErrors from "../components/FormControlErrors";
import FormControlHelper from "../components/FormControlHelper";
import StorageBoxInputSearch from "../components/StorageBoxInputSearch";
import Switch from "../components/Switch";

import LaboratoryTestConfig from "../config/LaboratoryTestConfig";

import moment from "moment";
import { DatePicker } from "react-tempusdominus-bootstrap";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";

import { parseInputDate } from "../helpers/commons";

const ResultButtonVariantMap = new Map([
  ['POSITIVE', 'danger'],
  ['NEGATIVE', 'success'],
  ['INCONCLUSIVE', 'secondary'],
]);

const ResultButtonIconMap = new Map([
  ['POSITIVE', faVirus],
  ['NEGATIVE', faVirusSlash],
  ['INCONCLUSIVE', faQuestion],
]);

const GeneralSection = props => {

	const [workgroups, setWorkgroups] = useState([]);
	const [workgroupUsers, setWorkgroupUsers] = useState([]);
	const [targetViruses, setTargetViruses] = useState([]);
	const [laboratoryTestProtocols, setLaboratoryTestProtocols] = useState([]);
	
	const { t } = useTranslation();
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
		
		if (isMounted) {
			
		}
		
		//Fetch Laboratory Workgroups
		API.findLaboratories({})
			.then(response => {
				if (isMounted) setWorkgroups(response.list);
			})
			.catch(error => { 
				log.error("Error Loading Analysis Workgroups: ", error.message);
		 		props.onError(error);
			})
			.finally(() => {
		 		//Get Workgroup Users
				if (props.values.workgroupId) {
					getWorkgroupUsers(props.values.workgroupId);
					//getStorageBoxes(props.values.workgroupId);
				}
			});
			
		//Fetch Target Viruses and Test Protocols
		API.findTargetViruses({})
			.then(response => {
				if (isMounted) setTargetViruses(response.list);
			})
			.catch(error => { 
				log.error("Error Loading Target Viruses: ", error.message);
		 		props.onError(error);
			})
			.finally(() => {
		 		//Get All Laboratory Test Protocols
				API.findLaboratoryTestProtocols({})
					.then(response => {
						if (isMounted) {	
							
							//setVirusProtocolsMap
							//response.list
							setLaboratoryTestProtocols(response.list);
							
						}
					})
					.catch(error => { 
						log.error("Error Loading Laboratory Test Protocols: ", error.message);
		 				props.onError(error);
					})
					.finally(() => {
		 				//Set related Laboratory Test Protocols
						if (props.values.targetVirusId) {
							//props.values.targetVirusId
							//setLaboratoryTestProtocols(response.list);
					}
				});
			});
		
		 return () => { isMounted = false };
		  
	}, []);
	
	const getWorkgroupUsers = (workgroupId) => {
    	API.findUsers({"filteredColumn": "workgroupId", "filter": workgroupId})
			.then(response => {
					setWorkgroupUsers(response.list); 					
			})
			.catch(error => { 
				log.error("Error Loading Workgroup Users: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		  //Do nothing yet
			});
    }

	/*const getStorageBoxes = (workgroupId) => {
    	API.findStorageBoxes(workgroupId, {})
			.then(response => {
					setStorageBoxes(response.list); 					
			})
			.catch(error => { 
				log.error("Error Loading Workgroup Storage Boxes: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		  //Do nothing yet
			});
    }*/

	const handleWorkgroupChange = (e) => {
        
        if (!e.target.value) {
        	setWorkgroupUsers([]);
			//setStorageBoxes([]);
        	
			props.setFieldValue("workgroupId", "");
			props.setFieldValue("dissociatedById", "");
			props.setFieldValue("storageBoxId", "");
			
        	return;
        }
        
		props.setFieldValue("workgroupId", e.target.value);

        //Get Workgroup Users
        getWorkgroupUsers(e.target.value);
        
        //Get Storage Boxes
		//getStorageBoxes(e.target.value);
        
        //Reset dissociatedBy and storageBox
        props.setFieldValue("dissociatedById", "");
		props.setFieldValue("storageBoxId", "");
    }

	const handleStorageBoxChange = (storageBox) => {
        
        if (!storageBox) {
        	
			props.setFieldValue("storageBoxId", "");
			
        	return;
        } /*else if (storageBox.id === props.values.storageBoxId) {
			return;
		}*/
		
		props.setFieldValue("storageBoxId", storageBox.id);

    }

	const handleTargetVirusChange = (e) => {
        
        props.setFieldValue("targetVirusId", e.target.value);

		//Reset Laboratory Test Protocol 
		props.setFieldValue("protocolId", "");
      
    }

	const handlePCRTypeChange = (e) => {
        
        props.setFieldValue("pcrType", e.target.value);

		if (!e.target.value) {
        	
			props.setFieldValue("result", "");
			props.setFieldValue("cycleThreshold", "");
			props.setFieldValue("bandIntensity", "");
			props.setFieldValue("sequenced", false);
			props.setFieldValue("sequencingMethod", "");
			props.setFieldValue("sequencingResult", "");
			props.setFieldValue("sequencingRemarks", "");
			props.setFieldValue("viralIsolation", false);
			props.setFieldValue("viralIsolationRemarks", "");
			
        	return;
        }

		//Reset Related Fields
		
		props.setFieldValue("cycleThreshold", "");
		props.setFieldValue("bandIntensity", "");
      
    }

	const handleSequencedChange = (e) => {
		       
        props.setFieldValue("sequenced", !JSON.parse(e.target.value));

		//Reset Related Fields
		props.setFieldValue("sequencingMethod", "");
		props.setFieldValue("sequencingResult", "");
		props.setFieldValue("sequencingRemarks", "");
		props.setFieldValue("viralIsolation", false);
		props.setFieldValue("viralIsolationRemarks", "");
      
    }

	const setTestResultValue = (result) => {
        
		if (props.values.result != result) {

        	props.setFieldValue("result", result);

			//Reset Related Fields
			props.setFieldValue("cycleThreshold", "");
			props.setFieldValue("bandIntensity", "");
			props.setFieldValue("sequenced", false);
			props.setFieldValue("sequencingMethod", "");
			props.setFieldValue("sequencingResult", "");
			props.setFieldValue("sequencingRemarks", "");
			props.setFieldValue("viralIsolation", false);
			props.setFieldValue("viralIsolationRemarks", "");
		}
      
    }

    
	return(
		<Fragment>					
			
			<Row>

				<FormGroup as={Col} md={6} controlId="formGridPCRType">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.pcrType.label"}>PCR Type</Trans> *</FormLabel>
					<FormControl as="select" name="pcrType" isInvalid={!(props.errors.pcrType == null)} value={props.values.pcrType} onChange={handlePCRTypeChange} >
		    			<option value="">{t(props.i18nPrefix+"form.pcrType.blank-option")}</option>
		    			{ LaboratoryTestConfig.PCRTypes.map(item =>
							<option key={item} className="text-uppercase" value={item}>{t("models.tests.enums.pcrType."+item)}</option>
		    			)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.pcrType} />
				</FormGroup>
				
				<FormGroup as={Col} md={6} controlId="formGridLabelIdPrefix">
    				<FormLabel ><Trans i18nKey={props.i18nPrefix+"form.labelIdPrefix.label"}>Label Id Prefix</Trans> <FormControlHelper text={props.i18nPrefix+"form.labelIdPrefix.helper"} /> *</FormLabel>
					<FormControl type={'text'} name="labelIdPrefix" isInvalid={!(props.errors.labelIdPrefix == null)} value={props.values.labelIdPrefix} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.labelIdPrefix.placeholder")} />
				    <FormControlErrors errors={props.errors.labelIdPrefix} />
				</FormGroup>
						
			</Row>
			
			<Row>

				<FormGroup as={Col} controlId="formGridTargetVirus">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.targetVirusId.label"}>Target Virus</Trans> *</FormLabel>
					<FormControl as="select" name="targetVirusId" isInvalid={!(props.errors.targetVirusId == null)} value={props.values.targetVirusId} onChange={handleTargetVirusChange} >
		    			<option value="">{t(props.i18nPrefix+"form.targetVirusId.blank-option")}</option>
		    			{ targetViruses.map(item =>
		    				<option key={item.id} value={item.id+""}>{item.name}</option>
		    			)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.targetVirusId} />
				 </FormGroup>
			
				<FormGroup as={Col} controlId="formGridTestProtocol">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.protocolId.label"}>Laboratory Test Protocol</Trans> *</FormLabel>
					<FormControl as="select" name="protocolId" disabled={!(props.values.targetVirusId)} isInvalid={!(props.errors.protocolId == null)} value={props.values.protocolId} onChange={props.onChange} >
		    			<option value="">{t(props.i18nPrefix+"form.protocolId.blank-option")}</option>
		    			{ laboratoryTestProtocols.filter(item => (props.values.targetVirusId && item.targetVirusId === props.values.targetVirusId )) 
							.map(item =>
		    					<option key={item.id} value={item.id+""}>{item.name}</option>
		    				)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.protocolId} />
				 </FormGroup>
				
			</Row>
			
			
			<Row >
				
				<FormGroup as={Col} className="mb-0" controlId="formGridWorkgroup">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.workgroupId.label"}>Workgroup</Trans> *</FormLabel>
					<FormControl as="select" name="workgroupId" isInvalid={!(props.errors.workgroupId == null)} value={props.values.workgroupId} onChange={handleWorkgroupChange} >
		    			<option value="">{t(props.i18nPrefix+"form.workgroupId.blank-option")}</option>
		    				{ workgroups.map(item =>
		    					<option key={item.id} value={item.id+""}>{item.name + " (" + item.shortcode + ")"}</option>
		    				)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.workgroupId} />
				 </FormGroup>
			
				<FormGroup as={Col} controlId="formGridStorageBox">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.storageBoxId.label"}>Storage Box</Trans></FormLabel>
					<StorageBoxInputSearch 
				    	i18nPrefix={props.i18nPrefix+"form."}
				    	onError={props.onError}
				    	isInvalid={!(props.errors.storageBoxId == null)}
				    	onChange={handleStorageBoxChange}
						workgroupId={props.values.workgroupId}
						disabled={!(props.values.workgroupId)}
						storageBoxId={props.values.storageBoxId}
				    />
					<FormControlErrors errors={props.errors.storageBoxId} />
				</FormGroup>
			</Row>
			
			<Row>

				<FormGroup as={Col} controlId="formGridDateTested">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.dateTested.label"}>Date Tested</Trans> *</FormLabel>
					<DatePicker
      					format="DD/MM/YYYY"
      					className={!(props.errors.dateTested == null) ? "is-invalid border border-danger rounded" : ""}
      					onChange={(e) => { 
							if (e.date) { 
      							if (e.date.isValid()) 
      								props.setFieldValue("dateTested", moment(e.date).format("YYYY-MM-DD"));
      							else
      								props.setFieldValue("dateTested", e.date.parsingFlags().inputDate);
      						} else if (e.date === null) { // reset if null (which is !== from undefined)
      							props.setFieldValue("dateTested", "");
      						}
      					}}
      					date={props.values.dateTested}
      					parseInputDate={parseInputDate}
      					locale={config.preferredLanguage}
      					keepInvalid={true}
    				/>
					<FormControlErrors errors={props.errors.dateTested} />
				</FormGroup>
				
				<FormGroup as={Col} controlId="formGridTestedById">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.testedById.label"}>Tested By</Trans> *</FormLabel>
					<FormControl as="select" disabled={!(props.values.workgroupId)} name="testedById" isInvalid={!(props.errors.testedById == null)} value={props.values.testedById} onChange={props.onChange} >
		    			<option value="">{t(props.i18nPrefix+"form.testedById.blank-option")}</option>
		    			{ workgroupUsers.map(item =>
		    					<option key={item.id} value={item.id}>{item.firstName+" "+item.lastName}</option>
		    			)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.testedById} />
				</FormGroup>
				
			</Row>
			
						<Row>    					   
				<FormGroup as={Col} md="auto">
					<Row>		
						<FormGroup className="mb-0" as={Col} controlId="formGridResult">
							<FormLabel><Trans i18nKey={props.i18nPrefix+"form.result.label"}>Result</Trans> *</FormLabel>
						</FormGroup>
					</Row>
					<Row>
						<Col className="pr-0">
							<ButtonToolbar >
								{ LaboratoryTestConfig.TestResults.map(item =>
									<ButtonGroup key={item} className="mr-3">
        								<Button
											disabled={!props.values.pcrType}
											variant={(props.values.result === item) ? ResultButtonVariantMap.get(item) : "outline-"+ResultButtonVariantMap.get(item)} 
											onClick={() => {setTestResultValue(item);}}
										>
											<FontAwesomeIcon icon={ResultButtonIconMap.get(item)} /> {t("models.tests.enums.result."+item)}
										</Button>
      								</ButtonGroup>
		    					)}
    						</ButtonToolbar>
							<FormControlErrors block={true} errors={props.errors.result} />
						</Col>
					</Row>
				</FormGroup>
				{ (props.values.result === "POSITIVE") ? 
					<Fragment>
					{(props.values.pcrType === "REALTIME") ? 
						<Col>
							<Row>		
								<FormGroup className="mb-0" as={Col} controlId="formCycleThresholdLabel">
									<FormLabel><Trans i18nKey={props.i18nPrefix+"form.cycleThreshold.label"}>CT</Trans> <FormControlHelper text={props.i18nPrefix+"form.cycleThreshold.helper"} /> *</FormLabel>
								</FormGroup>
							</Row>
							<Row>
								<FormGroup as={Col} controlId="formCycleThreshold">
									<FormControl type={'text'} name="cycleThreshold" isInvalid={!(props.errors.cycleThreshold == null)} value={props.values.cycleThreshold} onChange={props.onChange} />
									<FormControlErrors errors={props.errors.cycleThreshold} />
								</FormGroup>
							</Row>
						</Col>
						:
						<Fragment>
						{(props.values.pcrType === "CONVENTIONAL") ? 
						<Col>
							<Row>		
								<FormGroup className="mb-0" as={Col} controlId="formBandIntensityLabel">
									<FormLabel><Trans i18nKey={props.i18nPrefix+"form.bandIntensity.label"}>Band Intensity</Trans>  *</FormLabel>
								</FormGroup>
							</Row>
							<Row>
								<FormGroup as={Col} controlId="formBandIntensity">
									<FormControl as="select" name="bandIntensity" isInvalid={!(props.errors.bandIntensity == null)} value={props.values.bandIntensity} onChange={props.onChange} >
		    							<option value="">{t(props.i18nPrefix+"form.bandIntensity.blank-option")}</option>
		    							{ LaboratoryTestConfig.BandIntensities.map(item =>
											<option key={item} className="text-uppercase" value={item}>{t("models.tests.enums.bandIntensity."+item)}</option>
		    							)}
		    						</FormControl>
									<FormControlErrors errors={props.errors.bandIntensity} />
								</FormGroup>
							</Row>
						</Col>
						:
						null
						}
						</Fragment>
					}
					</Fragment>
					:
					null
				}
				
			</Row>
				
							
			{ (props.values.result === "POSITIVE") ? 
				<Row>
					<FormGroup as={Col} md={6} controlId="formGridSequenced">
	    				<FormLabel ><Trans i18nKey={props.i18nPrefix+"form.sequenced.label"}>Sequenced?</Trans></FormLabel>
	    				<Switch 
	    					name="sequenced"
	    					value={props.values.sequenced}
	    					checked={JSON.parse(props.values.sequenced)}
	    					onChange={handleSequencedChange}
	    				/>
						<FormControlErrors block={true} errors={props.errors.sequenced} />
	    			</FormGroup>
	       		</Row>
				: null
			}
		    							
		
		</Fragment>
		
	) 
}

const SequencingSection = props => {
	
	const { t } = useTranslation();

	const setSequencingResultValue = (result) => {
        
		if (props.values.sequencingResult != result) {

        	props.setFieldValue("sequencingResult", result);

		}
      
    }

	const handleViralIsolationChange = (e) => {
		       
        props.setFieldValue("viralIsolation", !JSON.parse(e.target.value));

		//Reset Related Fields
		props.setFieldValue("viralIsolationRemarks", "");
      
    }

	return(
		<Fragment>
			<Row > 
				<FormGroup as={Col} md="auto" className="mb-0">
					<Row>    					   
	    				<FormGroup className="mb-0" as={Col} controlId="formGridSequencingResult">
							<FormLabel><Trans i18nKey={props.i18nPrefix+"form.sequencingResult.label"}>Sequencing Result</Trans> *</FormLabel>
						</FormGroup>
					</Row>
					<Row className="align-items-center mb-3">
						<Col className="pr-0">
							<ButtonToolbar >
							{ LaboratoryTestConfig.SequencingResults.map(item =>
								<ButtonGroup key={item} className="mr-3">
        							<Button
										variant={(props.values.sequencingResult === item) ? ResultButtonVariantMap.get(item) : "outline-"+ResultButtonVariantMap.get(item)} 
										onClick={() => {setSequencingResultValue(item);}}
									>
										<FontAwesomeIcon icon={ResultButtonIconMap.get(item)} /> {t("models.tests.enums.sequencingResult."+item)}
									</Button>
      							</ButtonGroup>
		    				)}
    						</ButtonToolbar>
							<FormControlErrors block={true} errors={props.errors.sequencingResult} />
						</Col>
					</Row>
				</FormGroup>
				<Col>
					<Row>
						<FormGroup as={Col} className="mb-0" controlId="formGridSequencingMethodLabel">
							<FormLabel><Trans i18nKey={props.i18nPrefix+"form.sequencingMethod.label"}>Sequencing Method</Trans> *</FormLabel>
						</FormGroup>
					</Row>
					<Row>
						<FormGroup as={Col} controlId="formGridSequencingMethod">
							<FormControl as="select" name="sequencingMethod" isInvalid={!(props.errors.sequencingMethod == null)} value={props.values.sequencingMethod} onChange={props.onChange} >
		    					<option value="">{t(props.i18nPrefix+"form.sequencingMethod.blank-option")}</option>
		    					{ LaboratoryTestConfig.SequencingMethods.map(item =>
									<option key={item} className="text-uppercase" value={item}>{t("models.tests.enums.sequencingMethod."+item)}</option>
		    					)}
		    				</FormControl>
							<FormControlErrors errors={props.errors.sequencingMethod} />
						</FormGroup>
					</Row>
				</Col>
			</Row>
			<Row>
				<FormGroup as={Col} controlId="formGridSequencingRemarks">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.sequencingRemarks.label"}>Sequencing Remarks</Trans></FormLabel>
					<FormControl className="text-uppercase" as="textarea" rows={3} maxLength={255} name="sequencingRemarks" isInvalid={!(props.errors.sequencingRemarks == null)} value={props.values.sequencingRemarks} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.sequencingRemarks.placeholder")} />
					<FormControlErrors errors={props.errors.sequencingRemarks} />
				</FormGroup> 
			</Row>
			<Row>
				<FormGroup as={Col} md={6} controlId="formGridViralIsolation">
	    			<FormLabel ><Trans i18nKey={props.i18nPrefix+"form.viralIsolation.label"}>Viral Isolation?</Trans></FormLabel>
	    				<Switch 
	    					name="viralIsolation"
	    					value={props.values.viralIsolation}
	    					checked={JSON.parse(props.values.viralIsolation)}
	    					onChange={handleViralIsolationChange}
	    				/>
					<FormControlErrors block={true} errors={props.errors.viralIsolation} />
	    		</FormGroup>
	       	</Row>
			<Row>
				<FormGroup as={Col} controlId="formGridViralIsolationRemarks">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.viralIsolationRemarks.label"}>Viral Isolation Remarks</Trans></FormLabel>
					<FormControl className="text-uppercase" as="textarea" disabled={!(props.values.viralIsolation)} rows={3} maxLength={255} name="viralIsolationRemarks" isInvalid={!(props.errors.viralIsolationRemarks == null)} value={props.values.viralIsolationRemarks} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.viralIsolationRemarks.placeholder")} />
					<FormControlErrors errors={props.errors.viralIsolationRemarks} />
				</FormGroup> 
			</Row>
		</Fragment>
		
	) 
}

const MultipleLaboratoryTestsFormFields = { GeneralSection, SequencingSection };

export default MultipleLaboratoryTestsFormFields;
