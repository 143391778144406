import React, { Fragment, useState, useRef, useMemo  } from 'react';
import { Trans } from 'react-i18next';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button'

import { formatCoordinateDegrees } from "../helpers/commons";

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import L, {Icon} from 'leaflet'
import 'leaflet/dist/leaflet.css';

//Brasilia-DF
const defaultMapPosition = L.latLng(-15.7940931, -47.8823736);

const InteractiveMap = props => {

	const [mapMarkerPosition, setMapMarkerPosition] = useState((props.latitude && props.longitude) ? L.latLng(props.latitude, props.longitude) : null);
	
	const mapMarkerRef = useRef(null);
	
	const initialPosition = (props.latitude && props.longitude) ? L.latLng(props.latitude, props.longitude) : null;
		
	const handleMapMarkerChange = useMemo(
	    	() => ({
	      		dragend() {
	        		const marker = mapMarkerRef.current
	        		if (marker != null) {
	          			setMapMarkerPosition(marker.getLatLng());
	        		}
	      		},
	    	}), [],
	  	)
	
	  	
	return (
		<MapContainer 
			style={{height: "256px"}} 
			center={(mapMarkerPosition) ? mapMarkerPosition : defaultMapPosition} 
			zoom={4} 
			scrollWheelZoom={false}
		>
			<TileLayer
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
			<Marker 
				position={(mapMarkerPosition) ? mapMarkerPosition : defaultMapPosition}
				icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}
				draggable
				eventHandlers={handleMapMarkerChange}
				ref={mapMarkerRef}
				>
					
					<Popup minWidth={480}>
						<Row>
						<Col md="auto">
						{ (!initialPosition && !mapMarkerPosition) ? (
							<Fragment>
								<Trans i18nKey={props.i18nPrefix+"interactive-map.popup.initialized-without-coordinates"}>Map initialized without Coordinates</Trans>
					 		</Fragment>
							) : (!mapMarkerPosition.equals([props.latitude, props.longitude])) ? (
								<Fragment>
			 						<Trans i18nKey={props.i18nPrefix+"interactive-map.popup.coordinates-out-of-sync"} values={{ latitude : formatCoordinateDegrees(mapMarkerPosition.lat, false), longitude : formatCoordinateDegrees(mapMarkerPosition.lng, true) }}>You have change coordinates to <strong>{ formatCoordinateDegrees(mapMarkerPosition.lat, false) + "," + formatCoordinateDegrees(mapMarkerPosition.lng, true) }</strong></Trans>
									<Button className="ml-3" size="sm" onClick={() => { props.onConfirmCoordinates(mapMarkerPosition.lat, mapMarkerPosition.lng);}}>
										<Trans i18nKey={props.i18nPrefix+"interactive-map.popup.confirm-change-button"}>Confirm?</Trans>
									</Button>
								</Fragment>
							) : (initialPosition && initialPosition.equals([props.latitude, props.longitude])) ? (
								<Fragment>
									<Trans i18nKey={props.i18nPrefix+"interactive-map.popup.initialized-with-coordinates"}>Map initialized with Coordinates</Trans>
								</Fragment>
							) : (
								<Fragment>
									<Trans i18nKey={props.i18nPrefix+"interactive-map.popup.coordinates-in-sync"}>Coordinates Syncronized</Trans>
								</Fragment>
							)}
						</Col>
						</Row>
					</Popup>
			</Marker>
		</MapContainer>
	)
};

export default InteractiveMap;