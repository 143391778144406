import React,  { useState, useEffect} from 'react';
import { Link, useNavigate, useLocation, Route, Navigate, useOutletContext } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimesCircle, faCheckCircle, faVials } from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import Loader from "../components/Loader";
import StorageBoxFormFields from "../components/StorageBoxFormFields";
import SubjectActivities from "../components/SubjectActivities";
import Prompt from "../components/Prompt";

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";
import { useAuth } from "../services/use-auth";

import StorageBoxConfig from "../config/StorageBoxConfig";

import { ValidationError } from "../helpers/custom-errors";

import { compareJSON } from "../helpers/commons";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const validationSchema = Yup.object().shape(StorageBoxConfig.FormValidationRules);

const SaveStorageBox = props => {
	
	const [isLoading, setIsLoading] = useState(true);
	const [storageBox, setStorageBox] = useState(null);
	
	let navigate = useNavigate();
	
	const location = useLocation();
	
	let context = useOutletContext();
	
	const { t } = useTranslation();
	
	const config = useConfig();
	const auth = useAuth();
	
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
		
		if (location.pathname === '/analysis/boxes/update' && location.state && location.state.item) {
			API.lookupStorageBox(location.state.item.id)
			.then(response => {
					setStorageBox(response);
			})
			.catch(error => { 
				log.error("Error Loading Storage Box: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		}
		 
		 return () => { isMounted = false };
		  
	}, [location.state]);
	
	if (location.pathname === '/analysis/boxes/update') {
		if (!location.state || !location.state.item) {
			return (<Navigate  to="/analysis/boxes" />)
		} else if (isLoading) {
			return <Loader />
		} else if (!storageBox) {
			return null;
		}
	} else {
		if (!location.state || !location.state.item) {
			return (<Navigate  to="/analysis/boxes" />)
		}
	}
	
	let initialValues = {}, action, actionIcon;
	
	let defaultValues = validationSchema.default();
	
	if (location.pathname === '/analysis/boxes/update') {
		Object.keys(StorageBoxConfig.FormValidationRules)
			.forEach((key) => {
				initialValues[key] = (storageBox[key]) ? storageBox[key] : defaultValues[key];
			});
		
		action = "update";
		actionIcon = faCheckCircle
	} else {
		
		initialValues = defaultValues;
		action = "create";
		actionIcon = faPlusCircle;
		
		//Defining values for standard storage box 9x9
		//initialValues.cols = "9"
		//initialValues.rows = "9"
		
		initialValues.workgroupId = location.state.item.workgroupId;
		
	}
	
	const saveStorageBox = (values) =>
		new Promise((resolve, reject) => {
			if (location.pathname === '/analysis/boxes/update') {
				API.updateStorageBox(location.state.item.id, values, auth.isAdmin(), config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			} else {
				API.createStorageBox(values, auth.isAdmin(), config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			}
		});
		
	const handleCancelButton = () => {
		
		if (location.state.item && location.state.item.workgroupId) {
			navigate({ pathname: "/analysis/boxes", item: {"workgroupId": location.state.item.workgroupId} });
		}
  	};
		
	return (
		<Container>
			<Row className="mb-3">
        		<Col>
        			<h3>
        				<Trans i18nKey={"storageboxes.save.header-"+action}>Save StorageBox</Trans> 
        			</h3>
        		</Col>
        	</Row>
        	<Row className="mb-3">
    			<Col >	      
    				<Formik
    					initialValues={initialValues}
    					validationSchema={validationSchema}
    					validateOnChange={false}
    					validateOnBlur={false}     
    					onSubmit={(values, actions) => {
    						//Clear Error/Success
    						context.onError(null);
    						context.onSuccess(null);
					
    						saveStorageBox(values)
    							.then((response) => {
    								actions.resetForm({ values }) //Workaround to set dirty back to true
    								navigate("/analysis/boxes", {state: { item: {"workgroupId": values.workgroupId}, success: t("storageboxes.save.success")}});
    							})
    							.catch(error => { 
    								
    								actions.setSubmitting(false);
    								
    								if (error instanceof ValidationError) {		
    									
    									log.info("Save StorageBox Attempt Failed: ", error.message);

    									if (error.detail) {            						
    	              						for (let key of Object.keys(error.detail)) {
    	              							let errorMsgs = error.detail[key];
    	              							errorMsgs.forEach((message) => {
    	              								actions.setFieldError(key, message);
    	              							});
    	              						}
    	              					}
    								} else {
    									log.error("Error Saving StorageBox: ", error.message);
    								}
    								
    								context.onError(error);
    							})
					}}
		      	>
		      	{({isSubmitting, dirty, errors, values, handleChange, setFieldValue}) => (	
		      	<Form className="form-storage-box" noValidate>
		      		<Prompt
		      			when={!!dirty && !isSubmitting}
		      			message={JSON.stringify({
		      		           "title": t("form.unsaved-changes-modal.title"),
		      		           "bodyText": t("form.unsaved-changes-modal.body"),
		      		           "confirmText": t("form.unsaved-changes-modal.confirm"), 
		      		           "cancelText": t("form.unsaved-changes-modal.cancel"),
		      		           "size": "lg",
		      		           "variant": "danger",
		        			   "when": compareJSON(initialValues, values)
		      		          }
		      		      )
		      		   }
		      		/>

						<StorageBoxFormFields 
							values={values} 
							errors={errors} 
							onChange={handleChange} 
							onError={context.onError}
							setFieldValue={setFieldValue}
							action={action}
						/>
				    	
	    			    <Row className="mt-3">    					   
    						<Col>
    							<Button variant="success" type="submit" disabled={isSubmitting}> 
    								{isSubmitting ? <Trans i18nKey="storageboxes.save.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={actionIcon} /> <Trans i18nKey={"storageboxes.save.form.submit-"+action}>Submit</Trans></span>} 
    							</Button>
			
								{(location.pathname === '/analysis/boxes/update') ?
    									<Button variant="primary" className="ml-3" onClick={() => navigate("/analysis/boxes/items", { state: { "item": storageBox}})} ><FontAwesomeIcon icon={faVials} /> <Trans i18nKey="storageboxes.save.items">Items</Trans></Button> 
    									: null
    							}
    						    
    							<Button variant="secondary" className="float-right" onClick={handleCancelButton}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="storageboxes.save.cancel">Cancel</Trans></Button>
    					   </Col>
    			    </Row>   
	    			    
		      	</Form>	
		      )}
		      </Formik>
		      
		      </Col>
	        </Row>
			{(location.pathname === '/analysis/boxes/update') && (
				<SubjectActivities 
		 			subject="STORAGE_BOX" 
		 			subjectId={location.state.item.id} 
		 			onError={context.onError}
		 		/>
			)}
	     </Container>
	);
}

export default SaveStorageBox;
