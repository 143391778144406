import * as Yup from "yup";
import moment from "moment";

import { Regex } from '../helpers/commons'

import CollectionConfig from "../config/CollectionConfig";

const MaterialSamples = CollectionConfig.Samples;

const DefaultLabelIdPrefix = "T-";

const PCRTypes = [
	"CONVENTIONAL",
	"REALTIME"
]

const TestResults = [
	"POSITIVE",
	"NEGATIVE",
	"INCONCLUSIVE"
]

const BandIntensities = [
	"STRONG",
	"MEDIUM",
	"WEAK"
]

const SequencingMethods = [
	"SANGER",
	"NGS",
	"MINION"
]

const SequencingResults = [
	"POSITIVE",
	"NEGATIVE",
	"INCONCLUSIVE"
]

const GeneralFormValidationRules = {
	id: Yup.string()
		.nullable()
		.default(() => ""),
	pcrType: Yup.string()
		.oneOf([""].concat(PCRTypes), 'form.validation.string.enum')
		.required()
		.default(() => ""),
	targetVirusId: Yup.string()
		.required()
		.default(() => ""),
	protocolId: Yup.string()
		.required()
		.default(() => ""),
	result: Yup.string()
		.oneOf([""].concat(TestResults), 'form.validation.string.enum')
		.required()
		.default(() => ""),	
	cycleThreshold: Yup.string()
		.matches(Regex.DOUBLE, "laboratorytests.save-test-modal.form.cycleThreshold.validation.format")
		.when(['result','pcrType'], {
            is: (result, pcrType) => (result === "POSITIVE" && pcrType === "REALTIME"),
            then: () => Yup.string().required("laboratorytests.save-test-modal.form.cycleThreshold.validation.required")
          })
   		.default(() => ""),
	bandIntensity: Yup.string()
		.oneOf([""].concat(BandIntensities), 'form.validation.string.enum')
		.when(['result','pcrType'], {
            is: (result, pcrType) => (result === "POSITIVE" && pcrType === "CONVENTIONAL"),
            then: () => Yup.string().required("laboratorytests.save-test-modal.form.bandIntensity.validation.required")
          })
   		.default(() => ""),
	collectionId: Yup.string()
		.required()
		.default(() => ""),
	organismId: Yup.string()
		.default(() => ""),
	scientificName: Yup.string()
		.default(() => ""),
	sex: Yup.string()
		.default(() => ""),
	county: Yup.string()
		.default(() => ""),
	stateProvince: Yup.string()
		.default(() => ""),
	materialSample: Yup.string()
		.oneOf([""].concat(MaterialSamples), 'form.validation.string.enum')
		.required()
		.default(() => ""),
	labelId: Yup.string()
		.max(100, 'laboratorytests.save-test-modal.form.labelId.maximumlength')
		.required()
		.default(() => ""),
	workgroupId: Yup.string()
		.required()
		.default(() => ""),
	testedById: Yup.string()
		.required()
		.default(() => ""),
	dateTested: Yup.date()
		.typeError('laboratorytests.save-test-modal.form.dateTested.validation.format')
		.max(new Date(), "laboratorytests.save-test-modal.form.dateTested.validation.future")
		.required()
		.default(() => ""),
		//.default(() => moment(new Date()).format("YYYY-MM-DD")),
	storageBoxId: Yup.string()
		//.required()
		.default(() => ""),
	slotCol: Yup.string()
			.default(() => ""),
	slotRow: Yup.string()
			.default(() => "")
}

const SequencingFormValidationRules = {
	sequenced: Yup.boolean()
		.when(['result'], {
            is: (result) => (result === "POSITIVE"),
            then: () => Yup.boolean().required("laboratorytests.save-test-modal.form.sequenced.validation.required")
          })
    	.default(() => false),
	sequencingMethod: Yup.string()
		.oneOf([""].concat(SequencingMethods), 'form.validation.string.enum')
		.when(['result', 'sequenced'], {
            is: (result, sequenced) => (result === "POSITIVE" && sequenced),
            then: () => Yup.string().required("laboratorytests.save-test-modal.form.sequencingMethod.validation.required")
          })
		.default(() => ""),
	sequencingResult: Yup.string()
		.oneOf([""].concat(SequencingResults), 'form.validation.string.enum')
		.when(['result', 'sequenced'], {
            is: (result, sequenced) => (result === "POSITIVE" && sequenced),
            then: () => Yup.string().required("laboratorytests.save-test-modal.form.sequencingResult.validation.required")
          })
		.default(() => ""),
	sequencingRemarks: Yup.string()
		.max(255, 'laboratorytests.save-test-modal.form.sequencingRemarks.validation.maximumlength')
   		.default(() => ""),	
	viralIsolation: Yup.boolean()
    	.default(() => false),
	viralIsolationRemarks: Yup.string()
		.max(255, 'laboratorytests.save-test-modal.form.viralIsolationRemarks.validation.maximumlength')
   		.default(() => ""),	
}


const SaveMultipleGeneralFormValidationRules = {
	labelIdPrefix: Yup.string()
		.max(20, 'laboratorytests.save-multiple-laboratory-tests-modal.form.labelIdPrefix.validation.maximumlength')
		.default(() => DefaultLabelIdPrefix),
	pcrType: Yup.string()
		.oneOf([""].concat(PCRTypes), 'form.validation.string.enum')
		.required()
		.default(() => ""),
	targetVirusId: Yup.string()
		.required()
		.default(() => ""),
	protocolId: Yup.string()
		.required()
		.default(() => ""),
	result: Yup.string()
		.oneOf([""].concat(TestResults), 'form.validation.string.enum')
		.required()
		.default(() => ""),	
	cycleThreshold: Yup.string()
		.matches(Regex.DOUBLE, "laboratorytests.save-test-modal.form.cycleThreshold.validation.format")
		.when(['result','pcrType'], {
            is: (result, pcrType) => (result === "POSITIVE" && pcrType === "REALTIME"),
            then: () => Yup.string().required("laboratorytests.save-test-modal.form.cycleThreshold.validation.required")
          })
   		.default(() => ""),
	bandIntensity: Yup.string()
		.oneOf([""].concat(BandIntensities), 'form.validation.string.enum')
		.when(['result','pcrType'], {
            is: (result, pcrType) => (result === "POSITIVE" && pcrType === "CONVENTIONAL"),
            then: () => Yup.string().required("laboratorytests.save-test-modal.form.bandIntensity.validation.required")
          })
   		.default(() => ""),
	workgroupId: Yup.string()
		.required()
		.default(() => ""),
	testedById: Yup.string()
		.required()
		.default(() => ""),
	dateTested: Yup.date()
		.typeError('laboratorytests.save-test-modal.form.dateTested.validation.format')
		.max(new Date(), "collectionsamples.test-modal.form.dateTested.validation.future")
		.required()
		.default(() => ""),
		//.default(() => moment(new Date()).format("YYYY-MM-DD")),
	storageBoxId: Yup.string()
		//.required()
		.default(() => "")
}

const SaveMultipleSequencingFormValidationRules = {
	sequenced: Yup.boolean()
		.when(['result'], {
            is: (result) => (result === "POSITIVE"),
            then: () => Yup.boolean().required("laboratorytests.save-test-modal.form.sequenced.validation.required")
          })
    	.default(() => false),
	sequencingMethod: Yup.string()
		.oneOf([""].concat(SequencingMethods), 'form.validation.string.enum')
		.when(['result', 'sequenced'], {
            is: (result, sequenced) => (result === "POSITIVE" && sequenced),
            then: () => Yup.string().required("laboratorytests.save-test-modal.form.sequencingMethod.validation.required")
          })
		.default(() => ""),
	sequencingResult: Yup.string()
		.oneOf([""].concat(SequencingResults), 'form.validation.string.enum')
		.when(['result', 'sequenced'], {
            is: (result, sequenced) => (result === "POSITIVE" && sequenced),
            then: () => Yup.string().required("laboratorytests.save-test-modal.form.sequencingResult.validation.required")
          })
		.default(() => ""),
	sequencingRemarks: Yup.string()
		.max(255, 'laboratorytests.save-test-modal.form.sequencingRemarks.validation.maximumlength')
   		.default(() => ""),	
	viralIsolation: Yup.boolean()
    	.default(() => false),
	viralIsolationRemarks: Yup.string()
		.max(255, 'laboratorytests.save-test-modal.form.viralIsolationRemarks.validation.maximumlength')
   		.default(() => ""),	
}

const FilterFormValidationRules = {
	laboratoryId: Yup.string()
		.default(() => ""),
	storageBoxId: Yup.string()
		.default(() => ""),
	filter: Yup.string()
		.default(() => ""),
	filteredColumn: Yup.string()
		.default(() => "labelId"),
	start: Yup.string()
		.default(() => ""),
	end: Yup.string()
		.default(() => ""),
	materialSamples: Yup.array()
		.default(() => []),
	result: Yup.string()
		.oneOf([""].concat(TestResults), 'form.validation.string.enum')
   		.default(() => ""),
	targetVirusId: Yup.string()
		.default(() => ""),
}

const AdvancedFilterFormFields = {
	storageBoxId: true,
	filter: true,
	start: true,
	end : true,
	materialSamples : true
}

const LaboratoryTestConfig = { MaterialSamples, DefaultLabelIdPrefix, PCRTypes, TestResults, BandIntensities,
 	SequencingMethods, SequencingResults, GeneralFormValidationRules, SequencingFormValidationRules, 
	SaveMultipleGeneralFormValidationRules, SaveMultipleSequencingFormValidationRules, FilterFormValidationRules, AdvancedFilterFormFields};

export default LaboratoryTestConfig;