import * as Yup from "yup";
import moment from "moment";
import { isArrayWithLength } from "../helpers/commons";

const CaptureMethods = [
	"MIST_NET",
	"TRAP",
	"ACTIVE_SEARCH",
	"GUNSHOT",
	"RAN_OVER",
	"OTHER"
]

const CaptureTypes = [
	 "MARKED_AND_RELEASED",
	 "COLLECTED"
]

const MarkingTypes = [
   "TEMPORARY",
   "PERMANENT"
]

const TaxonGroups = [
	"AVES", // AVES
	"CHIROPTERA", // CHIROPTERA
	"RODENTIA", // RODENTIA
	"PRIMATES", // PRIMATES
	"OTHER" // OTHER
]

const Sexes = [
	"MALE",
	"FEMALE",
	"UNKNOWN"
]

const ReproductiveConditions = {
	"MALE":	[
		"ABDOMINAL_TESTES",
		"SCROTAL_TESTES",
		"BROOD_PATCH"
	],
	"FEMALE": [
		"IMMATURE_FEMALE",
		"VAGINAL_PLUG",
		"PREGNANT",
		"LACTATING",
		"POST_LACTATING",
		"OUT_OF_REPRODUCTIVE_CYCLE",
		"BROOD_PATCH"
	],
	"UNKNOWN":	[
		"BROOD_PATCH"
	],
	
}

const SymptomaticConditions = [
	"ASYMPTOMATIC",
	"SYMPTOMATIC",
	"DYING",
	"DEAD",
	"NOT_APPLICABLE"
]

const LifeStages = [
	"BABY",
	"YOUNG",
	"ADULT",
	"UNKNOWN"
]

const EstablishmentMeans = [
	"NATIVE",
	"EXOTIC",
	"DOMESTICATED",
	"INTRODUCED",
	"UNCERTAIN"
]

const DegreesOfEstablishment = [
	"WILDLIFE",
	"CAPTIVE",
	"WILDLIFE_SCREENING_CENTER"
]

const Samples = [
	"MIXED_SWAB",
	"MOUTH_SWAB",
	"RECTAL_SWAB",
	"CLOACAL_SWAB",
	"BLOOD",
	"LUNG",
	"KIDNEY",
	"LIVER",
	"INTESTINE",
	"HEART",
	"STOMACH",
	"SPLEEN",
	"TRACHEA",
	"FUR",
	"FEATHER",
	"FECES",
	"ECTOPARASITES",
	"ENTIRE_SPECIMEN",
	"OTHER"
]

const SamplesCodes = [
	"SM",
	"SO",
	"SA",
	"SC",
	"SG",
	"PU",
	"RI",
	"FI",
	"IN",
	"CO",
	"ES",
	"BA",
	"TR",
	"PE",
	"PN",
	"FE",
	"EC",
	"AN",
	"OU"
]

const SamplesMediums = [
	"PBS",
	"VTM",
	"ALCOHOL",
	"DRY",
	"EAGLE",
	"TRIZOL",
	"FTA",
	"LYSIS"
]

const SamplesPreservationMethods = [
	"FREEZING",
	"REFRIGERATION"
]

const SamplesCoolingAgents = [
	"DRY_ICE",
	"NITROGEN",
	"OTHER"
]

const OrganismIdValidationRules = {
	organismId: Yup.string()
	   	.matches("^[0-9]{2}[a-zA-Z]{2}[0-9]{1}[a-zA-Z]{1}[0-9]{5}$", "collections.save.form.organismId.validation.format")
	   	.required()
	   	.default(() => "")
}

const GeneralFormValidationRules = {
	workgroupId: Yup.string()
		.required()
		.default(() => ""),
	capturedById: Yup.string()
		.required()
		.default(() => ""),
	eventDate: Yup.date()
		.typeError('collections.save.form.eventDate.validation.format')
		.max(new Date(), "collections.save.form.eventDate.validation.future")
		.required()
		.default(() => moment(new Date()).format("YYYY-MM-DD")),
	county: Yup.string()
		.max(100, 'collections.save.form.county.validation.maximumlength')
		.required()
		.default(() => ""),
	stateProvince: Yup.string()
		.max(100, 'collections.save.form.stateProvince.validation.maximumlength')
		.default(() => ""),
	country: Yup.string()
		.max(100, 'collections.save.form.country.validation.maximumlength')
		.default(() => ""),
	locality: Yup.string()
		.max(255, 'collections.save.form.locality.validation.maximumlength')
		.default(() => ""),
	latitude: Yup.string()
   		.required()
		.matches("^(\\+|-)?(?:90(?:(?:\\.0{1,})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,})?))$", "collections.save.form.latitude.validation.format")
   		.default(() => ""),
	longitude: Yup.string()
	   	.required()
		.matches("^(\\+|-)?(?:180(?:(?:\\.0{1,})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,})?))$", "collections.save.form.longitude.validation.format")
   		.default(() => ""),
	captureMethod: Yup.string()
		.oneOf([""].concat(CaptureMethods), 'form.validation.string.enum')
		.required()
   		.default(() => ""),
   	samplingEffort: Yup.string()
		.max(255, 'collections.save.form.samplingEffort.validation.maximumlength')
   		.default(() => ""),	
   	habitat: Yup.string()
		.max(255, 'collections.save.form.habitat.validation.maximumlength')
   		.default(() => ""),	
	captureType: Yup.string()
		.oneOf([""].concat(CaptureTypes), 'form.validation.string.enum')
		.required()
   		.default(() => ""),
	markingType: Yup.string()
		.oneOf([""].concat(MarkingTypes), 'form.validation.string.enum')
   		.default(""),
	bandMarkingInfo: Yup.string()
		.max(100, 'collections.save.form.bandMarkingInfo.validation.maximumlength')
   		.default(() => ""),
   	fieldNumber: Yup.string()
		.max(100, 'collections.save.form.fieldNumber.validation.maximumlength')
   		.default(() => "")
}

const TaxonomyFormValidationRules = {
	scientificName: Yup.string()
		.max(100, 'collections.save.form.name.scientificName.maximumlength')
		.required()
   		.default(() => ""),
	taxonGroup: Yup.string()
		.required()
		.oneOf([""].concat(TaxonGroups), 'form.validation.string.enum')
   		.default(() => ""),
	vernacularName: Yup.string()
		.max(100, 'collections.save.form.name.vernacularName.maximumlength')
		.required()
   		.default(() => ""),
   	identifiedById: Yup.string()
		.required()
		.default(() => ""),
	dateIdentified: Yup.date()
		.typeError('collections.save.form.dateIdentified.validation.format')
		.max(new Date(), "collections.save.form.dateIdentified.validation.future")
		.required()
		.default(() => moment(new Date()).format("YYYY-MM-DD")),
   	taxonId: Yup.string()
   		.default(() => ""),
   	taxonRank: Yup.string()
   		.max(100, 'collections.save.form.name.taxonRank.maximumlength')
   		.default(() => ""),
   	kingdom: Yup.string()
   		.max(100, 'collections.save.form.name.kingdom.maximumlength')
   		.default(() => ""),
   	phylum: Yup.string()
   		.max(100, 'collections.save.form.name.phylum.maximumlength')
   		.default(() => ""),
	taxonClass: Yup.string()
		.max(100, 'collections.save.form.name.taxonClass.maximumlength')
		//.required()
   		.default(() => ""),
   	order: Yup.string()
		.max(100, 'collections.save.form.name.order.maximumlength')
		//.required()
   		.default(() => ""),
	family: Yup.string()
		.max(100, 'collections.save.form.name.family.maximumlength')
		//.required()
   		.default(() => ""),
	genus: Yup.string()
		.max(100, 'collections.save.form.name.genus.maximumlength')
   		.default(() => ""),
	specificEpithet: Yup.string()
		.max(100, 'collections.save.form.name.specificEpithet.maximumlength')
   		.default(() => ""),
   	infraspecificEpithet: Yup.string()
		.max(100, 'collections.save.form.name.infraspecificEpithet.maximumlength')
   		.default(() => "")
	
}

const HistoryFormValidationRules = {
	sex: Yup.string()
		.oneOf([""].concat(Sexes), 'form.validation.string.enum')
		.required()
   		.default(() => ""),
	reproductiveCondition: Yup.string()
		.when('sex', ([sex], schema) => {
			if (Object.keys(ReproductiveConditions).includes(sex)) {
				return Yup.string()
					.oneOf(ReproductiveConditions[sex], 'form.validation.string.enum');		
	        }
			return schema;
        })
   		.default(() => ""),
    lifeStage: Yup.string()
		.oneOf([""].concat(LifeStages), 'form.validation.string.enum')
		.required()
   		.default(() => ""),
    degreeOfEstablishment: Yup.string()
		.oneOf([""].concat(DegreesOfEstablishment), 'form.validation.string.enum')
		.required()
   		.default(() => ""),
   	establishmentMeans: Yup.string()
		.oneOf([""].concat(EstablishmentMeans), 'form.validation.string.enum')
		.required()
   		.default(() => ""),
	symptomaticCondition: Yup.string()
		.oneOf([""].concat(SymptomaticConditions), 'form.validation.string.enum')
   		.default(() => ""),
	historyRemarks: Yup.string()
		.max(255, 'collections.save.form.historyRemarks.maximumlength')
		.default(() => "")
}

const MeasurementFields = {
	"AVES":	[
		{"key": "totalLength", "required": true, "cols": 3, "append": true, "placeholder": false},
		{"key": "weight", "required": true, "cols": 3, "append": true, "placeholder": false},
		{"key": "tarsusLength", "required": false, "cols": 3, "append": true, "placeholder": false},
		{"key": "wingLength", "required": false, "cols": 3, "append": true, "placeholder": false},
		{"key": "tailLength", "required": false, "cols": 3, "append": true, "placeholder": false},
		{"key": "culmenLength", "required": false, "cols": 3, "append": true, "placeholder": false},
		{"key": "nostrilsLength", "required": false, "cols": 3, "append": true, "placeholder": false},
	],
	"CHIROPTERA":	[
		{"key": "forearmLength", "required": true, "cols": 3, "append": true, "placeholder": false},
		{"key": "weight", "required": true, "cols": 3, "append": true, "placeholder": false},
		{"key": "headBodyLength", "required": false, "cols": 3, "append": true, "placeholder": false},
		{"key": "tailLength", "required": false, "cols": 3, "append": true, "placeholder": false},
		{"key": "earLength", "required": false, "cols": 3, "append": true, "placeholder": false},
		{"key": "footIncludingNailLength", "required": false, "cols": 3, "append": true, "placeholder": false},
		{"key": "tragusLength", "required": false, "cols": 3, "append": true, "placeholder": false},
		{"key": "calcarLength", "required": false, "cols": 3, "append": true, "placeholder": false},
		{"key": "tibiaLength", "required": false, "cols": 3, "append": true, "placeholder": false},
	],
	"RODENTIA":	[
		{"key": "headBodyLength", "required": true, "cols": 3, "append": true, "placeholder": false},
		{"key": "tailLength", "required": true, "cols": 3, "append": true, "placeholder": false},
		{"key": "weight", "required": true, "cols": 3, "append": true, "placeholder": false},
		{"key": "earLength", "required": false, "cols": 3, "append": true, "placeholder": false},
		{"key": "footLength", "required": false, "cols": 3, "append": true, "placeholder": false},
	],
	"PRIMATES":	[
		{"key": "totalLength", "required": true, "cols": 3, "append": true, "placeholder": false},
		{"key": "tailLength", "required": true, "cols": 3, "append": true, "placeholder": false},
		{"key": "footLength", "required": true, "cols": 3, "append": true, "placeholder": false},
		{"key": "footIncludingNailLength", "required": true, "cols": 3, "append": true, "placeholder": false},
		{"key": "earLength", "required": true, "cols": 3, "append": true, "placeholder": false},
		{"key": "weight", "required": true, "cols": 3, "append": true, "placeholder": false},
	],
	"OTHER":	[
		{"key": "morphometricMeasurements", "required": true, "cols": 9, "append": false, "placeholder": true},
		{"key": "weight", "required": true, "cols": 3, "append": true, "placeholder": false},
	]
}

const PhysicalExamFormValidationRules = {
   	unableToCompleteBasicMeasurements: Yup.boolean()
    	.default(() => false),
	forearmLength: Yup.string()
		.matches("^[0-9]{0,}(\\.[0-9]{1,})?$", "collections.save.form.forearmLength.validation.format")
		.when(['unableToCompleteBasicMeasurements', 'taxonGroup'], ([unableToCompleteBasicMeasurements, taxonGroup], schema) => {			
			if (!unableToCompleteBasicMeasurements && MeasurementFields[taxonGroup] &&  MeasurementFields[taxonGroup].find((field) => (field.key === "forearmLength") && (field.required))) {
				return schema.required()
	        }
			return schema;
        })
   		.default(() => ""),
   	headBodyLength: Yup.string()
		.matches("^[0-9]{0,}(\\.[0-9]{1,})?$", "collections.save.form.headBodyLength.validation.format")
		.when(['unableToCompleteBasicMeasurements', 'taxonGroup'], ([unableToCompleteBasicMeasurements, taxonGroup], schema) => {			
			if (!unableToCompleteBasicMeasurements && MeasurementFields[taxonGroup] &&  MeasurementFields[taxonGroup].find((field) => (field.key === "headBodyLength") && (field.required))) {
				return schema.required()		
	        }
			return schema;
        })
   		.default(() => ""),
   	tailLength: Yup.string()
		.matches("^[0-9]{0,}(\\.[0-9]{1,})?$", "collections.save.form.tailLength.validation.format")
		.when(['unableToCompleteBasicMeasurements', 'taxonGroup'], ([unableToCompleteBasicMeasurements, taxonGroup], schema) => {			
			if (!unableToCompleteBasicMeasurements && MeasurementFields[taxonGroup] &&  MeasurementFields[taxonGroup].find((field) => (field.key === "tailLength") && (field.required))) {
				return schema.required()		
	        }
			return schema;
        })
   		.default(() => ""),
   	earLength: Yup.string()
		.matches("^[0-9]{0,}(\\.[0-9]{1,})?$", "collections.save.form.earLength.validation.format")
		.when(['unableToCompleteBasicMeasurements', 'taxonGroup'], ([unableToCompleteBasicMeasurements, taxonGroup], schema) => {			
			if (!unableToCompleteBasicMeasurements && MeasurementFields[taxonGroup] &&  MeasurementFields[taxonGroup].find((field) => (field.key === "earLength") && (field.required))) {
				return schema.required()		
	        }
			return schema;
        })
   		.default(() => ""),
   	footLength: Yup.string()
		.matches("^[0-9]{0,}(\\.[0-9]{1,})?$", "collections.save.form.footLength.validation.format")
		.when(['unableToCompleteBasicMeasurements', 'taxonGroup'], ([unableToCompleteBasicMeasurements, taxonGroup], schema) => {			
			if (!unableToCompleteBasicMeasurements && MeasurementFields[taxonGroup] &&  MeasurementFields[taxonGroup].find((field) => (field.key === "footLength") && (field.required))) {
				return schema.required()		
	        }
			return schema;
        })
   		.default(() => ""),
   	footIncludingNailLength: Yup.string()
		.matches("^[0-9]{0,}(\\.[0-9]{1,})?$", "collections.save.form.footIncludingNailLength.validation.format")
		.when(['unableToCompleteBasicMeasurements', 'taxonGroup'], ([unableToCompleteBasicMeasurements, taxonGroup], schema) => {			
			if (!unableToCompleteBasicMeasurements && MeasurementFields[taxonGroup] &&  MeasurementFields[taxonGroup].find((field) => (field.key === "footIncludingNailLength") && (field.required))) {
				return schema.required()		
	        }
			return schema;
        })
   		.default(() => ""),
   	tragusLength: Yup.string()
		.matches("^[0-9]{0,}(\\.[0-9]{1,})?$", "collections.save.form.tragusLength.validation.format")
		.when(['unableToCompleteBasicMeasurements', 'taxonGroup'], ([unableToCompleteBasicMeasurements, taxonGroup], schema) => {			
			if (!unableToCompleteBasicMeasurements && MeasurementFields[taxonGroup] &&  MeasurementFields[taxonGroup].find((field) => (field.key === "tragusLength") && (field.required))) {
				return schema.required()		
	        }
			return schema;
        })
   		.default(() => ""),
   	calcarLength: Yup.string()
		.matches("^[0-9]{0,}(\\.[0-9]{1,})?$", "collections.save.form.calcarLength.validation.format")
		.when(['unableToCompleteBasicMeasurements', 'taxonGroup'], ([unableToCompleteBasicMeasurements, taxonGroup], schema) => {			
			if (!unableToCompleteBasicMeasurements && MeasurementFields[taxonGroup] &&  MeasurementFields[taxonGroup].find((field) => (field.key === "calcarLength") && (field.required))) {
				return schema.required()		
	        }
			return schema;
        })
   		.default(() => ""),
   	tibiaLength: Yup.string()
		.matches("^[0-9]{0,}(\\.[0-9]{1,})?$", "collections.save.form.tibiaLength.validation.format")
		.when(['unableToCompleteBasicMeasurements', 'taxonGroup'], ([unableToCompleteBasicMeasurements, taxonGroup], schema) => {			
			if (!unableToCompleteBasicMeasurements && MeasurementFields[taxonGroup] &&  MeasurementFields[taxonGroup].find((field) => (field.key === "tibiaLength") && (field.required))) {
				return schema.required()		
	        }
			return schema;
        })
   		.default(() => ""),
   	totalLength: Yup.string()
		.matches("^[0-9]{0,}(\\.[0-9]{1,})?$", "collections.save.form.totalLength.validation.format")
		.when(['unableToCompleteBasicMeasurements', 'taxonGroup'], ([unableToCompleteBasicMeasurements, taxonGroup], schema) => {			
			if (!unableToCompleteBasicMeasurements && MeasurementFields[taxonGroup] &&  MeasurementFields[taxonGroup].find((field) => (field.key === "totalLength") && (field.required))) {
				return schema.required()		
	        }
			return schema;
        })
   		.default(() => ""),
   	tarsusLength: Yup.string()
		.matches("^[0-9]{0,}(\\.[0-9]{1,})?$", "collections.save.form.tarsusLength.validation.format")
		.when(['unableToCompleteBasicMeasurements', 'taxonGroup'], ([unableToCompleteBasicMeasurements, taxonGroup], schema) => {			
			if (!unableToCompleteBasicMeasurements && MeasurementFields[taxonGroup] &&  MeasurementFields[taxonGroup].find((field) => (field.key === "tarsusLength") && (field.required))) {
				return schema.required()		
	        }
			return schema;
        })
   		.default(() => ""),
   	wingLength: Yup.string()
		.matches("^[0-9]{0,}(\\.[0-9]{1,})?$", "collections.save.form.wingLength.validation.format")
		.when(['unableToCompleteBasicMeasurements', 'taxonGroup'], ([unableToCompleteBasicMeasurements, taxonGroup], schema) => {			
			if (!unableToCompleteBasicMeasurements && MeasurementFields[taxonGroup] &&  MeasurementFields[taxonGroup].find((field) => (field.key === "wingLength") && (field.required))) {
				return schema.required()		
	        }
			return schema;
        })
   		.default(() => ""),
   	culmenLength: Yup.string()
		.matches("^[0-9]{0,}(\\.[0-9]{1,})?$", "collections.save.form.culmenLength.validation.format")
		.when(['unableToCompleteBasicMeasurements', 'taxonGroup'], ([unableToCompleteBasicMeasurements, taxonGroup], schema) => {			
			if (!unableToCompleteBasicMeasurements && MeasurementFields[taxonGroup] &&  MeasurementFields[taxonGroup].find((field) => (field.key === "culmenLength") && (field.required))) {
				return schema.required()		
	        }
			return schema;
        })
   		.default(() => ""),
   	nostrilsLength: Yup.string()
		.matches("^[0-9]{0,}(\\.[0-9]{1,})?$", "collections.save.form.nostrilsLength.validation.format")
		.when(['unableToCompleteBasicMeasurements', 'taxonGroup'], ([unableToCompleteBasicMeasurements, taxonGroup], schema) => {			
			if (!unableToCompleteBasicMeasurements && MeasurementFields[taxonGroup] &&  MeasurementFields[taxonGroup].find((field) => (field.key === "nostrilsLength") && (field.required))) {
				return schema.required()		
	        }
			return schema;
        })
   		.default(() => ""),
	morphometricMeasurements: Yup.string()
		.max(100, 'collections.save.form.morphometricMeasurements.maximumlength')
		.when(['unableToCompleteBasicMeasurements', 'taxonGroup'], ([unableToCompleteBasicMeasurements, taxonGroup], schema) => {			
			if (!unableToCompleteBasicMeasurements && MeasurementFields[taxonGroup] &&  MeasurementFields[taxonGroup].find((field) => (field.key === "morphometricMeasurements") && (field.required))) {
				return schema.required()	
	        }
			return schema;
        })
   		.default(() => ""),
	weight: Yup.string()
		.matches("^[0-9]{0,}(\\.[0-9]{1,})?$", "collections.save.form.weight.validation.format")
		.when(['unableToCompleteBasicMeasurements', 'taxonGroup'], ([unableToCompleteBasicMeasurements, taxonGroup], schema) => {			
			if (!unableToCompleteBasicMeasurements && MeasurementFields[taxonGroup] &&  MeasurementFields[taxonGroup].find((field) => (field.key === "weight") && (field.required))) {
				return schema.required()		
	        }
			return schema;
        })
   		.default(() => ""),
	ectoparasite: Yup.string()
		.max(255, 'collections.save.form.ectoparasite.maximumlength')
   		.default(() => "")
}

const SamplesFormValidationRules = {
	samples: Yup.array()
		.min(1, 'collections.save.form.samples.validation.required')
		.default(() => []),
    otherSampleDescription: Yup.string()
		.max(100, 'collections.save.form.otherSampleDescription.maximumlength')
        .when(['samples'], {
            is: (samples) => (isArrayWithLength(samples) && samples.includes("OTHERS")),
            then: Yup.string().required("collections.save.form.otherSampleDescription.validation.required")
          })
         .default(() => ""),
    preparedById: Yup.string()
		.required()
		.default(() => ""),
	/*samplesMedium: Yup.string()
		.oneOf([""].concat(SamplesMediums), 'form.validation.string.enum')
		.required()
   		.default(() => ""),*/
	samplesMediums: Yup.array()
		.min(1, 'collections.save.form.samplesMediums.validation.required')
		.default(() => []),
	samplesPreservationMethod: Yup.string()
		.oneOf([""].concat(SamplesPreservationMethods), 'form.validation.string.enum')
		.required()
		.default(() => ""),
	samplesCoolingAgent: Yup.string()
		.oneOf([""].concat(SamplesCoolingAgents), 'form.validation.string.enum')
		.required()
		.default(() => ""),
	samplesRemarks: Yup.string()
		.max(255, 'collections.save.form.samplesRemarks.maximumlength')
		.default(() => "")
}

const PhotosFormValidationRules = {
	photos: Yup.array().default(() => [])
}

const ExtraFormValidationRules = {
	sisbioLicenseNumber: Yup.string()
		.max(100, 'collections.save.form.sisbioLicenseNumber.validation.maximumlength')
   		.default(() => ""),
	ceuaLicenseNumber: Yup.string()
		.max(100, 'collections.save.form.ceuaLicenseNumber.validation.maximumlength')
   		.default(() => ""),
	museumCatalogNumber: Yup.string()
		.max(100, 'collections.save.form.museumCatalogNumber.validation.maximumlength')
   		.default(() => ""),
	sisgenId: Yup.string()
		.max(100, 'collections.save.form.sisgenId.validation.maximumlength')
   		.default(() => ""),
	remarks: Yup.string()
		.max(255, 'collections.save.form.remarks.validation.maximumlength')
   		.default(() => ""),	
}

const CollectionConfig = { CaptureMethods, CaptureTypes, MarkingTypes, TaxonGroups, Sexes, ReproductiveConditions, SymptomaticConditions, 
		LifeStages, EstablishmentMeans, DegreesOfEstablishment, Samples, SamplesCodes, SamplesMediums, SamplesPreservationMethods, SamplesCoolingAgents,
		GeneralFormValidationRules, TaxonomyFormValidationRules, HistoryFormValidationRules, PhysicalExamFormValidationRules, MeasurementFields,
		SamplesFormValidationRules, PhotosFormValidationRules, ExtraFormValidationRules, OrganismIdValidationRules
};

export default CollectionConfig;