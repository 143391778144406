import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimesCircle, faCheck } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import FormLabel from 'react-bootstrap/FormLabel';
import InputGroup from 'react-bootstrap/InputGroup';

import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import DismissableFeedback from "../components/DismissableFeedback";

import { useAuth } from "../services/use-auth";
import API from "../services/backend-api";

import { AuthError, ValidationError } from "../helpers/custom-errors";
import { isArrayWithLength } from "../helpers/commons";

import "react-bootstrap-typeahead/css/Typeahead.css";
import "../styles/Typeahead.css";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const validationSchema = Yup.object().shape({
	   user: Yup.object({
			id: Yup.string().required("components.search-user-modal.form.user.validation.required"),
		})
});


const SearchUserModal = props => {

	const [_error, _setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [options, setOptions] = useState([]);
	
	const { t } = useTranslation();
	
	const auth = useAuth();

	const handleSearch = (query) => {
		
		setIsLoading(true);
	    
	    API.findUsers({filter: query})
	      .then(({ list }) => {
	    	  const options = list.map((i) => ({
	    		  id: i.id,
	    		  name: i.firstName + " " + i.lastName,
	    		  institution: i.institution,
	    	  }));

	    	  setOptions(options);
	    	  
	       })
	      .catch(error => { 
		      if (error instanceof AuthError) {		
		    	  auth.onUnathorized(error);
		      } else if (error instanceof ValidationError) {		
          						
				 log.info("Find Users Attempt Failed: ", error.message);
          						
				 _setError(error.message);		
          						
          	 } else {
          		log.error("Find Users Error: ", error.message);
          		_setError(t('error.api.general'));
          	 }
		 })
		 .finally(() => {
			 setIsLoading(false);
		 });
	  };

	// Bypass client-side filtering by returning `true`. Results are already
	// filtered by the search endpoint, so no need to do it again.
	const filterBy = () => true;
		
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-search-user"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-search-user">
  					<Trans i18nKey="components.search-user-modal.title">Search User</Trans>
  				</Modal.Title>
  			</Modal.Header>
  				<Formik
		      		initialValues={{ user: {id: ''}}}
		      		validationSchema={validationSchema}
		      		validateOnChange={true}
		      		validateOnBlur={true}     
		      		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);
					
		      			actions.setSubmitting(false);
						props.onChange(values.user);
					}}
		      	>
		      	{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue}) => (	
		      		<Form className="form-search-user" noValidate>		
		      		<Modal.Body className="mb-3">
		  				<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  
				    
				    	<FormLabel><Trans i18nKey="components.search-user-modal.form.filter.label">Search User by Name</Trans></FormLabel>
				    	<InputGroup>
          						<InputGroup.Prepend>
          							<InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
          						</InputGroup.Prepend>
          						<AsyncTypeahead
          							filterBy={filterBy}
          							id="filter"
          							name="filter"
          							isLoading={isLoading}
          							labelKey="name"
          							minLength={3}
          							onSearch={handleSearch}
          							onBlur={handleBlur}
          							onChange={(items) => { if(isArrayWithLength(items) && items.lenght !== 0) { setFieldValue("user", items[0]);}}}
          							isInvalid={!(errors.user == null)}
          							options={options}
          							placeholder={t('components.search-user-modal.form.filter.placeholder')}
          							emptyLabel={t('components.search-user-modal.form.filter.no-matches-found')}
          							searchText={t('components.search-user-modal.form.filter.searching')}
          							promptText={t('components.search-user-modal.form.filter.prompt')}
          							renderMenuItemChildren={(option, props) => (
          								<Highlighter search={props.text}>
          							      {option[props.labelKey] + " (" + option.institution + ")"}
          							    </Highlighter>
          							)}
          						/>	
          				</InputGroup>
          				 {!(errors.user == null) 
          					? <div className="text-left invalid-feedback" style={{display: "block"}}><Trans i18nKey={errors.user.id} /></div>
							: null
						}				
          				</Modal.Body>
          				<Modal.Footer>			      		
          				      <Button variant="success" type="submit" disabled={isSubmitting}> 
          				      		{isSubmitting ? <Trans i18nKey="components.search-user-modal.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={faCheck} /> <Trans i18nKey="components.search-user-modal.form.submit">Submit</Trans></span>} 
          				      </Button>
          				      <Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="components.search-user-modal.cancel">Cancel</Trans></Button>	
          				</Modal.Footer>
              		</Form>	

		      )}
		      </Formik>
		   </Modal>
	);
}

export default SearchUserModal;
