import React,  { useState, useEffect } from 'react';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import log from 'loglevel';

import Loader from "../components/Loader";
import PagedList from "../components/PagedList";
import SingleFilterForm from "../components/SingleFilterForm";

import API from "../services/backend-api";

const Workgroups = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [pagedWorkgroups, setPagedWorkgroups] = useState(null);
	
	const location = useLocation();
	
	let navigate = useNavigate();
	let context = useOutletContext();
		
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
		
		listWorkgroups({})
			.catch(error => { 
		 		log.error("Error Loading Initial Workgroups List: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		  
	}, []);
	
  const updateWorkgroup = (workgroup) => {
	  navigate("/workgroups/update", { state: { item: workgroup}});
  }
  
  const addWorkgroup = () => {
	  navigate("/workgroups/create");
  }
    
  const updatePage = (values) => {
	  listWorkgroups(values)
		.catch(error => { 
			log.error("Error Paginating Workgroups List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const sortDataTable = (values) => {
	  values.pageIndex = 0;
	  listWorkgroups(values)
		.catch(error => { 
			log.error("Error Sorting Workgroups List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const displayInactive = (item) => { 
	return (item.active) ? "" : "inactive-row"	
  }
  
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
  const listWorkgroups = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.listWorkgroups(mergeQueryParams(pagedWorkgroups, values)).then(response => {
			setPagedWorkgroups(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
	if (isLoading) 
		return <Loader />
	
	if (!pagedWorkgroups) 
		return null;
		
	return (
		<PagedList
			i18nPrefix="workgroups.list."
			page={pagedWorkgroups}
			pageSizeOptions={[10,25,50]}
			filterFormComponent={SingleFilterForm}
			filterParameters={{ filter: pagedWorkgroups.filter, filteredColumn: pagedWorkgroups.filteredColumn }}
			onFilter={listWorkgroups} 
			onError={context.onError}
			onSort={sortDataTable}
			onAdd={addWorkgroup}
			onPage={updatePage}
			dataTableColumns={["name", "headquarters","shortcode","types","region","totalUsers"]} 
			dataTableSortableColumns={["name", "headquarters", "shortcode", "region"]}
			dataTableCustomDisplayColumns={[["types", "Enum"]]}
			dataTableActions={[updateWorkgroup]}
			dataTableDefaultAction={updateWorkgroup}
			dataTableCustomRowStyle={displayInactive}		
		/>
	);
}

export default Workgroups;
