import React from "react";
import Spinner from 'react-bootstrap/Spinner'

//loading component for suspense fallback
const Loader = props => {
	const style = { position: "fixed", top: "50%", left: "50%"};

	return (
		<Spinner style={style} animation="border" role="status">
			<span className="sr-only">Carregando...</span>
		</Spinner>
	);
};

export default Loader;