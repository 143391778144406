import React,  { Fragment, useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle} from '@fortawesome/free-solid-svg-icons'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import FormCheck from 'react-bootstrap/FormCheck';
import Button from 'react-bootstrap/Button'

import FormControlErrors from "../components/FormControlErrors";
import Switch from "../components/Switch";

import DataTable from "../components/DataTable";
import CollectionSampleInputSearch from "../components/CollectionSampleInputSearch";
import StorageBoxInputSearch from "../components/StorageBoxInputSearch";

import Loader from "../components/Loader";
import SaveNucleicAcidExtractionModal from "../components/SaveNucleicAcidExtractionModal";
import SaveTissueDissociationModal from "../components/SaveTissueDissociationModal";
import SaveCDNASynthesisModal from "../components/SaveCDNASynthesisModal";
import SaveLaboratoryTestModal from "../components/SaveLaboratoryTestModal";
import ConfirmationDialogModal from "../components/ConfirmationDialogModal";

import moment from "moment";
import { DatePicker } from "react-tempusdominus-bootstrap";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

import { numToColumn } from "../helpers/commons";

import API from "../services/backend-api";
import { useAuth } from "../services/use-auth";
import { useConfig } from "../services/use-config";

import { parseInputDate } from "../helpers/commons";
import { isArrayWithLength } from "../helpers/commons";

const GeneralSection = props => {

	const [workgroups, setWorkgroups] = useState([]);
	const [workgroupUsers, setWorkgroupUsers] = useState([]);
	const [availableStorageBoxSlots, setAvailableStorageBoxSlots] = useState([]);
	const [cloneOrganismId, setCloneOrganismId] = useState(true);
	
	const { t } = useTranslation();
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
		
		if (props.origin === "collection-samples") {
		
			if (props.action === "create") {
				setCloneOrganismId(true);
				props.setFieldValue("labelId", props.values.organismId);
			} else {
				setCloneOrganismId((props.values.organismId === props.values.labelId));
			}
		}
		
			//Fetch Laboratory Workgroups
			API.findLaboratories({})
				.then(response => {
					if (isMounted) setWorkgroups(response.list);
				})
				.catch(error => { 
					log.error("Error Loading Analysis Workgroups: ", error.message);
		 			props.onError(error);
				})
				.finally(() => {
		 			//Get Workgroup Users
					if (props.values.workgroupId) {
						getWorkgroupUsers(props.values.workgroupId);
					}
				});
		
		
		 return () => { isMounted = false };
		  
	}, []);
	
	const getWorkgroupUsers = (workgroupId) => {
    	API.findUsers({"filteredColumn": "workgroupId", "filter": workgroupId})
			.then(response => {
					setWorkgroupUsers(response.list); 					
			})
			.catch(error => { 
				log.error("Error Loading Workgroup Users: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		  //Do nothing yet
			});
    }

	/*const getStorageBoxes = (workgroupId) => {
    	API.findStorageBoxes(workgroupId, {})
			.then(response => {
				setStorageBoxes(response.list); 					
			})
			.catch(error => { 
				log.error("Error Loading Workgroup Storage Boxes: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		  //Do nothing yet
			});
    }*/

	const getAvailableStorageBoxSlots = (storageBox) => {
		
		if (storageBox) {
			
			let allStorageBoxSlots = Array.apply(null, {length: Number(storageBox.cols)})
				.map((_, col) => { 
					return Array.apply(null, {length: Number(storageBox.rows)})
						.map((_, row) => { 
							return Object.assign({}, {col: col+1, row: row+1});								
						})
					}
				).flat();
				
			//setAvailableStorageBoxSlots(allStorageBoxSlots);
			
			//Get Storage Box Items
			getStorageBoxItems(storageBox)
				.then(response => {
		
					let unavailableSlotsMap = new Map(response.list
								.filter(s => (s.slotCol && s.slotRow && s.type !== "LABORATORY_SAMPLE" && props.values.id !== s.id ))
								.map((s) => [ s.slotRow+"-"+s.slotCol, s]));
					
					setAvailableStorageBoxSlots(allStorageBoxSlots.filter(s => !unavailableSlotsMap.get(s.row+"-"+s.col)));				
				})
				.catch(error => { 
					log.error("Error Loading Storage Box Samples: ", error.message);
		 			props.onError(error);
			})
			
		} else {
			setAvailableStorageBoxSlots([]);
		}
	}
	
	const getStorageBoxItems = (storageBox) =>
		new Promise((resolve, reject) => {
			API.getStorageBoxItems(storageBox.id).then(response => {
			resolve(response);
		}).catch(error => { 
			reject(error);
		});
	});
	
	const handleWorkgroupChange = (e) => {
        
        props.setFieldValue("workgroupId", e.target.value);
        
        if (!e.target.value) {
        	setWorkgroupUsers([]);
        	
			props.setFieldValue("screenedById", "");
			props.setFieldValue("storageBoxId", "");
			props.setFieldValue("slotCol", "");
			props.setFieldValue("slotRow", "");
			
        	return;
        }
        
        //Get Workgroup Users
        getWorkgroupUsers(e.target.value);

		//Get Storage Boxes
		//getStorageBoxes(e.target.value);
        
        //Reset screenedBy and storageBox
        props.setFieldValue("screenedById", "");
		props.setFieldValue("storageBoxId", "");
		props.setFieldValue("slotCol", "");
		props.setFieldValue("slotRow", "");
    }

	const handleStorageBoxLoad = (storageBox) => {
		getAvailableStorageBoxSlots(storageBox);
	}

	const handleStorageBoxChange = (storageBox) => {
		
        if (!storageBox) {
			setAvailableStorageBoxSlots([]);
        	
			props.setFieldValue("storageBoxId", "");
			props.setFieldValue("slotCol", "");
			props.setFieldValue("slotRow", "");
			
        	return;
        } /*else if (storageBox.id === props.values.storageBoxId) {
			return;
		}*/


		//Get Available Storage Box Slots
		getAvailableStorageBoxSlots(storageBox);
		
		props.setFieldValue("storageBoxId", storageBox.id);

        //Reset StorageBox Slot Position
		props.setFieldValue("slotCol", "");
		props.setFieldValue("slotRow", "");
    }

	const handleStorageBoxSlotPositionChange = (e) => {
		
		if (!e.target.value) {
			props.setFieldValue("slotCol", "");
			props.setFieldValue("slotRow", "");
		} else {
			let slotPositionInfo = e.target.value.split("-");
			props.setFieldValue("slotRow", slotPositionInfo[0]);		
		    props.setFieldValue("slotCol", slotPositionInfo[1]);
		}
		
	}

	const handleCloneOrganismIdChange = (e) => {
        
		setCloneOrganismId(e.target.checked);
		
        props.setFieldValue("labelId", (e.target.checked) ? props.values.organismId : "");
        
    }

	const buildGeneralInfo = (s) => {
       
		let generalInfo = s.organismId
			+ " - " + t("models.collections.enums.samples."+s.materialSample)
			+ " - " + s.scientificName
			+ " - " + t("models.collections.enums.sex."+s.sex)
			+ " - " + s.county + "/" + s.stateProvince;
    
		return generalInfo;
    }

	const buildSpecimenInfo = (s) => {
       
		let specimenInfo = s.scientificName + " (" + t("models.collections.enums.sex."+s.sex) + ")";
    
		return specimenInfo;
    }

	const buildLocationInfo = (s) => {
	       
		let locationInfo = s.county + "/" + s.stateProvince;
    
		return locationInfo;
    }

	const handleCollectionSampleChange = (collectionSample) => {
		
		if (!collectionSample) {
			
			props.setFieldValue("id", "");
			props.setFieldValue("collectionId", "");
			props.setFieldValue("materialSample", "");
        	props.setFieldValue("organismId", "");

			if (cloneOrganismId) {
				props.setFieldValue("labelId", "");
			}
			
			props.setFieldError("labInfo", "");
			
        	return;
        } 
		
		
		props.setFieldValue("id", collectionSample.laboratorySampleId);
		props.setFieldValue("collectionId", collectionSample.collectionId);
		props.setFieldValue("materialSample", collectionSample.materialSample);
        props.setFieldValue("organismId", collectionSample.organismId);

		if (cloneOrganismId) {
			props.setFieldValue("labelId", collectionSample.organismId);
		}
		
		//Compare workgroupId, storageBoxId, slotCol, SlotRow
		if (collectionSample.labInfo) {
			
			if (collectionSample.workgroupId !== props.values.workgroupId || 
				collectionSample.storageBoxId !== props.values.storageBoxId ) {
					props.setFieldError("labInfo", t(props.i18nPrefix+"form.labInfo.validation.mismatch", {labInfo: collectionSample.labInfo}));
					return;
			} 
			
		} 
		
		//If we got here, then we can cleanup labInfo error
		props.setFieldError("labInfo", "");
		
    }

	return(
		<Fragment>
			<Row >
				
				{ (props.action === "create" && props.origin === "storagebox-items") ?
				 <FormGroup as={Col} controlId="formGridSample">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.collection-sample-input-search.label"}>Select Collected Sample</Trans> *</FormLabel>
					<CollectionSampleInputSearch 
				    	i18nPrefix={props.i18nPrefix+"form."}
				    	onError={props.onError}
				    	isInvalid={!(props.errors.collectionId == null)}
				    	onChange={handleCollectionSampleChange}
				    />
					<FormControlErrors block={true} errors={props.errors.collectionId} />
					<FormControlErrors block={true} errors={props.errors.labInfo} />
				 </FormGroup>
				
				:
				
				(props.isModal) ?
				<FormGroup as={Col} controlId="formGridGeneralInfo">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.generalInfo.label"}>General Info</Trans></FormLabel>
					<FormControl plaintext readOnly defaultValue={buildGeneralInfo(props.values)} />
					<FormControlErrors block={true} errors={props.errors.collectionId} /> 
				</FormGroup>
				: 
				<Fragment>
					<FormGroup as={Col} controlId="formGridGeneralInfo1">
				    	<FormLabel><Trans i18nKey={props.i18nPrefix+"form.specimenInfo.label"}>Specimen Info</Trans></FormLabel>
						<FormControl className="text-uppercase" plaintext readOnly defaultValue={buildSpecimenInfo(props.values)} />
					</FormGroup>
					<FormGroup as={Col} controlId="formGridGeneralInfo2">
				    	<FormLabel><Trans i18nKey={props.i18nPrefix+"form.location.label"}>Location</Trans></FormLabel>
						<FormControl plaintext readOnly defaultValue={buildLocationInfo(props.values)} />
					</FormGroup>
				</Fragment>
				}
				
			</Row>

			<Row>
				
				<FormGroup as={Col} className="mb-0" controlId="formGridLabelId">
    				<FormLabel ><Trans i18nKey={props.i18nPrefix+"form.labelId.label"}>Label Id</Trans> *</FormLabel>
					<FormControl type={'text'} name="labelId" readOnly={cloneOrganismId} isInvalid={!(props.errors.labelId == null)} value={props.values.labelId} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.labelId.placeholder")} />
				    <FormControlErrors errors={props.errors.labelId} />
				</FormGroup>
				
				<FormGroup as={Col} className="mb-0" controlId="formGridWorkgroup">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.workgroupId.label"}>Workgroup</Trans> *</FormLabel>
					<FormControl as="select" name="workgroupId" disabled={(props.origin === "storagebox-items")} isInvalid={!(props.errors.workgroupId == null)} value={props.values.workgroupId} onChange={handleWorkgroupChange} >
		    			{(props.origin === "storagebox-items") ?
							workgroups.filter(item => (item.id == props.values.workgroupId )) 
		    					.map(item =>
		    						<option key={item.id} value={item.id+""}>{item.name + " (" + item.shortcode + ")"}</option>
		    					)
						: <Fragment>
							<option value="">{t(props.i18nPrefix+"form.workgroupId.blank-option")}</option>
		    				{ workgroups.map(item =>
		    					<option key={item.id} value={item.id+""}>{item.name + " (" + item.shortcode + ")"}</option>
		    				)}
						</Fragment>
						}
		    		</FormControl>
					<FormControlErrors errors={props.errors.workgroupId} />
				 </FormGroup>
			
			</Row>
			
			<Row className="mt-0">
				<FormGroup as={Col} className="align-self-center" controlId="formGridCloneOrganismId">
				    <FormCheck size="sm" type="checkbox" disabled={!(props.values.organismId)} onChange={handleCloneOrganismIdChange} checked={cloneOrganismId} label={t(props.i18nPrefix+"form.labelId.clone-checkbox")}/>
				 </FormGroup>
			</Row>
			
			<Row>

				<FormGroup as={Col} controlId="formGridDateScreened">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.dateScreened.label"}>Date Screened</Trans> *</FormLabel>
					<DatePicker
      					format="DD/MM/YYYY"
      					className={!(props.errors.dateScreened == null) ? "is-invalid border border-danger rounded" : ""}
      					onChange={(e) => { 
							if (e.date) { 
      							if (e.date.isValid()) 
      								props.setFieldValue("dateScreened", moment(e.date).format("YYYY-MM-DD"));
      							else
      								props.setFieldValue("dateScreened", e.date.parsingFlags().inputDate);
      						} else if (e.date === null) { // reset if null (which is !== from undefined)
      							props.setFieldValue("dateScreened", "");
      						}
      					}}
      					date={props.values.dateScreened}
      					parseInputDate={parseInputDate}
      					locale={config.preferredLanguage}
      					keepInvalid={true}
    				/>
					<FormControlErrors errors={props.errors.dateScreened} />
				</FormGroup>
				
				<FormGroup as={Col} controlId="formGridScreenedById">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.screenedById.label"}>Screened By</Trans> *</FormLabel>
					<FormControl as="select" disabled={!(props.values.workgroupId)} name="screenedById" isInvalid={!(props.errors.screenedById == null)} value={props.values.screenedById} onChange={props.onChange} >
		    			<option value="">{t(props.i18nPrefix+"form.screenedById.blank-option")}</option>
		    			{ workgroupUsers.map(item =>
		    					<option key={item.id} value={item.id}>{item.firstName+" "+item.lastName}</option>
		    			)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.screenedById} />
				</FormGroup>
				
			</Row>
			
			<Row>
			   
				{/* <FormGroup as={Col} controlId="formGridStorageBox">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.storageBoxId.label"}>Storage Box</Trans></FormLabel>
					<FormControl as="select" disabled={!(props.values.workgroupId)} name="storageBoxId" isInvalid={!(props.errors.storageBoxId == null)} value={props.values.storageBoxId} onChange={handleStorageBoxChange} >
		    			<option value="">{t(props.i18nPrefix+"form.storageBoxId.blank-option")}</option>
		    			{ storageBoxes.map(item =>
		    					<option key={item.id} value={item.id}>{item.labelId}</option>
		    			)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.storageBoxId} />
				</FormGroup>*/}
				
				<FormGroup as={Col} md={9} controlId="formGridStorageBox">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.storageBoxId.label"}>Storage Box</Trans></FormLabel>
					<StorageBoxInputSearch 
				    	i18nPrefix={props.i18nPrefix+"form."}
				    	onError={props.onError}
				    	isInvalid={!(props.errors.storageBoxId == null)}
				    	onChange={handleStorageBoxChange}
						onLoad={handleStorageBoxLoad}
						workgroupId={props.values.workgroupId}
						disabled={!(props.values.workgroupId)}
						//initialStorageBoxId={props.values.storageBoxId}
						storageBoxId={props.values.storageBoxId}
				    />
					<FormControlErrors errors={props.errors.storageBoxId} />
				</FormGroup>
				
				
				<FormGroup as={Col} controlId="formGridStorageBoxSlotPosition">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.storageBoxSlotPosition.label"}>Storage Box Slot Position</Trans></FormLabel>
					<FormControl as="select" disabled={!(props.values.storageBoxId)} name="storageBoxSlotPosition" isInvalid={!(props.errors.slotCol == null) || !(props.errors.slotRow == null)} value={props.values.slotRow+"-"+props.values.slotCol} onChange={handleStorageBoxSlotPositionChange} >
		    			<option value="">{t(props.i18nPrefix+"form.storageBoxSlotPosition.blank-option")}</option>
		    			{ availableStorageBoxSlots.map(item =>
								<option key={item.row+"-"+item.col} value={item.row+"-"+item.col}>{(item.row)+"-"+numToColumn(item.col)}</option>
		    			)}
		    		</FormControl>
					<FormControlErrors errors={(props.errors.slotCol) ? props.errors.slotCol : props.errors.slotRow} />
				</FormGroup>
			</Row>		    	
		
			  {(props.action === 'update')&& 
			  	<Row>    					   
	    			<FormGroup as={Col} controlId="formGridRanOut">
	    				<FormLabel ><Trans i18nKey={props.i18nPrefix+"form.ranOut.label"}>Run Out</Trans></FormLabel>
	    				<Switch 
	    					name="ranOut"
	    					value={props.values.ranOut}
	    					checked={JSON.parse(props.values.ranOut)}
	    					onChange={props.onChange}
	    				/>
	    			</FormGroup>
	    		</Row>
			  }
				
				
		
		</Fragment>
		
	) 
}

const TissueDissociationsSection = props => {

	const [isLoading, setIsLoading] = useState(true);
	const [listedDissociations, setListedDissociations] = useState(null);
	const [saveDissociationModalShow, setSaveDissociationModalShow] = useState(false);
	const [selectedDissociation, setSelectedDissociation] = useState(null);
	const [deleteDissociationModalShow, setDeleteDissociationModalShow] = useState(false);
	
	const { t } = useTranslation();
	const auth = useAuth();
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
		
		if (props.values && props.values.collectionId && props.values.materialSample) {
		
			//Loading Dissociations
	  		listDissociations({collectionId: props.values.collectionId, materialSample: props.values.materialSample})
			.catch(error => { 
	 			log.error("Error Loading Dissociations: ", error.message);
	 			props.onError(error);
			})
			.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
	
		}
		
		 return () => { isMounted = false };
		  
	}, []);

	const addDissociation = (e) => {
		let val= {};
		val.collectionId = props.values.collectionId;
		val.materialSample = props.values.materialSample;
		val.organismId = props.values.organismId;
		val.workgroupId = props.values.workgroupId;
		
		setSelectedDissociation(val);
	  	setSaveDissociationModalShow(true);
    }

	const updateDissociation = (extraction) => {
		setSelectedDissociation(extraction);
	  	setSaveDissociationModalShow(true);
    }

	const handleSaveDissociationModalHide = () => {
	  setSelectedDissociation(null); 
	  setSaveDissociationModalShow(false);
    }

    const handleDissociationSaved = (success) => {
	  setSelectedDissociation(null); 
	  setSaveDissociationModalShow(false);
	  refreshDissociationList();
	  props.onSuccess(success);
    }

	const refreshDissociationList = () => {
		  
	  //Refresh Dissociations
	  listDissociations({})
		.catch(error => { 
	 		log.error("Error Refreshing Dissociations: ", error.message);
	 		props.onError(error);
		})	
     }  

	const sortDissociationList = (values) => {
		  
	  //Refresh Dissociations
	  listDissociations(values)
		.catch(error => { 
	 		log.error("Error Sorting Dissociations: ", error.message);
	 		props.onError(error);
		})	
     } 

	const mergeQueryParams = (currentList, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentList) {
		  
		  queryParams.sortBy = currentList.sortBy;
		  queryParams.order = currentList.order;
		  queryParams.filteredColumn = currentList.filteredColumn;
		  queryParams.filter = currentList.filter;

		  queryParams.collectionId = currentList.collectionId;
		  queryParams.materialSample = currentList.materialSample;
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }

	const listDissociations = (values) =>
		new Promise((resolve, reject) => {
			//Clear Error
 			props.onError(null);
		
			API.findLaboratoryDissociationsByCollectionSample(mergeQueryParams(listedDissociations, values)).then(response => {
				setListedDissociations(response);
				if (props.action === "create" && isArrayWithLength(response.list)) {
					props.onWarning(t(props.i18nPrefix+"dissociations.warning.lab-sample-not-found"));
				}
				resolve(response);
			}).catch(error => {			
				reject(error);
			});
	});
	
	const displayRanOut = (item) => { 
		return (JSON.parse(item.ranOut)) ? "inactive-row": "";
  	}

 	const deleteDissociation = (dissociation) => {
		
	  	//Clear Error/Success
		props.onError(null);
		props.onSuccess(null);
		
		setDeleteDissociationModalShow(false);
		
		API.deleteLaboratoryDissociation(dissociation.id, auth.isAdmin(), config.csrfToken).then(response => {
			props.onSuccess(response.success)
			refreshDissociationList();
		}).catch(error => { 	
			log.error("Error Removing Dissociation: ", error.message);
			props.onError(error);		
		});
  }

  const confirmDissociationRemoval = (dissociation) => {
	  setSelectedDissociation(dissociation);
	  setDeleteDissociationModalShow(true);
  }

	const handleDeleteDissociationModalHide = () => {
	  setSelectedDissociation(null);
	  setDeleteDissociationModalShow(false);
    }
	
	if (isLoading) 
		return <Loader />
	
	if (!listedDissociations) 
		return null;
		

	return(
		<Fragment>
			{(selectedDissociation && saveDissociationModalShow) && <SaveTissueDissociationModal
				show={saveDissociationModalShow}
				onHide={handleSaveDissociationModalHide}
				size="lg"
				origin={props.origin}
				item={selectedDissociation}
				onError={props.onError}
				onItemSaved={handleDissociationSaved}
			/>}
			{(selectedDissociation) && <ConfirmationDialogModal
				item={selectedDissociation}
				show={deleteDissociationModalShow}
        		onHide={handleDeleteDissociationModalHide}
				size="lg"
				title={t("laboratorydissociations.delete-dissociation-confirmation-modal.title")}
				bodyText={t("laboratorydissociations.delete-dissociation-confirmation-modal.body", {item: selectedDissociation})}
				confirmText={t("laboratorydissociations.delete-dissociation-confirmation-modal.confirm")}
				cancelText={t("laboratorydissociations.delete-dissociation-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteDissociation}
			/>}
			<Row className="mb-3">
        		<Col sm={7} xl={8}>
					<h3>
        				{t(props.i18nPrefix+"dissociations.header", {count: listedDissociations.list.length})}
        			</h3>
				</Col>
				<Col sm={5} xl={4}>
					<div className="d-flex justify-content-end">
						<Button variant="outline-success"  onClick={addDissociation}><FontAwesomeIcon icon={faPlusCircle} /> <Trans i18nKey={props.i18nPrefix+"dissociations.add-button"} /></Button>	
					</div>
				</Col>
        	</Row>
			<Row>
        		<Col>
        			<DataTable 
        				items={listedDissociations.list} 
        				i18nPrefix={props.i18nPrefix+"dissociations.datatable."}
        				columns={["labelId", "reagent", "dateDissociated"]}
						sortableColumns={["labelId", "reagent", "dateDissociated"]}
						sortBy={listedDissociations.sortBy}
						sortDataTable={sortDissociationList}
				 		order={listedDissociations.order} 
						customDisplayColumns={[["reagent", "Enum"], ["dateDissociated", "LocalDate"]]} 
        				customRowStyle={displayRanOut}
						actions={[updateDissociation, confirmDissociationRemoval]} 
						defaultAction={updateDissociation} 
        			/>
        		</Col>
        	</Row>
		</Fragment>
	) 

}

const ExtractionsSection = props => {

	const [isLoading, setIsLoading] = useState(true);
	const [listedExtractions, setListedExtractions] = useState(null);
	const [saveExtractionModalShow, setSaveExtractionModalShow] = useState(false);
	const [selectedExtraction, setSelectedExtraction] = useState(null);
	const [deleteExtractionModalShow, setDeleteExtractionModalShow] = useState(false);
	
	const { t } = useTranslation();
	const auth = useAuth();
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
		
		if (props.values && props.values.collectionId && props.values.materialSample) {
		
			//Loading Extractions
	  		listExtractions({collectionId: props.values.collectionId, materialSample: props.values.materialSample})
			.catch(error => { 
	 			log.error("Error Loading Extractions: ", error.message);
	 			props.onError(error);
			})
			.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
	
		}
		
		 return () => { isMounted = false };
		  
	}, []);

	const addExtraction = (e) => {
		let val= {};
		val.collectionId = props.values.collectionId;
		val.materialSample = props.values.materialSample;
		val.organismId = props.values.organismId;
		val.workgroupId = props.values.workgroupId;
		
		setSelectedExtraction(val);
	  	setSaveExtractionModalShow(true);
    }

	const updateExtraction = (extraction) => {
		setSelectedExtraction(extraction);
	  	setSaveExtractionModalShow(true);
    }

	const handleSaveExtractionModalHide = () => {
	  setSelectedExtraction(null); 
	  setSaveExtractionModalShow(false);
    }

    const handleExtractionSaved = (success) => {
	  setSelectedExtraction(null); 
	  setSaveExtractionModalShow(false);
	  refreshExtractionList();
	  props.onSuccess(success);
    }

	const refreshExtractionList = () => {
		  
	  //Refresh Extractions
	  listExtractions({})
		.catch(error => { 
	 		log.error("Error Refreshing Extractions: ", error.message);
	 		props.onError(error);
		})	
     }  

	const sortExtractionList = (values) => {
		  
	  //Refresh Extractions
	  listExtractions(values)
		.catch(error => { 
	 		log.error("Error Sorting Extractions: ", error.message);
	 		props.onError(error);
		})	
     } 

	const mergeQueryParams = (currentList, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentList) {
		  
		  queryParams.sortBy = currentList.sortBy;
		  queryParams.order = currentList.order;
		  queryParams.filteredColumn = currentList.filteredColumn;
		  queryParams.filter = currentList.filter;

		  queryParams.collectionId = currentList.collectionId;
		  queryParams.materialSample = currentList.materialSample;
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }

	const listExtractions = (values) =>
		new Promise((resolve, reject) => {
			//Clear Error
 			props.onError(null);
		
			API.findLaboratoryExtractionsByCollectionSample(mergeQueryParams(listedExtractions, values)).then(response => {
				setListedExtractions(response);
				if (props.action === "create" && isArrayWithLength(response.list)) {
					props.onWarning(t(props.i18nPrefix+"extractions.warning.lab-sample-not-found"));
				}
				resolve(response);
			}).catch(error => {			
				reject(error);
			});
	});
	
	const displayRanOut = (item) => { 
		return (JSON.parse(item.ranOut)) ? "inactive-row": "";
  	}

 	const deleteExtraction = (extraction) => {
		
	  	//Clear Error/Success
		props.onError(null);
		props.onSuccess(null);
		
		setDeleteExtractionModalShow(false);
		
		API.deleteLaboratoryExtraction(extraction.id, auth.isAdmin(), config.csrfToken).then(response => {
			props.onSuccess(response.success)
			refreshExtractionList();
		}).catch(error => { 	
			log.error("Error Removing Extraction: ", error.message);
			props.onError(error);		
		});
  }

  const confirmExtractionRemoval = (extraction) => {
	  setSelectedExtraction(extraction);
	  setDeleteExtractionModalShow(true);
  }

	const handleDeleteExtractionModalHide = () => {
	  setSelectedExtraction(null);
	  setDeleteExtractionModalShow(false);
    }
	
	if (isLoading) 
		return <Loader />
	
	if (!listedExtractions) 
		return null;
		

	return(
		<Fragment>
			{(selectedExtraction && saveExtractionModalShow) && <SaveNucleicAcidExtractionModal
				show={saveExtractionModalShow}
				onHide={handleSaveExtractionModalHide}
				size="lg"
				origin={props.origin}
				item={selectedExtraction}
				onError={props.onError}
				onItemSaved={handleExtractionSaved}
			/>}
			{(selectedExtraction) && <ConfirmationDialogModal
				item={selectedExtraction}
				show={deleteExtractionModalShow}
        		onHide={handleDeleteExtractionModalHide}
				size="lg"
				title={t("laboratoryextractions.delete-extraction-confirmation-modal.title")}
				bodyText={t("laboratoryextractions.delete-extraction-confirmation-modal.body", {item: selectedExtraction})}
				confirmText={t("laboratoryextractions.delete-extraction-confirmation-modal.confirm")}
				cancelText={t("laboratoryextractions.delete-extraction-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteExtraction}
			/>}
			<Row className="mb-3">
        		<Col sm={7} xl={8}>
					<h3>
        				{t(props.i18nPrefix+"extractions.header", {count: listedExtractions.list.length})}
        			</h3>
				</Col>
				<Col sm={5} xl={4}>
					<div className="d-flex justify-content-end">
						<Button variant="outline-success"  onClick={addExtraction}><FontAwesomeIcon icon={faPlusCircle} /> <Trans i18nKey={props.i18nPrefix+"extractions.add-button"} /></Button>	
					</div>
				</Col>
        	</Row>
			<Row>
        		<Col>
        			<DataTable 
        				items={listedExtractions.list} 
        				i18nPrefix={props.i18nPrefix+"extractions.datatable."}
        				columns={["labelId", "geneticMaterial", "reagent", "dateExtracted"]}
						sortableColumns={["labelId", "geneticMaterial", "reagent", "dateExtracted"]}
						sortBy={listedExtractions.sortBy}
						sortDataTable={sortExtractionList}
				 		order={listedExtractions.order} 
						customDisplayColumns={[["geneticMaterial", "Enum"], ["reagent", "Enum"], ["dateExtracted", "LocalDate"]]} 
        				customRowStyle={displayRanOut}
						actions={[updateExtraction, confirmExtractionRemoval]} 
						defaultAction={updateExtraction} 
        			/>
        		</Col>
        	</Row>
		</Fragment>
	) 

}

const CDNASynthesesSection = props => {

	const [isLoading, setIsLoading] = useState(true);
	const [listedSyntheses, setListedSyntheses] = useState(null);
	const [saveSynthesisModalShow, setSaveSynthesisModalShow] = useState(false);
	const [selectedSynthesis, setSelectedSynthesis] = useState(null);
	const [deleteSynthesisModalShow, setDeleteSynthesisModalShow] = useState(false);
	
	const { t } = useTranslation();
	const auth = useAuth();
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
		
		if (props.values && props.values.collectionId && props.values.materialSample) {
		
			//Loading Syntheses
	  		listSyntheses({collectionId: props.values.collectionId, materialSample: props.values.materialSample})
			.catch(error => { 
	 			log.error("Error Loading Syntheses: ", error.message);
	 			props.onError(error);
			})
			.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
	
		}
		
		 return () => { isMounted = false };
		  
	}, []);

	const addSynthesis = (e) => {
		let val= {};
		val.collectionId = props.values.collectionId;
		val.materialSample = props.values.materialSample;
		val.organismId = props.values.organismId;
		val.workgroupId = props.values.workgroupId;
		
		setSelectedSynthesis(val);
	  	setSaveSynthesisModalShow(true);
    }

	const updateSynthesis = (extraction) => {
		setSelectedSynthesis(extraction);
	  	setSaveSynthesisModalShow(true);
    }

	const handleSaveSynthesisModalHide = () => {
	  setSelectedSynthesis(null); 
	  setSaveSynthesisModalShow(false);
    }

    const handleSynthesisSaved = (success) => {
	  setSelectedSynthesis(null); 
	  setSaveSynthesisModalShow(false);
	  refreshSynthesisList();
	  props.onSuccess(success);
    }

	const refreshSynthesisList = () => {
		  
	  //Refresh Syntheses
	  listSyntheses({})
		.catch(error => { 
	 		log.error("Error Refreshing Syntheses: ", error.message);
	 		props.onError(error);
		})	
     }  

	const sortSynthesisList = (values) => {
		  
	  //Refresh Syntheses
	  listSyntheses(values)
		.catch(error => { 
	 		log.error("Error Sorting Syntheses: ", error.message);
	 		props.onError(error);
		})	
     } 

	const mergeQueryParams = (currentList, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentList) {
		  
		  queryParams.sortBy = currentList.sortBy;
		  queryParams.order = currentList.order;
		  queryParams.filteredColumn = currentList.filteredColumn;
		  queryParams.filter = currentList.filter;

		  queryParams.collectionId = currentList.collectionId;
		  queryParams.materialSample = currentList.materialSample;
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }

	const listSyntheses = (values) =>
		new Promise((resolve, reject) => {
			//Clear Error
 			props.onError(null);
		
			API.findLaboratorySynthesesByCollectionSample(mergeQueryParams(listedSyntheses, values)).then(response => {
				setListedSyntheses(response);
				if (props.action === "create" && isArrayWithLength(response.list)) {
					props.onWarning(t(props.i18nPrefix+"syntheses.warning.lab-sample-not-found"));
				}
				resolve(response);
			}).catch(error => {			
				reject(error);
			});
	});
	
	const displayRanOut = (item) => { 
		return (JSON.parse(item.ranOut)) ? "inactive-row": "";
  	}

 	const deleteSynthesis = (extraction) => {
		
	  	//Clear Error/Success
		props.onError(null);
		props.onSuccess(null);
		
		setDeleteSynthesisModalShow(false);
		
		API.deleteLaboratorySynthesis(extraction.id, auth.isAdmin(), config.csrfToken).then(response => {
			props.onSuccess(response.success)
			refreshSynthesisList();
		}).catch(error => { 	
			log.error("Error Removing Synthesis: ", error.message);
			props.onError(error);		
		});
  }

  const confirmSynthesisRemoval = (extraction) => {
	  setSelectedSynthesis(extraction);
	  setDeleteSynthesisModalShow(true);
  }

	const handleDeleteSynthesisModalHide = () => {
	  setSelectedSynthesis(null);
	  setDeleteSynthesisModalShow(false);
    }
	
	if (isLoading) 
		return <Loader />
	
	if (!listedSyntheses) 
		return null;
		

	return(
		<Fragment>
			{(selectedSynthesis && saveSynthesisModalShow) && <SaveCDNASynthesisModal
				show={saveSynthesisModalShow}
				onHide={handleSaveSynthesisModalHide}
				size="lg"
				origin={props.origin}
				item={selectedSynthesis}
				onError={props.onError}
				onItemSaved={handleSynthesisSaved}
			/>}
			{(selectedSynthesis) && <ConfirmationDialogModal
				item={selectedSynthesis}
				show={deleteSynthesisModalShow}
        		onHide={handleDeleteSynthesisModalHide}
				size="lg"
				title={t("laboratorysyntheses.delete-synthesis-confirmation-modal.title")}
				bodyText={t("laboratorysyntheses.delete-synthesis-confirmation-modal.body", {item: selectedSynthesis})}
				confirmText={t("laboratorysyntheses.delete-synthesis-confirmation-modal.confirm")}
				cancelText={t("laboratorysyntheses.delete-synthesis-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteSynthesis}
			/>}
			<Row className="mb-3">
        		<Col sm={7} xl={8}>
					<h3>
        				{t(props.i18nPrefix+"syntheses.header", {count: listedSyntheses.list.length})}
        			</h3>
				</Col>
				<Col sm={5} xl={4}>
					<div className="d-flex justify-content-end">
						<Button variant="outline-success"  onClick={addSynthesis}><FontAwesomeIcon icon={faPlusCircle} /> <Trans i18nKey={props.i18nPrefix+"syntheses.add-button"} /></Button>	
					</div>
				</Col>
        	</Row>
			<Row>
        		<Col>
        			<DataTable 
        				items={listedSyntheses.list} 
        				i18nPrefix={props.i18nPrefix+"syntheses.datatable."}
        				columns={["labelId", "dateSynthesized"]}
						sortableColumns={["labelId", "dateSynthesized"]}
						sortBy={listedSyntheses.sortBy}
						sortDataTable={sortSynthesisList}
				 		order={listedSyntheses.order} 
						customDisplayColumns={[["dateSynthesized", "LocalDate"]]} 
        				customRowStyle={displayRanOut}
						actions={[updateSynthesis, confirmSynthesisRemoval]} 
						defaultAction={updateSynthesis} 
        			/>
        		</Col>
        	</Row>
		</Fragment>
	) 

}

const LaboratoryTestsSection = props => {

	const [isLoading, setIsLoading] = useState(true);
	const [listedTests, setListedTests] = useState(null);
	const [saveTestModalShow, setSaveTestModalShow] = useState(false);
	const [selectedTest, setSelectedTest] = useState(null);
	const [deleteTestModalShow, setDeleteTestModalShow] = useState(false);
	
	const { t } = useTranslation();
	const auth = useAuth();
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
		
		if (props.values && props.values.collectionId && props.values.materialSample) {
		
			//Loading Tests
	  		listTests({collectionId: props.values.collectionId, materialSample: props.values.materialSample})
			.catch(error => { 
	 			log.error("Error Loading Tests: ", error.message);
	 			props.onError(error);
			})
			.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
	
		}
		
		 return () => { isMounted = false };
		  
	}, []);

	const addTest = (e) => {
		let val= {};
		val.collectionId = props.values.collectionId;
		val.materialSample = props.values.materialSample;
		val.organismId = props.values.organismId;
		val.workgroupId = props.values.workgroupId;
		
		setSelectedTest(val);
	  	setSaveTestModalShow(true);
    }

	const updateTest = (test) => {
		setSelectedTest(test);
	  	setSaveTestModalShow(true);
    }

	const handleSaveTestModalHide = () => {
	  setSelectedTest(null); 
	  setSaveTestModalShow(false);
    }

    const handleTestSaved = (success) => {
	  setSelectedTest(null); 
	  setSaveTestModalShow(false);
	  refreshTestList();
	  props.onSuccess(success);
    }

	const refreshTestList = () => {
		  
	  //Refresh Tests
	  listTests({})
		.catch(error => { 
	 		log.error("Error Refreshing Tests: ", error.message);
	 		props.onError(error);
		})	
     }  

	const sortTestList = (values) => {
		  
	  //Refresh Tests
	  listTests(values)
		.catch(error => { 
	 		log.error("Error Sorting Tests: ", error.message);
	 		props.onError(error);
		})	
     } 

	const mergeQueryParams = (currentList, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentList) {
		  
		  queryParams.sortBy = currentList.sortBy;
		  queryParams.order = currentList.order;
		  queryParams.filteredColumn = currentList.filteredColumn;
		  queryParams.filter = currentList.filter;

		  queryParams.collectionId = currentList.collectionId;
		  queryParams.materialSample = currentList.materialSample;
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }

	const listTests = (values) =>
		new Promise((resolve, reject) => {
			//Clear Error
 			props.onError(null);
		
			API.findLaboratoryTestsByCollectionSample(mergeQueryParams(listedTests, values)).then(response => {
				setListedTests(response);
				if (props.action === "create" && isArrayWithLength(response.list)) {
					props.onWarning(t(props.i18nPrefix+"tests.warning.lab-sample-not-found"));
				}
				resolve(response);
			}).catch(error => {			
				reject(error);
			});
	});

 	const deleteTest = (test) => {
		
	  	//Clear Error/Success
		props.onError(null);
		props.onSuccess(null);
		
		setDeleteTestModalShow(false);
		
		API.deleteLaboratoryTest(test.id, auth.isAdmin(), config.csrfToken).then(response => {
			props.onSuccess(response.success)
			refreshTestList();
		}).catch(error => { 	
			log.error("Error Removing Test: ", error.message);
			props.onError(error);		
		});
  }

  const confirmTestRemoval = (test) => {
	  setSelectedTest(test);
	  setDeleteTestModalShow(true);
  }

	const handleDeleteTestModalHide = () => {
	  setSelectedTest(null);
	  setDeleteTestModalShow(false);
    }
	
	if (isLoading) 
		return <Loader />
	
	if (!listedTests) 
		return null;
		

	return(
		<Fragment>
			{(selectedTest && saveTestModalShow) && <SaveLaboratoryTestModal
				show={saveTestModalShow}
				onHide={handleSaveTestModalHide}
				size="lg"
				origin={props.origin}
				item={selectedTest}
				onError={props.onError}
				onItemSaved={handleTestSaved}
			/>}
			{(selectedTest) && <ConfirmationDialogModal
				item={selectedTest}
				show={deleteTestModalShow}
        		onHide={handleDeleteTestModalHide}
				size="lg"
				title={t("laboratorytests.delete-test-confirmation-modal.title")}
				bodyText={t("laboratorytests.delete-test-confirmation-modal.body", {item: selectedTest})}
				confirmText={t("laboratorytests.delete-test-confirmation-modal.confirm")}
				cancelText={t("laboratorytests.delete-test-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteTest}
			/>}
			<Row className="mb-3">
        		<Col sm={7} xl={8}>
					<h3>
        				{t(props.i18nPrefix+"tests.header", {count: listedTests.list.length})}
        			</h3>
				</Col>
				<Col sm={5} xl={4}>
					<div className="d-flex justify-content-end">
						<Button variant="outline-success"  onClick={addTest}><FontAwesomeIcon icon={faPlusCircle} /> <Trans i18nKey={props.i18nPrefix+"tests.add-button"} /></Button>	
					</div>
				</Col>
        	</Row>
			<Row>
        		<Col>
        			<DataTable 
        				items={listedTests.list} 
        				i18nPrefix={props.i18nPrefix+"tests.datatable."}
        				columns={["labelId", "pcrType", "targetVirusName" , "result", "dateTested"]}
						sortableColumns={["labelId", "pcrType", "result", "dateTested"]}
						sortBy={listedTests.sortBy}
						sortDataTable={sortTestList}
				 		order={listedTests.order} 
						customDisplayColumns={[["pcrType", "Enum"], ["result", "Enum"], ["dateTested", "LocalDate"]]} 
						actions={[updateTest, confirmTestRemoval]} 
						defaultAction={updateTest} 
        			/>
        		</Col>
        	</Row>
		</Fragment>
	) 

}


const LaboratorySampleFormFields = { GeneralSection, TissueDissociationsSection, ExtractionsSection,
	CDNASynthesesSection, LaboratoryTestsSection };
		
export default LaboratorySampleFormFields;
