import React,  { Fragment, useState, useEffect} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import FormCheck from 'react-bootstrap/FormCheck';

import FormControlErrors from "../components/FormControlErrors";
import Switch from "../components/Switch";

import CollectionSampleInputSearch from "../components/CollectionSampleInputSearch";
import StorageBoxInputSearch from "../components/StorageBoxInputSearch";

import moment from "moment";
import { DatePicker } from "react-tempusdominus-bootstrap";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

import { numToColumn } from "../helpers/commons";

import CDNASynthesisConfig from "../config/CDNASynthesisConfig";

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";

import { parseInputDate } from "../helpers/commons";

const CDNASynthesisFormFields = props => {

	const [workgroups, setWorkgroups] = useState([]);
	const [workgroupUsers, setWorkgroupUsers] = useState([]);
	const [availableStorageBoxSlots, setAvailableStorageBoxSlots] = useState([]);
	const [cloneOrganismId, setCloneOrganismId] = useState(true);
	
	const { t } = useTranslation();
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
		
		if (props.action === "create") {
			setCloneOrganismId(true);
			props.setFieldValue("labelId", buildLabelId(props.values));
		} else {
			setCloneOrganismId((buildLabelId(props.values) === props.values.labelId));
		}
		
		//Fetch Laboratory Workgroups
		API.findLaboratories({})
			.then(response => {
				if (isMounted) setWorkgroups(response.list);
			})
			.catch(error => { 
				log.error("Error Loading Analysis Workgroups: ", error.message);
		 		props.onError(error);
			})
			.finally(() => {
		 		//Get Workgroup Users
				if (props.values.workgroupId) {
					getWorkgroupUsers(props.values.workgroupId);
				}
			});
		
		 return () => { isMounted = false };
		  
	}, []);
	
	const getWorkgroupUsers = (workgroupId) => {
    	API.findUsers({"filteredColumn": "workgroupId", "filter": workgroupId})
			.then(response => {
					setWorkgroupUsers(response.list); 					
			})
			.catch(error => { 
				log.error("Error Loading Workgroup Users: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		  //Do nothing yet
			});
    }

	/*const getStorageBoxes = (workgroupId) => {
    	API.findStorageBoxes(workgroupId, {})
			.then(response => {
				setStorageBoxes(response.list); 					
			})
			.catch(error => { 
				log.error("Error Loading Workgroup Storage Boxes: ", error.message);
		 		props.onError(error);
			})
		 	.finally(() => {
		 		  //Do nothing yet
			});
    }*/

	const getAvailableStorageBoxSlots = (storageBox) => {
		
		if (storageBox) {
			
			let allStorageBoxSlots = Array.apply(null, {length: Number(storageBox.cols)})
				.map((_, col) => { 
					return Array.apply(null, {length: Number(storageBox.rows)})
						.map((_, row) => { 
							return Object.assign({}, {col: col+1, row: row+1});								
						})
					}
				).flat();
				
			//setAvailableStorageBoxSlots(allStorageBoxSlots);
			
			//Get Storage Box Items
			getStorageBoxItems(storageBox)
				.then(response => {
		
					let unavailableSlotsMap = new Map(response.list
								.filter(s => (s.slotCol && s.slotRow && s.type !== "CDNA_SYNTHESIS" && props.values.id !== s.id ))
								.map((s) => [ s.slotRow+"-"+s.slotCol, s]));
					
					setAvailableStorageBoxSlots(allStorageBoxSlots.filter(s => !unavailableSlotsMap.get(s.row+"-"+s.col)));				
				})
				.catch(error => { 
					log.error("Error Loading Storage Box Samples: ", error.message);
		 			props.onError(error);
			})
			
		} else {
			setAvailableStorageBoxSlots([]);
		}
	}
	
	const getStorageBoxItems = (storageBox) =>
		new Promise((resolve, reject) => {
			API.getStorageBoxItems(storageBox.id).then(response => {
			resolve(response);
		}).catch(error => { 
			reject(error);
		});
	});
	
	const handleWorkgroupChange = (e) => {
        
        props.setFieldValue("workgroupId", e.target.value);
        
        if (!e.target.value) {
        	setWorkgroupUsers([]);
        	
			props.setFieldValue("synthesizedById", "");
			props.setFieldValue("storageBoxId", "");
			props.setFieldValue("slotCol", "");
			props.setFieldValue("slotRow", "");
			
        	return;
        }
        
        //Get Workgroup Users
        getWorkgroupUsers(e.target.value);

		//Get Storage Boxes
		//getStorageBoxes(e.target.value);
        
        //Reset synthesizedBy and storageBox
        props.setFieldValue("synthesizedById", "");
		props.setFieldValue("storageBoxId", "");
		props.setFieldValue("slotCol", "");
		props.setFieldValue("slotRow", "");
    }

	const handleStorageBoxLoad = (storageBox) => {
		getAvailableStorageBoxSlots(storageBox);
	}

	const handleStorageBoxChange = (storageBox) => {
		
        if (!storageBox) {
			setAvailableStorageBoxSlots([]);
        	
			props.setFieldValue("storageBoxId", "");
			props.setFieldValue("slotCol", "");
			props.setFieldValue("slotRow", "");
			
        	return;
        } /*else if (storageBox.id === props.values.storageBoxId) {
			return;
		}*/


		//Get Available Storage Box Slots
		getAvailableStorageBoxSlots(storageBox);
		
		props.setFieldValue("storageBoxId", storageBox.id);

        //Reset StorageBox Slot Position
		props.setFieldValue("slotCol", "");
		props.setFieldValue("slotRow", "");
    }

	const handleStorageBoxSlotPositionChange = (e) => {
		
		if (!e.target.value) {
			props.setFieldValue("slotCol", "");
			props.setFieldValue("slotRow", "");
		} else {
			let slotPositionInfo = e.target.value.split("-");
			props.setFieldValue("slotRow", slotPositionInfo[0]);		
		    props.setFieldValue("slotCol", slotPositionInfo[1]);
		}
		
	}

	const buildGeneralInfo = (s) => {
       
		let generalInfo = s.organismId
			+ " - " + t("models.collections.enums.samples."+s.materialSample)
			+ " - " + s.scientificName
			+ " - " + t("models.collections.enums.sex."+s.sex)
			+ " - " + s.county + "/" + s.stateProvince;
    
		return generalInfo;
    }

	const buildLabelId = (i) => {
		return (i.organismId) ? CDNASynthesisConfig.DefaultLabelIdPrefix + i.organismId : "";
	}

/*	const buildSpecimenInfo = (s) => {
       
		let specimenInfo = s.scientificName + " (" + t("models.collections.enums.sex."+s.sex) + ")";
    
		return specimenInfo;
    }

	const buildLocationInfo = (s) => {
	       
		let locationInfo = s.county + "/" + s.stateProvince;
    
		return locationInfo;
    }
*/

	const handleCloneOrganismIdChange = (e) => {
        
		setCloneOrganismId(e.target.checked);
		
        props.setFieldValue("labelId", (e.target.checked) ? buildLabelId(props.values) : "");
        
    }

	const handleCollectionSampleChange = (sample) => {
		
		if (!sample) {
			
			//props.setFieldValue("id", "");
			props.setFieldValue("collectionId", "");
			props.setFieldValue("materialSample", "");
        	props.setFieldValue("organismId", "");
			
			if (cloneOrganismId) {
				props.setFieldValue("labelId", "");
			}
			
			props.setFieldError("labInfo", "");
			
        	return;
        } 
		
		
		//props.setFieldValue("id", sample.laboratorySampleId);
		props.setFieldValue("collectionId", sample.collectionId);
		props.setFieldValue("materialSample", sample.materialSample);
        props.setFieldValue("organismId", sample.organismId);
		
		if (cloneOrganismId) {
			props.setFieldValue("labelId", buildLabelId(sample));
		}
		
		//Check for sample lab info
		if (!sample.labInfo) {
			props.setFieldError("labInfo", t(props.i18nPrefix+"form.labInfo.validation.notfound"));
			return;
		}
		
		//If we got here, then we can cleanup labInfo error
		props.setFieldError("labInfo", "");
		
    }

	return(
		<Fragment>
			<Row >
				
				{(props.origin === "storagebox-items" || props.origin === "laboratory-syntheses") ? 
					(props.action === "create") ?
				 	<FormGroup as={Col} controlId="formGridSample">
				    	<FormLabel><Trans i18nKey={props.i18nPrefix+"form.collection-sample-input-search.label"}>Select Laboratory Sample</Trans> *</FormLabel>
						<CollectionSampleInputSearch 
				    		i18nPrefix={props.i18nPrefix+"form."}
				    		onError={props.onError}
				    		isInvalid={!(props.errors.collectionId == null)}
				    		onChange={handleCollectionSampleChange}
				    	/>
						<FormControlErrors block={true} errors={props.errors.collectionId} />
						<FormControlErrors block={true} errors={props.errors.labInfo} />
				 	</FormGroup>
					:
				
					<FormGroup as={Col} controlId="formGridGeneralInfo">
				    	<FormLabel><Trans i18nKey={props.i18nPrefix+"form.generalInfo.label"}>General Info</Trans></FormLabel>
						<FormControl plaintext readOnly defaultValue={buildGeneralInfo(props.values)} />
						<FormControlErrors block={true} errors={props.errors.collectionId} /> 
					</FormGroup>
				
				: null
				}
				
			</Row>
			
			<Row>
				
				<FormGroup as={Col} className="mb-0" controlId="formGridLabelId">
    				<FormLabel ><Trans i18nKey={props.i18nPrefix+"form.labelId.label"}>Label Id</Trans> *</FormLabel>
					<FormControl type={'text'} name="labelId" isInvalid={!(props.errors.labelId == null)} readOnly={cloneOrganismId} value={props.values.labelId} onChange={props.onChange} placeholder={t(props.i18nPrefix+"form.labelId.placeholder")} />
				    <FormControlErrors errors={props.errors.labelId} />
				</FormGroup>
				
				<FormGroup as={Col} className="mb-0" controlId="formGridWorkgroup">
				    <FormLabel><Trans i18nKey={props.i18nPrefix+"form.workgroupId.label"}>Workgroup</Trans> *</FormLabel>
					<FormControl as="select" name="workgroupId" disabled={(props.origin === "storagebox-items")} isInvalid={!(props.errors.workgroupId == null)} value={props.values.workgroupId} onChange={handleWorkgroupChange} >
		    			{(props.origin === "storagebox-items") ?
							workgroups.filter(item => (item.id == props.values.workgroupId )) 
		    					.map(item =>
		    						<option key={item.id} value={item.id+""}>{item.name + " (" + item.shortcode + ")"}</option>
		    					)
						: <Fragment>
							<option value="">{t(props.i18nPrefix+"form.workgroupId.blank-option")}</option>
		    				{ workgroups.map(item =>
		    					<option key={item.id} value={item.id+""}>{item.name + " (" + item.shortcode + ")"}</option>
		    				)}
						</Fragment>
						}
		    		</FormControl>
					<FormControlErrors errors={props.errors.workgroupId} />
				 </FormGroup>
			
			</Row>
			
			<Row className="mt-0">
				<FormGroup as={Col} className="align-self-center" controlId="formGridCloneOrganismId">
				    <FormCheck size="sm" type="checkbox" disabled={!(props.values.organismId)} onChange={handleCloneOrganismIdChange} checked={cloneOrganismId} label={t(props.i18nPrefix+"form.labelId.clone-checkbox")}/>
				 </FormGroup>
			</Row>
			
			<Row>

				<FormGroup as={Col} controlId="formGridDateSynthesized">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.dateSynthesized.label"}>Date Synthesized</Trans> *</FormLabel>
					<DatePicker
      					format="DD/MM/YYYY"
      					className={!(props.errors.dateSynthesized == null) ? "is-invalid border border-danger rounded" : ""}
      					onChange={(e) => { 
							if (e.date) { 
      							if (e.date.isValid()) 
      								props.setFieldValue("dateSynthesized", moment(e.date).format("YYYY-MM-DD"));
      							else
      								props.setFieldValue("dateSynthesized", e.date.parsingFlags().inputDate);
      						} else if (e.date === null) { // reset if null (which is !== from undefined)
      							props.setFieldValue("dateSynthesized", "");
      						}
      					}}
      					date={props.values.dateSynthesized}
      					parseInputDate={parseInputDate}
      					locale={config.preferredLanguage}
      					keepInvalid={true}
    				/>
					<FormControlErrors errors={props.errors.dateSynthesized} />
				</FormGroup>
				
				<FormGroup as={Col} controlId="formGridSynthesizedById">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.synthesizedById.label"}>Synthesized By</Trans> *</FormLabel>
					<FormControl as="select" disabled={!(props.values.workgroupId)} name="synthesizedById" isInvalid={!(props.errors.synthesizedById == null)} value={props.values.synthesizedById} onChange={props.onChange} >
		    			<option value="">{t(props.i18nPrefix+"form.synthesizedById.blank-option")}</option>
		    			{ workgroupUsers.map(item =>
		    					<option key={item.id} value={item.id}>{item.firstName+" "+item.lastName}</option>
		    			)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.synthesizedById} />
				</FormGroup>
				
			</Row>
			
			<Row>
			   
				
				<FormGroup as={Col} md={9} controlId="formGridStorageBox">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.storageBoxId.label"}>Storage Box</Trans></FormLabel>
					<StorageBoxInputSearch 
				    	i18nPrefix={props.i18nPrefix+"form."}
				    	onError={props.onError}
				    	isInvalid={!(props.errors.storageBoxId == null)}
				    	onChange={handleStorageBoxChange}
						onLoad={handleStorageBoxLoad}
						workgroupId={props.values.workgroupId}
						disabled={!(props.values.workgroupId)}
						//initialStorageBoxId={props.values.storageBoxId}
						storageBoxId={props.values.storageBoxId}
				    />
					<FormControlErrors errors={props.errors.storageBoxId} />
				</FormGroup>
				
				
				<FormGroup as={Col} controlId="formGridStorageBoxSlotPosition">
					<FormLabel><Trans i18nKey={props.i18nPrefix+"form.storageBoxSlotPosition.label"}>Storage Box Slot Position</Trans></FormLabel>
					<FormControl as="select" disabled={!(props.values.storageBoxId)} name="storageBoxSlotPosition" isInvalid={!(props.errors.slotCol == null) || !(props.errors.slotRow == null)} value={props.values.slotRow+"-"+props.values.slotCol} onChange={handleStorageBoxSlotPositionChange} >
		    			<option value="">{t(props.i18nPrefix+"form.storageBoxSlotPosition.blank-option")}</option>
		    			{ availableStorageBoxSlots.map(item =>
								<option key={item.row+"-"+item.col} value={item.row+"-"+item.col}>{(item.row)+"-"+numToColumn(item.col)}</option>
		    			)}
		    		</FormControl>
					<FormControlErrors errors={(props.errors.slotCol) ? props.errors.slotCol : props.errors.slotRow} />
				</FormGroup>
			</Row>		    	
		
			  {(props.action === 'update')&& 
			  	<Row>    					   
	    			<FormGroup as={Col} controlId="formGridRanOut">
	    				<FormLabel ><Trans i18nKey={props.i18nPrefix+"form.ranOut.label"}>Run Out</Trans></FormLabel>
	    				<Switch 
	    					name="ranOut"
	    					value={props.values.ranOut}
	    					checked={JSON.parse(props.values.ranOut)}
	    					onChange={props.onChange}
	    				/>
	    			</FormGroup>
	    		</Row>
			  }
				
				
		
		</Fragment>
		
	) 
}

		
export default CDNASynthesisFormFields;
