import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate, useLocation, useOutletContext } from 'react-router-dom';
import log from 'loglevel';

import Container from 'react-bootstrap/Container'

import PagedList from "../components/PagedList";

import CollectionSamplesFilterForm from "../components/CollectionSamplesFilterForm";
import Loader from "../components/Loader";
import SaveLaboratorySampleModal from "../components/SaveLaboratorySampleModal";
import SaveNucleicAcidExtractionModal from "../components/SaveNucleicAcidExtractionModal";
import SaveTissueDissociationModal from "../components/SaveTissueDissociationModal";
import SaveMultipleLaboratorySamplesModal from "../components/SaveMultipleLaboratorySamplesModal";
import SaveMultipleTissueDissociationsModal from "../components/SaveMultipleTissueDissociationsModal";
import SaveMultipleNucleicAcidExtractionsModal from "../components/SaveMultipleNucleicAcidExtractionsModal";
import DisplayCollectionSampleModal from "../components/DisplayCollectionSampleModal";

import CollectionSampleConfig from "../config/CollectionSampleConfig";
import { numToColumn } from "../helpers/commons";

import API from "../services/backend-api";

import * as Yup from "yup";

const validationSchema = Yup.object().shape(CollectionSampleConfig.FilterFormValidationRules);

const dataTableActionModals = [
	  {
        key: 'action-0',
        component: SaveLaboratorySampleModal,
		size: 'lg',
		multiple : false
      },
	  {
        key: 'action-1',
        component: SaveTissueDissociationModal,
		size: 'lg',
		multiple : false
      },
	  {
        key: 'action-2',
        component: SaveNucleicAcidExtractionModal,
		size: 'lg',
		multiple : false
      },
	  {
        key: 'action-3',
        component: DisplayCollectionSampleModal,
		size: 'lg',
		multiple : false
      },
	  {
        key: 'multiple-action-0',
        component: SaveMultipleLaboratorySamplesModal,
		size: 'lg',
		multiple : true
      },
	  {
        key: 'multiple-action-1',
        component: SaveMultipleTissueDissociationsModal,
		size: 'lg',
		multiple : true
      },
	  {
        key: 'multiple-action-2',
        component: SaveMultipleNucleicAcidExtractionsModal,
		size: 'lg',
		multiple : true
      }
    ];


const CollectionSamples = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [pagedCollectionSamples, setPagedCollectionSamples] = useState(null);
	const [selectedDataTableItem, setSelectedDataTableItem] = useState(null);
	const [selectedDataTableItems, setSelectedDataTableItems] = useState(null);
	const [dataTableActionModalsShowMap, setDataTableActionModalsShowMap] = useState(new Map());
	
	const location = useLocation();
	
	let context = useOutletContext();
	
	let navigate = useNavigate();
	
	let defaultValues = validationSchema.default();
	
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
			
		//Initializing Action Modals Show Status
		resetModalsVisibility();
			
		listCollectionSamples({})
			.catch(error => { 
		 		log.error("Error Loading Initial Collection Samples Page: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		
	}, []);
	
  const resetModalsVisibility = () => {
	let tempMap = new Map();
	dataTableActionModals.forEach((item, index) => {
		tempMap.set(item.key, false);
	});
	setDataTableActionModalsShowMap(tempMap);
  }

  const handleItemsSaved = (success) => {
	  handleActionModalHide();
	  refreshPage();
	  context.onSuccess(success);
   }

  const handleActionModalHide = () => {
	  resetModalsVisibility();
	  setSelectedDataTableItem(null); 
      setSelectedDataTableItems(null);
	  
  }

  const showActionModal = (action) => { 
	  let tempMap = dataTableActionModalsShowMap;
	  tempMap.set(action, true);
	  setDataTableActionModalsShowMap(tempMap);
   }

  const saveLaboratorySample = (item, action) => {
	  navigate("/analysis/laboratorysamples/update", { state: { item: item}});
  }
 
  const saveLaboratoryDissociation = (item, action) => {
	  setSelectedDataTableItem(item);
	  showActionModal(action)
  }

  const saveLaboratoryExtraction = (item, action) => {
	  setSelectedDataTableItem(item);
	  showActionModal(action)
  }

  const saveMultipleLaboratorySamples = (items, action) => {
	  setSelectedDataTableItems(items);
	  showActionModal(action)
  }

  const createMultipleTissueDissociations = (items, action) => {
	  setSelectedDataTableItems(items);
	  showActionModal(action)
  }

 const createMultipleNucleicAcidExtractions = (items, action) => {
	  setSelectedDataTableItems(items);
	  showActionModal(action)
  }


  const displayCollectionSample = (item, action) => {
	  setSelectedDataTableItem(item);
	  showActionModal(action)
  }
    
  const updatePage = (values) => {
	  listCollectionSamples(values)
		.catch(error => { 
			log.error("Error Paginating Collections List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const sortCollectionSamples = (values) => {
	  values.pageIndex = 0;
	  listCollectionSamples(values)
		.catch(error => { 
			log.error("Error Sorting Collections List: ", error.message);
	 		context.onError(error);
		})
		
  }

  const displayRanOut = (item) => { 
	return (item.laboratorySampleRanOut) ? "inactive-row": "";
  }
  
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  
		  queryParams.pageIndex = currentPage.pageIndex;
		  queryParams.pageSize = currentPage.pageSize;
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;

		  for(let property in CollectionSampleConfig.FilterFormValidationRules) {
				if(currentPage.hasOwnProperty(property)) {
					queryParams[property] = (currentPage[property]) ? currentPage[property] : defaultValues[property];
				}
		   }
	  }
	  
	  if (newValues) {
		  for(let property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
  const refreshPage = () => {
		  
	  //List Collection Samples with no new values
	  listCollectionSamples({})
		.catch(error => { 
	 		log.error("Error Refreshing Collection Samples List: ", error.message);
	 		context.onError(error);
		})	
  }  

  const filterCollectionSamples = (values) => {
	  values.pageIndex = 0;
	  return listCollectionSamples(values)
		.catch(error => { 
			log.error("Error Filtering Collection Samples List: ", error.message);
	 		context.onError(error);
		})
		
  }

 const exportCollectionSamples = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.exportCollectionSamples(mergeQueryParams(pagedCollectionSamples, values), true).then(response => {
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});

  const listCollectionSamples = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.pageCollectionSamples(mergeQueryParams(pagedCollectionSamples, values)).then(response => {
			setPagedCollectionSamples(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
	
	if (isLoading) 
		return <Loader />
	
	if (!pagedCollectionSamples) 
		return null;
		
	const buildLabInfo = (s) => {
		
		return (s.laboratory) ? 
			s.laboratory 
				+ ((s.storageBoxLabelId) 
					? " | " + s.storageBoxLabelId
						+ ((s.storageBoxSlotCol && s.storageBoxSlotRow) 
							? " [" + s.storageBoxSlotRow + "-" + numToColumn(s.storageBoxSlotCol)+"]"
							: "")
					: "")
			: "";
	}
			
	return (
		<Container >
			{ dataTableActionModals.map((item, index) => {
								
				const { 
		    		component: Component, 
		    		...rest 
		    	} = item;
			
				return (
					<Fragment key={item.key}>
					{ (dataTableActionModalsShowMap.get(item.key)) ?
						<Fragment>
							{(item.multiple && selectedDataTableItems) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="collection-samples"
									items={selectedDataTableItems}
									onError={context.onError}
									onItemsSaved={handleItemsSaved}
								/>
								: 
								(!item.multiple && selectedDataTableItem) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="collection-samples"
									item={selectedDataTableItem}
									onError={context.onError}
									onItemSaved={handleItemsSaved}
								/>
								:
								null
							}
						</Fragment>
						:
						null
					}
					</Fragment>
	      		)
    		})}
			
			<PagedList
				i18nPrefix="collectionsamples.list."
				page={pagedCollectionSamples}
				list={pagedCollectionSamples.list.map((s) => Object.assign({}, s, {labInfo: buildLabInfo(s)}))}
				pageSizeOptions={[10,25,50,100]}
				filterFormComponent={CollectionSamplesFilterForm}
				onFilter={filterCollectionSamples} 
				onError={context.onError}
				onSort={sortCollectionSamples}
				onPage={updatePage}
				onExport={exportCollectionSamples}
				dataTableColumns={["organismId", "materialSample", "eventDate", "observatory", "labInfo"]}
				dataTableSortableColumns={["organismId","eventDate"]}
				dataTableCustomDisplayColumns={[["eventDate", "LocalDate"],["materialSample", "Enum"]]}
				dataTableActions={[saveLaboratorySample, saveLaboratoryDissociation, saveLaboratoryExtraction, displayCollectionSample]}
				dataTableDefaultAction={saveLaboratorySample}
				dataTableMultipleSelectActions={[saveMultipleLaboratorySamples, createMultipleTissueDissociations, createMultipleNucleicAcidExtractions]}
				dataTableCustomRowStyle={displayRanOut}
				dataTableHandleTextOverflow={true}
			/>
			
			
      </Container>
	
	);
}

export default CollectionSamples;
