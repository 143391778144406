import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

//import {utmObj} from 'utm-latlng';

import * as Yup from "yup";
import { setLocale } from 'yup';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button'

import FormControlErrors from "../components/FormControlErrors";

setLocale({
	mixed: {
		default: 'form.validation.mixed.default',
		required: 'form.validation.mixed.required',
	}
});
const validationSchema = Yup.object().shape({
	  easting: Yup.string()
	  	.matches("^[0-9]{0,}(.[0-9]{1,})?$", "collections.set-coordinates-modal.form.utm-converter.easting.validation.format")
		.required()
		.default(() => ""),
	  northing: Yup.string()
	  	.matches("^[0-9]{0,}(.[0-9]{1,})?$", "collections.set-coordinates-modal.form.utm-converter.northing.validation.format")
		.required()
		.default(() => ""),
	  zone: Yup.string()
	  	.matches("^([1-9]|[1-5][0-9]|60)$", "collections.set-coordinates-modal.form.utm-converter.zone.validation.format")
		.required()
		.default(() => ""),
	  band: Yup.string()
	  	.matches("^[CDEFGHJKLMNPQRSTUVWX]$", "collections.set-coordinates-modal.form.utm-converter.band.validation.format")
		.required()
		.default(() => "")
});

const UTMCoordinatesConverter = props => {

	const [easting, setEasting] = useState("");
	const [northing, setNorthing] = useState("");
	const [zone, setZone] = useState("");
	const [band, setBand] = useState("");
	const [errors, setErrors] = useState({});
	const { t } = useTranslation();
	
	//const bandLetters = "CDEFGHJKLMNPQRSTUVWX";
	
	/*useEffect(() => {
		let isMounted = true; 
		
		if (props.latitude && props.longitude) {
			convertLatLngToUtm(props.latitude, props.longitude)
		}
		  
	}, []);*/
	
	const convertUtmToLatLng = (easting, northing, zone, band) => {
	
		//Clear Error
		props.onError(null);
		setErrors({});
		
		validationSchema.validate({ easting: easting, northing: northing, zone: zone, band: band }, {abortEarly: false})
			.then(function (value) {
  				
  				var utmObj = require('utm-latlng');
				var utm=new utmObj(); //Default Ellipsoid is 'WGS84'
		
				let location = utm.convertUtmToLatLng(value.easting, value.northing, value.zone, value.band);
		
				if (isNaN(location.lat) || isNaN(location.lng)) {
					log.error("UTM Coordinates Conversion failed: ", location);
          			props.onError(t(props.i18nPrefix+"utm-converter.error"));
				} else {
					props.onChange({latitude: location.lat, longitude: location.lng});
				}
			})
			.catch(function (err) {
  				
  				let validationErrors = {};
  				err.inner.forEach((error) => {
  					validationErrors[error.path]= error.errors;
  				});
  				
  				setErrors(validationErrors);
			});

	}
	
	/*const convertLatLngToUtm = (latitude, longitude) => {
	
		var utmObj = require('utm-latlng');
		var utm=new utmObj(); //Default Ellipsoid is 'WGS84'
		
		let utmCoordinates = utm.convertLatLngToUtm(latitude, longitude, 4);
		
		setEasting(utmCoordinates.Easting);
		setNorthing(utmCoordinates.Northing);
		setZone(utmCoordinates.ZoneNumber);
		setBand(utmCoordinates.ZoneLetter);
		
	}*/
	   
	
	return (
    	<div className="border rounded p-4">
				    	
			<Row>
				 <Col md={10}>
				    		
				   	<FormGroup as={Row} controlId="formGridUTMEastingNorthing">
				    	<FormLabel column="sm" sm={2}><Trans i18nKey={props.i18nPrefix+"utm-converter.easting.label"}>UTM Easting</Trans> *</FormLabel>
							<Col sm={4}>
								<FormControl size="sm" type={'text'} name="easting" isInvalid={!(errors.easting == null)} value={easting} onChange={e => setEasting(e.target.value)}  />
								<FormControlErrors errors={errors.easting} />
							</Col>
				    	<FormLabel column="sm" sm={{ span: 2, offset: 0 }}><Trans i18nKey={props.i18nPrefix+"utm-converter.northing.label"}>UTM Northing</Trans> *</FormLabel>
								<Col sm={4}>
									<FormControl size="sm" type={'text'} name="northing" isInvalid={!(errors.northing == null)} value={northing} onChange={e => setNorthing(e.target.value)}  />
									<FormControlErrors errors={errors.northing} />
								</Col>
				    		</FormGroup>

				    		<FormGroup as={Row} className="mb-1" controlId="formGridUTMZoneBand">
				    			<FormLabel column="sm" sm={2}><Trans i18nKey={props.i18nPrefix+"utm-converter.zone.label"}>UTM Zone</Trans> *</FormLabel>
								<Col sm={4}>
									<FormControl size="sm" type={'text'} name="zone" isInvalid={!(errors.zone == null)} value={zone} onChange={e => setZone(e.target.value)}   />
									<FormControlErrors errors={errors.zone} />
								</Col>
				    			<FormLabel column="sm" sm={{ span: 2, offset: 0 }}><Trans i18nKey={props.i18nPrefix+"utm-converter.band.label"}>UTM Band</Trans> *</FormLabel>
								<Col sm={4}>
									{/*<FormControl size="sm" type={'text'} name="band" isInvalid={!(errors.band == null)} value={band} onChange={e => setBand(e.target.value)}   />*/}
									<FormControl size="sm" as="select" name="band" isInvalid={!(errors.band == null)} value={band} onChange={e => setBand(e.target.value)} >
		    							<option value="">{t(props.i18nPrefix+"utm-converter.band.options.blank")}</option>
		    							{ "CDEFGHJKLMNPQRSTUVWX".split('').map(c => 
		    								<option key={c} value={c}>{c}</option>
		    							)}
		    						</FormControl>
									<FormControlErrors errors={errors.band} />
								</Col>
				    		</FormGroup>
				    			
				    		</Col>
				    		
				    		<Col className="text-center align-self-center">
							 	<Button variant="primary" size="sm" onClick={() => {convertUtmToLatLng(easting, northing, zone, band);}}><Trans i18nKey={props.i18nPrefix+"utm-converter.convert-button"}>Convert</Trans></Button>
							</Col>
			</Row>
						
		</div>
	)
};

export default UTMCoordinatesConverter;