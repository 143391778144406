import React,  { Fragment, useState, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';

import InputGroup from 'react-bootstrap/InputGroup';

import FormControlErrors from "../components/FormControlErrors";
import FormControlHelper from "../components/FormControlHelper";

import Switch from "../components/Switch";

import { AuthError, ValidationError } from "../helpers/custom-errors";

import API from "../services/backend-api";
import { useAuth } from "../services/use-auth";

import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import "react-bootstrap-typeahead/css/Typeahead.css";
import "../styles/Typeahead.css";

const StorageBoxFormFields = props => {

	const [isSearchingTag, setIsSearchingTag] = useState(false);
	const [workgroups, setWorkgroups] = useState([]);
	const [options, setOptions] = useState([]);
	const typeaheadRef = useRef(null);
	
	const { t } = useTranslation();
	const auth = useAuth();
	
	useEffect(() => {
		let isMounted = true; 
		
		//Fetch Laboratory Workgroups
		API.findWorkgroups({"types": ["LABORATORY"]}, auth.isAdmin())
			.then(response => {
				if (isMounted) setWorkgroups(response.list);
			})
			.catch(error => { 
				log.error("Error Loading Analysis Workgroups: ", error.message);
		 		props.onError(error);
			});
		
		 return () => { isMounted = false };
		  
	}, []);
	
	
	const handleTagSearch = (query) => {
	    
	    findTags({filter: query})
	      .then(({ list }) => {
				
			  const options = list.map((e,index)=> {
					return ({
	    		  		id: e.id,
						name: e.name
	    	  		})
			  });  	 
		
	    	  setOptions(options);
	    	  
	       })
	      .catch(error => { 
		      if (error instanceof AuthError) {		
		    	  auth.onUnathorized(error);
		      } else if (error instanceof ValidationError) {		
          						
				 log.info("Find Tags Attempt Failed: ", error.message);
         
				 props.onError(new Error(t(error.message)))		
          						
          	 } else {
          		log.error("Find tags Error: ", error.message);
				props.onError(new Error(t('error.api.general')))
          	 }
		 });
	  }
	  
	  const findTags = (values) => {
		
		setIsSearchingTag(true);
		
		return new Promise((resolve, reject) => {
			API.findLaboratoryTags(props.values.workgroupId, values, auth.isAdmin())
			.then(response => {
				resolve(response);
			}).catch(error => {			
				reject(error);
			}).finally(() => {
			 	setIsSearchingTag(false);
		 	});
		});
		
	}
    
	return(
		<Fragment>
			<Row>
				<FormGroup as={Col} controlId="formGridLabelId">
					<FormLabel ><Trans i18nKey="storageboxes.save.form.labelId.label">Label Id</Trans> *</FormLabel>
				    <FormControl className="text-uppercase" type={'text'} name="labelId" isInvalid={!(props.errors.labelId == null)} value={props.values.labelId} onChange={props.onChange} placeholder={t('storageboxes.save.form.labelId.placeholder')} />
				    <FormControlErrors errors={props.errors.labelId} />
				</FormGroup>
			
				<FormGroup as={Col} controlId="formGridWorkgroup">
				    <FormLabel><Trans i18nKey="storageboxes.save.form.workgroupId.label">Workroup</Trans> *</FormLabel>
					<FormControl as="select" name="workgroupId" isInvalid={!(props.errors.workgroupId == null)} value={props.values.workgroupId} onChange={props.onChange} >
		    			<option value="">{t("storageboxes.save.form.workgroupId.blank-option")}</option>
		    				{ workgroups.map(item =>
		    					<option key={item.id} value={item.id+""}>{item.name}</option>
		    				)}
		    		</FormControl>
					<FormControlErrors errors={props.errors.workgroupId} />
				 </FormGroup>
			</Row>
			
			<Row>
				<FormGroup as={Col} controlId="formGridTags">
					<FormLabel><Trans i18nKey="storageboxes.save.form.tags.label">Tags</Trans> <FormControlHelper text="storageboxes.save.form.tags.helper"/></FormLabel>
					<AsyncTypeahead
      					multiple
      					id="tags"
						disabled={!(props.values.workgroupId)}
						isInvalid={!(props.errors.tags == null)}
						isLoading={isSearchingTag}
						labelKey="name"
						onSearch={handleTagSearch}
      					onChange={(selected) => {
        					props.setFieldValue("tags", selected);
        					// Keep the menu open when making multiple selections.
        					typeaheadRef.current.toggleMenu();
      					}}
      					options={options}
						placeholder={t("storageboxes.save.form.tags.placeholder")}
						emptyLabel={t("storageboxes.save.form.tags.empty-label")}
      					ref={typeaheadRef}
						renderMenuItemChildren={(option, props) => (
							<Highlighter search={props.text}>
						      {option.name}
						    </Highlighter>
						)}
						selected={props.values.tags}

    			/>
				<FormControlErrors block={true} errors={props.errors.tags} />
			</FormGroup>
			
			</Row>
			
			<Row>
				<FormGroup as={Col} controlId="formGridRemarks">
					<FormLabel><Trans i18nKey="storageboxes.save.form.remarks.label">Remarks</Trans></FormLabel>
					<FormControl className="text-uppercase" as="textarea" rows={2} maxLength={255} name="remarks" isInvalid={!(props.errors.remarks == null)} value={props.values.remarks} onChange={props.onChange} placeholder={t('storageboxes.save.form.remarks.placeholder')} />
					<FormControlErrors errors={props.errors.remarks} />
				</FormGroup> 
			</Row>
			   
			<Row className="mb-0">
			    <FormGroup className="mb-0" as={Col} controlId="formGridArrangement">
			    	<FormLabel ><Trans i18nKey="storageboxes.save.form.arrangement.label">Arrangement</Trans> *</FormLabel>
			    </FormGroup>
			  </Row>
	
			<Row>
			    <FormGroup as={Col} md="3" controlId="formGridColumns">
			    	<InputGroup>
						<FormControl type={'text'} name="cols" isInvalid={!(props.errors.cols == null)} value={props.values.cols} onChange={props.onChange} />
						<InputGroup.Append>
							<InputGroup.Text>colunas</InputGroup.Text>
						</InputGroup.Append>
					</InputGroup>
			    	<FormControlErrors block={true} errors={props.errors.cols} />
			    </FormGroup>
				<FormGroup as={Col} md="auto" className="d-flex align-items-center" controlId="formGridColumns">
					<FormLabel className="font-weight-bold mb-0">X</FormLabel>
				</FormGroup>
				<FormGroup as={Col} md="3" controlId="formGridRows">
			    	<InputGroup>
						<FormControl type={'text'} name="rows" isInvalid={!(props.errors.rows == null)} value={props.values.rows} onChange={props.onChange}  />
						<InputGroup.Append>
							<InputGroup.Text>linhas</InputGroup.Text>
						</InputGroup.Append>
					</InputGroup>
			    	<FormControlErrors block={true} errors={props.errors.rows } />
			    </FormGroup>
			  </Row>
	
	
		      { /*<Row>
				<FormGroup as={Col} controlId="formGridArrangement">
			    	<FormLabel ><Trans i18nKey="storageboxes.save.form.arrangement.label">Arrangement</Trans> *</FormLabel>
			    	<FormControl type={'text'} name="cols" isInvalid={!(props.errors.cols == null)} value={props.values.cols} onChange={props.onChange} />
			    	<FormControl type={'text'} name="rows" isInvalid={!(props.errors.rows == null)} value={props.values.rows} onChange={props.onChange}  />
					<FormControlErrors errors={props.errors.cols} />
					<FormControlErrors errors={props.errors.rows} />
			    </FormGroup>
			  </Row>*/
				}
		    			
			  {(props.action === 'update')&& 
			  	<Row>    					   
	    			<FormGroup as={Col} controlId="formGridDiscarded">
	    				<FormLabel ><Trans i18nKey="storageboxes.save.form.discarded.label">Discarded</Trans></FormLabel>
	    				<Switch 
	    					name="discarded"
	    					value={props.values.discarded}
	    					checked={JSON.parse(props.values.discarded)}
	    					onChange={props.onChange}
	    				/>
	    			</FormGroup>
	    		</Row>
			  }
				
				
		
		</Fragment>
		
	) 
}


export default StorageBoxFormFields;
