import React,  { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation, Route, Navigate, useOutletContext } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import UserFormFields from "../components/UserFormFields";
import Loader from "../components/Loader";
import Prompt from "../components/Prompt";
import SubjectActivities from "../components/SubjectActivities";

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";

import UserConfig from "../config/UserConfig";
import UserPasswordConfig from "../config/UserPasswordConfig";

import { ValidationError } from "../helpers/custom-errors";

import { compareJSON } from "../helpers/commons";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   },
	   string: {
		     email: 'form.validation.string.email',
		   },
	 });


const updateUserValidationSchema = Yup.object().shape({
	...UserConfig.GeneralFormValidationRules,
	...UserConfig.AccessControlFormValidationRules,
	...UserConfig.WorkgroupsFormValidationRules,
});

const createUserValidationSchema = Yup.object().shape({
	...UserConfig.GeneralFormValidationRules,
	...UserPasswordConfig.FormValidationRules,
	...UserConfig.AccessControlFormValidationRules,
	...UserConfig.WorkgroupsFormValidationRules,
});


const saveUserTabs = [
	  {
        key: 'general',
        component: UserFormFields.GeneralSection,
		actions: ['create', 'update'],
      }, 
      {
        key: 'access-control',
        component: UserFormFields.AccessControlSection,
		actions: ['create', 'update']
      },
      {
        key: 'workgroups',
        component: UserFormFields.WorkgroupsSection,
		actions: ['update']
      }
    ];

const SaveUser = props => {
	
	const [tabKey, setTabKey] = useState('general');
	const [isLoading, setIsLoading] = useState(true);
	const [user, setUser] = useState(null);
	
	let navigate = useNavigate();
	
	const location = useLocation();
	
	let context = useOutletContext();
	
	const { t } = useTranslation();
	
	const config = useConfig();
	
	const tabs = saveUserTabs;
	
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
		
		if (location.pathname === '/users/update' && location.state && location.state.item) {
			API.lookupUser(location.state.item.id)
			.then(response => {
					setUser(response);
			})
			.catch(error => { 
				log.error("Error Loading User: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		}
		 
		 return () => { isMounted = false };
		  
	}, [location.state]);
	
	if (location.pathname === '/users/update') {
		if (!location.state.item) {
			return (<Navigate  to="/users" />)
		} else if (isLoading) {
			return <Loader />
		} else if (!user) {
			return null;
		}
	}
	
	let initialValues = {}, action, actionIcon, validationSchema, linearValidationRules;
	
	let defaultValues = createUserValidationSchema.default();
		
	if (location.pathname === '/users/update') {
		//let mergedRules = Object.assign(UserConfig.GeneralFormValidationRules, UserConfig.AccessControlFormValidationRules);
		Object.keys(Object.assign({}, UserConfig.GeneralFormValidationRules, UserConfig.AccessControlFormValidationRules, UserConfig.WorkgroupsFormValidationRules))
			.forEach((key) => {
				initialValues[key] = (user[key]) ? user[key] : defaultValues[key];
		});
				
		action = "update";
		actionIcon = faCheckCircle;
		validationSchema = updateUserValidationSchema;
		linearValidationRules = [
			UserConfig.GeneralFormValidationRules,
			UserConfig.AccessControlFormValidationRules,
			UserConfig.WorkgroupsFormValidationRules
		]
		
	} else {
		
		initialValues = defaultValues;
		action = "create";
		actionIcon = faPlusCircle
		validationSchema = createUserValidationSchema;
		linearValidationRules = [
			Object.assign({}, UserConfig.GeneralFormValidationRules, UserPasswordConfig.FormValidationRules),
			UserConfig.AccessControlFormValidationRules,
			UserConfig.WorkgroupsFormValidationRules
		];
		
	}
	
	const saveUser = (values) =>
		new Promise((resolve, reject) => {
			if (location.pathname === '/users/update') {
				API.updateUser(location.state.item.id, values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			} else {
				API.createUser(values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			}
		});
		
    
    /*const hasGeneralErrors = (errors) => {
		 return !(errors.firstName == null)
		 	|| !(errors.lastName == null)
		 	|| !(errors.displayName == null)
		 	|| !(errors.email == null)
		 	|| !(errors.mobilePhone == null)
		 	|| !(errors.newPassword == null)
		 	|| !(errors.confirmedNewPassword == null);
    }
	
	const hasPermissionErrors = (errors) => {
		 return !(errors.roles == null)
    }

	*/

	const hasTabErrors = (indexTab, errors) => {	
		
		return (Object.keys(linearValidationRules[indexTab])
			.find((key) => !(errors[key] == null)))
    }
    
  
	return (
		<Container >
			<Row className="mb-3">
        		<Col>
        			<h3>
        				<Trans i18nKey={"users.save.header-"+action}>Save User</Trans> 
        			</h3>
        		</Col>
  
        	</Row>
        	<Row className="mb-3">
    			<Col>
    				<Formik
    					initialValues={initialValues}
    					validationSchema={validationSchema}
    					validateOnChange={false}
    					validateOnBlur={false}     
    					onSubmit={(values, actions) => {
    						//Clear Error/Success
    						context.onError(null);
    						context.onSuccess(null);
					
    						saveUser(values)
    							.then((response) => {
    								actions.resetForm({ values }) //Workaround to set dirty back to true
    								navigate("/users", {state: { success: t("users.save.success")}});
    							})
    							.catch(error => { 
    								
    								actions.setSubmitting(false);
    								
    								if (error instanceof ValidationError) {		
    									
    									log.info("Save User Attempt Failed: ", error.message);

    									if (error.detail) {            						
    	              						for (let key of Object.keys(error.detail)) {
    	              							let errorMsgs = error.detail[key];
    	              							errorMsgs.forEach((message) => {
    	              								actions.setFieldError(key, message);
    	              							});
    	              						}
    	              					}
    								} else {
    									log.error("Error Saving User: ", error.message);
    								}
    								
    								context.onError(error);
    								
    							})
					}}
		      	>
		      	{({isSubmitting, dirty, errors, values, handleChange, setFieldValue}) => (	
		      	
		      	<Form className="form-user" noValidate>
		      		<Prompt
		      			when={!!dirty && !isSubmitting}
		      			message={JSON.stringify({
		      		           "title": t("form.unsaved-changes-modal.title"),
		      		           "bodyText": t("form.unsaved-changes-modal.body"),
		      		           "confirmText": t("form.unsaved-changes-modal.confirm"), 
		      		           "cancelText": t("form.unsaved-changes-modal.cancel"),
		      		           "size": "lg",
		      		           "variant": "danger",
		        			   "when": compareJSON(initialValues, values)
		      		          }
		      		      )
		      		   }
		      		/>

					<Tab.Container id="userFormTab" activeKey={tabKey} onSelect={(k) => setTabKey(k)} >
					
					<Nav variant="tabs" className="mb-3">
					
						{ tabs.map((tab, index) => {
							
							if (!tab.actions.includes(action)) return null;
							
							return (
								<Nav.Item key={index}>
    								<Nav.Link eventKey={tab.key} className={ hasTabErrors(index, errors)  ? "text-danger" : ""}><Trans i18nKey={"users.save.tabs."+tab.key}>{tab.key}</Trans>{ hasTabErrors(index, errors) ? "*" : ""}</Nav.Link>
  								</Nav.Item>
	      					)
	      				})}
	
  						{/* 
				
						<Nav.Item>
    						<Nav.Link eventKey="general" className={ hasGeneralErrors(errors)  ? "text-danger" : ""}><Trans i18nKey="users.save.tabs.general">General</Trans>{ hasGeneralErrors(errors) ? "*" : ""}</Nav.Link>
  						</Nav.Item>
  						<Nav.Item>
   							<Nav.Link eventKey="permissions" className={ hasPermissionErrors(errors) ? "text-danger" : ""}><Trans i18nKey="users.save.tabs.permissions">Permissions</Trans>{ hasPermissionErrors(errors) ? "*" : ""}</Nav.Link>
  						</Nav.Item>
						{(location.pathname === '/users/update') &&
						<Nav.Item>
   							<Nav.Link eventKey="workgroups"><Trans i18nKey="users.save.tabs.workgroups">Workgroups</Trans></Nav.Link>
  						</Nav.Item>
						}
						*/}
						
					</Nav>
					
					
					<Tab.Content>
						{ tabs.map((tab, index) => {
								
							const { 
		    					component: Component, 
		    						...rest 
		    				} = tab;
								
							
							if (!tab.actions.includes(action)) return null;
							
							return (
								
								<Tab.Pane key={index} eventKey={tab.key}>
									<Component 
										values={values} 
										errors={errors} 
										onChange={handleChange} 
										onError={context.onError}
										setFieldValue={setFieldValue}
										action={action}
									/>
								</Tab.Pane>

	      						)
    						})}
						
					</Tab.Content>
					
					
					
					{/*
					<Tab.Content>
        				<Tab.Pane eventKey="general">

				    	
    					
    					</Tab.Pane>
        				<Tab.Pane eventKey="permissions">
							<Row>
								<FormGroup as={Col} controlId="formGridRoles">
									<FormLabel><Trans i18nKey="users.save.form.roles.label">Roles</Trans> *</FormLabel>
    	       						<fieldset className={!(errors.roles == null) ? "is-invalid" : ""}>
    	       	 						<div className={!(errors.roles == null) ? "border border-danger rounded p-2" : "border rounded p-2"}>
    	       	 							{ UserConfig.Roles.map(item => 
												<div className="form-check mb-1" key={item}>
													<FormCheck 
        												className="text-uppercase"
        												type="checkbox"
        												name="roles"
        												id={item}
        												value={item}
        												label={t("users.save.form.roles.values."+item)}
        												onChange={handleChange}
        												checked={values.roles.includes(item)}
      												/>
                								</div>
			    							)}
        								</div>
  									</fieldset>
				    				<FormControlErrors errors={errors.roles} />	
								</FormGroup>
				        	</Row>
							<Row>
								<FormGroup as={Col} controlId="formGridPermissions">
									<FormLabel><Trans i18nKey="users.save.form.permissions.label">Permissions</Trans></FormLabel>
    	       						<fieldset className={!(errors.permissions == null) ? "form-group is-invalid" : "form-group"}>
    	       	 						<div className={!(errors.permissions == null) ? "border border-danger rounded p-2" : "border rounded p-2"}>
    	       	 							{ UserConfig.Permissions.map(item => 
												<div className="form-check mb-1" key={item}>
													<FormCheck 
        												className="text-uppercase"
        												type="checkbox"
        												name="permissions"
        												id={item}
        												value={item}
        												label={t("users.save.form.permissions.values."+item)}
        												onChange={handleChange}
        												checked={values.permissions.includes(item)}
      												/>
                								</div>
			    							)}
        								</div>
  									</fieldset>
				    				<FormControlErrors errors={errors.permissions} />	
								</FormGroup>
				        	</Row>
        				</Tab.Pane>
						{(location.pathname === '/users/update') &&
						<Tab.Pane eventKey="workgroups">
							<Row>
        						<Col>
        							<DataTable 
        								items={(user) ? user.workgroups : []} 
        								i18nPrefix="users.save.workgroups-datatable." 
        								columns={["name", "headquarters", "type","region"]}
        								customDisplayColumns={[["type", "Enum"], ["region", "Enum"]]} 
        								actions={[goToWorkgroup]} 
        							/>
        						</Col>
        					</Row>
						</Tab.Pane>
						}
      				</Tab.Content>
					*/}
					
					</Tab.Container>
					
	    			    <Row className="mt-3">    					   
    						<Col>
    							<Button variant="success" type="submit" disabled={isSubmitting}> 
    								{isSubmitting ? <Trans i18nKey="users.save.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={actionIcon} /> <Trans i18nKey={"users.save.form.submit-"+action}>Submit</Trans></span>} 
    							</Button>
    						    
    							<Button variant="secondary" className="float-right" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="users.save.cancel">Cancel</Trans></Button>
    					   </Col>
    			       </Row>     
		      	</Form>	
		      )}
		      </Formik>
		      
		      </Col>
	        </Row>
	        
	     </Container>
	);
}

export default SaveUser;
