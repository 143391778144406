import React, { useState, useEffect, useRef  } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter} from '@fortawesome/free-solid-svg-icons'

import FormGroup from 'react-bootstrap/FormGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';

import FormControlErrors from "../components/FormControlErrors";
//import Loader from "../components/Loader";

import StorageBoxInputSearch from "../components/StorageBoxInputSearch";

import moment from "moment";
import { DatePicker } from "react-tempusdominus-bootstrap";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";

import * as Yup from "yup";

//import { ValidationError } from "../helpers/custom-errors";

import TissueDissociationConfig from "../config/TissueDissociationConfig";

import { useConfig } from "../services/use-config";

import { parseInputDate } from "../helpers/commons";

import API from "../services/backend-api";

import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "../styles/Typeahead.css";

const displayFiltersButton = {
    position: "absolute", 
	right: "0.75rem"
}

const validationSchema = Yup.object().shape(TissueDissociationConfig.FilterFormValidationRules);

const TissueDissociationsFilterFormFields = props => {
	
	const [laboratories, setLaboratories] = useState([]);
	const typeaheadRef = useRef(null);
	const [displayMoreFilters, setDisplayMoreFilters] = useState(false);
		
	const { t } = useTranslation();
	
	const config = useConfig();
	
	useEffect(() => {
		let isMounted = true; 
			
		API.findLaboratories({}).then(response => {
				if (isMounted) setLaboratories(response.list);
			}).catch(error => {			
				log.error("Error Loading Laboratory Workgroups: ", error.message);
		 		props.onError(error);
			});
		
		return () => { isMounted = false };
		  
	}, []);
	
		
    const createMaterialSampleOption = (item) => {
        
        return Object.assign({}, {"id": item, "displayText": t(props.i18nPrefix+"materialSamples.options."+item)})
    }

	const toogleDisplayFilters = () => {
		
		let currentDisplayMode = displayMoreFilters;
		
		if (displayMoreFilters) {
			
			let defaultValues = validationSchema.default();
			
			Object.keys(TissueDissociationConfig.AdvancedFilterFormFields)
			.forEach((key) => {
				props.setFieldValue(key, defaultValues[key]);
			});
			
			props.submitForm();
		}
		
		setDisplayMoreFilters(!currentDisplayMode);
		
    } 


	const handleStorageBoxChange = (storageBox) => {
		
        if (!storageBox) {
			props.setFieldValue("storageBoxId", "");
        	return;
        } 

		props.setFieldValue("storageBoxId", storageBox.id);

    }

	const handleLaboratoryChange = (e) => {
        
        props.setFieldValue("laboratoryId", e.target.value);
        
        if (!e.target.value) {
        	
			props.setFieldValue("storageBoxId", "");
			
        	return;
        }
        
		props.setFieldValue("storageBoxId", "");
    }
	
	return (
		<fieldset>
			<div style={displayFiltersButton}>  					   
				<Button variant="link" onClick={toogleDisplayFilters} className="float-right"><FontAwesomeIcon icon={faFilter} /> {(displayMoreFilters)
					? <Trans i18nKey={props.i18nPrefix+"display-less-filters"}>Display - Filters</Trans>
					: <Trans i18nKey={props.i18nPrefix+"display-more-filters"}>Display + Filters</Trans>
					}	
				</Button>
			</div>
			<div className={"border rounded p-4"}>		
				<FormGroup as={Row} controlId="formGridLaboratory">
					<FormLabel column sm="2">
      					<Trans i18nKey={props.i18nPrefix+"laboratoryId.label"}>Laboratory</Trans>
    				</FormLabel>
					<Col sm="7">
						<FormControl as="select" name="laboratoryId" value={props.values.laboratoryId} isInvalid={!(props.errors.laboratoryId == null)}  onChange={handleLaboratoryChange} >
		    				<option value="">{t(props.i18nPrefix+"laboratoryId.blank-option")}</option>
		    				{ laboratories.map(item =>
		    					<option key={item.id} value={item.id+""}>{item.name+" ("+item.shortcode+")"}</option>
		    				)}
		    			</FormControl>
						<FormControlErrors errors={props.errors.laboratoryId} />
   					</Col>
				</FormGroup>
				
				{ displayMoreFilters &&
				 	<FormGroup as={Row} controlId="formGridStorageBox">
						<FormLabel column sm="2">
							<Trans i18nKey={props.i18nPrefix+"storageBoxId.label"}>Storage Box</Trans>
						</FormLabel>
						<Col sm="7">
							<StorageBoxInputSearch 
				    			i18nPrefix={props.i18nPrefix}
				    			onError={props.onError}
				    			isInvalid={!(props.errors.storageBoxId == null)}
				    			onChange={handleStorageBoxChange}
								workgroupId={props.values.laboratoryId}
								disabled={!(props.values.laboratoryId)}
								storageBoxId={props.values.storageBoxId}
				    		/>
							<FormControlErrors errors={props.errors.storageBoxId} />
						</Col>
					</FormGroup>
				}
	
				
						
				<FormGroup as={Row} controlId="formGridLabelId">
					<FormLabel column sm="2">
      					<Trans i18nKey={props.i18nPrefix+"labelId.label"}>Label Id</Trans>
    				</FormLabel>
					<Col sm="7">
						<FormControl type={'search'} name="filter" isInvalid={!(props.errors.filter == null)} value={props.values.filter} onChange={props.onChange} placeholder={t(props.i18nPrefix+"labelId.placeholder")} />
					</Col>
				</FormGroup>
						
				{ displayMoreFilters &&
				<FormGroup as={Row} controlId="formGridDateRange" >
					<FormLabel column sm="2">
      					<Trans i18nKey={props.i18nPrefix+"dateRange.label"}>Date Range</Trans>
    				</FormLabel>
					<Col sm="3">
						<DatePicker
      						format="DD/MM/YYYY"
      						className={!(props.errors.start == null) ? "is-invalid border border-danger rounded" : ""}
      						onChange={(e) => { 
      							if (e.date) { 
      								if (e.date.isValid()) 
      									props.setFieldValue("start", moment(e.date).format("YYYY-MM-DD"));
      								else
      									props.setFieldValue("start", e.date.parsingFlags().inputDate);
      							} else if (e.date === null) { // reset if null (which is !== from undefined)
      								props.setFieldValue("start", "");
      							}
      						}}
      						date={props.values.start}
      						parseInputDate={parseInputDate}
      						locale={config.preferredLanguage}
      						keepInvalid={true}
    					/>
						<FormControlErrors errors={props.errors.start} />
					</Col>
					<FormLabel column sm="1" className="text-center">
      					<Trans i18nKey={props.i18nPrefix+"dateRange.until"}>until</Trans>
    				</FormLabel>
					<Col sm="3">
						<DatePicker
      						format="DD/MM/YYYY"
      						className={!(props.errors.end == null) ? "is-invalid border border-danger rounded" : ""}
      						onChange={(e) => { 
      							if (e.date) { 
      								if (e.date.isValid()) 
      									props.setFieldValue("end", moment(e.date).format("YYYY-MM-DD"));
      								else
      									props.setFieldValue("end", e.date.parsingFlags().inputDate);
      							} else if (e.date === null) { // reset if null (which is !== from undefined)
      								props.setFieldValue("end", "");
      							}
      						}}
      						date={props.values.end}
      						parseInputDate={parseInputDate}
      						locale={config.preferredLanguage}
      						keepInvalid={true}
    					/>
						<FormControlErrors errors={props.errors.end} />
					</Col>
				</FormGroup>
				}
						
				<FormGroup as={Row} controlId="formGridAction" className="mb-1">
					<FormLabel column sm="2">
      					<Trans i18nKey={props.i18nPrefix+"materialSamples.label"}>Material Samples</Trans>
    				</FormLabel>
					<Col sm="7">
						<Typeahead
      						multiple
      						id="materialSamples"
							isInvalid={!(props.errors.materialSamples == null)}
							labelKey={(option) => option.displayText }
      						onChange={(selected) => {
        						props.setFieldValue("materialSamples", selected.map(item => item.id));
        						// Keep the menu open when making multiple selections.
        						typeaheadRef.current.toggleMenu();
      						}}
      						options={TissueDissociationConfig.MaterialSamples.map(item => createMaterialSampleOption(item) )}
      						placeholder={t(props.i18nPrefix+"materialSamples.placeholder")}
							emptyLabel={t(props.i18nPrefix+"materialSamples.empty-label")}
      						ref={typeaheadRef}
      						selected={props.values.materialSamples.map(item => createMaterialSampleOption(item))}
    					/>
						<FormControlErrors errors={props.errors.materialSamples} />
					</Col>
					<Col sm="3">
						<Button type="submit" disabled={props.isSubmitting} onClick={() => {}}> 
							{props.isSubmitting ? <Trans i18nKey={props.i18nPrefix+"submitting"}>Submit</Trans> : <Trans i18nKey={props.i18nPrefix+"submit"}>Submit</Trans>} 
						</Button>
					</Col>
				</FormGroup>
				
			</div>
		</fieldset>
	)

}

export default TissueDissociationsFilterFormFields;
