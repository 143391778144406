import React, { useState, useEffect, useContext, createContext } from "react";
import { useTranslation } from 'react-i18next';
import API from "../services/backend-api";
import { useConfig } from "../services/use-config";
import { AuthError, ValidationError, HttpError } from "../helpers/custom-errors";
import { isArrayWithLength } from "../helpers/commons";
import {Roles} from "../config/Roles";

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [status, setStatus] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [globalError, setGlobalError] = useState(null);
  const [globalSuccess, setGlobalSuccess] = useState(null);
  
  const { t } = useTranslation();
  
  const config = useConfig();
  
  const loginUser = ({ values }) =>
	new Promise((resolve, reject) => {
		API.loginUser(values, config.csrfToken).then(response => {
			setStatus(response.status);
			setUser(response.userData);
			resolve(response);
		}).catch(error => reject(error));
  });
	
  const changeUserPassword = ({ values }) =>
	new Promise((resolve, reject) => {
		API.changeUserPassword(values, config.csrfToken).then(response => {
			setStatus(response.status);
			setUser(response.userData);
			resolve(response);
		}).catch(error => reject(error));
  });
	
 /* const selectUserWorkgroup = ({ values }) =>
	new Promise((resolve, reject) => {
		API.selectUserWorkgroup(values).then(response => {
			setStatus(response.status);
			setUser(response.userData);
			resolve(response);
		}).catch(error => reject(error));
  });*/
	
  const logoutUser = () =>
	new Promise((resolve, reject) => {
		API.logoutUser(config.csrfToken).then(response => {
			setUser(null);
			setStatus(null);
			setGlobalSuccess(response.success);
			resolve(response);
		}).catch(error => reject(error));
  });
	
 const onUnathorized = (error) => {
	setUser(null);
	setStatus(null);
	setGlobalError(error);
  };
  
  const isAdmin = () => {
	  return (user 
			  && isArrayWithLength(user.roles) 
			  && (user.roles.indexOf(Roles.ADMIN) !== -1 || user.roles.indexOf(Roles.ROOT) !== -1 ))
  };

  const isRoot = () => {
	  return (user 
			  && isArrayWithLength(user.roles) 
			  && (user.roles.indexOf(Roles.ROOT) !== -1 ))
  };
	
  const sendUserPasswordResetEmail = ({ values }) =>
	new Promise((resolve, reject) => {
		API.sendUserPasswordResetEmail(values, config.csrfToken).then(response => {
			resolve(response);
		}).catch(error => reject(error));
  });
	
  const confirmUserPasswordReset = ({ values }) =>
	new Promise((resolve, reject) => {
		API.confirmUserPasswordReset(values, config.csrfToken).then(response => {
			resolve(response);
		}).catch(error => reject(error));
  });
	
  const verifyUserPasswordResetToken = ({ values }) =>
	new Promise((resolve, reject) => {
		API.verifyUserPasswordResetToken(values, config.csrfToken).then(response => {
			resolve(response);
		}).catch(error => {
			if (error instanceof ValidationError) {		
				setGlobalError(error); 
			}
			reject(error);
		})
  });

  useEffect(() => {
	  
	  let isMounted = true; 
	  
	  API.getUserAuthState()
		.then((response) => {
			if (isMounted) {
				setUser(response.userData);
				setStatus(response.status);
			}
		})
		.catch(error => {		
			if (isMounted) {
				if (error instanceof AuthError)	{	
					if (error.code === "auth-02") {
						setGlobalError(error);
					}
				} else {
					setGlobalError(new HttpError('GENERIC', 500, t('error.api.general')));
				}
			}
		})
	   .finally(() => {
		   if (isMounted) setInitialized(true);
		});

	    return () => { isMounted = false }; 
	    
  }, []);
  
  // Return the user object and auth methods
  return {
	onUnathorized,
	globalError,
	globalSuccess,
	initialized,
	status,
	user,
	isAdmin,
	isRoot,
    loginUser,
    logoutUser,
    changeUserPassword, 
    /*selectUserWorkgroup,*/
    sendUserPasswordResetEmail,
    confirmUserPasswordReset,
    verifyUserPasswordResetToken
  };
}