import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import FormLabel from 'react-bootstrap/FormLabel';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import DismissableFeedback from "../components/DismissableFeedback";
import FormControlErrors from "../components/FormControlErrors";

import { useConfig } from "../services/use-config";
import { useAuth } from "../services/use-auth";
import API from "../services/backend-api";

import { AuthError, ValidationError } from "../helpers/custom-errors";

import WorkgroupLicenseConfig from "../config/WorkgroupLicenseConfig";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const validationSchema = Yup.object().shape(WorkgroupLicenseConfig.FormValidationRules);

const AddWorkgroupLicenseModal = props => {

	const [_error, _setError] = useState(null);
	
	const { t } = useTranslation();
	
	const config = useConfig();
	
	const auth = useAuth();
	
	let defaultValues = validationSchema.default();
		
	let initialValues = defaultValues;
	
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-add-workgroup-license"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-add-workgroup-license">
  					<Trans i18nKey="workgroups.add-license-modal.title">Add License</Trans>
  				</Modal.Title>
  			</Modal.Header>
  				<Formik
		      		initialValues={initialValues}
		      		validationSchema={validationSchema}
		      		validateOnChange={false}
		      		validateOnBlur={false}     
		      		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);
					
		      			API.addWorkgroupLicense(props.workgroup.id, values, config.csrfToken)
		      			.then(response => {
							actions.setSubmitting(false);
							props.onLicenseAdded(response.success);
						})
		      			.catch(error => { 
		      				
		      				actions.setSubmitting(false);
		      				
		      				if (error instanceof AuthError) {		
		    					auth.onUnathorized(error);
		    				} else if (error instanceof ValidationError) {		
          						
					 			log.info("Add Workgroup License Attempt Failed: ", error.message);
          						
					 			_setError(error.message);
          						
					 			if (error.detail) {            						
              						for (let key of Object.keys(error.detail)) {
              							let errorMsgs = error.detail[key];
              							errorMsgs.forEach((message) => {
              								actions.setFieldError(key, message);
              							});
              						}
              					}
          						
          					} else {
          						
          						log.error("Add Workgroup License Error: ", error.message);
          						_setError(t('error.api.general'));
          					}

					 	})
					}}
		      	>
		      	{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue}) => (	
		      		<Form className="form-add-workgroup-license" noValidate>		
		      		<Modal.Body className="mb-3">
		  				<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  
				    	
						<Row>
			    			<FormGroup as={Col} controlId="formGridType">
			    				<FormLabel ><Trans i18nKey="workgroups.add-license-modal.form.type.label">Type</Trans> *</FormLabel>
			    				<FormControl as="select" name="type" isInvalid={!(errors.type == null)} value={values.type} onChange={handleChange} >
			    				<option value="">{t("workgroups.add-license-modal.form.type.options.blank")}</option>
			    					{ WorkgroupLicenseConfig.Types.map(item =>
			    						<option key={item} value={item}>{t("workgroups.add-license-modal.form.type.options."+item)}</option>
			    					)}
			    				</FormControl>
			    				<FormControlErrors errors={errors.type} />
			    			</FormGroup>
							<FormGroup as={Col} md={7} controlId="formGridLicenseNumber">
			    				<FormLabel ><Trans i18nKey="workgroups.add-license-modal.form.licenseNumber.label">License Number</Trans> *</FormLabel>
			    				<FormControl className="text-uppercase" type={'text'} name="licenseNumber" isInvalid={!(errors.licenseNumber == null)} value={values.licenseNumber} onChange={handleChange} placeholder={t('workgroups.add-license-modal.form.licenseNumber.placeholder')} />
			    				<FormControlErrors errors={errors.licenseNumber} />
			    			</FormGroup>
						</Row>
						
						<Row>
							<FormGroup as={Col} controlId="formGridRemarks">
								<FormLabel><Trans i18nKey="workgroups.add-license-modal.form.remarks.label">Remarks</Trans></FormLabel>
								<FormControl className="text-uppercase" as="textarea" rows={3} maxLength={255} name="remarks" isInvalid={!(errors.remarks == null)} value={values.remarks} onChange={handleChange} placeholder={t('workgroups.add-license-modal.form.remarks.placeholder')} />
								<FormControlErrors errors={errors.remarks} />
							</FormGroup>
						</Row>
		
          				</Modal.Body>
          				<Modal.Footer>			      		
          				      <Button variant="success" type="submit" disabled={isSubmitting}> 
          				      		{isSubmitting ? <Trans i18nKey="workgroups.add-license-modal.form.submitting">Please wait...</Trans> : <Trans i18nKey="workgroups.add-license-modal.form.submit">Add</Trans>} 
          				      </Button>
          				      <Button variant="secondary" onClick={props.onHide}><Trans i18nKey="workgroups.add-license-modal.cancel">Cancel</Trans></Button>	
          				</Modal.Footer>
              		</Form>	

		      )}
		      </Formik>
		   </Modal>
	);
}

export default AddWorkgroupLicenseModal;
