import React, { useState, useEffect} from 'react';
//import PropTypes from 'prop-types';  
import { useLocation} from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCheckCircle, faPlusCircle} from '@fortawesome/free-solid-svg-icons'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import DismissableFeedback from "../components/DismissableFeedback";
import Loader from "../components/Loader";
import LaboratorySampleFormFields from "../components/LaboratorySampleFormFields";
import SubjectActivities from "../components/SubjectActivities";
import Prompt from "../components/Prompt";

import { useConfig } from "../services/use-config";
import API from "../services/backend-api";

import LaboratorySampleConfig from "../config/LaboratorySampleConfig";

import { compareJSON } from "../helpers/commons";
import { AuthError, ValidationError } from "../helpers/custom-errors";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const validationSchema = Yup.object().shape(LaboratorySampleConfig.GeneralFormValidationRules);

const SaveLaboratorySampleModal = props => {

	const [_error, _setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [laboratorySample, setLaboratorySample] = useState(null);
	
	const { t } = useTranslation();
	
	const location = useLocation();
	const config = useConfig();

	useEffect(() => {
		let isMounted = true; 
		
		if (props.item && props.item.id) {
			
			API.lookupLaboratorySample(props.item.id)
				.then(response => {
					setLaboratorySample(response);
				})
				.catch(error => { 
					log.error("Error Loading Laboratory Sample: ", error.message);
		 			props.onError(error);
				})
		 		.finally(() => {
		 			if (isMounted) {
		 				setIsLoading(false);
		 			}
				}); 
		}
		 
		return () => { isMounted = false };
		  
	}, []);

	
	if  (!props.item) {
		return null
	} else if (props.item.id) {
		if (isLoading) {
			return <Loader />
		} else if (!laboratorySample) {
			return null;
		}
	}	
			
	let initialValues = {}, action, actionIcon, origin;
	
	let defaultValues = validationSchema.default();
	
	//Define Modal Origin
	origin = (location.pathname === '/analysis/boxes/items') ? "storagebox-items" 
		: (location.pathname === '/analysis/collectionsamples') ? "collection-samples"
		: null;
	
	//if laboratorySample != null, then we have an update
	if (laboratorySample) {
		Object.keys(LaboratorySampleConfig.GeneralFormValidationRules)
			.forEach((key) => {
				initialValues[key] = (laboratorySample[key]) ? laboratorySample[key] : defaultValues[key];
			});
		
		action = "update";
		actionIcon = faCheckCircle
		
		//Check for consistency
		if (origin === "storagebox-items") {
				
			//initialValues.workgroupId = props.item.workgroupId;
			//initialValues.storageBoxId = props.item.storageBoxId;
			//initialValues.slotCol = props.item.slotCol;
			//initialValues.slotRow = props.item.slotRow;
				
				
		} else if (origin === "collection-samples") {
			//initialValues.collectionId = props.item.collectionId;
			//initialValues.materialSample = props.item.materialSample;
				
		}
	
	} else {	
		initialValues = defaultValues;
		action = "create";
		actionIcon = faPlusCircle
		
		//Override with values from props depending on Origin
		if (origin === "storagebox-items") {
			initialValues.workgroupId = props.item.workgroupId;
			initialValues.storageBoxId = props.item.storageBoxId;
			initialValues.slotCol = props.item.slotCol;
			initialValues.slotRow = props.item.slotRow;
				
		} else if (origin === "collection-samples") {
			
			initialValues.collectionId = props.item.collectionId;
			initialValues.materialSample = props.item.materialSample;
			initialValues.organismId = props.item.organismId;
			initialValues.scientificName = props.item.scientificName;
			initialValues.sex = props.item.sex;
			initialValues.county = props.item.county;
			initialValues.stateProvince = props.item.stateProvince;
			
			//By defaut, we clone organismId to labelId
			initialValues.labelId = props.item.organismId;
		}

	}
	
	const saveLaboratorySample = (values) =>
		new Promise((resolve, reject) => {
			if (values.id) {
				API.updateLaboratorySample(values.id, values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			} else {
				API.createLaboratorySample(values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			}
		});
	
	return (
		<Modal
		   	show={props.show}
		    onHide={props.onHide}
		    size={props.size}
		    aria-labelledby="contained-modal-save-laboratory-sample-modal"
		    centered
		    backdrop="static"
		    keyboard={false}
		    >
		    <Modal.Header closeButton>
  				<Modal.Title id="contained-modal-save-laboratory-sample-modal">
  					<Trans i18nKey="collectionsamples.save-laboratory-sample-modal.title">Save Laboratory Sample</Trans>
  				</Modal.Title>
  			</Modal.Header>
  			{(isLoading) ? (
				<Modal.Body className="mb-3"><Loader /></Modal.Body>
			) : (
  				<Formik
		      		initialValues={initialValues}
		      		validationSchema={validationSchema}
		      		validateOnChange={false}
		      		validateOnBlur={false}     
		      		onSubmit={(values, actions) => {
		      			//Clear Error
		      			_setError(null);

						saveLaboratorySample(values)
    						.then((response) => {
    							actions.resetForm({ values }) //Workaround to set dirty back to true
								actions.setSubmitting(false);
								props.onItemSaved(t('collectionsamples.save-laboratory-sample-modal.success'));
    						})
    						.catch(error => { 
    								
    							actions.setSubmitting(false);
								
								if (error instanceof AuthError) {		
		    	  					//auth.onUnathorized(error);
									props.onError(error);
		     					 } else if (error instanceof ValidationError) {		
          						
				 					log.info("Save Laboratory Sample Attempt Failed: ", error.message);
          						
				 					_setError(error.message);		
								
									if (error.detail) {            						
    	              					for (let key of Object.keys(error.detail)) {
    	              						let errorMsgs = error.detail[key];
    	              						errorMsgs.forEach((message) => {
    	              							actions.setFieldError(key, message);
    	              						});
    	              					}
    	              				}
          						
          	 					} else {
          							log.error("Error Saving Laboratory Sample: ", error.message);
          							_setError(t('error.api.general'));
          	 					}	
    								
    						})
    			   		}
      					      			
					}
		      	>
		      	{({isSubmitting, errors, values, handleChange, handleBlur, setFieldValue, setFieldError, dirty}) => (	
		      		
		      		<Form className="form-save-laboratory-sample-modal" noValidate>		
		      			<Modal.Body className="pt-2">
		  				
		  					<DismissableFeedback feedback={_error} onClose={() => _setError(null)} type="danger" />	  

  							<Prompt
		      					when={!!dirty && !isSubmitting}
		      					message={JSON.stringify({
		      		           			"title": t("form.unsaved-changes-modal.title"),
		      		           			"bodyText": t("form.unsaved-changes-modal.body"),
		      		          		 	"confirmText": t("form.unsaved-changes-modal.confirm"), 
		      		           			"cancelText": t("form.unsaved-changes-modal.cancel"),
		      		           			"size": "lg",
		      		           			"variant": "danger",
		        			   			"when": compareJSON(initialValues, values)
		      		          		})
		      		   			}
		      				/>

							<LaboratorySampleFormFields.GeneralSection
								values={values} 
								errors={errors} 
								onChange={handleChange} 
								onError={props.onError}
								setFieldValue={setFieldValue}
								setFieldError={setFieldError}
								action={action}
								origin={origin}
								isModal={true}
								i18nPrefix="collectionsamples.save-laboratory-sample-modal."
							/>
	
							{(action === "update") && (
		 						<SubjectActivities 
		 							subject="LABORATORY_SAMPLE" 
		 							subjectId={props.item.id} 
		 							onError={props.onError}
		 						/>
		 					)}
	
						
          				</Modal.Body>
          				<Modal.Footer>			 
          				      <Button variant="success" type="submit" disabled={isSubmitting}> 
          				      		{isSubmitting ? <Trans i18nKey="collectionsamples.save-laboratory-sample-modal.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={actionIcon} /> <Trans i18nKey={"collectionsamples.save-laboratory-sample-modal.form.submit-"+action}>Submit</Trans></span>} 
          				      </Button>
          				      <Button variant="secondary" onClick={props.onHide}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="collectionsamples.save-laboratory-sample-modal.cancel">Cancel</Trans></Button>	
          				</Modal.Footer>
              		</Form>	
				)}
		      </Formik>
			)}
		</Modal>
	);
}

export default SaveLaboratorySampleModal;
