/* eslint-disable no-undef */
import { AuthError, ValidationError, HttpError } from "../helpers/custom-errors";
import { isArrayWithLength } from "../helpers/commons";
//import log from 'loglevel';


const __BACKEND_API_PREFIX = "/app";

function appendPrefix(path) {
	return __BACKEND_API_PREFIX + path;
}

function sendHttpRequest(method, url, async, body) {
  
  let prefixedUrl = appendPrefix(url);

  //First check if fetch/async/await is supported
  if (window.fetch && async && isAsyncSupported()) {
	
	let fetchParams = {};
	fetchParams.method = method;
	fetchParams.accept = "application/json";
	fetchParams.body = body;

	return fetch(prefixedUrl, fetchParams)
   		.then(checkStatus);
  } else {

	
  //If fetch/await is not supported, we use XMLHttpRequest instead
  return new Promise(function(resolve, reject) {
    var xhr;
    var checkStatusXHR = function() {
      let error = new HttpError("GENERIC", xhr.status, `HTTP Error ${xhr.responseText}`);

	  try {

			let data;

			//if NOT an attachment, interpret response as json
        	if (xhr.getResponseHeader('Content-Disposition') && xhr.getResponseHeader('Content-Disposition').indexOf('attachment') > -1) {
				data = new Blob([xhr.response]);
        	} else {
				data = JSON.parse(xhr.responseText);
			}
		
			if (xhr.status >= 200 && xhr.status < 299) {
				resolve(data);
			} else if (xhr.status >= 400 && xhr.status < 499) { //Http Client Error
				//Check if code attribute has been properly set
				if (data.code) {
					//Check if AuthError
					if (data.code.indexOf("auth-") !== -1) {
						error = new AuthError(data.code, xhr.status, data.message);
					} else { 
						error = new ValidationError(data.code, xhr.status, data.detail, data.message);
					}
				} 
	    	} 

		} catch(err) {
			//Do nothing here. This block exists only to intercept json parse exception
			//Proper error handler should happen in block bellow
			
			log.error("backend-api.checkStatusXHR: " + err);
			
    	}

		reject(error);
	};

    xhr = new XMLHttpRequest;
    if (async) {
      xhr.onreadystatechange = function() {
        if (xhr.readyState !== 4) {
          return;
        }
        checkStatusXHR();
      };
    }
    xhr.open(method, prefixedUrl, async);
    xhr.send(body);
    if (!async) {
      checkStatusXHR();
    }
  });
}
}

async function checkStatus(response) {
	
	//Initially, we set default HttpError with generic error message
	let error = new HttpError("GENERIC", response.status, `HTTP Error ${response.statusText}`);
	
	// Then we to process/parse response body in json format
	// If it fails, a standard Error/Exception will be thrown and handled in the catch statement
	try {
		
		let data;

		//if NOT an attachment, interpret response as json
		
        if (response.headers.get('Content-Disposition') && response.headers.get('Content-Disposition').indexOf('attachment') > -1) {
			data = await response.blob();
        } else {
			data = await response.json();
		}

		//const jsonData = await response.json();
		
		if (response.ok) {
			return data;
		} else if (response.status >= 400 && response.status < 499) { //Http Client Error
			//Check if code attribute has been properly set
			if (data.code) {
				//Check if AuthError
				if (data.code.indexOf("auth-") !== -1) {
					error = new AuthError(data.code, response.status, data.message);
				} else { 
					error = new ValidationError(data.code, response.status, data.detail, data.message);
				}
			} 
	    } 

	} catch (err) {
		//Do nothing here. This block exists only to intercept json parse exception
		//Proper error handler should happen in block bellow
	}
	
	//Finally Error is throw to be handled in upper level
	throw error;
}




function objectToFormData(obj, blackList) {
	var fd = new FormData();
	var formKey;

	for(var property in obj) {
		if(obj.hasOwnProperty(property) && (!isArrayWithLength(blackList) || !blackList.includes(property))) {
			formKey = property;
			fd.append(formKey, obj[property]);
		}
	}

	return fd;    
};

function objectToQueryParameters(obj, blackList) {
	var queryString = "";
	var filterKey;

	for(var property in obj) {
		if(obj.hasOwnProperty(property) && (!isArrayWithLength(blackList) || !blackList.includes(property))) {
			filterKey = property;
			if (queryString) queryString = queryString.concat("&");
			else queryString = queryString.concat("?");
			queryString = queryString.concat(filterKey, '=', encodeURIComponent(obj[property]));
		}
	}

	return queryString;    
};

function getConfig() {
   /*return fetch(appendPrefix('/config'), {
	  accept: "application/json"
   })
   .then(checkStatus);*/

   //Force Syncronous Request
   return sendHttpRequest('GET', '/config', false);
}

function getUserAuthState() {
	  
	return fetch(appendPrefix('/auth'), {
		accept: "application/json"
	})
	.then(checkStatus);
}

function updateLanguage(data, csrfToken) {

	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/lang'), {
		method: 'post',
		body: form
	})
	.then(checkStatus);
}

function loginUser(data, csrfToken) {

	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);

	return fetch(appendPrefix('/login'), {
	    method: 'post',
		body: form
   })
   .then(checkStatus);
}

function logoutUser(csrfToken) {

	var form = new FormData();
	form.append("csrfToken", csrfToken);

	return fetch(appendPrefix('/logout'), {
	    method: 'post',
		body: form
   })
   .then(checkStatus);
}

/*function selectUserWorkgroup(data) {

	var form = objectToFormData(data);
	return fetch(appendPrefix('/workgroup'), {
		method: 'post',
		body: form
	})
	.then(checkStatus)
}*/

function changeUserPassword(data, csrfToken) {

	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	return fetch(appendPrefix('/pwd/change'), {
		method: 'PATCH',
		body: form
	})
	.then(checkStatus)
}

function sendUserPasswordResetEmail(data, csrfToken) {

	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);

	return fetch(appendPrefix('/pwd/recover'), {
	    method: 'post',
		body: form
   })
   .then(checkStatus);
}

function confirmUserPasswordReset(data, csrfToken) {

	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);

	return fetch(appendPrefix('/pwd/reset'), {
		method: 'PATCH',
		body: form
	})
   .then(checkStatus);
}

function verifyUserPasswordResetToken(data, csrfToken) {
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/pwd/token'), {
		method: 'post',
		body: form
	})
   .then(checkStatus);
}

function listWorkgroups(filter) {

	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix('/workgroups')+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function lookupWorkgroup(id) {

	return fetch(appendPrefix('/workgroups/'+id), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createWorkgroup(data, csrfToken) {
	
	var form = objectToFormData(data, ["types"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.types)) {
		data.types.forEach((item) => {
			form.append("types[]", item);
		});
	}
	
	return fetch(appendPrefix('/workgroups'), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function updateWorkgroup(id, data, csrfToken) {
	
	var form = objectToFormData(data, ["types"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.types)) {
		data.types.forEach((item) => {
			form.append("types[]", item);
		});
	}
	
	return fetch(appendPrefix('/workgroups/'+id), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function getWorkgroupUsers(id, isAdmin) {

	var path = (isAdmin ? '/workgroups/'+id+'/users/admin' : '/workgroups/'+id+'/users');
	return fetch(appendPrefix(path), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function getWorkgroupLicenses(id, isAdmin) {

	var path = (isAdmin ? '/workgroups/'+id+'/licenses/admin' : '/workgroups/'+id+'/licenses');
	return fetch(appendPrefix(path), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function addWorkgroupUser(id, data, csrfToken) {

	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/workgroups/'+id+'/users'), {
		method: 'PUT',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function addWorkgroupLicense(id, data, csrfToken) {

	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/workgroups/'+id+'/licenses'), {
		method: 'PUT',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function removeWorkgroupUser(id, data, csrfToken) {

	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/workgroups/'+id+'/users'), {
		method: 'DELETE',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function removeWorkgroupLicense(id, data, csrfToken) {

	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/workgroups/'+id+'/licenses'), {
		method: 'DELETE',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function findUsers(filter) {

	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix('/users/all')+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function listUsers(filter) {

	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix('/users')+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function lookupUser(id) {

	return fetch(appendPrefix('/users/'+id), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createUser(data, csrfToken) {
	
	var form = objectToFormData(data, ["roles", "permissions", "workgroups"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.roles)) {
		data.roles.forEach((item) => {
			form.append("roles[]", item);
		});
	}
	
	if (data && isArrayWithLength(data.permissions)) {
		data.permissions.forEach((item) => {
			form.append("permissions[]", item);
		});
	}
	
	return fetch(appendPrefix('/users'), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function updateUser(id, data, csrfToken) {
	
	var form = objectToFormData(data, ["roles", "permissions","workgroups"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.roles)) {
		data.roles.forEach((item) => {
			form.append("roles[]", item);
		});
	}
	
	if (data && isArrayWithLength(data.permissions)) {
		data.permissions.forEach((item) => {
			form.append("permissions[]", item);
		});
	}
	
	return fetch(appendPrefix('/users/'+id), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function updateUserPassword(id, data, csrfToken) {
		
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/users/'+id+'/pwd'), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function updateSelfPassword(data, csrfToken) {
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix('/users/self/pwd'), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function listCollections(filter, isAdmin) {
	
	var path = (isAdmin ? '/collections/admin' : '/collections');
	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function lookupCollection(id, isAdmin) {

	var path = (isAdmin ? '/collections/'+id+'/admin' : '/collections/'+id);
	return fetch(appendPrefix(path), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function lookupCollectionByOrganismId(id) {

	var path = '/collections/organism/'+id;
	return fetch(appendPrefix(path), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createCollection(data, isAdmin, csrfToken) {
	
	var path = (isAdmin ? '/collections/admin' : '/collections');
	
	var form = objectToFormData(data, ["samples", "samplesMediums", "photos"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.samples)) {
		data.samples.forEach((item) => {
			form.append("samples[]", item);
		});
	}
	
	if (data && isArrayWithLength(data.samplesMediums)) {
		data.samplesMediums.forEach((item) => {
			form.append("samplesMediums[]", item);
		});
	}
	
	if (data && isArrayWithLength(data.photos)) {
		data.photos.forEach((item, index) => {
			if (item) {
				if (item.uploadedImage)
					form.append("uploadedPhotos[]", item.uploadedImage);
			}
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function updateCollection(id, data, isAdmin, csrfToken) {
	
	var path = (isAdmin ? '/collections/'+id+'/admin' : '/collections/' + id);
	
	var form = objectToFormData(data, ["samples", "samplesMediums", "photos"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.samples)) {
		data.samples.forEach((item) => {
			form.append("samples[]", item);
		});
	}
	
	if (data && isArrayWithLength(data.samplesMediums)) {
		data.samplesMediums.forEach((item) => {
			form.append("samplesMediums[]", item);
		});
	}
	
	if (data && isArrayWithLength(data.photos)) {
		data.photos.forEach((item, index) => {
			if (item) {
				if (item.uploadedImage)
					form.append("uploadedPhotos[]", item.uploadedImage);
				else
					form.append("photos[]", JSON.stringify({id: item.id}));
			}
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'PATCH',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function findWorkgroups(filter, isAdmin) {

	var path = (isAdmin ? '/workgroups/all/admin' : '/workgroups/all');

	var query = objectToQueryParameters(filter, ["types"]);
	
	if (filter && filter.types && isArrayWithLength(filter.types)) {
		filter.types.forEach((item) => {

			if (query) query = query.concat("&");
			else query = query.concat("?");
			
			query = query.concat('types[]', '=', item);
		});
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findObservatories(filter) {

	var path = '/workgroups/observatories';
	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findLaboratories(filter) {

	var path = '/workgroups/laboratories';
	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findInstitutions(filter) {

	var path = '/institutions/all';
	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function pageLocations(filter) {

	var path = '/locations';
	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

/*function findLocations(filter) {

	var path = '/locations/all';
	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}*/

function findSpecies(filter) {

	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix('/collections/species')+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function getCollectionPhotoUrl(id, isAdmin, attachment) {

	var path = (isAdmin ? '/collections/photos/'+id+'/admin' : '/collections/photos/'+id);
	var query = (attachment) ? objectToQueryParameters({"a": true}) : "";
	
	return appendPrefix(path)+query;
}

function getExportCollectionsUrl(filter, isAdmin, attachment) {

	var path = (isAdmin ? '/collections/export/admin' : '/collections/export');
	var query = (attachment) ? objectToQueryParameters(Object.assign({"a": true}, filter)) : filter;
	
	return appendPrefix(path)+query;
}

function exportCollections(filter, isAdmin, attachment) {

	var path = (isAdmin ? '/collections/export/admin' : '/collections/export');
	var queryFilter = (attachment) ? Object.assign({"a": true}, filter) : filter;
	var query = objectToQueryParameters(queryFilter,["pageIndex","pageSize"]);
		
	return fetch(appendPrefix(path)+query, {
	    accept: "text/csv"
	 })
	.then(checkStatus)
	
}

function exportCollectionsSibbr(id, filter, isAdmin, attachment) {

	var path = (isAdmin ? '/collections/export/sibbr/'+id+'/admin' : '/collections/export/sibbr/'+id);
	var queryFilter = (attachment) ? Object.assign({"a": true}, filter) : filter;
	var query = objectToQueryParameters(queryFilter);
	
	return fetch(appendPrefix(path)+query, {
	    accept: "text/csv"
	 })
	.then(checkStatus)
	
}

function importCollections(data, isAdmin, csrfToken) {

	var path = (isAdmin ? '/collections/import/admin' : '/collections/import');
	
	var form = objectToFormData(data, ["data"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.data)) {
		data.data.forEach((item, index) => {
			if (item) {
				form.append("data[]", JSON.stringify(item));
			}
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
	
}

function deleteCollectionPhoto(id, isAdmin, csrfToken) {

	var path = (isAdmin ? '/collections/photos/'+id+'/admin' : '/collections/photos/'+id);

	var form = objectToFormData({"csrfToken": csrfToken});
	
	return fetch(appendPrefix(path), {
		method: 'DELETE',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
	
}

function getNextCollectionOrganismId(data, isAdmin, csrfToken) {
	
	var path = (isAdmin ? '/collections/organism/id/admin' : '/collections/organism/id');
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path), {
		method: 'post',
	    accept: "application/json",
	    body: form
	 })
	.then(checkStatus)
}

function findActivities(filter, isAdmin) {

	var path = (isAdmin ? '/activities/all/admin' : '/activities/all');
	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function listActivities(filter) {

	var path = '/activities';
	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findLaboratoryTags(id, filter, isAdmin) {

	var path = (isAdmin ? '/lab/'+id+'/tags/all/admin' : '/lab/'+id+'/tags/all');
	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createLaboratoryTag(data, isAdmin, csrfToken) {

    var path = (isAdmin ? '/lab/tags/admin' : '/lab/tags');
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path), {
		method: 'POST',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function updateLaboratoryTag(id, data, isAdmin, csrfToken) {

    var path = (isAdmin ? '/lab/tags/'+id+'/admin' : '/lab/tags/'+id);
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path), {
		method: 'PATCH',
		accept: "application/json",
		body: form
		
	 })
	.then(checkStatus)
}

function deleteLaboratoryTag(id, isAdmin, csrfToken) {

    var path = (isAdmin ? '/lab/tags/'+id+'/admin' : '/lab/tags/'+id);
	var form = objectToFormData({"csrfToken": csrfToken});
	
	return fetch(appendPrefix(path), {
		method: 'DELETE',
		accept: "application/json",
		body: form
		
	 })
	.then(checkStatus)
}

function lookupStorageBox(id) {

	var path = '/lab/boxes/'+id;
	return fetch(appendPrefix(path), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function pageStorageBoxes(id, filter) {

	var path = '/lab/'+id+'/boxes';
	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findStorageBoxes(id, filter) {

	var path = '/lab/'+id+'/boxes/all';
	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createStorageBox(data, isAdmin, csrfToken) {

    var path = (isAdmin ? '/lab/boxes/admin' : '/lab/boxes');
	
	var form = objectToFormData(data, ["tags"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.tags)) {
		data.tags.forEach((item) => {
			form.append("tags[]", JSON.stringify(item));
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'POST',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function updateStorageBox(id, data, isAdmin, csrfToken) {

    var path = (isAdmin ? '/lab/boxes/'+id+'/admin' : '/lab/boxes/'+id);
	
	var form = objectToFormData(data, ["tags"]);
	form.append("csrfToken", csrfToken);
	
	if (data && isArrayWithLength(data.tags)) {
		data.tags.forEach((item) => {
			form.append("tags[]", JSON.stringify(item));
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'PATCH',
		accept: "application/json",
		body: form
		
	 })
	.then(checkStatus)
}

function getStorageBoxItems(id) {

	var path = '/lab/boxes/'+id+'/items';
	return fetch(appendPrefix(path), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function pageCollectionSamples(filter) {
	
	var path = '/collections/samples';
	var query = objectToQueryParameters(filter, ["materialSamples"]);
	
	if (filter && filter.materialSamples && isArrayWithLength(filter.materialSamples)) {
		filter.materialSamples.forEach((item) => {

			if (query) query = query.concat("&");
			else query = query.concat("?");
			
			query = query.concat('materialSamples[]', '=', item);
		});
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function exportCollectionSamples(filter, attachment) {
	
	var path = '/collections/samples/export';
	
	var queryFilter = (attachment) ? Object.assign({"a": true}, filter): filter;
	var query = objectToQueryParameters(queryFilter, ["pageIndex","pageSize","materialSamples"]);
	
	if (filter && filter.materialSamples && isArrayWithLength(filter.materialSamples)) {
		filter.materialSamples.forEach((item) => {

			if (query) query = query.concat("&");
			else query = query.concat("?");
			
			query = query.concat('materialSamples[]', '=', item);
		});
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "text/csv"
	 })
	.then(checkStatus)
}


function lookupLaboratorySample(id) {

	var path = '/lab/samples/'+id;
	return fetch(appendPrefix(path), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function pageLaboratorySamples(id, filter) {

	var path = '/lab/'+id+'/samples';
	var query = objectToQueryParameters(filter);
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createLaboratorySample(data, csrfToken) {

    var path = '/lab/samples';
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path), {
		method: 'POST',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function updateLaboratorySample(id, data, csrfToken) {

    var path = '/lab/samples/'+id;
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path), {
		method: 'PATCH',
		accept: "application/json",
		body: form
		
	 })
	.then(checkStatus)
}

function saveMultipleLaboratorySamples(data, csrfToken) {

    var path = '/lab/samples/m';
	
	var form = new FormData();
	form.append("csrfToken", csrfToken);
	
	if (isArrayWithLength(data)) {
		data.forEach((item, index) => {
			if (item) {
				form.append("data[]", JSON.stringify(item));
			}
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'POST',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function getStorageBoxItemTypePath(itemType) {
	let path = (itemType === "LABORATORY_SAMPLE") ? "samples" :
			   (itemType === "NUCLEIC_ACID_EXTRACTION") ? "extractions" :
			   (itemType === "TISSUE_DISSOCIATION") ? "dissociations" :
			   (itemType === "CDNA_SYNTHESIS") ? "syntheses" :
			   (itemType === "LABORATORY_TEST") ? "tests" :
				"unknown";
				
	return path;
}

function removeStorageBoxItem(boxId, itemType, itemId, csrfToken) {

	//let typePath = getStorageBoxItemTypePath(itemType);

	//let path = '/lab/boxes/'+ boxId +'/'+typePath+'/'+ itemId;
	let path = '/lab/boxes/'+ boxId +'/items';
	
	var query = objectToQueryParameters({id: itemId, type: itemType});
	
	let form = new FormData();
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path)+query, {
		method: 'DELETE',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}
		

function updateStorageBoxItem(boxId, data, csrfToken) {

	//let typePath = getStorageBoxItemTypePath(itemType);
	//let path = '/lab/boxes/'+ boxId +'/'+typePath+'/'+ itemId;
	
	let path = '/lab/boxes/'+ boxId +'/items';
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path), {
		method: 'PATCH',
		accept: "application/json",
		body: form
		
	 })
	.then(checkStatus)
}

function pageLaboratoryExtractions(filter) {
	
	var path = '/lab/extractions';
	var query = objectToQueryParameters(filter, ["materialSamples"]);
	
	if (filter && filter.materialSamples && isArrayWithLength(filter.materialSamples)) {
		filter.materialSamples.forEach((item) => {

			if (query) query = query.concat("&");
			else query = query.concat("?");
			
			query = query.concat('materialSamples[]', '=', item);
		});
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}


function findLaboratoryExtractionsByCollectionSample(filter) {
	
	var path = '/lab/samples/extractions/all';
	var query = objectToQueryParameters(filter);
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function lookupLaboratoryExtraction(id) {

	var path = '/lab/extractions/'+id;
	return fetch(appendPrefix(path), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createLaboratoryExtraction(data, csrfToken) {

    var path = '/lab/extractions';
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path), {
		method: 'POST',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function updateLaboratoryExtraction(id, data, csrfToken) {

    var path = '/lab/extractions/'+id;
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path), {
		method: 'PATCH',
		accept: "application/json",
		body: form
		
	 })
	.then(checkStatus)
}

function deleteLaboratoryExtraction(id, isAdmin, csrfToken) {

    var path = (isAdmin ? '/lab/extractions/'+id+'/admin' : '/lab/extractions/'+id);
	var form = objectToFormData({"csrfToken": csrfToken});
	
	return fetch(appendPrefix(path), {
		method: 'DELETE',
		accept: "application/json",
		body: form
		
	 })
	.then(checkStatus)
}

function saveMultipleLaboratoryExtractions(data, csrfToken) {

    var path = '/lab/extractions/m';
	
	var form = new FormData();
	form.append("csrfToken", csrfToken);
	
	if (isArrayWithLength(data)) {
		data.forEach((item, index) => {
			if (item) {
				form.append("data[]", JSON.stringify(item));
			}
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'POST',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function deleteMultipleLaboratoryExtractions(data, isAdmin, csrfToken) {

	var path = (isAdmin ? '/lab/extractions/m/admin' : '/lab/extractions/m');
	
	var form = new FormData();
	form.append("csrfToken", csrfToken);
	
	if (isArrayWithLength(data)) {
		data.forEach((item, index) => {
			if (item) {
				form.append("data[]", JSON.stringify(item));
			}
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'DELETE',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function pageLaboratoryDissociations(filter) {
	
	var path = '/lab/dissociations';
	var query = objectToQueryParameters(filter, ["materialSamples"]);
	
	if (filter && filter.materialSamples && isArrayWithLength(filter.materialSamples)) {
		filter.materialSamples.forEach((item) => {

			if (query) query = query.concat("&");
			else query = query.concat("?");
			
			query = query.concat('materialSamples[]', '=', item);
		});
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findLaboratoryDissociationsByCollectionSample(filter) {
	
	var path = '/lab/samples/dissociations/all';
	var query = objectToQueryParameters(filter);
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function lookupLaboratoryDissociation(id) {

	var path = '/lab/dissociations/'+id;
	return fetch(appendPrefix(path), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createLaboratoryDissociation(data, csrfToken) {

    var path = '/lab/dissociations';
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path), {
		method: 'POST',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function updateLaboratoryDissociation(id, data, csrfToken) {

    var path = '/lab/dissociations/'+id;
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path), {
		method: 'PATCH',
		accept: "application/json",
		body: form
		
	 })
	.then(checkStatus)
}

function deleteLaboratoryDissociation(id, isAdmin, csrfToken) {

    var path = (isAdmin ? '/lab/dissociations/'+id+'/admin' : '/lab/dissociations/'+id);
	var form = objectToFormData({"csrfToken": csrfToken});
	
	return fetch(appendPrefix(path), {
		method: 'DELETE',
		accept: "application/json",
		body: form
		
	 })
	.then(checkStatus)
}

function saveMultipleLaboratoryDissociations(data, csrfToken) {

    var path = '/lab/dissociations/m';
	
	var form = new FormData();
	form.append("csrfToken", csrfToken);
	
	if (isArrayWithLength(data)) {
		data.forEach((item, index) => {
			if (item) {
				form.append("data[]", JSON.stringify(item));
			}
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'POST',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function deleteMultipleLaboratoryDissociations(data, isAdmin, csrfToken) {

	var path = (isAdmin ? '/lab/dissociations/m/admin' : '/lab/dissociations/m');
	
	var form = new FormData();
	form.append("csrfToken", csrfToken);
	
	if (isArrayWithLength(data)) {
		data.forEach((item, index) => {
			if (item) {
				form.append("data[]", JSON.stringify(item));
			}
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'DELETE',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function findLaboratorySynthesesByCollectionSample(filter) {
	
	var path = '/lab/samples/syntheses/all';
	var query = objectToQueryParameters(filter);
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function pageLaboratorySyntheses(filter) {
	
	var path = '/lab/syntheses';
	var query = objectToQueryParameters(filter, ["materialSamples"]);
	
	if (filter && filter.materialSamples && isArrayWithLength(filter.materialSamples)) {
		filter.materialSamples.forEach((item) => {

			if (query) query = query.concat("&");
			else query = query.concat("?");
			
			query = query.concat('materialSamples[]', '=', item);
		});
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function lookupLaboratorySynthesis(id) {

	var path = '/lab/syntheses/'+id;
	return fetch(appendPrefix(path), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createLaboratorySynthesis(data, csrfToken) {

    var path = '/lab/syntheses';
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path), {
		method: 'POST',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function updateLaboratorySynthesis(id, data, csrfToken) {

    var path = '/lab/syntheses/'+id;
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path), {
		method: 'PATCH',
		accept: "application/json",
		body: form
		
	 })
	.then(checkStatus)
}

function deleteLaboratorySynthesis(id, isAdmin, csrfToken) {

    var path = (isAdmin ? '/lab/syntheses/'+id+'/admin' : '/lab/syntheses/'+id);
	var form = objectToFormData({"csrfToken": csrfToken});
	
	return fetch(appendPrefix(path), {
		method: 'DELETE',
		accept: "application/json",
		body: form
		
	 })
	.then(checkStatus)
}

function saveMultipleLaboratorySyntheses(data, csrfToken) {

    var path = '/lab/syntheses/m';
	
	var form = new FormData();
	form.append("csrfToken", csrfToken);
	
	if (isArrayWithLength(data)) {
		data.forEach((item, index) => {
			if (item) {
				form.append("data[]", JSON.stringify(item));
			}
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'POST',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function deleteMultipleLaboratorySyntheses(data, isAdmin, csrfToken) {

	var path = (isAdmin ? '/lab/syntheses/m/admin' : '/lab/syntheses/m');
	
	var form = new FormData();
	form.append("csrfToken", csrfToken);
	
	if (isArrayWithLength(data)) {
		data.forEach((item, index) => {
			if (item) {
				form.append("data[]", JSON.stringify(item));
			}
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'DELETE',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function findLaboratoryTestsByCollectionSample(filter) {
	
	var path = '/lab/samples/tests/all';
	var query = objectToQueryParameters(filter);
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findLaboratoryTests(filter) {
	
	var path = '/lab/tests/all';
	var query = objectToQueryParameters(filter,["pageIndex","pageSize"]);
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function lookupLaboratoryTest(id) {

	var path = '/lab/tests/'+id;
	return fetch(appendPrefix(path), {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function createLaboratoryTest(data, csrfToken) {

    var path = '/lab/tests';
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path), {
		method: 'POST',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function updateLaboratoryTest(id, data, csrfToken) {

    var path = '/lab/tests/'+id;
	
	var form = objectToFormData(data);
	form.append("csrfToken", csrfToken);
	
	return fetch(appendPrefix(path), {
		method: 'PATCH',
		accept: "application/json",
		body: form
		
	 })
	.then(checkStatus)
}

function deleteLaboratoryTest(id, isAdmin, csrfToken) {

    var path = (isAdmin ? '/lab/tests/'+id+'/admin' : '/lab/tests/'+id);
	var form = objectToFormData({"csrfToken": csrfToken});
	
	return fetch(appendPrefix(path), {
		method: 'DELETE',
		accept: "application/json",
		body: form
		
	 })
	.then(checkStatus)
}

function findTargetViruses(filter) {
	
	var path = '/lab/tests/viruses/all';
	var query = objectToQueryParameters(filter);
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function findLaboratoryTestProtocols(filter) {
	
	var path = '/lab/tests/protocols/all';
	var query = objectToQueryParameters(filter);
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function pageLaboratoryTests(filter) {
	
	var path = '/lab/tests';
	var query = objectToQueryParameters(filter, ["materialSamples"]);
	
	if (filter && filter.materialSamples && isArrayWithLength(filter.materialSamples)) {
		filter.materialSamples.forEach((item) => {

			if (query) query = query.concat("&");
			else query = query.concat("?");
			
			query = query.concat('materialSamples[]', '=', item);
		});
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "application/json"
	 })
	.then(checkStatus)
}

function saveMultipleLaboratoryTests(data, csrfToken) {

    var path = '/lab/tests/m';
	
	var form = new FormData();
	form.append("csrfToken", csrfToken);
	
	if (isArrayWithLength(data)) {
		data.forEach((item, index) => {
			if (item) {
				form.append("data[]", JSON.stringify(item));
			}
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'POST',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function deleteMultipleLaboratoryTests(data, isAdmin, csrfToken) {

	var path = (isAdmin ? '/lab/tests/m/admin' : '/lab/tests/m');
	
	var form = new FormData();
	form.append("csrfToken", csrfToken);
	
	if (isArrayWithLength(data)) {
		data.forEach((item, index) => {
			if (item) {
				form.append("data[]", JSON.stringify(item));
			}
		});
	}
	
	return fetch(appendPrefix(path), {
		method: 'DELETE',
		accept: "application/json",
		body: form
	 })
	.then(checkStatus)
}

function exportLaboratoryTests(filter, attachment) {
	
	var path = '/lab/tests/export';
	
	var queryFilter = (attachment) ? Object.assign({"a": true}, filter): filter;
	var query = objectToQueryParameters(queryFilter, ["materialSamples"]);
	
	if (filter && filter.materialSamples && isArrayWithLength(filter.materialSamples)) {
		filter.materialSamples.forEach((item) => {

			if (query) query = query.concat("&");
			else query = query.concat("?");
			
			query = query.concat('materialSamples[]', '=', item);
		});
	}
	
	return fetch(appendPrefix(path)+query, {
	    accept: "text/csv"
	 })
	.then(checkStatus)
}

const API = { getConfig, updateLanguage, getUserAuthState, loginUser, logoutUser, changeUserPassword, /*selectUserWorkgroup, */
		sendUserPasswordResetEmail, confirmUserPasswordReset, verifyUserPasswordResetToken, listWorkgroups, listUsers,
		lookupWorkgroup, createWorkgroup, updateWorkgroup, getWorkgroupUsers, addWorkgroupUser, removeWorkgroupUser, 
		getWorkgroupLicenses, addWorkgroupLicense, removeWorkgroupLicense, 
		findUsers, lookupUser, createUser, updateUser, updateUserPassword, updateSelfPassword, listCollections, findWorkgroups, findObservatories, findLaboratories, findInstitutions, pageLocations, 
		createCollection, updateCollection, lookupCollection, lookupCollectionByOrganismId, findSpecies, getCollectionPhotoUrl, deleteCollectionPhoto, getNextCollectionOrganismId, 
		getExportCollectionsUrl, exportCollections, exportCollectionsSibbr, importCollections, findActivities, listActivities,
		findLaboratoryTags, createLaboratoryTag, updateLaboratoryTag, deleteLaboratoryTag,
		lookupStorageBox, pageStorageBoxes, findStorageBoxes, createStorageBox, updateStorageBox, 
		getStorageBoxItems, removeStorageBoxItem, updateStorageBoxItem, 
		pageCollectionSamples, exportCollectionSamples, 
		lookupLaboratorySample, pageLaboratorySamples, createLaboratorySample, updateLaboratorySample, saveMultipleLaboratorySamples,
		findLaboratoryExtractionsByCollectionSample, findLaboratoryDissociationsByCollectionSample, findLaboratorySynthesesByCollectionSample, findLaboratoryTestsByCollectionSample, 
		pageLaboratoryExtractions, lookupLaboratoryExtraction, createLaboratoryExtraction, updateLaboratoryExtraction, deleteLaboratoryExtraction, 
		saveMultipleLaboratoryExtractions, deleteMultipleLaboratoryExtractions,
		pageLaboratoryDissociations, lookupLaboratoryDissociation, createLaboratoryDissociation, updateLaboratoryDissociation, deleteLaboratoryDissociation,
		saveMultipleLaboratoryDissociations, deleteMultipleLaboratoryDissociations,
		pageLaboratorySyntheses, lookupLaboratorySynthesis, createLaboratorySynthesis, updateLaboratorySynthesis, deleteLaboratorySynthesis,
		saveMultipleLaboratorySyntheses, deleteMultipleLaboratorySyntheses,
		findLaboratoryTests, lookupLaboratoryTest, createLaboratoryTest, updateLaboratoryTest, deleteLaboratoryTest,
		findTargetViruses, findLaboratoryTestProtocols,
		pageLaboratoryTests, exportLaboratoryTests,
		saveMultipleLaboratoryTests, deleteMultipleLaboratoryTests
	};
	
export default API;
