import React, { useState, useEffect, Fragment } from 'react';
import { useLocation, useOutletContext  } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import Container from 'react-bootstrap/Container'

import PagedList from "../components/PagedList";

import NucleicAcidExtractionsFilterForm from "../components/NucleicAcidExtractionsFilterForm";
import Loader from "../components/Loader";
import SaveNucleicAcidExtractionModal from "../components/SaveNucleicAcidExtractionModal";
import SaveMultipleNucleicAcidExtractionsModal from "../components/SaveMultipleNucleicAcidExtractionsModal";
import SaveCDNASynthesisModal from "../components/SaveCDNASynthesisModal";
import SaveLaboratoryTestModal from "../components/SaveLaboratoryTestModal";
import DisplayCollectionSampleModal from "../components/DisplayCollectionSampleModal";
import ConfirmationDialogModal from "../components/ConfirmationDialogModal";
import SaveMultipleCDNASynthesesModal from "../components/SaveMultipleCDNASynthesesModal";
import SaveMultipleLaboratoryTestsModal from "../components/SaveMultipleLaboratoryTestsModal";

import Filler from "../components/Filler";

import NucleicAcidExtractionConfig from "../config/NucleicAcidExtractionConfig";
import { numToColumn } from "../helpers/commons";

import API from "../services/backend-api";
import { useAuth } from "../services/use-auth";
import { useConfig } from "../services/use-config";

import * as Yup from "yup";

const validationSchema = Yup.object().shape(NucleicAcidExtractionConfig.FilterFormValidationRules);

const dataTableActionModals = [
	  {
        key: 'action-0',
        component: SaveNucleicAcidExtractionModal,
		size: 'lg',
		multiple : false
      },
		{
        key: 'action-1',
        component: Filler,
		size: 'lg',
		multiple : false
      },
	  {
        key: 'action-2',
        component: SaveCDNASynthesisModal,
		size: 'lg',
		multiple : false
      },
	  {
        key: 'action-3',
        component: SaveLaboratoryTestModal,
		size: 'lg',
		multiple : false
      },
	  {
        key: 'action-4',
        component: DisplayCollectionSampleModal,
		size: 'lg',
		multiple : false
      },
	  {
        key: 'multiple-action-0',
        component: SaveMultipleNucleicAcidExtractionsModal,
		size: 'lg',
		multiple : true
      },
	  {
        key: 'multiple-action-1',
        component: Filler,
		size: 'lg',
		multiple : true
      },
	  {
        key: 'multiple-action-2',
        component: SaveMultipleCDNASynthesesModal,
		size: 'lg',
		multiple : true
      },
      {
        key: 'multiple-action-3',
        component: SaveMultipleLaboratoryTestsModal,
		size: 'lg',
		multiple : true
      },
    ];


const NucleicAcidExtractions = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [pagedNucleicAcidExtractions, setPagedNucleicAcidExtractions] = useState(null);
	const [selectedDataTableItem, setSelectedDataTableItem] = useState(null);
	const [selectedDataTableItems, setSelectedDataTableItems] = useState(null);
	const [dataTableActionModalsShowMap, setDataTableActionModalsShowMap] = useState(new Map());
	
	const location = useLocation();
	let context = useOutletContext();
	
	const { t } = useTranslation();
	const auth = useAuth();
	const config = useConfig();
	
	
	let defaultValues = validationSchema.default();
	
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
			
		//Initializing Action Modals Show Status
		resetModalsVisibility();
			
		listNucleicAcidExtractions({})
			.catch(error => { 
		 		log.error("Error Loading Initial Nucleic Acid Extractions Page: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		 
		 return () => { isMounted = false };
		
	}, []);
	

  const resetModalsVisibility = () => {
	let tempMap = new Map();
	dataTableActionModals.forEach((item, index) => {
		tempMap.set(item.key, false);
	});
	setDataTableActionModalsShowMap(tempMap);
  }
  
  const saveNucleicAcidExtraction = (item, action) => {
	  setSelectedDataTableItem(item);
	  showActionModal("action-0")
  }

  const addNucleicAcidExtraction = () => {
	  let item = {};
	  setSelectedDataTableItem(item);
	  showActionModal("action-0")
  }

  const deleteNucleicAcidExtraction = (extraction) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		//setDeleteExtractionModalShow(false);
		
		API.deleteLaboratoryExtraction(extraction.id, auth.isAdmin(), config.csrfToken).then(response => {
			context.onSuccess(response.success)
			refreshPage();
		}).catch(error => { 	
			log.error("Error Removing Extraction: ", error.message);
			context.onError(error);		
		}).finally(() => {
			handleActionModalHide();
		});
  }

  const deleteMultipleNucleicAcidExtractions = (extractions) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		let listOfExtractionsId = extractions.map(e => Object.assign({}, {"id": e.id}));
		
		API.deleteMultipleLaboratoryExtractions(listOfExtractionsId, auth.isAdmin(), config.csrfToken).then(response => {
			//context.onSuccess(response.success)
			context.onSuccess(t('laboratoryextractions.delete-multiple-extractions-confirmation-modal.success'));
			refreshPage();
		}).catch(error => { 	
			log.error("Error Removing Extraction: ", error.message);
			context.onError(error);		
		}).finally(() => {
			handleActionModalHide();
		});
  }

  const confirmNucleicAcidExtractionDeletion = (item, action) => {
	  setSelectedDataTableItem(item);
	  showActionModal(action)
  }

   const handleItemsSaved = (success) => {
	  handleActionModalHide();
	  refreshPage();
	  context.onSuccess(success);
   }

  const saveMultipleNucleicAcidExtractions = (items, action) => {
	  setSelectedDataTableItems(items);
	  showActionModal(action)
  }

  const saveMultipleCDNASyntheses = (items, action) => {
	  setSelectedDataTableItems(items);
	  showActionModal(action)
  }

  const saveMultipleLaboratoryTests = (items, action) => {
	  setSelectedDataTableItems(items);
	  showActionModal(action)
  }

  const confirmMultipleNucleicAcidExtractionsDeletion = (items, action) => {
	  setSelectedDataTableItems(items);
	  showActionModal(action)
  }

  const displayCollectionSample = (item, action) => {
	  setSelectedDataTableItem(item);
	  showActionModal(action)
  }

  const createLaboratorySynthesis = (item, action) => {
	
	  let val= {};
	  val.collectionId = item.collectionId;
	  val.materialSample = item.materialSample;
	  val.organismId = item.organismId;
	  val.workgroupId = item.workgroupId;

	  setSelectedDataTableItem(val);
	  showActionModal(action)
  }

  const createLaboratoryTest = (item, action) => {
	  let val= {};
	  val.collectionId = item.collectionId;
	  val.materialSample = item.materialSample;
	  val.organismId = item.organismId;
	  val.workgroupId = item.workgroupId;

	  setSelectedDataTableItem(val);
	  showActionModal(action)
  }

    
  const updatePage = (values) => {
	  listNucleicAcidExtractions(values)
		.catch(error => { 
			log.error("Error Paginating Extractions List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const sortNucleicAcidExtractions = (values) => {
	  values.pageIndex = 0;
	  listNucleicAcidExtractions(values)
		.catch(error => { 
			log.error("Error Sorting Extractions List: ", error.message);
	 		context.onError(error);
		})
		
  }

  const displayRanOut = (item) => { 
	return (item.laboratorySampleRanOut) ? "inactive-row": "";
  }
  
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  
		  queryParams.pageIndex = currentPage.pageIndex;
		  queryParams.pageSize = currentPage.pageSize;
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;

		  for(let property in NucleicAcidExtractionConfig.FilterFormValidationRules) {
				if(currentPage.hasOwnProperty(property)) {
					queryParams[property] = (currentPage[property]) ? currentPage[property] : defaultValues[property];
				}
		   }
	  }
	  
	  if (newValues) {
		  for(let property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
  const refreshPage = () => {
		  
	  //List Nucleic Acid Extractions with no new values
	  listNucleicAcidExtractions({})
		.catch(error => { 
	 		log.error("Error Refreshing Nucleic Acid Extractions List: ", error.message);
	 		context.onError(error);
		})	
  }  

  const filterNucleicAcidExtractions = (values) => {
	  values.pageIndex = 0;
	  return listNucleicAcidExtractions(values)
		.catch(error => { 
			log.error("Error Filtering Nucleic Acid Extractions List: ", error.message);
	 		context.onError(error);
		})
		
  }

 const exportNucleicAcidExtractions = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.exportNucleicAcidExtractions(mergeQueryParams(pagedNucleicAcidExtractions, values), true).then(response => {
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});

  const listNucleicAcidExtractions = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.pageLaboratoryExtractions(mergeQueryParams(pagedNucleicAcidExtractions, values)).then(response => {
			setPagedNucleicAcidExtractions(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
	const handleActionModalHide = () => {
	  resetModalsVisibility();
	  setSelectedDataTableItem(null); 
      setSelectedDataTableItems(null);
	  
    }

	const showActionModal = (action) => { 
	  let tempMap = dataTableActionModalsShowMap;
	  tempMap.set(action, true);
	  setDataTableActionModalsShowMap(tempMap);
   }

	if (isLoading) 
		return <Loader />
	
	if (!pagedNucleicAcidExtractions) 
		return null;
		
	const buildLabInfo = (s) => {
		
		return (s.laboratory) ? 
			s.laboratory 
				+ ((s.storageBoxLabelId) 
					? " | " + s.storageBoxLabelId
						+ ((s.slotCol && s.slotRow) 
							? " [" + s.slotRow + "-" + numToColumn(s.slotCol)+"]"
							: "")
					: "")
			: "";
	}
			
	return (
		<Container >
			{ dataTableActionModals.map((item, index) => {
								
				const { 
		    		component: Component, 
		    		...rest 
		    	} = item;
			
				return (
					<Fragment key={item.key}>
					{ (dataTableActionModalsShowMap.get(item.key)) ?
						<Fragment>
							{(item.multiple && selectedDataTableItems) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="laboratory-extractions"
									items={selectedDataTableItems}
									onError={context.onError}
									onItemsSaved={handleItemsSaved}
								/>
								: 
								(!item.multiple && selectedDataTableItem) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="laboratory-extractions"
									item={selectedDataTableItem}
									onError={context.onError}
									onItemSaved={handleItemsSaved}
								/>
								:
								null
							}
						</Fragment>
						:
						null
					}
					</Fragment>
	      		)
    		})}

			{(selectedDataTableItem) && <ConfirmationDialogModal
				item={selectedDataTableItem}
				show={dataTableActionModalsShowMap.get("action-1")}
        		onHide={handleActionModalHide}
				size="lg"
				title={t("laboratoryextractions.delete-extraction-confirmation-modal.title")}
				bodyText={t("laboratoryextractions.delete-extraction-confirmation-modal.body", {item: selectedDataTableItem})}
				confirmText={t("laboratoryextractions.delete-extraction-confirmation-modal.confirm")}
				cancelText={t("laboratoryextractions.delete-extraction-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteNucleicAcidExtraction}
			/>}
			
			{(selectedDataTableItems) && <ConfirmationDialogModal
				item={selectedDataTableItems}
				show={dataTableActionModalsShowMap.get("multiple-action-1")}
        		onHide={handleActionModalHide}
				size="lg"
				title={t("laboratoryextractions.delete-multiple-extractions-confirmation-modal.title")}
				bodyText={t("laboratoryextractions.delete-multiple-extractions-confirmation-modal.body", {count: selectedDataTableItems.length})}
				confirmText={t("laboratoryextractions.delete-multiple-extractions-confirmation-modal.confirm")}
				cancelText={t("laboratoryextractions.delete-multiple-extractions-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteMultipleNucleicAcidExtractions}
			/>}
			
			<PagedList
				i18nPrefix="laboratoryextractions.list."
				page={pagedNucleicAcidExtractions}
				list={pagedNucleicAcidExtractions.list.map((s) => Object.assign({}, s, {labInfo: buildLabInfo(s)}))}
				pageSizeOptions={[10,25,50,100]}
				filterFormComponent={NucleicAcidExtractionsFilterForm}
				onFilter={filterNucleicAcidExtractions} 
				onError={context.onError}
				onSort={sortNucleicAcidExtractions}
				onAdd={addNucleicAcidExtraction}
				onPage={updatePage}
				//onExport={exportNucleicAcidExtractions}
				dataTableColumns={["labelId", "materialSample", "dateExtracted", "labInfo"]}
				dataTableSortableColumns={["labelId","dateExtracted"]}
				dataTableCustomDisplayColumns={[["dateExtracted", "LocalDate"],["materialSample", "Enum"]]}
				dataTableActions={[saveNucleicAcidExtraction, confirmNucleicAcidExtractionDeletion, createLaboratorySynthesis, createLaboratoryTest, displayCollectionSample]}
				dataTableDefaultAction={saveNucleicAcidExtraction}
				dataTableMultipleSelectActions={[saveMultipleNucleicAcidExtractions, confirmMultipleNucleicAcidExtractionsDeletion, saveMultipleCDNASyntheses, saveMultipleLaboratoryTests]}
				dataTableCustomRowStyle={displayRanOut}
				dataTableHandleTextOverflow={true}
			/>
				
      </Container>
	
	);
}

export default NucleicAcidExtractions;
