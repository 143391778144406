import React,  { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation, Route, Navigate, useOutletContext} from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimesCircle, faCheckCircle, faUsers, faIdBadge } from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { Formik, Form} from 'formik';
import * as Yup from "yup";
import { setLocale } from 'yup';

import Loader from "../components/Loader";
import WorkgroupFormFields from "../components/WorkgroupFormFields";
import SubjectActivities from "../components/SubjectActivities";
import Prompt from "../components/Prompt";

import API from "../services/backend-api";
import { useConfig } from "../services/use-config";

import WorkgroupConfig from "../config/WorkgroupConfig";

import { ValidationError } from "../helpers/custom-errors";

import { compareJSON } from "../helpers/commons";

setLocale({
	   mixed: {
		  default: 'form.validation.mixed.default',
		  required: 'form.validation.mixed.required',
	   }
	 });

const validationSchema = Yup.object().shape(WorkgroupConfig.FormValidationRules);

const SaveWorkgroup = props => {
	
	const [isLoading, setIsLoading] = useState(true);
	const [workgroup, setWorkgroup] = useState(null);
	
	let navigate = useNavigate();
	
	const location = useLocation();
	
	let context = useOutletContext();
	
	const { t } = useTranslation();
	
	const config = useConfig()
	
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
		
		if (location.pathname === '/workgroups/update' && location.state && location.state.item) {
			API.lookupWorkgroup(location.state.item.id)
			.then(response => {
					setWorkgroup(response);
			})
			.catch(error => { 
				log.error("Error Loading Workgroup: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
		}
		 
		 return () => { isMounted = false };
		  
	}, [location.state]);
	
	if (location.pathname === '/workgroups/update') {
		if (!location.state.item) {
			return (<Navigate  to="/workgroups" />)
		} else if (isLoading) {
			return <Loader />
		} else if (!workgroup) {
			return null;
		}
	}
	
	let initialValues = {}, action, actionIcon;
	
	let defaultValues = validationSchema.default();
	
	if (location.pathname === '/workgroups/update') {
		Object.keys(WorkgroupConfig.FormValidationRules)
			.forEach((key) => {
				initialValues[key] = (workgroup[key]) ? workgroup[key] : defaultValues[key];
			});
		
		action = "update";
		actionIcon = faCheckCircle
	} else {
		
		initialValues = defaultValues;
		action = "create";
		actionIcon = faPlusCircle
	}
	
	const saveWorkgroup = (values) =>
		new Promise((resolve, reject) => {
			if (location.pathname === '/workgroups/update') {
				API.updateWorkgroup(location.state.item.id, values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			} else {
				API.createWorkgroup(values, config.csrfToken).then(response => {
					resolve(response);
				}).catch(error => reject(error));
			}
		});
		
	return (
		<Container>
			<Row className="mb-3">
        		<Col>
        			<h3>
        				<Trans i18nKey={"workgroups.save.header-"+action}>Save Workgroup</Trans> 
        			</h3>
        		</Col>
        	</Row>
        	<Row className="mb-3">
    			<Col >	      
    				<Formik
    					initialValues={initialValues}
    					validationSchema={validationSchema}
    					validateOnChange={false}
    					validateOnBlur={false}     
    					onSubmit={(values, actions) => {
    						//Clear Error/Success
    						context.onError(null);
    						context.onSuccess(null);
					
    						saveWorkgroup(values)
    							.then((response) => {
    								actions.resetForm({ values }) //Workaround to set dirty back to true
    								navigate("/workgroups", {state: { success: t("workgroups.save.success")}});
    							})
    							.catch(error => { 
    								
    								actions.setSubmitting(false);
    								
    								if (error instanceof ValidationError) {		
    									
    									log.info("Save Workgroup Attempt Failed: ", error.message);

    									if (error.detail) {            						
    	              						for (let key of Object.keys(error.detail)) {
    	              							let errorMsgs = error.detail[key];
    	              							errorMsgs.forEach((message) => {
    	              								actions.setFieldError(key, message);
    	              							});
    	              						}
    	              					}
    								} else {
    									log.error("Error Saving Workgroup: ", error.message);
    								}
    								
    								context.onError(error);
    							})
					}}
		      	>
		      	{({isSubmitting, dirty, errors, values, handleChange, setFieldValue}) => (	
		      	<Form className="form-workgroup" noValidate>
		      		<Prompt
		      			when={!!dirty && !isSubmitting}
		      			message={JSON.stringify({
		      		           "title": t("form.unsaved-changes-modal.title"),
		      		           "bodyText": t("form.unsaved-changes-modal.body"),
		      		           "confirmText": t("form.unsaved-changes-modal.confirm"), 
		      		           "cancelText": t("form.unsaved-changes-modal.cancel"),
		      		           "size": "lg",
		      		           "variant": "danger",
		      		           
		        			   "when": compareJSON(initialValues, values)
		      		          }
		      		      )
		      		   }
		      		/>

						<WorkgroupFormFields 
							values={values} 
							errors={errors} 
							onChange={handleChange} 
							onError={context.onError}
							setFieldValue={setFieldValue}
							action={action}
						/>
				    	
				    	
				    	{/*
				    	<Row>
				    		<FormGroup as={Col} md={7} xl={8} controlId="formGridName">
				    			<FormLabel ><Trans i18nKey="workgroups.save.form.name.label">Name</Trans> *</FormLabel>
				    			<FormControl className="text-uppercase" type={'text'} name="name" isInvalid={!(errors.name == null)} value={values.name} onChange={handleChange} placeholder={t('workgroups.save.form.name.placeholder')} />
				    			<FormControlErrors errors={errors.name} />
				    		</FormGroup>
				    		<FormGroup as={Col} md={5} xl={4} controlId="formGridHeadquarters">
				    			<FormLabel ><Trans i18nKey="workgroups.save.form.headquarters.label">Headquarters</Trans> *</FormLabel>
				    			<FormControl className="text-uppercase" type={'text'} name="headquarters" isInvalid={!(errors.headquarters == null)} value={values.headquarters} onChange={handleChange} placeholder={t('workgroups.save.form.headquarters.placeholder')} />
				    			<FormControlErrors errors={errors.headquarters} />
				    		</FormGroup>
				        </Row>
				        
				        <Row>
			    			<FormGroup as={Col} controlId="formGridShortcode">
			    				<FormLabel ><Trans i18nKey="workgroups.save.form.shortcode.label">Shortcode</Trans> *</FormLabel>
			    				<FormControl className="text-uppercase" as={InputMask} mask='aa-9' type={'text'} name="shortcode" isInvalid={!(errors.shortcode == null)} value={values.shortcode} onChange={handleChange} placeholder={t('workgroups.save.form.shortcode.placeholder')} />
			    				<FormControlErrors errors={errors.shortcode} />
			    				</FormGroup>
			    			<FormGroup as={Col} controlId="formGridType">
			    				<FormLabel ><Trans i18nKey="workgroups.save.form.type.label">Type</Trans> *</FormLabel>
			    				<FormControl as="select" name="type" isInvalid={!(errors.type == null)} value={values.type} onChange={handleChange} >
			    					<option value="">{t("workgroups.save.form.type.options.blank")}</option>
			    					{ WorkgroupConfig.Types.map(item =>
			    					      <option key={item} value={item}>{t("workgroups.save.form.type.options."+item)}</option>
			    					)}
			    			    </FormControl>
			    				<FormControlErrors errors={errors.type} />
			    			</FormGroup>
			    				
			    			<FormGroup as={Col} controlId="formGridRegion">
		    					<FormLabel ><Trans i18nKey="workgroups.save.form.region.label">Region</Trans> *</FormLabel>
		    					<FormControl as="select" name="region" isInvalid={!(errors.region == null)} value={values.region} onChange={handleChange} >
		    						<option value="">{t("workgroups.save.form.region.options.blank")}</option>
		    						{ WorkgroupConfig.Regions.map(item =>
		    					      <option key={item} value={item}>{t("workgroups.save.form.region.options."+item)}</option>
		    						)}
		    					</FormControl>
		    					<FormControlErrors errors={errors.region} />
		    				</FormGroup>
			    		</Row>
		    			
			    		{(location.pathname === '/workgroups/update') && 
			    			<Row>    					   
	    						<FormGroup as={Col} controlId="formGridActive">
	    							<FormLabel ><Trans i18nKey="workgroups.save.form.active.label">Active</Trans></FormLabel>
	    							<Switch 
	    								name="active"
	    								value={values.active}
	    					    		checked={JSON.parse(values.active)}
	    								onChange={handleChange}
	    							/>
	    						</FormGroup>
	    					</Row>
			    		}
			    		
			    		*/}
			    		
	    			    <Row className="mt-3">    					   
    						<Col>
    							<Button variant="success" type="submit" disabled={isSubmitting}> 
    								{isSubmitting ? <Trans i18nKey="workgroups.save.form.submitting">Please wait...</Trans> : <span><FontAwesomeIcon icon={actionIcon} /> <Trans i18nKey={"workgroups.save.form.submit-"+action}>Submit</Trans></span>} 
    							</Button>
    							
    							{(location.pathname === '/workgroups/update') ?
    									<Button variant="primary" className="ml-3" onClick={() => navigate("/workgroups/users", { state: { "item": location.state.item}})} ><FontAwesomeIcon icon={faUsers} /> <Trans i18nKey="workgroups.save.users">Users</Trans></Button> 
    									: null
    							}
    							
    							{(location.pathname === '/workgroups/update') ?
    									<Button variant="warning" className="text-white ml-3" onClick={() => navigate("/workgroups/licenses", { state: { "item": location.state.item}})} ><FontAwesomeIcon icon={faIdBadge} /> <Trans i18nKey="workgroups.save.licenses">Licenses</Trans></Button> 
    									: null
    							}
    						    
    							<Button variant="secondary" className="float-right" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faTimesCircle} /> <Trans i18nKey="workgroups.save.cancel">Cancel</Trans></Button>
    					   </Col>
    			    </Row>   
	    			    
		      	</Form>	
		      )}
		      </Formik>
		      
		      </Col>
	        </Row>
			{(location.pathname === '/workgroups/update') && (
				<SubjectActivities 
		 			subject="WORKGROUP" 
		 			subjectId={location.state.item.id} 
		 			onError={context.onError}
		 		/>
			)}
	     </Container>
	);
}

export default SaveWorkgroup;
