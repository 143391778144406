import React, {useEffect} from "react";

import 'bs-stepper/dist/css/bs-stepper.min.css';

const Header = props => {
	
	return (
	    <div className={"bs-stepper-header"+(props.className ? " "+props.className : "")}>
	        {props.children}
	    </div>
	);
};

const HeaderItem = props => {
	return (
		<div className={"step"+(props.className ? " "+props.className : "")} data-target={props.target} >
        	<button className="step-trigger">
        		<span className={"bs-stepper-circle"+(props.isInvalid ? " bg-danger" : "")}>{props.number}</span>
        		<span className={"bs-stepper-label"+(props.isInvalid ? " text-danger" : "")}>{props.label}</span>
        	</button>
        </div>
	);
};

const HeaderLine = props => {
	return (
		<div className="line"></div>
	);
};


const ContentItem = props => {
	
	return (
		<div id={props.id} className={"content"+(props.className ? " "+props.className : "")}>
			{props.children}
        </div>
	);
};

const Content = props => {	
	return (
	    <div className={"bs-stepper-content"+(props.className ? " "+props.className : "")}>
	    	{props.children}
	    </div>
	);
};



const Container = props => {
	
	useEffect(() => {
		let isMounted = true; 
		
		if (isMounted)
			props.onMounted()
		
		return () => { isMounted = false };
		  
	}, []);
	
	
	return (
		<div id={props.id} className={"bs-stepper"+(props.className ? " "+props.className : "")} >
			{props.children}
		</div>
	)
}
	
const CustomStepper = { Container, Header, HeaderItem, HeaderLine, Content, ContentItem };

export default CustomStepper;