import React, { Fragment } from 'react';
import { geolocated } from "react-geolocated";
import { Trans } from 'react-i18next';
import Alert from 'react-bootstrap/Alert';

import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'

import { formatCoordinateDegrees } from "../helpers/commons";

const GeoLocation = props => {
	
	if ((!props.isGeolocationAvailable)||(!props.isGeolocationEnabled)) {		
		return (
			<Alert variant={"danger"}>
				{(!props.isGeolocationAvailable) ? (
					<Trans i18nKey={props.i18nPrefix+"geo-location.error.browser.unsupported"} >Your browser does not support Geolocation</Trans> 
				) : (
					<Trans i18nKey={props.i18nPrefix+"geo-location.error.browser.not-enabled"} >Geolocation is not enabled</Trans> 
				)}
				<Button className="ml-3" size="sm" variant="danger" onClick={() => { props.onRetry() }}>
					<Trans i18nKey={props.i18nPrefix+"geo-location.retry"} >Try again</Trans>		
				</Button>
			</Alert>
		);
	} 
		
	
	return (
		<Alert variant={"success"}>
			{ (props.coords) ? (
				<Fragment>
					<Trans i18nKey={props.i18nPrefix+"geo-location.success"} values={{ latitude : formatCoordinateDegrees(props.coords.latitude, false), longitude : formatCoordinateDegrees(props.coords.longitude, true) }}>You are at {formatCoordinateDegrees(props.coords.latitude, false) + ", " + formatCoordinateDegrees(props.coords.longitude, true)}</Trans>
					<Button className="ml-3" size="sm" variant="success" onClick={() => { props.onConfirmCoordinates(props.coords.latitude, props.coords.longitude)}}>
						<Trans i18nKey={props.i18nPrefix+"geo-location.confirm"} >Confirm?</Trans>		
					</Button>
				</Fragment>
			 ) : (
				<Fragment>
					 <Trans i18nKey={props.i18nPrefix+"geo-location.waiting"} >Getting the location data...</Trans> <Spinner animation="border" size="sm"/>
				</Fragment>
			 )}
		</Alert>
	)
};

export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 10000,
})(GeoLocation);